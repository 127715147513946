<form [formGroup]="form" (ngSubmit)="submit()">
  <fieldset [disabled]="loading">
    <!-- old Password -->
    <app-form-control *ngIf="mode === 'user'" [control]="oldPassword" type="password" prefix="password-reset.old-password"></app-form-control>

    <!-- Password -->
    <div class="form-group">
      <label for="password">{{ 'password-reset.password' | translate }}</label>
      <input type="password" [formControl]="password" class="form-control" id="password"
        placeholder="{{ 'password-reset.password-placeholder' | translate }}"
        [class.is-invalid]="password.invalid && password.touched" [popover]="passwordTemplate" placement="right"
        triggers="focus">
      <span [ngClass]="'score-' + passwordStrength.score" class="password-strength-indicator">
        <span></span><span></span><span></span><span></span>
      </span>
      <ng-template #passwordTemplate>
        <strong *ngIf="passwordStrength.score < 3"
          class="mb-1">{{ 'password-reset.password-help' |  translate }}</strong>
        <p *ngIf="passwordStrength.score >= 3" class="text-success mb-1">
          <i class="fa fa-check-circle-o"></i>
          {{ 'password-reset.password-help-good' |  translate }}
        </p>
        <p class="text-danger mb-0">{{passwordStrength.feedback.warning}}</p>
        <p class="mb-0" *ngFor="let s of passwordStrength.feedback.suggestions">{{s}}.</p>
      </ng-template>
    </div>

    <!-- Repeat Password -->
    <div class="form-group">
      <label class="" for="password2">{{ 'password-reset.password2' | translate }}</label>
      <input type="password" [formControl]="password2" class="form-control" id="password2"
        placeholder="{{ 'password-reset.password2-placeholder' | translate }}"
        [class.is-invalid]="form.errors?.notSame && password2.touched">
      <ng-container *ngIf="form.errors && password2.touched">
        <small class="form-text text-danger"
          *ngIf="form.errors?.notSame">{{ 'password-reset.password-no-match' | translate }}</small>
      </ng-container>
    </div>

    <!-- Submit -->
    <button type="submit" class="btn btn-block btn-primary">{{ 'password-reset.button' | translate }}</button>
  </fieldset>

  <app-overlay-spinner *ngIf="loading"></app-overlay-spinner>

</form>