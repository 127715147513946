import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../pages-authenticated/admin/admin.service";
import {Organization} from "../../../model/organization";
import {
  DataSharingGroupsAdvancedModalComponent
} from "../data-sharing-groups-advanced-modal/data-sharing-groups-advanced-modal.component";

@Component({
  selector: 'app-data-sharing-groups-modal',
  templateUrl: './data-sharing-groups-modal.component.html',
  styleUrls: ['./data-sharing-groups-modal.component.scss']
})
export class DataSharingGroupsModalComponent implements OnInit {
  prefix = 'data-sharing-groups.create-modal.';
  form: FormGroup
  organizations: any = []
  loading = true
  scrollLoading = false
  page = 0
  lastPage = 0
  modalResult
  name

  constructor(
    public modal: BsModalRef,
    private as: AdminService,
  ) {

  }

  async ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(this.name || '', [Validators.required, Validators.maxLength(150)]),
      search: new FormControl('', [Validators.maxLength(40)])
    })
    try {
      const organizationsPage = await this.as.getOrganizationsWithUsersPage('', 0)
      this.lastPage = organizationsPage.lastPage
      this.organizations = organizationsPage.organizations.map((o: Organization) => {
        o.selected = false
        return o
      })
      this.organizations = this.organizations.filter((o: Organization) => o.users.length > 0)
      this.loading = false
    } catch (e) {
      this.loading = false
      console.log(e)
    }

  }

  async onScroll() {
    this.page += 1
    if (this.page === this.lastPage) return
    this.scrollLoading = true
    try {
      const res = await this.as.getOrganizationsWithUsersPage('', this.page)
      const orgs = res.organizations.filter((o: Organization) => o.users.length > 0)
      this.organizations = this.organizations.concat(orgs.map((o: Organization) => {
        o.selected = false
        return o
      }))
      this.scrollLoading = false
    } catch (e) {
      this.scrollLoading = false
      console.log("error", e)
    }
  }

  async doSearch() {
    const q = this.form.get('search').value


    try {
      this.loading = true
      const organizationsPage = await this.as.getOrganizationsWithUsersPage(q, 0)
      this.lastPage = organizationsPage.lastPage
      this.organizations = organizationsPage.organizations.map((o: Organization) => {
        o.selected = false
        return o
      })
      this.organizations = this.organizations.filter((o: Organization) => o.users.length > 0)
      this.loading = false
    } catch (e) {
      this.loading = false
      console.log(e)
    }
  }

  onNext() {
    if (this.validateNextBtn()) return
    this.modalResult = {
      name: this.form.get('name').value,
      organizations: this.organizations.filter((o: Organization) => o.selected)
    }
    this.modal.hide()
  }

  validateNextBtn() {
    return (this.organizations.filter((o: Organization) => o.selected).length < 2 || this.form.invalid)
  }
}
