import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-confirm-profile-changes-modal',
  templateUrl: './confirm-profile-changes-modal.component.html',
  styleUrls: ['./confirm-profile-changes-modal.component.scss']
})
export class ConfirmProfileChangesModalComponent implements OnInit {
  prefix = 'confirm-profile-modal.'
  constructor(
    public modal: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

}
