import {Component, OnInit} from '@angular/core'

import {Subscriptions} from '../../../../model/subscriptions'
import {User} from '../../../../model/user'
import {NavService} from '../../../services/nav.service'
import {UserService} from '../../../services/user.service'

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss', './introduction.media.scss']
})
export class IntroductionComponent implements OnInit {
  prefix = 'intro.'

  private _subs = new Subscriptions()

  user: User
  steps = [
    {
      icon: 'assets/img/landing-icon-3.png',
      name: "product-lines",
      r_link: '/products',
    },
    {
      icon: 'assets/img/landing-icon-1.webp',
      name: "register",
      r_link: '/devices',
    },
    {
      icon: 'assets/img/landing-icon-2.webp',
      name: "training",
      r_link: '/training',
    },
    {
      icon: 'assets/img/landing-icon-5.png',
      name: "transfer-data",
      r_link: '/transfer-data',
    },
    {
      icon: 'assets/img/landing-icon-4.png',
      name: "monitor",
      r_link: '/dashboard',
    },
  ]

  constructor(private us: UserService, public nav: NavService) {
  }

  ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe(u => this.user = u))
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

}
