import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FoldoutContentDirective } from './foldout-content.directive';
import { FoldoutToggleDirective } from './foldout-toggle.directive';
import { FoldoutComponent } from './foldout/foldout.component';



@NgModule({
  declarations: [
    FoldoutComponent, 
    FoldoutToggleDirective, 
    FoldoutContentDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FoldoutComponent, 
    FoldoutToggleDirective, 
    FoldoutContentDirective,
  ]
})
export class FoldoutModule { }
