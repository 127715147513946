import {Injectable} from '@angular/core'

import {ApiService} from '../../services/api.service'
import {User, UsersPage, Membership} from '../../../model/user'
import {UserSearchCriteria} from '../../../model/search-criteria'
import {toObject} from '../../../model/helpers'
import {Organization, OrganizationPage} from '../../../model/organization'
import {Device} from '../../../model/device'
import {ProductLine} from '../../../model/Products'
import {DataSharingGroupPageResponse} from "../../../model/dataSharingGroup";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private api: ApiService) {
  }

  async getPendingUsers() {
    const result = await this.api.get("admin/pending-users")
    const users = []
    for (let u of result.users) {
      users.push(User.deserialize(u))
    }

    return users
  }

  //todo Change to permissions
  async setMemberships(u: User, memberships: Membership[]) {
    const result = await this.api.put('admin/memberships', {uuid: u.uuid, memberships})
    return User.deserialize(result.user)
  }

  async updateUser(u: Partial<User>): Promise<User> {
    const result = await this.api.put('admin/user', u)
    return User.deserialize(result.user)
  }

  async resetPassword(data: { uuid: string, password: string }) {
    await this.api.put('admin/password', data)
  }

  async approveUser(user: User) {
    await this.api.post('admin/approve-user', {uuid: user.uuid})
  }

  async purgeUser(user: User, force = false) {
    const param = force ? `?force=1` : ''
    await this.api.delete(`admin/user/${user.uuid}${param}`)
  }

  async getUsers(s: UserSearchCriteria): Promise<UsersPage> {
    const result = await this.api.get(`admin/user${s.toQueryParams()}`)
    console.log('result', result)
    result.users = result.users.map(u => User.deserialize(u))
    result.criteria = toObject<UserSearchCriteria>(UserSearchCriteria, result.criteria)

    return result
  }

  async updateLastMaintained(d, id): Promise<Device> {
    const res = await this.api.put('admin/last-maintained', {date: d, dId: id})
    return res.device
  }

  async confirmEmail(u: User) {
    await this.api.post("admin/confirm-email", {uuid: u.uuid})
  }

  async resendConfirmEmail(u: User) {
    await this.api.post("admin/resend-confirm-email", {uuid: u.uuid})
  }

  async getDashboardOrganizations() {
    return await this.api.get('admin/get-dashboard-organizations')
  }

  async getHierarchiesPage(q: string, page = 0, limit = true) {
    const res = await this.api.get(`admin/hierarchies-page?page=${page}&q=${encodeURIComponent(q)}&limit=${limit}`)
    return res.hierarchies
  }

  async getHierarchyOrgsList() {
    const res = await this.api.get('admin/hierarchy-orgs-list')
    return res.orgs
  }

  async createHierarchy(data) {
    const res = await this.api.post('admin/create-hierarchy', data)
    return res.hierarchy
  }

  async addOrgsToHierarchy(hierarchyId, orgs) {
    const res = await this.api.put('admin/add-orgs', {hierarchyId, orgs})
    return res.orgs
  }

  async getHierarchyOrgs(id) {
    const res = await this.api.get(`admin/hierarchy-orgs?hierarchyId=${id}`)
    return res.orgs
  }

  async removeOrgFromHierarchy(id) {
    const res = await this.api.put('admin/remove-org-from-hierarchy', {id})
    return res
  }

  async renameHierarchy(id, name) {
    const res = await this.api.put('admin/rename-hierarchy', {id, name})
    return res.hierarchy
  }

  async deleteHierarchy(id) {
    await this.api.put('admin/delete-hierarchy', {id})
  }

  async getHierarchyOrgsByStatus(id, status) {
    const res = await this.api.get(`admin/hierarchy-organizations-status?id=${id}&status=${status}`)
    res.organizations = res.organizations.map(o => {
      if (o.users.length) {
        o.users = o.users.map(u => User.deserialize(u))
      }
      return o
    })

    return res.organizations
  }

  async updateOrgPermission(id, permission) {
    return await this.api.put('admin/update-organization-permission', {id, permission})
  }

  async getHierarchyById(id) {
    return await this.api.get(`admin/hierarchy-by-id?id=${id}`)
  }

  async setPermissions(user, permissions, roles) {
    const result = await this.api.put('admin/permissions', {uuid: user.uuid, permissions, roles})
    return User.deserialize(result.user)
  }

  async updateUsersPermissions(users) {
    return await this.api.put('admin/users-permissions', {users})
  }

  async emailApprove(uuid) {
    await this.api.post(`admin/email-approve-user`, {uuid})
  }

  async customEmail(data) {
    await this.api.post(`admin/custom-email`, data)
  }

  async getOrgProductLines(orgId: number) {
    return await this.api.get(`admin/productLines?orgId=${orgId}`)
  }

  async getUsersByOrganizationId(orgId: number) {
    const result = await this.api.get(`admin/usersByOrganizationId?orgId=${orgId}`)
    return result.data.map(u => User.deserialize(u))
  }

  async updateProductCreatedBy(data) {
    return await this.api.put(`admin/update-product-created-by`, {...data})
  }

  async updateDeviceCreatedBy(data) {
    return await this.api.put(`admin/update-device-created-by`, {...data})
  }

  async getDevicesByOrgId(orgId: string) {
    return await this.api.get(`admin/getDevicesByOrgId?orgId=${orgId}`)
  }

  async getOrganizationsWithUsersPage(q: string, page: number) {
    return await this.api.get(`admin/organizations-with-users-page?page=${page}&q=${q}`);
  }

  async getAllOrganizations(): Promise<Organization[]> {
    const res =  await this.api.get(`admin/all-organizations`);
    return res.organizations
  }

  async createDataSharingGroup(data): Promise<Organization[]> {
    return await this.api.post(`admin/data-sharing-group`, {data});
  }

  async updateDataSharingGroup(data): Promise<Organization[]> {
    return await this.api.put(`admin/data-sharing-group`, {data});
  }

  async getDataSharingGroupsPage(page: number, q: string): Promise<DataSharingGroupPageResponse> {
    return await this.api.get(`admin/data-sharing-groups?page=${page}&q=${q}`);
  }

  async deleteDataSharingGroup(id: number): Promise<any> {
    return await this.api.delete(`admin/data-sharing-group/?id=${id}`);
  }
}
