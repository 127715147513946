<div class="modal-container">
  <header class="header">
    <p class="header__title">{{prefix + 'title' | translate}}</p>

    <app-buttons
      class="header__close"
      type="invisible-icon"
      (onClick)="modal.hide()"
      width="auto"
      height="auto"
    >
      <img src="/assets/icons/cross.svg">
    </app-buttons>
  </header>

  <main class="main">
    <p class="main__question">{{prefix + 'question' | translate}}</p>

    <ul class="main__users-list">
      <li class="main__users-list__user" *ngFor="let u of users">
        <p class="main__users-list__user__text">{{u.firstName + ' ' + u.lastName}} <span> - {{u.email}}</span></p>
      </li>
    </ul>
  </main>

  <footer class="footer">
    <app-buttons
      class="main__controls__next-btn"
      type="outline-dark-green"
      width="110px"
      height="40px"
      fontSize="16px"
      (onClick)="modal.hide()"
      [text]="prefix + 'close-btn' | translate"
    ></app-buttons>
    <app-buttons
      class="main__controls__next-btn"
      type="dark-green"
      width="110px"
      height="40px"
      fontSize="16px"
      (onClick)="share()"
      [text]="prefix + 'share-btn' | translate"
    ></app-buttons>
  </footer>
</div>
