<div class="modal-body organizations-container">
  <p class="subtitle m-0">{{prefix + 'child-subtitle' | translate}}</p>

  <div class="organizations">
    <div *ngFor="let o of organizations; let idx = index"
         class="row organization" [ngClass]="{selected: o.selected}">
      <div class="col px-4">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" [value]="o.id" [id]="o.id"
                 (change)="onCheckboxChanged($event, idx)">
          <label class="text-dark custom-control-label" [for]="o.id">{{o.name}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="next-btn" (click)="save()">{{prefix + 'save-button' | translate}}</button>
</div>
