<div class="py-4 mx-4 container-fluid px-5">
  <div class="header">
    <h1 class="mb-3">{{prefix + 'title' | translate }}</h1>
    <p class="sub-title">{{ prefix + 'sub-title' | translate }}</p>
  </div>

  <div class="notifications">
    <div *ngIf="notifications.length; else noData">
      <div *ngFor="let notification of notifications" [class.clickable]="notification.routerLink" class="container p-0"
           [routerLink]="notification.routerLink"
           [queryParams]="notification.queryParams">
        <div class="notification"
             [ngStyle]="{'border-bottom': true ? '1px solid #E0E0E0' : 'none'}">
          <div class="icon">
            <svg *ngIf="notification.notificationType ==='test-kit'" width="24" height="24" viewBox="0 0 24 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#F3F3F3"/>
              <path
                d="M16.84 17.4633L13.688 10.9497C13.6221 10.8136 13.5873 10.6618 13.5873 10.5106V5.41176C13.5873 5.18436 13.4029 5 13.1755 5H10.4887C10.2613 5 10.0769 5.18436 10.0769 5.41176V10.5106C10.0769 10.6618 10.0421 10.8136 9.97625 10.9497L6.82426 17.4633C6.65387 17.7904 6.66548 18.1736 6.85585 18.4902C7.04773 18.8094 7.38461 18.9999 7.75701 18.9999H15.9072C16.2796 18.9999 16.6165 18.8094 16.8084 18.4903C16.9988 18.1736 17.0104 17.7904 16.84 17.4633ZM10.7175 11.3084C10.8372 11.0612 10.9004 10.7853 10.9004 10.5106V5.82353H12.7638V10.5106C12.7638 10.7853 12.827 11.0612 12.9467 11.3084L13.6867 12.8377H9.97751L10.7175 11.3084ZM16.1026 18.0659C16.0826 18.0991 16.0236 18.1764 15.9072 18.1764H7.75701C7.64065 18.1764 7.58163 18.0991 7.56167 18.0659C7.54168 18.0327 7.50117 17.9445 7.5556 17.842C7.55802 17.8375 7.56035 17.8328 7.56257 17.8282L9.57901 13.6612H14.0852L16.1017 17.8282C16.1039 17.8328 16.1062 17.8375 16.1086 17.842C16.1631 17.9445 16.1225 18.0327 16.1026 18.0659Z"
                fill="#828282"/>
              <path
                d="M12.9464 15.584C12.719 15.584 12.5346 15.7683 12.5346 15.9957V16.0072C12.5346 16.2346 12.719 16.419 12.9464 16.419C13.1738 16.419 13.3581 16.2346 13.3581 16.0072V15.9957C13.3581 15.7683 13.1738 15.584 12.9464 15.584Z"
                fill="#828282"/>
              <path
                d="M10.7204 16.1992C10.493 16.1992 10.3087 16.3836 10.3087 16.611V16.6224C10.3087 16.8498 10.493 17.0342 10.7204 17.0342C10.9478 17.0342 11.1322 16.8498 11.1322 16.6224V16.611C11.1322 16.3836 10.9478 16.1992 10.7204 16.1992Z"
                fill="#828282"/>
              <path d="M18 5.5V2H20V5.5H18Z" fill="white"/>
              <path d="M18 6V8H20V6H18Z" fill="white"/>
              <path
                d="M19 0C16.2501 0 14 2.25011 14 5C14 7.74989 16.2501 10 19 10C21.7499 10 24 7.74989 24 5C24 2.25011 21.7499 0 19 0ZM19.5769 7.5H18.4231V6.53846H19.5769V7.5ZM19.5769 5.38462H18.4231V2.30769H19.5769V5.38462Z"
                fill="#FBAC07"/>
            </svg>

            <svg *ngIf="notification.notificationType === 'device-control'" width="24" height="24" viewBox="0 0 24 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#F3F3F3"/>
              <path
                d="M5 11.5005C5 15.9005 5.1 16.0005 8.3 16.1005C11 16.1005 11.2 16.3005 9.5 17.0005L7.5 17.9005H9.5C10.6 18.0005 13.2 18.3005 15.3 18.6005C18.9 19.2005 19 19.2005 19 16.3005C19 14.6005 18.7 11.8005 18.4 10.1005C17.8 7.00046 17.7 7.00046 11.4 7.00046H5V11.5005ZM17 9.50046C17 10.3005 16.3 11.0005 15.5 11.0005C14.7 11.0005 14 11.9005 14 13.0005C14 14.7005 13.3 15.0005 10 15.0005C6.1 15.0005 6 14.9005 6 11.5005V8.00046H11.5C15.7 8.00046 17 8.30046 17 9.50046ZM18 15.0005C18 17.0005 17.5 18.0005 16.5 18.0005C15.5 18.0005 15 17.0005 15 15.0005C15 13.0005 15.5 12.0005 16.5 12.0005C17.5 12.0005 18 13.0005 18 15.0005Z"
                fill="#828282"/>
              <path d="M18 5.5V2H20V5.5H18Z" fill="white"/>
              <path d="M18 6V8H20V6H18Z" fill="white"/>
              <path
                d="M19 0C16.2501 0 14 2.25011 14 5C14 7.74989 16.2501 10 19 10C21.7499 10 24 7.74989 24 5C24 2.25011 21.7499 0 19 0ZM19.5769 7.5H18.4231V6.53846H19.5769V7.5ZM19.5769 5.38462H18.4231V2.30769H19.5769V5.38462Z"
                fill="#FF0101"/>
            </svg>

            <svg *ngIf="notification.notificationType === 'training'" width="24" height="24" viewBox="0 0 24 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#F3F3F3"/>
              <path
                d="M19.5311 8.0125H17.9915V5.46877C17.9915 5.3063 17.9074 5.15543 17.7692 5.07002C17.6311 4.98462 17.4585 4.97687 17.3132 5.04949L11.9999 7.70613L6.6867 5.04949C6.54139 4.97684 6.36886 4.98459 6.23064 5.07002C6.09245 5.15543 6.00833 5.3063 6.00833 5.46877V8.0125H4.46875C4.20987 8.0125 4 8.22238 4 8.48125V18.0208C4 18.2796 4.20987 18.4895 4.46875 18.4895H19.5311C19.79 18.4895 19.9999 18.2796 19.9999 18.0208V8.48125C19.9999 8.22238 19.79 8.0125 19.5311 8.0125ZM4.93749 17.552V8.94999H6.00833V15.2593C6.00833 15.4369 6.10864 15.5992 6.26745 15.6786L10.0143 17.552H4.93749ZM11.5312 17.2623L6.94582 14.9696V6.2272L11.5312 8.5199V17.2623ZM12.4687 8.5199L17.0541 6.2272V14.9696L12.4687 17.2623V8.5199ZM19.0624 17.552H13.9856L17.7324 15.6786C17.8912 15.5992 17.9915 15.4369 17.9915 15.2593V8.94999H19.0624V17.552Z"
                fill="#828282"/>
              <path
                d="M8.27581 9.33929L9.78205 10.0924C10.0135 10.2082 10.2952 10.1144 10.411 9.88279C10.5267 9.65123 10.4329 9.3697 10.2013 9.25389L8.69509 8.50077C8.46359 8.38499 8.18197 8.47883 8.06619 8.71039C7.95041 8.94195 8.04428 9.22351 8.27581 9.33929V9.33929Z"
                fill="#828282"/>
              <path
                d="M8.27581 11.7865L9.78205 12.5396C10.0135 12.6554 10.2952 12.5616 10.411 12.33C10.5267 12.0985 10.4329 11.8169 10.2013 11.7011L8.69509 10.948C8.46359 10.8323 8.18197 10.9261 8.06619 11.1577C7.95041 11.3892 8.04428 11.6707 8.27581 11.7865V11.7865Z"
                fill="#828282"/>
              <path
                d="M8.27581 14.2348L9.78205 14.9879C10.0135 15.1037 10.2952 15.0099 10.411 14.7783C10.5267 14.5467 10.4329 14.2652 10.2013 14.1494L8.69509 13.3963C8.46359 13.2805 8.18197 13.3743 8.06619 13.6059C7.95041 13.8375 8.04428 14.119 8.27581 14.2348V14.2348Z"
                fill="#828282"/>
              <path
                d="M15.3051 8.50077L13.7988 9.25389C13.5673 9.36967 13.4734 9.65123 13.5892 9.88279C13.705 10.1144 13.9866 10.2082 14.2181 10.0924L15.7243 9.33929C15.9559 9.22351 16.0498 8.94195 15.934 8.71039C15.8182 8.47883 15.5366 8.38499 15.3051 8.50077Z"
                fill="#828282"/>
              <path
                d="M15.3051 10.948L13.7988 11.7012C13.5673 11.8169 13.4734 12.0985 13.5892 12.33C13.705 12.5616 13.9866 12.6555 14.2181 12.5396L15.7243 11.7865C15.9559 11.6707 16.0498 11.3892 15.934 11.1577C15.8182 10.9261 15.5366 10.8323 15.3051 10.948Z"
                fill="#828282"/>
              <path
                d="M15.3051 13.3963L13.7988 14.1494C13.5673 14.2652 13.4734 14.5467 13.5892 14.7783C13.705 15.0098 13.9866 15.1037 14.2181 14.9879L15.7243 14.2348C15.9559 14.119 16.0498 13.8375 15.934 13.6059C15.8182 13.3743 15.5366 13.2805 15.3051 13.3963Z"
                fill="#828282"/>
              <path d="M18 5.5V2H20V5.5H18Z" fill="white"/>
              <path d="M18 6V8H20V6H18Z" fill="white"/>
              <path
                d="M19 0C16.2501 0 14 2.25011 14 5C14 7.74989 16.2501 10 19 10C21.7499 10 24 7.74989 24 5C24 2.25011 21.7499 0 19 0ZM19.5769 7.5H18.4231V6.53846H19.5769V7.5ZM19.5769 5.38462H18.4231V2.30769H19.5769V5.38462Z"
                fill="#FF0101"/>
            </svg>
          </div>

          <!--    <div class="text-container">-->
          <p *ngIf="!notification.showOrganization && !notification.showUser && notification.value"
             class="text text-dark">
            {{  prefix + notification.text + '-value' | translate : {value: notification.value} }}
          </p>
          <p *ngIf="!notification.showOrganization && notification.showUser && notification.value"
             class="text text-dark">
            {{  prefix + notification.text + '-user-value' | translate : {
            value: notification.value,
            user: notification.user.firstName + ' ' + notification.user.lastName
          } }}
          </p>
          <p *ngIf="notification.showOrganization && notification.showUser && notification.value"
             class="text text-dark">
            {{  prefix + notification.text + '-user-org-value' | translate : {
            value: notification.value,
            user: notification.user.firstName + ' ' + notification.user.lastName,
            org: notification.organization.name
          } }}
          </p>

          <p *ngIf="!notification.showOrganization && !notification.showUser && !notification.value"
             class="text text-dark">
            {{ prefix + notification.text | translate}}
          </p>
          <p *ngIf="!notification.showOrganization && notification.showUser && !notification.value"
             class="text text-dark">
            {{ prefix + notification.text + '-user' | translate : {
            user: notification.user.firstName + ' ' + notification.user.lastName
          } }}
          </p>
          <p *ngIf="notification.showOrganization && notification.showUser && !notification.value"
             class="text text-dark">
            {{ prefix + notification.text + '-user-org' | translate : {
            user: notification.user.firstName + ' ' + notification.user.lastName,
            org: notification.organization.name
          } }}
          </p>
          <!--      <p>{{notification.created}}</p>-->
          <!--      <p>{{getDate(notification.created)}}</p>-->
          <!--      <p>{{getDate(notification.created) | timeago:true}}</p>-->
          <!--    </div>-->
          <p class="date">{{getDate(notification.created) | timeago}}</p>
          <button class="delete" (click)="delete(notification.id)">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.4219 1.875H9.84375V1.40625C9.84375 0.63085 9.2129 0 8.4375 0H6.5625C5.7871 0 5.15625 0.63085 5.15625 1.40625V1.875H2.57812C1.93195 1.875 1.40625 2.4007 1.40625 3.04688V4.6875C1.40625 4.94637 1.61613 5.15625 1.875 5.15625H2.13117L2.53614 13.6606C2.57191 14.4117 3.18891 15 3.94078 15H11.0592C11.8111 15 12.4281 14.4117 12.4639 13.6606L12.8688 5.15625H13.125C13.3839 5.15625 13.5938 4.94637 13.5938 4.6875V3.04688C13.5938 2.4007 13.068 1.875 12.4219 1.875ZM6.09375 1.40625C6.09375 1.14779 6.30404 0.9375 6.5625 0.9375H8.4375C8.69596 0.9375 8.90625 1.14779 8.90625 1.40625V1.875H6.09375V1.40625ZM2.34375 3.04688C2.34375 2.91765 2.4489 2.8125 2.57812 2.8125H12.4219C12.5511 2.8125 12.6562 2.91765 12.6562 3.04688V4.21875C12.5118 4.21875 2.94237 4.21875 2.34375 4.21875V3.04688ZM11.5274 13.616C11.5155 13.8664 11.3098 14.0625 11.0592 14.0625H3.94078C3.69015 14.0625 3.48448 13.8664 3.47259 13.616L3.06973 5.15625H11.9303L11.5274 13.616Z"
                fill="#BDBDBD"/>
              <path
                d="M7.5 13.125C7.75887 13.125 7.96875 12.9151 7.96875 12.6562V6.5625C7.96875 6.30363 7.75887 6.09375 7.5 6.09375C7.24113 6.09375 7.03125 6.30363 7.03125 6.5625V12.6562C7.03125 12.9151 7.2411 13.125 7.5 13.125Z"
                fill="#BDBDBD"/>
              <path
                d="M9.84375 13.125C10.1026 13.125 10.3125 12.9151 10.3125 12.6562V6.5625C10.3125 6.30363 10.1026 6.09375 9.84375 6.09375C9.58488 6.09375 9.375 6.30363 9.375 6.5625V12.6562C9.375 12.9151 9.58485 13.125 9.84375 13.125Z"
                fill="#BDBDBD"/>
              <path
                d="M5.15625 13.125C5.41512 13.125 5.625 12.9151 5.625 12.6562V6.5625C5.625 6.30363 5.41512 6.09375 5.15625 6.09375C4.89738 6.09375 4.6875 6.30363 4.6875 6.5625V12.6562C4.6875 12.9151 4.89735 13.125 5.15625 13.125Z"
                fill="#BDBDBD"/>
            </svg>
          </button>
        </div>

      </div>
    </div>
    <ng-template #noData>
      <div class="container p-0">
        <div class="notification">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
                 role="img"
                 width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36">
              <path class="clr-i-outline clr-i-outline-path-1"
                    d="M13.72 27.69L3.29 17.27a1 1 0 0 1 1.41-1.41l9 9L31.29 7.29A1 1 0 0 1 32.7 8.7z"
                    fill="currentColor"/>
            </svg>
          </div>
          <p class="text text-dark mw-100">
            {{prefix + 'no-data' | translate}}
          </p>
        </div>
      </div>
    </ng-template>
  </div>
  <app-paging *ngIf="results?.lastPage > 0" [current]="results.page" [last]="results.lastPage" (pageChanged)="toPage($event)"></app-paging>
</div>
