<div class="landing-container p-3">
  <div class="nav-container">
    <nav class="navbar navbar-light navbar-expand justify-content-center">
      <a href="/" class="navbar-brand d-flex w-50 mr-auto">
        <img class src="../../assets/icons/logo-white.svg" alt="logo" title="logo"/>
      </a>
      <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
        <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link btn" routerLink="/auth/login">{{'landing.sign-in' | translate }}</a>
          </li>
          <li class="nav-item sign-up">
            <a class="nav-link btn btn-outline-light" routerLink="/auth/register">{{'landing.sign-up' | translate }}</a>
          </li>
          <li class="nav-item sign-up">
            <a class="nav-link btn btn-link bio-link"
               href="https://www.bioanalyt.com/">{{'landing.bioanalyt-link' | translate }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <div class="content-container row h-35">
    <h1 class="title col-sm-12 auto">{{'landing.title' | translate}}</h1>

    <div class="long-container col-sm-12 auto text-wrap">

      <p class="long-description">
        {{ 'landing.long-description' | translate }}
      </p>

      <ul class="bullet-points">
        <li>{{ 'landing.bullet-1' | translate }}</li>
        <li>{{ 'landing.bullet-2' | translate }}</li>
        <li>{{ 'landing.bullet-3' | translate }}</li>
        <li>{{ 'landing.bullet-4' | translate }}</li>
      </ul>

      <p class="long-description" [innerHtml]="'landing.description-footer' | translate">
      </p>
    </div>

    <button type="button" class="btn btn-primary get-started"
            routerLink="/auth/login">{{'intro.get-started' | translate }}</button>
  </div>

  <img class="landing-image" src="../../assets/img/landing-bg.webp">
</div>
