<app-devices-advanced
  *ngIf="showAdvanced"
  [deviceTypes]="deviceTypes"
  [measurementTypes]="measurementTypes"
  (onClose)="close()"
></app-devices-advanced>

<main *ngIf="!showAdvanced && !loading">
  <p class="title">{{prefix + 'title' | translate}}</p>

  <button class="advanced-btn" *ngIf="canCreate" (click)="showAdvanced = true">
    <img src="assets/icons/icon-add-device.svg">
    <p>{{prefix + 'add-advanced' | translate}}</p>
  </button>

  <div class="head">
    <p class="subtitle">{{ prefix + 'sub-title' | translate }}</p>

    <div class="buttons">
      <button
        *ngIf="canCreate"
        (click)="addDevice()"
        class="add-btn">
        <i class="fa fa-plus mr-1" aria-hidden="true"></i>
        {{ prefix + 'update-button' | translate }}
      </button>

      <button
        [routerLink]="['/tickets/new']"
        [queryParams]="{ category: 'device' }"
        class="order-btn">
        {{ prefix + 'order-button' | translate }}
      </button>
    </div>
  </div>

  <div class="table-container scrollbar">
    <app-devices-table
      [devices]="devices"
      [canCreate]="canCreate"
      [canDelete]="canDelete"
      (onDeleted)="onDeleted($event)"
      (onUpdated)="onUpdated($event)"
      (onPending)="blockingLoader = $event"
    ></app-devices-table>
  </div>

  <div class="mobile-table">
    <app-devices-mobile-table
      [devices]="devices"
      [canCreate]="canCreate"
      [canDelete]="canDelete"
      (onDeleted)="onDeleted($event)"
      (onUpdated)="onUpdated($event)"
      (onPending)="blockingLoader = $event"
    ></app-devices-mobile-table>
  </div>
</main>

<div class="loader-wrapper" *ngIf="loading">
  <div class="loader-container">
    <app-dots-loader></app-dots-loader>
  </div>
</div>

<div class="block-loader" *ngIf="blockingLoader">
  <div class="loader-container">
    <app-dots-loader></app-dots-loader>
  </div>
</div>
