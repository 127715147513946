<div class="modal-container">
  <header class="header">
    <p class="header__title">{{prefix + 'title' | translate}}</p>

    <app-buttons
      class="header__close"
      type="invisible-icon"
      (onClick)="modal.hide()"
      width="auto"
      height="auto"
    >
      <img src="/assets/icons/cross.svg">
    </app-buttons>
  </header>
  <main class="main">

    <div class="main__organizations"
         *ngIf="!loading; else loader"
    >
      <div class="main__organizations__list"
           infiniteScroll
           [infiniteScrollDistance]="0"
           [infiniteScrollThrottle]="50"
           [alwaysCallback]="true"
           [scrollWindow]="false"
           (scrolled)="onScroll()">
        <div
          class="main__organizations__list__item"
          *ngFor="let organization of organizations; let idx = index"
          [ngClass]="{'checked' : organization.selected}"

        >
          <input
            class="main__organizations__list__item__checkbox"
            type="checkbox"
            [id]="idx"
            [checked]="organization.selected"
            [disabled]="existingOrganizations.includes(organization.id)"
            [(ngModel)]="organization.selected">
          <label [for]="idx" class="main__organizations__list__item__label">
            <p class="main__organizations__list__item__label__text text-overflow-100">{{organization.name}}</p>
          </label>
        </div>
        <div class="scroll-dots-loader-container" *ngIf="scrollLoading">
          <app-scroll-dots-loader
            class="scroll-dots-loader"
          ></app-scroll-dots-loader>
        </div>
      </div>
    </div>

    <div class="main__controls">
      <app-buttons
        class="main__controls__cancel-btn"
        type="outline-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        [text]="prefix + 'cancel-btn' | translate"
      ></app-buttons>


      <app-buttons
        [disabled]="validateNextBtn()"
        class="main__controls__next-btn"
        type="filled-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        [text]="prefix + 'next-btn' | translate"
        (onClick)="onNext()"
      ></app-buttons>
    </div>
  </main>
</div>

<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loader-container">
      <app-dots-loader></app-dots-loader>
    </div>
  </div>
</ng-template>
