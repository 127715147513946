<div [ngStyle]="{width: widthPX ? widthPX + 'px' : 'auto'}" [ngClass]="addClass" [popover]="template" [placement]="placement" container="body" #popover="bs-popover"
  triggers="" containerClass="onboarding-popover">
  <ng-content></ng-content>
</div>
<ng-template #template>

  <span class="content" [innerHtml]="text | translate : params"></span>
  <div class="mt-2">
    <button type="button" (click)="done()" class="btn btn-secondary btn-sm mr-3">{{ okButton | translate }}</button>
    <div class="d-inline-block custom-control custom-checkbox middle">
      <input type="checkbox" class="custom-control-input" id="checkbox-{{name}}-1" [(ngModel)]="disableTips">
      <label class="custom-control-label" for="checkbox-{{name}}-1">{{ 'onboarding.checkbox' | translate }}</label>
    </div>
  </div>

</ng-template>
