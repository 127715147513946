<div class="form-group position-relative" [ngStyle]="{'margin-bottom': marginBottom}">
  <label *ngIf="!hideLabel && type !== 'checkbox' && type !== 'non-control-checkbox'" for="{{id}}"
         [ngStyle]="{'font-size': labelFontSize + 'px'}"
         class="fz-14 text-dark">
    {{ prefix + '.label' | translate : {value: labelValue} }}
    <a *ngIf="popoverOptions?.enabled" href="javascript:void(0)" tabindex="-1"
      [popover]="prefix + '.popover' | translate : popoverOptions.params" [placement]="popoverOptions.placement"
      #infoPopover="bs-popover" (onShown)="popoverShown($event)">
      <i class="fa fa-question-circle-o"></i></a>

    <p *ngIf="optional" class="optional">{{'form-control.optional' | translate : {value: optional} }}</p>
  </label>

  <!-- Text / number / password -->
  <input #textInput [readonly]="disabled" *ngIf="type === 'text' || type === 'number' || type === 'password'"
    [type]="type" [step]="steps" [class.is-invalid]="control.invalid && control.touched"
    [class.is-valid]="control.valid && control.touched" class="form-control" id="{{id}}" [formControl]="control"
    placeholder="{{ prefix + '.placeholder' | translate }}" (blur)="hidePopover()">

  <!-- Typeahead -->
  <input #typeAheadInput *ngIf="type === 'typeahead'" type="text"
    [class.is-invalid]="control.invalid && control.touched" [class.is-valid]="control.valid && control.touched"
    class="form-control" id="{{id}}" [formControl]="control" [typeahead]="typeaheadValues" [typeaheadOptionsLimit]="10"
    [typeaheadMinLength]="1" placeholder="{{ prefix + '.placeholder' | translate }}" (blur)="hidePopover()">

  <!-- Textarea -->
  <textarea #textareaInput *ngIf="type === 'textarea'" [class.is-invalid]="control.invalid && control.touched"
    [class.is-valid]="control.valid && control.touched" class="form-control" id="{{id}}" [formControl]="control"
    placeholder="{{ prefix + '.placeholder' | translate }}" rows="5" (blur)="hidePopover()">
    </textarea>

  <!-- Search -->
  <div *ngIf="type === 'search-input'" class="search">
    <div class="lens-container">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0524 13.0526C13.1685 12.9363 13.3064 12.8441 13.4581 12.7812C13.6099 12.7183 13.7725 12.6859 13.9368 12.6859C14.1011 12.6859 14.2637 12.7183 14.4155 12.7812C14.5672 12.8441 14.7051 12.9363 14.8212 13.0526L19.6337 17.8651C19.8682 18.0994 20.0001 18.4174 20.0002 18.749C20.0003 19.0806 19.8687 19.3986 19.6343 19.6332C19.3999 19.8677 19.082 19.9996 18.7504 19.9997C18.4188 19.9998 18.1007 19.8682 17.8662 19.6338L13.0537 14.8213C12.9375 14.7052 12.8453 14.5673 12.7824 14.4156C12.7195 14.2639 12.6871 14.1012 12.6871 13.9369C12.6871 13.7727 12.7195 13.61 12.7824 13.4583C12.8453 13.3065 12.9375 13.1686 13.0537 13.0526H13.0524Z" fill="#68686D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.125 15C9.02784 15 9.92184 14.8222 10.7559 14.4767C11.5901 14.1312 12.348 13.6248 12.9864 12.9864C13.6248 12.348 14.1312 11.5901 14.4767 10.7559C14.8222 9.92184 15 9.02784 15 8.125C15 7.22216 14.8222 6.32817 14.4767 5.49405C14.1312 4.65994 13.6248 3.90204 12.9864 3.26364C12.348 2.62524 11.5901 2.11883 10.7559 1.77333C9.92184 1.42783 9.02784 1.25 8.125 1.25C6.30164 1.25 4.55295 1.97433 3.26364 3.26364C1.97433 4.55295 1.25 6.30164 1.25 8.125C1.25 9.94836 1.97433 11.697 3.26364 12.9864C4.55295 14.2757 6.30164 15 8.125 15ZM16.25 8.125C16.25 10.2799 15.394 12.3465 13.8702 13.8702C12.3465 15.394 10.2799 16.25 8.125 16.25C5.97012 16.25 3.90349 15.394 2.37976 13.8702C0.856024 12.3465 0 10.2799 0 8.125C0 5.97012 0.856024 3.90349 2.37976 2.37976C3.90349 0.856024 5.97012 0 8.125 0C10.2799 0 12.3465 0.856024 13.8702 2.37976C15.394 3.90349 16.25 5.97012 16.25 8.125Z" fill="#68686D"/>
      </svg>
    </div>
    <input [readonly]="disabled" type="text"
            class="search-input" id="{{id}}" [formControl]="control"
           placeholder="{{ prefix + '.placeholder' | translate }}" (blur)="hidePopover()">
  </div>

  <!-- Select -->
  <select *ngIf="type === 'select'" class="form-control custom-select" id="{{id}}" [formControl]="control"
    [class.is-invalid]="control.invalid && control.touched" [class.is-valid]="control.valid && control.touched"
    [ngStyle]="{'background-color': bg ? bg : 'white'}"
  >
    <option *ngFor="let o of options" [ngValue]="o.value">{{ o.name}}</option>
  </select>


  <select *ngIf="type === 'select-outline'" class="select-outline text-primary" id="{{id}}" [formControl]="control"
          [class.is-invalid]="control.invalid && control.touched" [class.is-valid]="control.valid && control.touched"
          [ngStyle]="{'background-color': bg ? bg : 'white'}"
  >
    <option *ngFor="let o of options" [ngValue]="o.value">{{ o.name | translate }}</option>
  </select>

  <!-- Date -->
  <input #dateInput *ngIf="type === 'date'" type="text" class="form-control"
    placeholder="{{ prefix + '.placeholder' | translate }}" [class.is-invalid]="control.invalid && control.touched"
    [class.is-valid]="control.valid && control.touched" bsDatepicker [formControl]="control" />

  <!-- checkbox -->
  <div class="custom-control custom-checkbox" *ngIf="type === 'checkbox'">
    <input class="custom-control-input" type="checkbox" value="" id="{{id}}" [formControl]="control" (click)="onCheckboxChanged($event)">
    <label class="custom-control-label text-dark" for="{{id}}">
      {{ prefix + '.label' | translate }}
    </label>
  </div>


    <div class="non-control-checkbox" *ngIf="type === 'non-control-checkbox'" [ngClass]="{'checked' : selected && !disableSelectEffect}">
      <input [disabled]="disabled" class="non-control-checkbox__input" type="checkbox" [id]="id" [checked]="selected" (change)="onCheckboxChanged($event)" (keyup.enter)="onEnterPressed.emit()">
      <label [for]="id" class="non-control-checkbox__label mb-0">
        <p class="non-control-checkbox__label__text">{{checkboxLabel}} <span class="non-control-checkbox__label__text__sub-text" *ngIf="checkboxSubLabel"> - {{checkboxSubLabel}}</span></p>
      </label>
    </div>
  <!--NEW-->

  <input #textInput [readonly]="disabled" *ngIf="type === 'new-input' || type === 'new-number'"
         [ngStyle]="{'background-color': bg ? bg : 'white'}" [class.hideArrows]="hideArrows"
         [type]="type.substr(4)" [step]="steps" [class.is-invalid]="control.invalid && control.touched"
         [class.is-valid]="control.valid && control.touched" class="new-input" id="{{id}}" [formControl]="control"
         placeholder="{{ prefix + '.placeholder' | translate :{value: placeholder} }}" (blur)="hidePopover()">

  <input #textInput [readonly]="disabled" *ngIf="type === 'email'"
         [ngStyle]="{'background-color': bg ? bg : 'white'}"
         type="email" [step]="steps" [class.is-invalid]="control.invalid && control.touched"
         [class.is-valid]="control.valid && control.touched" class="new-input" id="{{id}}" [formControl]="control"
         placeholder="{{ prefix + '.placeholder' | translate :{value: placeholder} }}" (blur)="hidePopover()">
<!--Address-->

  <input #textInput *ngIf="type === 'address'" #address
         [ngStyle]="{'background-color': bg ? bg : 'white'}"
         type="text" [class.is-invalid]="control.invalid && control.touched"
         [class.is-valid]="control.valid && control.touched" class="new-input" id="{{id}}" [formControl]="control"
         placeholder="{{ prefix + '.placeholder' | translate :{value: placeholder} }}" (blur)="hidePopover()">
<!--City-->

  <input #textInput *ngIf="type === 'city'" #city
         [ngStyle]="{'background-color': bg ? bg : 'white'}"
         type="text" [class.is-invalid]="control.invalid && control.touched"
         [class.is-valid]="control.valid && control.touched" class="new-input" id="{{id}}" [formControl]="control"
         placeholder="{{ prefix + '.placeholder' | translate :{value: placeholder} }}" (blur)="hidePopover()">


<!--Password-->
  <div class="password-container" *ngIf="type === 'new-password'">
    <input #textInput [readonly]="disabled" *ngIf="type === 'new-password'"
           [ngStyle]="{'background-color': bg ? bg : 'white'}"
           [type]="passType" [step]="steps" [class.is-invalid]="control.invalid && control.touched"
           [class.is-valid]="control.valid && control.touched" class="new-input" id="{{id}}" [formControl]="control"
           placeholder="{{ prefix + '.placeholder' | translate :{value: placeholder} }}" (blur)="hidePopover()">
    <button class="show-pass" type="button" (click)="showPass()">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.8602 10.5723C21.6636 10.3035 16.9808 3.98926 10.9999 3.98926C5.01902 3.98926 0.33593 10.3035 0.139606 10.5721C-0.0465352 10.8271 -0.0465352 11.1731 0.139606 11.4282C0.33593 11.697 5.01902 18.0112 10.9999 18.0112C16.9808 18.0112 21.6636 11.697 21.8602 11.4284C22.0466 11.1733 22.0466 10.8271 21.8602 10.5723ZM10.9999 16.5607C6.59434 16.5607 2.77867 12.3698 1.64914 10.9998C2.7772 9.62849 6.58489 5.4398 10.9999 5.4398C15.4052 5.4398 19.2206 9.62995 20.3506 11.0007C19.2226 12.372 15.4149 16.5607 10.9999 16.5607Z" fill="#BEC1C8"/>
        <path d="M11.0001 6.64844C8.60064 6.64844 6.64844 8.60064 6.64844 11.0001C6.64844 13.3996 8.60064 15.3518 11.0001 15.3518C13.3996 15.3518 15.3518 13.3996 15.3518 11.0001C15.3518 8.60064 13.3996 6.64844 11.0001 6.64844ZM11.0001 13.9012C9.40037 13.9012 8.09902 12.5998 8.09902 11.0001C8.09902 9.40042 9.40042 8.09902 11.0001 8.09902C12.5998 8.09902 13.9012 9.40042 13.9012 11.0001C13.9012 12.5998 12.5998 13.9012 11.0001 13.9012Z" fill="#BEC1C8"/>
      </svg>
    </button>
  </div>

<!--Select-->
  <select *ngIf="type === 'new-select'" class="new-select" id="{{id}}" [formControl]="control"
          [class.is-invalid]="control.invalid && control.touched" [class.is-valid]="control.valid && control.touched"
          [ngStyle]="{
          'background-color': bg ? bg : 'white',
          'text-transform': textTransform
          }" (change)="onSelect($event, control.value)"
  >
    <option
      *ngFor="let o of options;
      let idx = index"
      [selected]="idx === 0"
      [ngValue]="o.value"
      [disabled]="o.disabled"
      [ngStyle]="{'text-transform': textTransform}"
    >
      {{ o.name | translate}}
    </option>
  </select>


<!--  Multi Select-->




<!--  Radio-->

  <div class="d-flex justify-content-around" *ngIf="type === 'radio'">
    <div class="custom-control custom-radio" *ngFor="let o of options">
      <input [ngStyle]="{'background-color': bg ? bg : 'white'}"
             type="radio" [step]="steps" [class.is-invalid]="control.invalid && control.touched"
             [class.is-valid]="control.valid && control.touched" class="custom-control-input" [id]="id + o.value" [formControl]="control"
             (blur)="hidePopover()" [value]="o.value" (change)="onRadioSelected(o.value)">
      <label class="custom-control-label text-dark" [for]="id + o.value">{{o.name}}</label>
    </div>
  </div>

  <p class="mb-2 mt-1 py-0 subtitle" *ngIf="subTitle.length">{{ subTitle | translate }}</p>


  <ng-container *ngIf="control && control.invalid && control.touched">
      <div [ngStyle]="{display: idx > 0 ? 'none' : 'block'}" class="m-0" *ngFor="let e of errors; let idx = index">
        <small class="form-text text-danger">{{ prefix + '.error-' + e | translate : control.errors }}</small>
      </div>
  </ng-container>
  <ng-content></ng-content>
</div>
