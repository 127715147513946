<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col">
    <div class="card border-0 form">
      <div class="card-header bg-white">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col text-center" *ngFor="let tab of tabs"
              (click)="tabClicked(tab)">
            <a class="nav-link border-top-0 border-left-0 border-right-0 bg-transparent"
               [ngClass]="{'active': showTab ===tab}">{{tab}}</a>
          </li>
        </ul>
      </div>

      <div class="card-body bg-white border-bottom">
        <div *ngIf="orgs" class="tab-content" [ngSwitch]="showTab">
          <div class="tab-pane fade active show" *ngSwitchCase="'parent'">
            <button class="btn btn-primary" (click)="allocate(true)">Add</button>
            <div *ngFor="let o of orgs.parent" class="border p-2 row">
              <p class="col-9">{{o.name}}</p>
              <button class="btn btn-danger col-3" (click)="delete(o.id)">DELETE</button>
            </div>
          </div>


          <div class="tab-pane fade active show" *ngSwitchCase="'child'">
            <button class="btn btn-primary" (click)="allocate(false)">Add</button>
            <div *ngFor="let o of orgs.child" class="border p-2 row">
              <p class="col-9">{{o.name}}</p>
              <button class="btn btn-danger col-3" (click)="delete(o.id)">DELETE</button>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>

</div>
