import { Component, OnInit } from '@angular/core';
import { documentation } from '../../../model/documentation';


@Component({
  selector: 'app-help-nav',
  templateUrl: './help-nav.component.html',
  styleUrls: ['./help-nav.component.scss']
})
export class HelpNavComponent implements OnInit {
  links = documentation;
  constructor() { }

  ngOnInit() {
  }

}
