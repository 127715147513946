<div class="content-container">
  <div class="card content-card py-4">

    <div class="row m-0 p-0">
      <div class="col">
        <div (click)="exitAdvanced()" class="ml-1 mb-3 go-back">
          <img src="assets/icons/grey-arrow-left.svg" class="arrow-back">
          <p>{{ prefix + "menu-title" | translate }}</p>
        </div>
      </div>
    </div>

    <div class="card-body mx-4 my-0 pt-0">
      <h2 class="m-0">{{ prefix + "title-1" | translate }}</h2>


      <form [formGroup]="form" (ngSubmit)="save()">
        <fieldset>
          <div class="row width">
            <app-form-control
              *ngIf="measurementTypes"
              type="new-select"
              class="col-md-12"
              [selectOptions]="measurementTypes"
              labelFontSize="16"
              [prefix]="prefix + 'choose'"
              [control]="form.get('measurementTypeId')"
            ></app-form-control>
          </div>

          <div class="row m-0">
            <p class="col-12 m-0 p-0 text-dark" *ngIf="form.get('measurementTypeId').value === 1">{{ prefix + "choose.title" | translate }}</p>
            <figure *ngFor="let d of displayTestKits">
              <img
                class="col-6 p-0 m-2 device-img"
                [src]="api + d.image"
                (click)="selectTestKit(d)"
                [ngClass]="{ selected: selectedTestKit?.name == d.name }"
              />
              <figcaption class="text-center">{{d.measurementType}}</figcaption>
            </figure>
          </div>


          <div class="row width">
            <app-form-control
              class="col-md-12"
              [showInfo]="false"
              [prefix]="prefix + 'lot'"
              labelFontSize="16"
              [control]="form.get('lot')"
              [subTitle]="prefix + 'lot.subTitle'"
              type="new-input"
            >
            </app-form-control>
          </div>

          <div class="row width">
            <app-form-control
              class="col-md-12"
              [showInfo]="false"
              labelFontSize="16"
              [prefix]="prefix + 'organization'"
              [control]="form.get('origin')"
              type="new-input"
            >
            </app-form-control>
          </div>

          <div class="row width">
            <app-form-control
              class="col-md-12"
              [showInfo]="false"
              labelFontSize="16"
              [prefix]="prefix + 'unused'"
              [control]="form.get('unused')"
              type="new-number"
              [subTitle]="prefix + 'unused.subTitle'"
            ></app-form-control>
          </div>

          <div class="row width">
            <div class="date col-6">
              <p class="date-title">Received date</p>
              <div class="datepicker">
                <input name="dash-drp1" id="dash-drp1" type="text"
                       [minDate]="minDate"
                       [value]="form.get('received').value"
                       #dp1="bsDatepicker"
                       class="form-control"
                       bsDatepicker (bsValueChange)="receivedDateChange($event)"
                       placement="top"
                       [bsConfig]="{ isAnimated: true, selectFromOtherMonth: true, dateInputFormat: 'YYYY-MM-DD'}">
                <button class="calendar" (click)="dp1.toggle()" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="#8b8b8b" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699zM1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756L1.4 7.742zm5.267 6.877v1.666H5v-1.666h1.667zm4.166 0v1.666H9.167v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H15zm-8.333-3.977v1.666H5v-1.666h1.667zm4.166 0v1.666H9.167v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H15zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0v-.92z"/></svg>
                </button>
              </div>
            </div>

            <div class="date col-6">
              <p class="date-title">Expired date</p>
              <div class="datepicker">
                <input name="dash-drp2" id="dash-drp2" type="text"
                       #dp2="bsDatepicker"
                       [minDate]="minDate"
                       [value]="form.get('expires').value"
                       class="form-control"
                       bsDatepicker (bsValueChange)="expiredDateChange($event)"
                       placement="top"
                       [bsConfig]="{ isAnimated: true, selectFromOtherMonth: true, dateInputFormat: 'YYYY-MM-DD'}">
                <button class="calendar" (click)="dp2.toggle()" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="#8b8b8b" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699zM1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756L1.4 7.742zm5.267 6.877v1.666H5v-1.666h1.667zm4.166 0v1.666H9.167v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H15zm-8.333-3.977v1.666H5v-1.666h1.667zm4.166 0v1.666H9.167v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H15zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0v-.92z"/></svg>
                </button>
              </div>
            </div>
          </div>
          <div class="text-left">
            <button type="submit" class="btn btn-primary btn-lg mt-3" [disabled]="form.invalid">
              {{ "devices.save" | translate }}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
    <img class="bg" src="assets/img/training-image.webp" alt="training-image"/>
  </div>
</div>
