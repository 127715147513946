import {Component, OnDestroy, OnInit} from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../model/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from '../modal.service';
import { SelectOrgModalComponent } from '../select-org-modal/select-org-modal.component';
import { AdminService } from '../../pages-authenticated/admin/admin.service';
import * as moment from 'moment';
import { EditUserGroupsModalComponent } from '../edit-user-groups-modal/edit-user-groups-modal.component';
import {Subscriptions} from '../../../model/subscriptions'

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss']
})
export class UserDetailsModalComponent implements OnInit, OnDestroy {
  _subs = new Subscriptions()
  prefix = "admin.users.details.";
  user: User;
  form: FormGroup;
  busy = false;
  issues = {
    "user.not-active": false,
    "user.not-approved": false,
    "user.no-organization": false,
    "user.email-not-confirmed": false,
  };
  mailSent = false;
  modalResult


  constructor(
    public modal: BsModalRef,
    private ms: ModalService,
    private as: AdminService,
  ) {
    this.form = new FormGroup({
      uuid: new FormControl(null),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      active: new FormControl(true),
      organization: new FormControl(''),
      organizationCode: new FormControl(''),
    });
  }

  ngOnInit() {
    //fill the form
    const form = {
      uuid: this.user.uuid,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      active: this.user.active,
      organization: this.user.organization ? this.user.organization.name : null,
      organizationCode: this.user.organization ? this.user.organization.code : null,
    }
    this.form.setValue(form);

    //get issues
    for (const m of this.user.statusMessages) {
      this.issues[m] = true;
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  async save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    this.busy = true;
    try {
      const values = this.form.value
      delete values.organizationCode;
      const gotten = await this.as.updateUser(values);
      Object.assign(this.user, gotten);
      this.modal.hide();
    } catch (err) {
      console.error(err);
    }
    this.modalResult = this.user
    this.busy = false;
  }

  async openRoleModal() {
    this.busy = true;
    const result = await this.ms.show(EditUserGroupsModalComponent, { initialState: { user: this.user} });

    if (result) this.user = result

    this.busy = false;
  }

  async openOrgModal() {
    this.busy = true;
    const result = await this.ms.show(SelectOrgModalComponent, { initialState: {
      user: this.user,
      limit: false,
      uuid: this.form.get('uuid').value
    } });
    if (result) {
      this.form.get('organization').setValue(result.name);
      this.issues["user.no-organization"] = false;
    }
    this.busy = false;
  }

  async resendEmail() {
    this.busy = true;
    try {
      await this.as.resendConfirmEmail(this.user);
      this.mailSent = true;
    } catch (err) {
      console.error(err);
    }
    this.busy = false;
  }

  async confirmEmail() {
    this.busy = true;
    try {
      await this.as.confirmEmail(this.user);
      this.issues["user.email-not-confirmed"] = false;
      this.user.emailConfirmed = moment();
    } catch (err) {
      console.error(err);
    }

    this.busy = false;
  }

}
