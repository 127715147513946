<div class="login-container">
  <nav class="navbar navbar-expand-md navbar-dark bg-transparent">
    <a routerLink="/landing">
      <img src="../../../assets/icons/logo-green-black.svg">
    </a>
  </nav>

  <div class="card card-login align-middle card-transparent">
    <div class="card-header">
      <h2 class="text-center">
        <span class="title-dark">{{'login.sign-in-to' | translate }}</span>
        <span class="title-colored">{{'login.title' | translate }}</span>
      </h2>
    </div>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="login()">
        <fieldset [disabled]="loading">
          <div class="row">
            <app-form-control
              class="col-md-12 mb-2"
              type="email"
              [prefix]="prefix + 'email'"
              [control]="email"
              [bg]="'#F7F7F8'"
            ></app-form-control>

            <app-form-control
              class="col-md-12"
              type="new-password"
              [prefix]="prefix + 'password'"
              [control]="password"
              bg="#F7F7F8"
            ></app-form-control>
            <div class="col-md-12 text-left">
              <a [routerLink]="['/auth/forgot-password']" class="text-center forgot d-inline-block float-right">
                {{ 'login.forgot-button' | translate }}
              </a>
            </div>
          </div>
          <button type="submit" class="submit-btn">{{ 'login.sign-in' | translate }}</button>
          <div class="form-text text-danger text-center"
               *ngIf="credentialsInvalid">{{ 'login.credentials-invalid' | translate }}</div>
        </fieldset>
      </form>
      <div>
      </div>
      <div>
        <a routerLink="/auth/register"
           class="text-center text-dark font-weight-bold card-link d-block mt-4">
          <span>{{ 'login.signup-button-1' | translate }}</span>
          <span class="title-colored font-weight-bold">{{ 'login.signup-button-2' | translate }}</span>
        </a>
      </div>


    </div>
  </div>
</div>
