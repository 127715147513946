<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate : {product: product.name} }}</h5>
</div>

<div class="modal-body">
  <p>{{ prefix + 'description' | translate : {product: product.name} }}</p>
</div>

<div class="modal-footer">
  <button type="button" (click)="close(false)" class="btn btn-light" data-dismiss="modal">{{ prefix + 'no' | translate }}</button>
  <button type="button" (click)="close(true)" class="btn btn-primary">{{ prefix + 'yes' | translate }}</button>
</div>
