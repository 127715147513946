import { Component, Input, OnChanges, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';

import {Product, ProductLine} from '../../../../model/Products';
import { Sample } from '../../../../model/sample';
import { Subscriptions } from '../../../../model/subscriptions';
import { CacheService } from '../../../services/cache.service';
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../../../services/data.service";
import { from } from 'rxjs'

@Component({
  selector: 'app-processed-samples',
  templateUrl: './processed-samples.component.html',
  styleUrls: ['./processed-samples.component.scss']
})
export class ProcessedSamplesComponent implements OnChanges, OnInit, OnDestroy {
  private _sub = new Subscriptions();
  @Output() processAgain = new EventEmitter<Sample>();

  @Input() samples: Sample[] = [];
  productLines: ProductLine[];
  productInfo: any = {};
  awaitingProductInfo = true;
  showHierarchy = false
  hierarchyProduct: Product

  grouped: { [key: string]: Sample[] } = {};
  get groupNames(): string[] { return Object.getOwnPropertyNames(this.grouped); }

  constructor(
    private cache: CacheService,
    private route: ActivatedRoute,
    private data: DataService,

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(async q => {
      setTimeout(()=> {
        this._sub.add(this.cache.productLines.subscribe(lines => {
        this.productLines = lines;
        this.productInfo = {};
        for (let l of lines) {
          for (let p of l.products) {
            this.productInfo[p.id] = {name: p.name, line: l.name};
          }
        }
        if (this.awaitingProductInfo) {
          this.awaitingProductInfo = false;
          this.ngOnChanges();

        }
      }));
      }, 1000)
      if (q.organizationId) {
        this.showHierarchy = true
        this._sub.add(from(this.data.getProductLinesByOrgId(+q.organizationId)).subscribe(lines => {
          lines.forEach(pl => {
            pl.products.forEach(p => {
              if (p.id === +q.productId) {
                this.hierarchyProduct = p;
              }
            })
          })
          // this.selectProduct(this.hierarchyProduct)
        }))
      }
      // }
    })
  }

  ngOnDestroy() {
    this._sub.unsubscribeAll();
  }

  ngOnChanges() {
    if (!this.samples || this.samples.length === 0 || this.awaitingProductInfo) return;
    this.group(this.samples);
  }

  private group(samples: Sample[], reset = true) {
    if (reset) this.grouped = {};

    for (let s of samples) {
      if (s.mode === "DEVICE CONTROL") {
        if (!this.grouped['Device Control']) this.grouped['Device Control'] = [];
        this.grouped['Device Control'].push(s);
      } else {
        const parts = [];
        if (s.productId && this.productInfo[s.productId]) {
          parts.push(this.productInfo[s.productId].name)
        } else if (this.hierarchyProduct && this.hierarchyProduct.name) {
          parts.push(this.hierarchyProduct.name);
        }

        if (s.userBatchId) parts.push(s.userBatchId);

        if (parts.length === 0) parts.push("unknown");
        const name = parts.join(' - ');

        if (!this.grouped[name]) this.grouped[name] = [];
        this.grouped[name].push(s);
      }
    }
  }

  add(samples: Sample[]) {
    this.group(samples, false);
  }

  undo(s: Sample, group: string) {
    const index = this.grouped[group].findIndex(sample => s === sample);
    if (index > -1) {
      this.grouped[group].splice(index, 1);
      if (this.grouped[group].length === 0) delete this.grouped[group];
    }

    this.processAgain.next(s);
  }

}
