import {Component, OnInit} from '@angular/core'
import {DocsService} from '../../../services/docs.service'
import {DocumentsPage, Document} from '../../../../model/document'
import {ModalService} from '../../../shared/modal.service'
import {UploadDocModalComponent} from '../../../shared/upload-doc-modal/upload-doc-modal.component'
import * as moment from 'moment'

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss', './docs.media.scss']
})
export class DocsComponent implements OnInit {
  page: DocumentsPage
  busy = false
  docTypes: string[] = []
  docFroms: string[] = []

  bsDateRange: Date[] = []

  filters: any[] = []
  typeFilter = null

  constructor(
    private dm: DocsService,
    private modals: ModalService,
  ) {
  }

  async ngOnInit() {
    this.busy = true
    await this.getPage(0)
    this.busy = false
  }

  async getPage(page: number) {
    this.page = await this.dm.getDocuments(page)
    this.page.documents.map((doc) => {
      if (this.docTypes.indexOf(doc.documentType) === -1) {
        this.docTypes.push(doc.documentType)
      }
      if (this.docFroms.indexOf(doc.author.getName()) === -1) {
        this.docFroms.push(doc.author.getName())
      }
    })
  }

  docFilter($event, attr) {
    const selectElem = $event.target
    const val = selectElem.value
    if (val === 'all') return delete this.filters[attr]

    this.filters[attr] = {
      attr,
      val
    }
  }

  async upload() {
    const doc: Document = await this.modals.show(UploadDocModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static'
    })
    if (!doc) return

    this.page.documents.unshift(doc)
  }


  async fileHandler(e) {
    const ddFile: File[] = [e]
    const doc: Document = await this.modals.show(UploadDocModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      initialState: {
        ddFile
      }
    })
    if (!doc) return

    this.page.documents.unshift(doc)
  }

  dateChange(dates) {
    if (!dates || dates.length !== 2) return
    if (dates[0] === null || dates[1] === null) return

    const val = {
      from: dates[0],
      to: dates[1]
    }


    this.filters['date'] = {
      attr: 'date',
      val
    }
  }

  clearDate() {
    this.bsDateRange = []
    delete this.filters['date']
  }
}
