import { HttpClient } from '@angular/common/http';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { deLocale } from 'ngx-bootstrap/locale';


import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';

defineLocale('de', deLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private us: UserService,
    private api: ApiService,
    translate: TranslateService,
    localeService: BsLocaleService,
    router: Router,
    titleService: Title,
    route: ActivatedRoute,
    private http: HttpClient
  ) {
    //Set language and locale
    translate.setDefaultLang('en');
    translate.use(locale);
    localeService.use(locale);

    //Titles
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        titleService.setTitle(this.getTitle(route.snapshot));
      }
    });

    this.init();
  }

  private getTitle(r: ActivatedRouteSnapshot): string {
    const titlePrefix = "iCheck Connect";
    let title: string;
    if (r.data.title) title = r.data.title;

    while (r.firstChild) {
      r = r.firstChild;
      if (r.data.title) title = r.data.title;
    }

    if (title) return `${titlePrefix} - ${title}`;
    return titlePrefix;
  }

  private async init() {
    // await this.api.ready()
    await this.us.init();
  }
}
