<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body position-relative">
  <form [formGroup]="form" (submit)="upload()">
    <fieldset [disabled]="busy">

      <app-file-upload
        #uploader
        [ngClass]="{hidden: ddFile}"
        [uploadImmediately]="false"
        [control]="form.get('file')"
        (progress)="uploadProgress($event)">
      </app-file-upload>
      <div class="file-name-container">
        <p class="label text-dark">Chosen file</p>
        <div class="file-name" *ngIf="ddFile">
          <p class="m-0">{{ddFile[0].name}}</p>
        </div>
      </div>
      <app-form-control type="new-input" [control]="form.get('name')" [prefix]="prefix + 'name'"></app-form-control>
      <app-form-control [control]="form.get('documentType')" [prefix]="prefix + 'document-type'" type="new-select"
                        [selectOptions]="selectOptions">
      </app-form-control>
      <app-form-control *ngIf="showSupport" [control]="form.get('forSupport')" [prefix]="prefix + 'for-support'"
                        type="checkbox">
      </app-form-control>

      <app-form-control *ngIf="showDescription && showSupport" [control]="form.get('description')"
                        [prefix]="prefix + 'description'"
                        type="textarea">

      </app-form-control>

      <app-form-control
        *ngIf="role === 'superAdmin' ||
              role === 'admin' ||
              role === 'manager'"
        [control]="form.get('toUser')"
        [prefix]="prefix + 'for-user'"
        type="checkbox">
      </app-form-control>
      <app-form-control
        *ngIf="(role === 'superAdmin' ||
              role === 'admin' ||
              role === 'manager') &&
      !showSupport" [control]="form.get('user')"
        [prefix]="prefix + 'select-user'"
        type="new-select"
        [selectOptions]="userOptions"></app-form-control>
      <div class="mb-2">
        <button (click)="showDescription = true" class="btn btn-sm btn-link" type="button" *ngIf="!showDescription">
          <i class="fa fa-plus"></i>
          {{ prefix + 'add-description-button' | translate }}
        </button>
      </div>

      <div class="text-right">
        <span *ngIf="showError" class="text-danger mr-2">{{ prefix + 'upload-error' | translate }}</span>
        <button class="btn btn-block upload-btn" type="submit"><i class="fa fa-upload"></i>
          {{ prefix + 'submit' | translate }}</button>
      </div>

    </fieldset>
    <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>
  </form>
</div>
