import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {Sample} from '../../../../model/sample'
import {ProductLine, Product} from '../../../../model/Products'
import {CacheService} from '../../../services/cache.service'
import {Subscriptions} from '../../../../model/subscriptions'
import {DataService} from '../../../services/data.service'
import {FancyTableOptions, FancyTableComponent} from '../../../shared/fancy-table/fancy-table.component'
import {TrainingService} from '../../../services/training.service'
import {InputModalComponent} from '../../../shared/input-modal/input-modal.component'
import {ModalService} from 'src/app/shared/modal.service'
import {Import} from '../../../../model/import'
import {Device} from '../../../../model/device'

@Component({
  selector: 'app-import-training-data',
  templateUrl: './import-training-data.component.html',
  styleUrls: ['./import-training-data.component.scss']
})
export class ImportTrainingDataComponent implements OnInit, OnDestroy {
  private _subs = new Subscriptions()
  prefix = 'import-training.'
  tableOptions: FancyTableOptions = {
    columns: [
      {name: 'date', property: 'created', type: 'date'},
      {name: 'product', property: 'product.name'},
      {
        name: 'batch',
        type: 'function',
        getValue: (s: Sample) => `Batch: ${s.deviceBatchId} Sample: ${s.deviceSampleId}`
      },
      {
        name: 'user-batch', type: 'function', getValue: (s: Sample) => {
          const parts = []
          //todo translate these strings
          if (s.userBatchId) parts.push('Batch: ' + s.userBatchId)
          if (s.userSampleId) parts.push('Sample: ' + s.userSampleId)
          return parts.join(' ')
        }
      },
      {name: 'result', type: 'text', property: 'result'}
    ],
    maxSelected: Infinity
  }
  @ViewChild('table') table: FancyTableComponent

  samples: Sample[]
  trainingLine: ProductLine
  productLines: ProductLine[]
  product: Product
  loading = false
  modalOpen = false
  import: Import
  devices: Device[] = []
  concentration: number

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cache: CacheService,
    private ds: DataService,
    private ts: TrainingService,
    private modals: ModalService,
  ) {
  }

  async ngOnInit() {
    //Load Import Samples if needed
    //Otherwise load training and unindexed samples from me
    const importId = this.route.snapshot.queryParams.importId
    this.concentration = this.route.snapshot.queryParams.concentration

    //Load Training ProductIds, so we can switch
    const productId = Number(this.route.snapshot.queryParams.productId)

    this._subs.add(this.cache.productLines.subscribe(lines => {
      this.trainingLine = lines.find(l => l.training)
      this.productLines = lines
      if (!this.product && productId) {
        this.product = this.trainingLine.products.find(p => p.id === productId)
      } else if (isNaN(productId) && this.trainingLine.products.length > 0) {
        this.product = this.trainingLine.products[0]
      }
    }))
    this._subs.add(this.cache.devices.subscribe(d => {
      this.devices = d
    }))
    const samples = await this.ds.getSamples(productId, importId)
    this.samples = samples.sort((a, b) => b.created.unix() - a.created.unix())
    this.import = await this.ds.getImport(importId)

  }

  async openBatchNameModal() {
    this.modalOpen = true
    const text = await this.modals.show(InputModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        title: 'import.set-batch-title',
        placeholder: 'import.batch-name-placeholder',
        text: ''
      }
    })
    if (text) {
      this.setBatchName(text)
    }
  }

  async setBatchName(name: string) {
    //Check to see if we're overwriting a batch name anywhere
    const selected = this.samples.filter(s => s.selected)

    const withBatchName = selected.filter(s => s.userBatchId && s.userBatchId !== name)
    if (withBatchName.length > 0 && withBatchName.length < selected.length) {
      const confirmed = await this.modals.showConfirm({
        title: 'import.overwrite-batch.title',
        content: 'import.overwrite-batch.content'
      })
      if (!confirmed) return
    }

    for (const s of selected) {
      s.userBatchId = name
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  async process() {
    if (!this.product) throw new Error('No product!')
    const results = await this.ds.saveSamples(this.table.selected)
    const ids = this.table.selected.map(s => s.id)
    await this.ds.addTrainingAttempt(ids, this.product.id, this.concentration)
    await this.lastMaintained()
    this.router.navigate(['/training/results'], {queryParams: {productId: this.product.id}})
    this.ts.deleteTrainingProgress()
  }

  async lastMaintained() {
    const dev = this.devices.find(d => d.id === this.import.deviceId)
    dev.maintained = this.import.created
    const i = await this.ds.updateDevice(dev)
  }
}

