import {Component, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {FormGroup} from '@angular/forms'
import {AdminService} from '../../pages-authenticated/admin/admin.service'

@Component({
  selector: 'app-create-hierarchy-modal',
  templateUrl: './create-hierarchy-modal.component.html',
  styleUrls: ['./create-hierarchy-modal.component.scss']
})
export class CreateHierarchyModalComponent implements OnInit {

  prefix = 'admin.hierarchy.create-hierarchy-modal.'
  form: FormGroup
  step = 'parent'
  data
  busy = false
  modalResult

  constructor(
    public modal: BsModalRef,
    private as: AdminService
  ) {
  }

  async ngOnInit() {
  }

  parentSelected(data) {
    this.data = data
    this.step = 'child'
  }

  async childSelected(data) {
    this.data.organizations = this.data.organizations.concat(data)
    this.data.organizations = this.data.organizations.map(o => {
      return {
        id: o.id,
        status: o.status,
        permission: o.permission
      }
    })
    this.busy = true
    try {
      // const gotten = await this.as.createHierarchy(this.data)
      // this.modalResult = gotten
      this.modal.hide()
    } catch (e) {
      console.log(e)
      this.busy = false
    }
  }
}
