import { Component, OnInit } from '@angular/core';
import {Device} from '../../../model/device'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {units} from '../../../model/sample'
import {Organization} from '../../../model/organization'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {DataService} from '../../services/data.service'
import * as moment from 'moment'

@Component({
  selector: 'app-device-detailed-modal',
  templateUrl: './device-detailed-modal.component.html',
  styleUrls: ['./device-detailed-modal.component.scss']
})
export class DeviceDetailedModalComponent implements OnInit {
  newDevice = false
  busy = false
  device: Device
  modalResult: Device = null
  prefix = "device-modal."
  form: FormGroup
  typeOptions = []
  unitOptions = units
  organization?: Organization
  deviceType = 'chroma'
  canDelete

  get title(): string {
    if (this.newDevice) return 'new-title'
    return 'edit-title'
  }

  get saveButton(): string {
    if (this.newDevice) return 'new-save'
    return 'edit-save'
  }

  constructor(
    private modal: BsModalRef,
    private ds: DataService,
    private dataService: DataService
  ) {
    this.form = new FormGroup({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      type: new FormControl('', Validators.required),
      lot: new FormControl('', Validators.required),
      origin: new FormControl('', Validators.required),
      range: new FormGroup({
        minRange: new FormControl('', Validators.required),
        maxRange: new FormControl('', Validators.required),
      }, this.range),
      unit: new FormControl('AU', Validators.required),
      received: new FormControl('', Validators.required)
    })
  }

  range(range: FormGroup) {
    if (+range.get('minRange').value < +range.get('maxRange').value) return null
    return {invalidRange: true}
  }

  ngOnInit() {
    this.busy = true
    this.dataService.getTestKitTypes().then(testKitTypes => {
      this.typeOptions = testKitTypes.map(d => {
        return {name: d.name, value: d.type}
      })
      this.busy = false
    })
    if (this.newDevice) {
      this.device = this.getDefaultDevice()
      console.log('this.device', this.device)
    }
    this.form.patchValue(this.device)
    this.form.get('range').get('minRange').setValue(this.device.minRange)
    this.form.get('range').get('maxRange').setValue(this.device.maxRange)
  }

  private getDefaultDevice(): Device {
    return {
      id: null,
      type: this.deviceType,
      lot: '',
      minRange: 5,
      maxRange: 13,
      unit: 'AU',
      active: true,
      received: moment().format('YYYY-MM-DD')
    }
  }

  close(save = false) {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    if (save) {
      Object.assign(this.device, data)
      this.modalResult = this.device
    }
    this.modal.hide()
  }

  async save() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.busy = true
    try {
      if (this.newDevice) {
        await this.saveNew()
      } else {
        await this.update()
      }
      this.close(true)
    } catch (err) {
      console.error("Not happening", err)
    }
    this.busy = false
  }

  private saveNew() {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    if (this.organization) this.form.get('organizationId').setValue(this.organization.id)
    return this.ds.addDevice(data)
  }

  private update() {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    return this.ds.updateDevice(data)
  }

  async remove() {
    this.busy = true

    try {
      await this.ds.removeDevice(this.device)
      this.device.active = false
      this.close(true)
    } catch (err) {
      console.error("Nope", err)
    }

    this.busy = false
  }
}
