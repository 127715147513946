<!--<div class="container mt-4">-->
<!--  <p class="title font-weight-bold text-dark">My Profile Settings</p>-->

<!--  <div class="row">-->
<!--    <div class="mt-4 mr-5 col-3">-->
<!--      <div class="card bg-transparent border-0">-->
<!--        <div class="card-header mx-auto bg-transparent border-0 text-center pb-0">-->
<!--          <div class="profile-image-container">-->
<!--            <img [src]="userpic ? userpic : 'assets/img/profile.png'" alt="Profile Image" class="profile-image">-->

<!--            <div class="change-profile-image">-->
<!--              <app-file-upload format="userpic" [fileFormats]="'image/png, image/jpg, image/jpeg'" #fileUpload-->
<!--                               (progress)="uploadProgress($event)">-->
<!--              </app-file-upload>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--        <div class="card-body text-center">-->
<!--          <div class="row">-->
<!--            <p class="text-dark col mb-0 font-weight-bold">{{user.firstName}} {{user.lastName}}</p>-->
<!--          </div>-->

<!--          <div class="row">-->
<!--            <p class="col text-gray fz-14">{{user.organization.name}}</p>-->
<!--          </div>-->

<!--          <div class="mt-3 row copy-form">-->
<!--            <p class="mb-2 p-0 col-12 text-left text-dark">-->
<!--              {{ 'settings.org-code' | translate }}-->
<!--            </p>-->
<!--            <input-->
<!--              id="code"-->
<!--              type="text"-->
<!--              [value]="user.organization.code"-->
<!--              readonly-->
<!--              class="text-primary border-0 col-10 form-control copy-input">-->
<!--            <app-copy-button class="p-0 col-2" [text]="user.organization.code"><i class="fa fa-copy"></i>-->
<!--            </app-copy-button>-->
<!--          </div>-->

<!--          <div class="mt-3 row copy-form">-->
<!--            <p class="mb-2 p-0 col-12 text-left text-dark">-->
<!--              {{ 'settings.fortis-id' | translate }}-->
<!--            </p>-->
<!--            <input-->
<!--              id="fortis-id"-->
<!--              type="text"-->
<!--              [value]="user.organization.code"-->
<!--              readonly-->
<!--              class="text-primary border-0 col-10 form-control copy-input">-->
<!--            <app-copy-button class="p-0 col-2" [text]="user.organization.code"><i class="fa fa-copy"></i>-->
<!--            </app-copy-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col">-->
<!--      <div class="card border-0 form">-->
<!--        <div class="card-header bg-white">-->
<!--          <ul class="nav nav-tabs card-header-tabs">-->
<!--            <li class="nav-item col-3 text-center" *ngFor="let tab of tabs"-->
<!--                (click)="tabClicked(tab)">-->
<!--              <a class="nav-link border-top-0 border-left-0 border-right-0 bg-transparent"-->
<!--                 [ngClass]="{'active': showTab ===tab}">{{ (prefix + 'tabs.' + tab + '.title') | translate }}</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

<!--        <div class="card-body bg-white border-bottom">-->
<!--          <div class="tab-content" [ngSwitch]="showTab">-->
<!--            <div class="tab-pane fade active show" *ngSwitchCase="'account'">-->
<!--              <form [formGroup]="accountForm" (ngSubmit)="saveAccount()" class="d-inline-block">-->
<!--                <fieldset>-->
<!--                  <div class="row">-->
<!--                    <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false" [prefix]="prefix +-->
<!--                              'tabs.account.form.firstName'" [control]="accountForm.get('firstName')">-->
<!--                    </app-form-control>-->
<!--                    <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false"-->
<!--                                      [prefix]="prefix + 'tabs.account.form.lastName'"-->
<!--                                      [control]="accountForm.get('lastName')">-->
<!--                    </app-form-control>-->
<!--                    <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false"-->
<!--                                      [prefix]="prefix + 'tabs.account.form.phone'"-->
<!--                                      [control]="accountForm.get('phone')">-->
<!--                    </app-form-control>-->
<!--                    <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false"-->
<!--                                      [prefix]="prefix + 'tabs.account.form.email'"-->
<!--                                      [disabled]="true"-->
<!--                                      [control]="accountForm.get('email')">-->
<!--                    </app-form-control>-->
<!--                    <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false"-->
<!--                                      [prefix]="prefix + 'tabs.account.form.city'"-->
<!--                                      [control]="accountForm.get('city')">-->
<!--                    </app-form-control>-->
<!--                  </div>-->
<!--                </fieldset>-->
<!--              </form>-->

<!--              <div *ngIf="device">-->
<!--                <p class="device-type text-dark m-0">Device Type: {{device.type}}</p>-->
<!--                <p class="device-type text-dark m-0">Last Used</p>-->
<!--                <p class="font-weight-bold text-primary m-0">{{device.maintained | date: "M/d/yyyy"}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="py-4 card-footer bg-white">-->
<!--          <button class="btn btn-block btn-lg col-5 save-btn" (click)="saveAccount()">-->
<!--            {{ 'settings.save' | translate }}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->
<!--</div>-->


<main>
  <p class="title">{{prefix + 'title' | translate}}</p>

  <div class="content">
    <div class="profile-container">
      <div class="user-data">
        <div class="image-container">
          <img [src]="userpic ? userpic : 'assets/img/profile.png'" alt="Profile Image" class="profile-image">

          <div class="change-profile-image">
            <app-file-upload format="userpic" [fileFormats]="'image/png, image/jpg, image/jpeg'"
                             (progress)="uploadProgress($event)">
            </app-file-upload>
          </div>
        </div>

        <p class="username">{{user.getName()}}</p>
        <p class="org-name">{{user.organization.name}}</p>
      </div>

      <div class="codes">
        <div class="code-container">
          <p class="code-title">{{ 'settings.org-code' | translate }}</p>
          <div class="code">
            <p>{{user.organization.code}}</p>
            <app-copy-button [text]="user.organization.code"><i class="fa fa-copy"></i>
            </app-copy-button>
          </div>
        </div>

        <div class="code-container" *ngIf="user.organization['createdBy'] === 'fortis'">
          <p class="code-title">{{ 'settings.fortis-id' | translate }}</p>
          <div class="code">
            <p>{{user.organization.code}}</p>
            <app-copy-button [text]="user.organization.code"><i class="fa fa-copy"></i>
            </app-copy-button>
          </div>
        </div>

        <div class="code-container" *ngIf="user.customId">
          <p class="code-title">{{ 'settings.custom-id' | translate }}</p>
          <div class="code">
            <p>{{user.customId}}</p>
            <app-copy-button [text]="user.customId"><i class="fa fa-copy"></i>
            </app-copy-button>
          </div>
        </div>
      </div>
    </div>

    <div class="tabs-container">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item col-3 text-center" *ngFor="let tab of tabs"
            (click)="tabClicked(tab)">
          <a class="nav-link border-top-0 border-left-0 border-right-0 bg-transparent"
             [ngClass]="{'active': showTab ===tab}">{{ (prefix + 'tabs.' + tab + '.title') | translate }}</a>
        </li>
      </ul>


      <div class="card-body bg-white border-bottom">
        <div class="tab-content" [ngSwitch]="showTab">
          <div class="tab-pane fade active show" *ngSwitchCase="'account'">
            <form [formGroup]="accountForm" (ngSubmit)="saveAccount()" class="d-inline-block">
              <fieldset>
                <div class="row">
                  <app-form-control
                    class="col-md-6"
                    [showInfo]="false"
                    [prefix]="prefix +'tabs.account.form.firstName'"
                    type="new-input"
                    [control]="accountForm.get('firstName')">
                  </app-form-control>
                  <app-form-control
                    class="col-md-6"
                    [showInfo]="false"
                    [prefix]="prefix + 'tabs.account.form.lastName'"
                    type="new-input"
                    [control]="accountForm.get('lastName')">
                  </app-form-control>
                  <app-form-control
                    class="col-md-6"
                    [showInfo]="false"
                    type="new-input"
                    [prefix]="prefix + 'tabs.account.form.phone'"
                    [control]="accountForm.get('phone')">
                  </app-form-control>
                  <app-form-control
                    class="col-md-6"
                    type="new-input"
                    [showInfo]="false"
                    [prefix]="prefix + 'tabs.account.form.email'"
                    [disabled]="true"
                    [control]="accountForm.get('email')">
                  </app-form-control>
                  <app-form-control
                    class="col-md-6"
                    [showInfo]="false"
                    type="new-input"
                    [prefix]="prefix + 'tabs.account.form.city'"
                    [control]="accountForm.get('city')">
                  </app-form-control>
                </div>
              </fieldset>
            </form>

            <div *ngIf="device">
              <p class="device-type text-dark m-0">Device Type: {{device.type}}</p>
              <p class="device-type text-dark m-0">Last Used</p>
              <p class="font-weight-bold text-primary m-0">{{device.maintained.toString?.() | date: "M/d/yyyy"}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn btn-block col-5 save-btn" (click)="saveAccount()">
          {{ 'settings.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</main>


<div class="loader-container" *ngIf="busy">
  <app-dots-loader></app-dots-loader>
</div>
