<div *ngIf="!loading; else loadingSpinner" class="container m-0 py-3 dash-container">
  <h1 class="title">{{'menu.dashboard' | translate }}</h1>

  <div class="book-container">
    <img class="book" src="../../../../assets/img/dashboard-book.svg" alt="book">
  </div>

  <nav class="navbar navbar-expand-md navbar-light px-0 py-4 mb-2 mt-4">
    <div class="navbar-collapse w-100">
      <form class="nav-form" (ngSubmit)="submit()">
        <!-- Date picker -->
        <div class="form-responsive">
          <app-onboarding-message *ngIf="role !== 'user'; else userName" text="onboarding.dashboard.analyst" name="dash-analyst"
                                  placement="bottom">
            <app-multi-select #analysts (selectionChange)="userFilterChanged($event)"
                              [page]="usersCurrentPage"
                              [lastPage]="usersLastPage"
                              [organizationId]="params.organizationId"
                              translateBase="dashboard.user-filter"
                              (onLoadMore)="loadMoreUsers($event)"
                              [items]="organizationsList"></app-multi-select>
          </app-onboarding-message>

          <ng-template #userName>
            <div class="user-name mr-2">
              <p class="text-overflow-100">{{user.getName()}}</p>
            </div>
          </ng-template>


          <!-- Product Dropdown -->
          <app-onboarding-message text="onboarding.dashboard.sample" name="dash-sample" placement="bottom">
            <div class="btn-group mr-2" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="dropdown-btn"
                      aria-controls="dropdown-basic">
              <span *ngIf="selectedProduct; else noProductSelected" class="center">
                <span class="text-left text-overflow">{{ selectedProduct.name }}</span>
                  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="arrow">
                    <path
                      d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"
                      fill="#68686D"/>
                  </svg>
              </span>
                <ng-template #noProductSelected><span class="fa fa-leaf fa-fw"></span>
                  <span class="text-left text-overflow">{{prefix + 'no-product-selected' | translate}}</span>
                </ng-template>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let o of organizationsList">
                  <div *ngIf="canShowProducts(o.id)" class="product-lines-container">
                    <p class="text-primary font-weight-bold org-name text-overflow">{{o.name}}</p>

                    <div *ngIf="o.productLines && o.productLines.length; else noPl" class="product-lines">
                      <li *ngFor="let pl of o.productLines" class="disabled product-lines" role="menuitem">
                        <div>
                          <div class="product-line-name">
                            <a class="dropdown-item text-dark p-0 disabled name text-overflow"
                               href="javascript:void(0)">
                              {{ pl.name }}
                            </a>
                          </div>

                          <div *ngIf="pl.products.length; else noP">
                            <a class="dropdown-item product"
                               *ngFor="let p of pl.products"
                               [class.active]="selectedProduct && p.id === selectedProduct.id"
                               (click)="selectProduct(p, pl.organizationId)">
                              <p class="product-name text-dark pointer m-0 text-overflow">{{ p.name }}</p>
                            </a>
                          </div>
                          <ng-template #noP>
                            <div class="no-product">
                              <p class="m-0 text-danger error-text">No products</p>
                            </div>
                          </ng-template>

                        </div>
                      </li>
                    </div>

                    <ng-template #noPl>
                      <div class="no-product-lines">
                        <p class="m-0 text-danger error-text">No product Lines</p>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
                <div *ngIf="role === 'superAdmin' && !params.organizationId" class="select-user">
                  <p class="mb-0">{{prefix + 'select-user' | translate}}</p>
                </div>
              </ul>
            </div>
          </app-onboarding-message>

          <app-onboarding-message *ngIf="selectedProduct" text="onboarding.dashboard.control-values"
                                  name="dash-control-values" placement="bottom" [widthPX]="200">
            <app-dropdown
              [items]="measurementTypesSelectOptions"
              [selectedItem]="selectedMeasurementType"
              (itemSelected)="selectMeasurementType($event)"
              prefix="dashboard-measurement-types"
              [hideLabel]="true"
              [fontSize]="16"
            ></app-dropdown>
          </app-onboarding-message>

        </div>

        <div class="nav-form-bottom form-responsive">
          <app-onboarding-message text="onboarding.dashboard.date-range" name="dash-date-range" placement="bottom">
            <div class="datepicker-container mr-2">
              <div class="calendar" (click)="drp.show()">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.8086 1.32812H13.2812V0.53125C13.2812 0.237867 13.0434 0 12.75 0C12.4566 0 12.2188 0.237867 12.2188 0.53125V1.32812H4.78125V0.53125C4.78125 0.237867 4.54342 0 4.25 0C3.95658 0 3.71875 0.237867 3.71875 0.53125V1.32812H2.19141C0.983045 1.32812 0 2.31117 0 3.51953V14.8086C0 16.017 0.983045 17 2.19141 17H14.8086C16.017 17 17 16.017 17 14.8086V3.51953C17 2.31117 16.017 1.32812 14.8086 1.32812ZM2.19141 2.39062H3.71875V2.92188C3.71875 3.21526 3.95658 3.45312 4.25 3.45312C4.54342 3.45312 4.78125 3.21526 4.78125 2.92188V2.39062H12.2188V2.92188C12.2188 3.21526 12.4566 3.45312 12.75 3.45312C13.0434 3.45312 13.2812 3.21526 13.2812 2.92188V2.39062H14.8086C15.4311 2.39062 15.9375 2.89704 15.9375 3.51953V4.78125H1.0625V3.51953C1.0625 2.89704 1.56891 2.39062 2.19141 2.39062ZM14.8086 15.9375H2.19141C1.56891 15.9375 1.0625 15.4311 1.0625 14.8086V5.84375H15.9375V14.8086C15.9375 15.4311 15.4311 15.9375 14.8086 15.9375Z"
                    fill="#68686D"/>
                </svg>
              </div>
              <input name="dash-drp" id="dash-drp" type="text"
                     autocomplete="off"
                     placeholder="{{ 'dashboard.daterange-placeholder' | translate }}" class="datepicker"
                     bsDaterangepicker (bsValueChange)="dateChange($event)" [ngModel]="bsDateRange"
                     #drp="bsDaterangepicker"
                     [bsConfig]="{ isAnimated: true, selectFromOtherMonth: true }">
            </div>
          </app-onboarding-message>


          <button
            class="submit-btn"
            [disabled]="validateSubmitButton()"
            type="submit"
          >{{ 'dashboard.submit-button' | translate }}</button>
        </div>
      </form>

      <div class="control-buttons">
        <div class="top">
          <app-onboarding-message text="onboarding.dashboard.reset" name="dash-upload" placement="left">
            <button
              class="control-small mr-10 reset-btn"
              type="button"
              [disabled]="validateDisableButton()"
              (click)="resetData()"
            >
              <i class="fa fa-undo fa-fw" aria-hidden="true"></i>
              {{ 'dashboard.reset-button' | translate }}
            </button>
          </app-onboarding-message>

          <app-onboarding-message text="onboarding.dashboard.reset" name="dash-upload" placement="left">
            <button [routerLink]="['/upload']" class="control-small">
              {{ "dashboard.upload-more-button" | translate }}
            </button>
          </app-onboarding-message>
        </div>

        <div class="bottom">
          <app-onboarding-message text="onboarding.dashboard.download" name="dash-export" placement="left"
                                  addClass="w-100">
            <button class="export-btn" type="button" (click)="generateReportChart()" [disabled]="controlDataEmpty">
              <i class="fa fa-download fa-fw" aria-hidden="true"></i>
              {{ 'dashboard.export-button' | translate }}
            </button>
          </app-onboarding-message>
        </div>
      </div>

      <!--      <ul class="navbar-nav">-->
      <!--        <li class="nav-item">-->
      <!--        </li>-->
      <!--        &lt;!&ndash; <li class="nav-item ml-2">-->
      <!--          <app-onboarding-message text="onboarding.dashboard.upload" name="dash-upload" placement="left">-->
      <!--            <button class="btn btn-primary my-2 my-sm-0" type="button" queryParamsHandling-->
      <!--                    [routerLink]="['/training/import']"-->
      <!--                    [queryParams]="{'productId': search.productId, 'fromDash': true}">-->
      <!--              <i class="fa fa-upload fa-fw" aria-hidden="true"></i>-->
      <!--              {{ 'dashboard.upload-button' | translate }}-->
      <!--            </button>-->
      <!--          </app-onboarding-message>-->
      <!--        </li> &ndash;&gt;-->
      <!--        <li class="nav-item ml-2">-->

      <!--        </li>-->


      <!--        <li class="nav-item ml-2">-->
      <!--        </li>-->
      <!--      </ul>-->
    </div>
  </nav>

  <div class="content-row">

    <!--  Row 1  -->
    <div class="row chart-100">
      <!--  Total Producton Control Chart  -->
      <div class="col text-center">
        <div class="card border-0" *ngIf="!controlDataEmpty; else noData">
          <div class="card-body">
            <div>
              <div class="text-left d-inline-block">
                <h3 class="chart-title">{{'dashboard.charts.production-control-title' | translate}}</h3>
              </div>
              <div class="text-right d-inline-block">
                <div *ngIf="controlValue" class="btn-group" role="group" aria-label="Switch Units">
                  <!--                  <button (click)="switchUnits('mg RE/kg')" type="button" class="btn btn-sm"-->
                  <!--                          [ngClass]="controlValue?.unit === 'mg RE/kg' ? 'btn-secondary' : 'btn-outline-secondary'">-->
                  <!--                    {{'dashboard.charts.label-mg' | translate }}-->
                  <!--                  </button>-->
                  <button *ngFor="let u of switchUnitsOptions[controlValue.label]" (click)="switchUnits(u)"
                          type="button" class="btn btn-sm"
                          [ngClass]="controlValue?.unit === u ? 'btn-secondary' : 'btn-outline-secondary'">{{u}}</button>
                </div>
              </div>
            </div>
            <app-chart [options]="controlChart"></app-chart>
          </div>
          <app-overlay-spinner *ngIf="controlChartLoading"></app-overlay-spinner>
        </div>
      </div>

    </div>

    <!--  Row 2  -->
    <!--    <div class="row">-->
    <div class="row">
      <div class="text-center col col-grid">
        <!-- Usage chart -->
        <div class="card border-0" *ngIf="usageChart?.hasData; else noUsageData">
          <div class="card-body">
            <div class="text-left d-inline-block">
              <h3 class="text-left chart-title">{{'dashboard.charts.device-usage-title' | translate }}</h3>
            </div>
            <app-chart [options]="usageChart"></app-chart>
          </div>
        </div>
      </div>

      <div class="text-center col col-grid">
        <!-- Stock chart -->
        <div class="card border-0" *ngIf="stockChart?.hasData; else noStockData">
          <div class="card-body">
            <div class="text-left d-inline-block">
              <h3 class="text-left chart-title">{{'dashboard.charts.test-kit-title' | translate }}</h3>
            </div>
            <app-chart [options]="stockChart"></app-chart>
          </div>
        </div>
      </div>
    </div>


    <!--    </div>-->

    <!--  Row 3  -->
    <div class="row">
      <div class="text-center col col-grid">
        <!-- Compliance chart -->
        <div class="card border-0" *ngIf="usageChart?.hasData; else noLimitsData">
          <div class="card-body">
            <div class="text-left d-inline-block">
              <h3 class="text-left chart-title">{{'dashboard.charts.tolerance-limits-title' | translate }}</h3>
            </div>
            <div id="qcChart">
              <app-chart [options]="toleranceChart"></app-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center col col-grid">
        <!-- Stock chart -->
        <div class="card border-0" *ngIf="trainingChart?.hasData; else noTrainingData">
          <div class="card-body">
            <div class="text-left d-inline-block">
              <h3 class="text-left chart-title">{{'dashboard.charts.training-chart.title' | translate }}</h3>
            </div>
            <app-training-chart [options]="trainingChart"></app-training-chart>
          </div>
        </div>
      </div>
    </div>

    <!--  TEMPLATES BELOW  -->

    <ng-template #noData>
      <app-no-data class="col" icon="no-data-total-chart" header="charts.control-chart.no-data"
                   [content]="search.from && search.to ? 'charts.control-chart.no-data-content' : 'charts.control-chart.no-data-content-no-date'"
                   subtitle="charts.control-chart.no-data-subtitle"
                   [params]="{ product: getProductName(), from: search.from ? search.from.format('DD.MM.YY') : null, to: search.to ? search.to.format('DD.MM.YY') : null }">

        <button [routerLink]="['/upload']" class="btn btn-primary upload-btn">
          {{ "dashboard.upload-more-button" | translate }}
        </button>
      </app-no-data>
    </ng-template>

    <ng-template #noUsageData>
      <app-no-data class="col" icon="no-data-chart" header="charts.usage-chart.no-data"
                   [content]="search.from && search.to ? 'charts.usage-chart.no-data-content' : 'charts.usage-chart.no-data-content-no-date'"
                   subtitle="charts.limits-chart.no-data-subtitle"
                   [params]="{ product: getProductName(), from: search.from ? search.from.format('DD.MM.YY') : null, to: search.to ? search.to.format('DD.MM.YY') : null }">
      </app-no-data>
    </ng-template>

    <ng-template #noStockData>
      <app-no-data class="col" icon="no-data-tube" header="charts.stock-chart.no-data"
                   [content]="search.from && search.to ? 'charts.stock-chart.no-data-content' : 'charts.stock-chart.no-data-content-no-date'"
                   subtitle="charts.limits-chart.no-data-subtitle"
                   [params]="{ product: getProductName(), from: search.from ? search.from.format('DD.MM.YY') : null, to: search.to ? search.to.format('DD.MM.YY') : null }">

        <!--        <button [routerLink]="['/stock']" class="btn btn-light">-->
        <!--          <i class="fa fa-upload fa-fw" aria-hidden="true"></i>-->
        <!--          {{ "dashboard.to-stock-button" | translate }}-->
        <!--        </button>-->
      </app-no-data>
    </ng-template>

    <ng-template #noLimitsData>
      <app-no-data class="col" icon="no-data-circle" header="charts.limits-chart.no-data"
                   [content]="search.from && search.to ? 'charts.limits-chart.no-data-content' : 'charts.limits-chart.no-data-content-no-date'"
                   subtitle="charts.limits-chart.no-data-subtitle"
                   [params]="{ product: getProductName(), from: search.from ? search.from.format('DD.MM.YY') : null, to: search.to ? search.to.format('DD.MM.YY') : null }">
      </app-no-data>
    </ng-template>

    <ng-template #noTrainingData>
      <app-no-data class="col" icon="no-data-book" header="charts.training-chart.no-data"
                   [content]="search.from && search.to ? 'charts.training-chart.no-data-content' : 'charts.training-chart.no-data-content-no-date'"
                   subtitle="charts.training-chart.no-data-subtitle">
      </app-no-data>
    </ng-template>
  </div>
</div>
<!--TODO: This should be dynamic, but it's just going to stay as a placeholder until Anthony provides further details-->

<div class="card p-0 m-0 report-container" id="contentToConvert" *ngIf="customReportData !== null">
  <div class="card-header">
    <a class="navbar-brand d-flex">
      <img class="logo-img m-2" src="assets/icons/logo-white.svg" alt="logo" title="logo"/>
    </a>
  </div>
  <div class="card-body">
    <p class="title">{{'dashboard.report.type' | translate}}</p>

    <!-- Product Details -->
    <div class="d-block report-details">
      <p class="data-label">{{prefix + 'report.data-labels.product-line' | translate}}<span class="data-value">{{customReportData.productLineName}}</span>
      </p>
      <p class="data-label">{{prefix + 'report.data-labels.product-category' | translate}}<span class="data-value">{{customReportData.productCategory}}</span>
      </p>
      <p class="data-label">{{prefix + 'report.data-labels.product' | translate}}<span class="data-value">{{customReportData.productName}}</span>
      </p>
      <p class="data-label mb-20">{{prefix + 'report.data-labels.measurement-type' | translate}}<span class="data-value">{{customReportData.measurementType}}</span>
      </p>
      <p class="data-label">{{prefix + 'report.data-labels.device' | translate}}<span class="data-value">{{customReportData.device}}</span>
      </p>
      <p class="data-label">{{prefix + 'report.data-labels.analyst' | translate}}<span class="data-value">{{analysts}}</span>
      </p>
      <p class="data-label">{{prefix + 'report.data-labels.batch' | translate}}<span class="data-value">{{customReportData.batch}}</span>
      </p>
      <p class="data-label mb-20">{{prefix + 'report.data-labels.target' | translate}}<span class="data-value">{{customReportData.target}}</span>
      </p>

      <p class="date-range mb-2 text-center">
        <span class="primary-underline">{{prefix + 'report.average-results.text1' | translate}}</span>
        {{prefix + 'report.average-results.text2' | translate}}<br>
        <span class="font-weight-bold">{{customReportData.timePeriod}}</span>
      </p>

      <div class="row mb-20">
        <div class="col text-center">
          <div class="result">
            {{customReportData.resultsAverage}}
          </div>
        </div>
      </div>

      <p class="date-range mb-2 text-center">
        <span class="primary-underline">{{prefix + 'report.compliance.text1' | translate}}</span>
        {{prefix + 'report.compliance.text2' | translate}}<br>
        <span class="font-weight-bold">{{customReportData.timePeriod}}</span>
      </p>

      <div class="row">
        <div class="col text-center">
          <div class="compliance-chart">
            <img class="chart-img" id="base64Chart" [src]="chartBase64" (load)="downloadReport()"
                 alt="charting data image"/>
          </div>
          <div class="grey-border"></div>
        </div>
      </div>

    </div>
  </div>
  <div class="card-footer">
    <p class="date float-right mb-4">{{customReportData.date}}</p>
  </div>
</div>


<ng-template #loadingSpinner>
  <div class="w-100 h-100 position-relative">
    <div class="loader-container">
      <app-dots-loader></app-dots-loader>
    </div>
  </div>
</ng-template>
