import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-disable-samples-modal',
  templateUrl: './disable-samples-modal.component.html',
  styleUrls: ['./disable-samples-modal.component.scss']
})
export class DisableSamplesModalComponent implements OnInit {
  prefix = 'all-data.disable-samples-modal.';
  samples
  state

  modalResult


  constructor(
    public modal: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }

  confirm() {
    this.modalResult = true
    this.modal.hide()
  }
}
