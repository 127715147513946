import {Component, OnInit} from '@angular/core'
import {Organization} from '../../../model/organization'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-hierarchy-modal-child',
  templateUrl: './create-hierarchy-modal-child.component.html',
  styleUrls: ['./create-hierarchy-modal-child.component.scss']
})
export class CreateHierarchyModalChildComponent implements OnInit {

  prefix = 'admin.hierarchy.create-hierarchy-modal.'
  form: FormGroup
  organizations: Organization[]
  searchedOrganizations: Organization[]
  selectedOrganizations: Organization[] = []
  modalResult
  prevOrgs: Organization[]
  q: string

  constructor(
    public modal: BsModalRef,
    private as: AdminService
  ) {
    this.form = new FormGroup({
      search: new FormControl('')
    })
  }

  async ngOnInit() {
    this.organizations = await this.as.getHierarchyOrgsList()
    if (this.prevOrgs.length) {
      const ids = this.prevOrgs.map(o => o.id)
      this.organizations = this.organizations.filter(o => {
        return !ids.includes(o.id)
      })
    }
    this.organizations = this.organizations.map(o => {
      o.selected = false
      return o
    })
  }

  onCheckboxChanged(e, idx) {
    if (this.searchedOrganizations) {
      this.searchedOrganizations[idx].selected = e.target.checked
      this.searchedOrganizations[idx].status = 'child'
    } else {
      this.organizations[idx].selected = e.target.checked
      this.organizations[idx].status = 'child'
    }
    if (e.target.checked) {
      // this.selectedOrganizations.push(this.organizations[idx])
      this.selectedOrganizations.push(this.searchedOrganizations ? this.searchedOrganizations[idx] : this.organizations[idx])
    } else {
      this.selectedOrganizations = this.selectedOrganizations.filter(o => o.id !== +e.target.value)
    }
  }

  save() {
    this.modalResult = this.selectedOrganizations
    this.modal.hide()
  }

  search() {
    if (!this.form.get('search').value) {
      this.ngOnInit();
    }
    this.q = this.form.get('search').value;
    if (this.prevOrgs.length) {
      const ids = this.prevOrgs.map(o => o.id)
      this.searchedOrganizations = this.organizations.filter(o => {
        // if (o.name.toLocaleLowerCase().indexOf(this.q.toLocaleLowerCase()) > -1) {
          return !ids.includes(o.id)
        // }
      })
    }
    this.searchedOrganizations = this.organizations.filter(o => {
      if (o.name.toLocaleLowerCase().indexOf(this.q.toLocaleLowerCase()) > -1) {
        o.selected = false
        return o
      }
    })
    console.log(this.searchedOrganizations, 'orgsss')
  }
}
