import {Injectable} from '@angular/core'

import {DashboardResponse, TrainingResponse, ControlChartData} from '../../model/api-response'
import {ControlValue} from '../../model/control-value'
import {Import} from '../../model/import'
import {Product, ProductLine} from '../../model/Products'
import {Sample} from '../../model/sample'
import {SearchCriteria, TrainingSearchCriteria} from '../../model/search-criteria'
import {ApiService} from './api.service'
import {CacheService} from './cache.service'
import {Device} from '../../model/device'
import {TrainingAttempt} from '../../model/TrainingAttempt'
import {Page} from '../../model/Page'
import {User} from '../../model/user'
import {Measurement} from '../../model/measurement'
import {DeviceType} from '../../model/deviceType'
import {DashboardData} from '../../model/dashboard'
import {Organization, OrganizationPage} from '../../model/organization'

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  lastImport: Import

  constructor(
    private api: ApiService,
    private cache: CacheService,
  ) {
  }

  async getDashboardData(search: SearchCriteria): Promise<DashboardResponse> {
    const result = await this.api.get(`u/dashboard${search.toQueryParams()}`)
    result.search = SearchCriteria.deserialize(result.search)
    return result
  }

  async getControlChart(search: SearchCriteria, units?: string): Promise<ControlChartData> {
    const result = await this.api.get(`u/control-chart${search.toQueryParams()}&units=${encodeURIComponent(units)}`)
    return result.chart
  }

  async getTrainingData(search: TrainingSearchCriteria): Promise<TrainingResponse> {
    const result = await this.api.get(`u/training${search.toQueryParams()}`)
    console.log('result', result)
    result.search = TrainingSearchCriteria.deserialize(result.search)
    result.attempts = result.attempts.map(a => {
      const ta = TrainingAttempt.deserialize(a)
      ta.setTrainingChart(result.controlValue)
      ta.setUsageChart()
      return ta
    })
    return result
  }

  async getTrainingResults(search: TrainingSearchCriteria): Promise<TrainingResponse> {
    const result = await this.api.get(`u/training-results`)
    result.search = TrainingSearchCriteria.deserialize(result.search)
    result.attempts = result.attempts.map(a => {
      const ta = TrainingAttempt.deserialize(a)
      // ta.setTrainingChart(result.controlValue)
      return ta
    })

    return result
  }

  async getTrainingChart(hierarchyId) {
    return await this.api.get(`u/training-chart?hierarchyId=${hierarchyId}`)
  }

  async addTrainingAttempt(sampleIds: number[], productId: number, concentration: number, deviceId?: number) {
    const result = await this.api.post('u/training-attempt', {sampleIds, productId, concentration, deviceId})
    return result
  }

  async addTrainingProduct(data): Promise<Product> {
    const result = await this.api.post('u/training-product', data)
    this.cache.productLines.value = result.productLines
    return result.product
  }

  async getProductLines() {
    const result = await this.api.get('u/product-lines')
    // this.cache.productLines = result.productLines;
    return result
  }

  async getSamples(productId?: number, importId?: number): Promise<Sample[]> {
    const params = []
    if (productId) params.push(`productId=${productId}`)
    if (importId) params.push(`importId=${importId}`)

    const result = await this.api.get(`u/samples?${params.join('&')}`)

    return result.samples.map(s => Sample.deserialize(s))
  }

  async getAllUsers(): Promise<User[]> {
    const result = await this.api.get('u/users')
    result.users = result.users.map(u => User.deserialize(u))
    result.users.sort((a, b) => a.getName().localeCompare(b.getName()))
    return result.users
  }

  async getAllDevices(): Promise<Device[]> {
    const result = await this.api.get('u/device')
    return result.devices
  }

  async getAllProductLines(): Promise<ProductLine[]> {
    return await this.api.get('u/all-product-lines')
  }

  async getSamplesPage(page = 0, productId,  search?: string): Promise<Page<Sample>> {
    const params = []
    params.push(`page=${page}`)
    // if (productId) params.push(`productId=${productId}`)
    if (search) params.push(`q=${search}`)

    const result = await this.api.get(`u/samples-page?${params.join('&')}`)
    return new Page<Sample>(result, Sample)
  }

  async getSharedSamplesPage(page = 0, search?: string): Promise<any> {
    const params = []
    params.push(`page=${page}`)
    if (search) params.push(`q=${search}`)

    return await this.api.get(`u/shared-samples-page?${params.join('&')}`)
  }

  async getImport(id: number) {
    if (this.lastImport && id === this.lastImport.id) return this.lastImport

    const response = await this.api.get(`u/import/${id}`)
    return Import.deserialize(response)
  }

  async saveSamples(samples: Sample[]) {
    const data = samples.map(s => s.serialize())
    return await this.api.put('u/samples', data)
  }

  async processCsv(data) {
    const result = await this.api.post('u/csv', data)

    this.lastImport = Import.deserialize(result.import)
    return this.lastImport
  }

  async saveSettings(c: ControlValue) {
    const result = await this.api.post('u/control-values', c)
    c.id = result.controlValue.id
  }

  async updateSettings(c: ControlValue) {
    const data = {
      productId: c[0].productId,
      cv: c
    }
    return await this.api.put('u/control-values', data)
  }

  async saveProductLine(pl: ProductLine): Promise<ProductLine> {
    if (!pl.organizationId) pl.organizationId = this.cache.organization.value.id

    const body = {...pl}
    delete body.products

    const result = await this.api.post('u/product-line', body)
    pl.id = result.productLine.id
    this.updateProductLineInCache(pl)
    return result.productLine
  }

  async deleteProductLine(pl: ProductLine) {
    await this.api.delete(`u/product-line/${pl.id}`)
    this.removeProductLineFromCache(pl)
  }

  async deleteProduct(p: Product) {
    await this.api.delete(`u/product/${p.id}`)
    this.removeProductFromCache(p)
  }

  async saveProduct(p: Product) {
    const body = {...p}

    const result = await this.api.post('u/product', body)
    p.id = result.product.id
    this.updateProductInCache(p)
  }

  private updateProductLineInCache(pl: ProductLine) {
    const fromCache = this.cache.productLines.value.find(i => i.id === pl.id)
    if (fromCache) {
      Object.assign(fromCache, pl)
    } else {
      this.cache.productLines.value.push(pl)
    }
    //Trigger update
    this.cache.productLines.next(this.cache.productLines.value)
  }

  private updateProductInCache(p: Product) {
    const lineFromCache = this.cache.productLines.value.find(i => i.id === p.productLineId)
    if (!lineFromCache) {
      console.error("No product line for product", p)
      return
    }

    const productFromCache = lineFromCache.products.find(i => i.id === p.id)
    if (productFromCache) {
      Object.assign(productFromCache, p)
    } else {
      lineFromCache.products.push(p)
    }

    //Trigger update
    this.cache.productLines.next(this.cache.productLines.value)
  }

  private removeProductLineFromCache(pl: ProductLine) {
    const index = this.cache.productLines.value.findIndex(i => i.id === pl.id)
    if (index === -1) return
    this.cache.productLines.value.splice(index, 1)

    //Trigger update
    this.cache.productLines.next(this.cache.productLines.value)
  }

  removeProductFromCache(p: Product) {
    const lineFromCache = this.cache.productLines.value.find(i => i.id === p.productLineId)
    if (!lineFromCache) {
      console.error("No product line for product", p)
      return
    }

    const index = lineFromCache.products.findIndex(i => i.id === p.id)
    if (index === -1) return
    lineFromCache.products.splice(index, 1)

    //Trigger update
    this.cache.productLines.next(this.cache.productLines.value)
  }

  async addDevice(d: Device): Promise<Device> {
    const result = await this.api.post('u/device', d)
    Object.assign(d, result.device)

    //update cache
    this.cache.devices.value.push(d)
    this.cache.devices.trigger()

    return result.device
  }

  async updateDevice(d: Device): Promise<Device> {
    const result = await this.api.put('u/device', d)
    Object.assign(d, result.device)

    //update cache
    const dev = this.cache.devices.value.find(cachedDev => d.id === cachedDev.id)
    if (dev) Object.assign(dev, result.device)
    this.cache.devices.trigger()

    return result.device
  }

  async removeDevice(d: Device) {
    await this.api.delete(`u/device/${d.id}`)
    d.active = false

    //update cache
    const dev = this.cache.devices.value.find(cachedDev => d.id === cachedDev.id)
    if (dev) dev.active = false
    this.cache.devices.trigger()

    return d
  }

  async getOrgWithUsersByHierarchyId(id) {
    return await this.api.get(`u/hierarchy-users?id=${id}`)
  }

  async getProductLinesByOrgId(organizationId) {
    return await this.api.get(`u/product-lines-id?id=${organizationId}`)
  }

  async getDevicesByOrgId(organizationId) {
    return await this.api.get(`u/devices-id?id=${organizationId}`)
  }

  async getDashboardHierarchyOrgs(id) {
    return await this.api.get(`u/dashboard-hierarchy-organizations?id=${id}`)
  }

  getDeviceTypes(): Promise<DeviceType[]> {
    return this.api.get('device-types')
  }

  getTestKitTypes() {
    return this.api.get('testKitTypes')
  }

  getMeasurementTypes(): Promise<Measurement[]> {
    return this.api.get(`measurement-types`)
  }

  getProductTypes(): Promise<any> {
    return this.api.get(`product-types`)
  }

  getProductCategories(): Promise<any> {
    return this.api.get(`product-categories`)
  }

  async getDeviceByImportId(importId): Promise<Device> {
    return this.api.get(`u/device-by-import?importId=${importId}`)
  }

  exportTpCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return
    }

    const separator = ';'
    const keys = Object.keys(rows[0])

    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k]
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""')
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`
          }
          return cell
        }).join(separator)
      }).join('\n')

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  async getTrainingUsageChart(search: TrainingSearchCriteria) {
    const result = await this.api.get(`u/training-usage-chart${search.toQueryParams()}`)
    return result
  }

  async getProductLineById(id): Promise<ProductLine> {
    return this.api.get(`u/product-line-by-id?id=${id}`)
  }

  async initDashboard(): Promise<DashboardData> {
    return this.api.get(`u/init-dashboard?page=0`)
  }

  async loadMoreUsers(page): Promise<OrganizationPage> {
    return this.api.get(`u/dashboard-users-page?page=${page}`)
  }

  async enableSamples(ids: number[]): Promise<{ result: string }> {
    return this.api.put(`u/enable-samples`, {ids})
  }

  async disableSamples(ids: number[]): Promise<{ result: string }> {
    return this.api.put(`u/disable-samples`, {ids})
  }

  async enableSharedSamples(ids: number[]): Promise<{ result: string }> {
    return this.api.put(`u/enable-shared-samples`, {ids})
  }

  async disableSharedSamples(ids: number[]): Promise<{ result: string }> {
    return this.api.put(`u/disable-shared-samples`, {ids})
  }

  async getReceivingUsers(userUuid: number): Promise<Organization[]> {
    const res = await this.api.get(`u/receiving-users?uuid=${userUuid}`)
    return res.result
  }

  async shareSamples(samples: any, users) {
    return this.api.put(`u/share-samples`, {samples, users})
  }

  async getSharedDashboardData(data) {
    return this.api.get(`u/shared-dashboard-data?uuid=${data.uuid}&from=${data.from}&to=${data.to}`)
  }
}
