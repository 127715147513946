<div class="container py-3">

  <section class="products mt-3 p-3 card">
    <h3>{{ 'settings.title-products' | translate }}</h3>

    <form [formGroup]="form" (ngSubmit)="save()">
      <fieldset [disabled]="busy">

        <div class="form-group">
          <label for="product-select">{{ 'settings.products-label' | translate }}</label>
          <select (change)="selectProduct()" class="form-control custom-select" id="product-select"
            formControlName="product">
            <ng-container *ngFor="let l of productLines">
              <option [ngValue]="p" *ngFor="let p of l.products">{{ p.name }} ({{ l.name }})</option>
            </ng-container>
          </select>
          <button class="btn btn-link" type="button" (click)="addProduct()">
            <i class="fa fa-plus"></i> {{ 'settings.add-product' | translate }}
          </button>
        </div>

        <!-- Control Values -->

        <h4>{{ 'settings.control-value.title' | translate : { product: productName } }}</h4>
        <div class="row" *ngIf="!isTrainingProduct; else trainingValues">
          <app-form-control class="col-md-6" [showInfo]="true" prefix="settings.control-value.label"
            [control]="form.get('label')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" type="select" [selectOptions]="['mg RE/kg', 'IU/g']"
            prefix="settings.control-value.unit" [control]="form.get('unit')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.target" [control]="form.get('target')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.national-standard" [control]="form.get('nationalStandard')">
          </app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.min-warn" [control]="form.get('minWarn')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.min-limit" [control]="form.get('minLimit')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.max-warn" [control]="form.get('maxWarn')"></app-form-control>
          <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
            prefix="settings.control-value.max-limit" [control]="form.get('maxLimit')"></app-form-control>
        </div>

        <ng-template #trainingValues>
          <div [innerHtml]="'settings.training-value' | translate"></div>
          <div class="row">
            <app-form-control class="col-md-6" [showInfo]="true" steps=".25" type="number"
              prefix="settings.control-value.concentration" [control]="form.get('target')"></app-form-control>
          </div>
        </ng-template>

        <div class="text-right">
          <button *ngIf="form.value.target >= 30" type="button" (click)="openDiluteModal()" class="btn btn-link float-left">
            <i class="fa fa-flask"></i>
            {{ 'settings.dilution-settings' | translate }}
          </button>

          <button type="submit" class="btn btn-primary">
            <i class="fa fa-check fa-fw" aria-hidden="true"></i>
            {{ 'settings.save' | translate }}
          </button>
        </div>
      </fieldset>
    </form>


  </section>

</div>