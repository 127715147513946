<div class="email-confirm-container">
  <nav class="navbar navbar-expand-md navbar-dark bg-primary">
    <a class="navbar-brand" href="#">{{ 'title' | translate }}</a>
  </nav>

  <!-- Confirming complete -->
  <div class="holder" *ngIf="state === 'loading'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'confirm-email.title' | translate }}</h4>
      <div class="card-body spinner">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>

  <!-- Missing token -->
  <div class="holder" *ngIf="state === 'no-token'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'confirm-email.missing-token' | translate }}</h4>
      <div class="card-body ">
          {{ 'confirm-email.missing-token-content' | translate }}
      </div>
    </div>
  </div>

  <!-- Some error complete -->
  <div class="holder" *ngIf="state === 'error'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'confirm-email.error' | translate }}</h4>
      <div class="card-body">
          {{ 'confirm-email.error-content' | translate }}
      </div>
    </div>
  </div>

</div>