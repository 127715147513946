import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { User } from 'src/model/user';
import { ToastService } from 'src/app/services/toast.service';
import { AdminService } from 'src/app/pages-authenticated/admin/admin.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss']
})
export class PasswordResetModalComponent implements OnInit {
  user: User;
  hasError = false;
  showResetForm = false;
  prefix = 'admin.password-reset.';
  busy = false;

  constructor(
    private toasts: ToastService,
    private modal: BsModalRef,
    private us: UserService,
  ) {

  }

  ngOnInit() {
  }

  completed() {
    this.modal.hide();
    this.toasts.success("password-reset.success-toast");
  }

  close() {
    this.modal.hide();
  }

  async sendResetEmail() {

    this.busy = true;
    try {
      await this.us.requestPasswordReset(this.user.email);
      this.modal.hide();
      this.toasts.success("password-reset.sent-success-toast");
    } catch (err) {
      console.error(err);
    }
    this.busy = false;

  }

}
