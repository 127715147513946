import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';

import { OnboardingMessageComponent } from '../shared/onboarding-message/onboarding-message.component';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private active: OnboardingMessageComponent[] = [];
  shown: string[] = [];
  disableTips: boolean;

  get hasTips() { return this.active.length > 0; }

  constructor(
    private us: UserService,
    private api: ApiService,
    private router: Router,
  ) {
    this.us.userChanged.subscribe((u) => {
      this.shown = [...u.tipsShown];
      this.disableTips = u.disableTips;
    });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      debounceTime(2000)
    ).subscribe((event) => {
      //We just navigated to a new page. Show all things that should be shown.

      //Some magic to get the route details.
      let current = this.router.routerState.root;
      while (current.firstChild) {
        current = current.firstChild;
      }
      //If onboarding is delayed, we won't show onboarding message, but instead wait for the page itself to call showAll()
      if (current.snapshot.data.onboarding !== 'delayed') this.showAll();
    });

  }

  register(c: OnboardingMessageComponent) {
    if (c.name === 'not-named') console.warn("Onboarding component not named");
    this.active.push(c);
  }

  unregister(c: OnboardingMessageComponent) {
    const index = this.active.findIndex(comp => comp === c);
    if (index > -1) this.active.splice(index, 1);
  }

  async markShown(name: string, disableTips: boolean) {
    this.shown.push(name);
    this.disableTips = disableTips;
    return this.api.post('u/tip', { name, disableTips });

  }

  async showAll(again = false) {
    const ordered = this.active.sort((a, b) => {
      return Number(a.order) - Number(b.order);
    });

    for (let c of ordered) {
      //If we've already shown it and shouldn't show again, continue to the next.
      if (this.shown.indexOf(c.name) > -1 && !again) continue;

      if (c.popover) {
        await c.show();
      }

    }

  }
}
