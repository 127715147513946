<div class="modal-header">
  <p class="m-0">{{ prefix + 'title' | translate}}</p>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="row">
    <div class="col">
      <app-form-control type="text" [control]="this.form.get('name')" [prefix]="prefix + 'name'"></app-form-control>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
      <button class="btn btn-primary" (click)="rename()">{{prefix + 'save-button' | translate}}</button>
    </div>
  </div>
</div>
