import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import * as moment from 'moment'
import {CacheService} from '../../services/cache.service'
import {DataService} from '../../services/data.service'
import {StoreService} from '../../services/store.service'
import {DeviceType} from '../../../model/deviceType'
import {environment} from '../../../environments/environment'
import {SelectOption} from '../form-control/form-control.component'
import {noWhitespaceValidator} from "../../helpers/validators";

@Component({
  selector: 'app-devices-advanced',
  templateUrl: './devices-advanced.component.html',
  styleUrls: ['./devices-advanced.component.scss']
})
export class DevicesAdvancedComponent implements OnInit {

  @Input() deviceTypes: DeviceType[]
  @Input() measurementTypes: SelectOption[]
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()

  prefix = 'devices.advanced.'
  api = environment.api
  form: FormGroup
  unitOptions = units
  selectedDevice: DeviceType
  minDate = new Date()
  loading = false
  minRanges = {
    chroma: '3',
    chroma3: '3',
    fluoro: '50',
    iron: '1.5',
    carotene: '0.15',
    iodine: '1',
    'vitamin-e': '1',
  }
  maxRanges = {
    chroma: '30',
    chroma3: '30',
    fluoro: '3000',
    iron: '12',
    carotene: '15',
    iodine: '13',
    'vitamin-e': '25',
  }

  displayDevices: any = [
    {
      src: 'assets/img/case-cut-out.png',
      selected: true,
      name: 'iCheck Chroma 3',
      type: 'chroma3'
    },
    {
      src: 'assets/img/case-back.png',
      selected: false,
      name: 'iCheck Chroma',
      type: 'chroma'
    }
  ]

  constructor(
    private cache: CacheService,
    private ds: DataService,
    private store: StoreService,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      measurementTypeId: new FormControl(1),
      lot: new FormControl('', [Validators.required, noWhitespaceValidator]),
      minRange: new FormControl('', Validators.required),
      maxRange: new FormControl('', Validators.required),
      received: new FormControl(moment().format('YYYY-MM-DD'), Validators.required),
      origin: new FormControl('', [Validators.required, noWhitespaceValidator]),
      maintained: new FormControl(''),
      unit: new FormControl(''),
      type: new FormControl(''),
    })

    this.form.controls['measurementTypeId'].valueChanges.subscribe(value => {
      this.displayDevices = this.deviceTypes.filter(dt => dt.measurementTypeId === value)
      if (value === 1) {
        this.selectedDevice = null
      } else {
        this.selectDeviceType(this.displayDevices[0])
      }
    })

    this.displayDevices = this.deviceTypes.filter(dt => dt.measurementTypeId === 1)
    if (this.form.get('measurementTypeId').value !== 1) {
      this.selectedDevice = this.displayDevices[0]
    }
  }

  selectDeviceType(device: DeviceType) {
    this.selectedDevice = device
    this.form.patchValue({
      minRange: device.min,
      maxRange: device.max,
      unit: device.unit,
      type: device.type,
    })
  }

  exitAdvanced() {
    this.onClose.emit(true)
  }

  receivedDateChange($event: Date) {
    const date = moment($event).format('YYYY-MM-DD')
    this.form.get('received').setValue(date)
  }

  async saveDevice() {
    if (!this.selectedDevice) return
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }
    this.loading = true
    this.form.get('organizationId').setValue(this.cache.organization.getValue().id)
    this.store.set('device-set', true)
    this.form.get('type').setValue(this.selectedDevice.type)
    this.form.get('unit').setValue(this.unitOptions[0])
    this.form.get('maintained').setValue(null)
    const qqq = await this.ds.addDevice(this.form.value)
    this.loading = false
    this.exitAdvanced()
  }
}

export const units = [
  'mg RE/kg',
  'IU/g',
  'AU'
]
