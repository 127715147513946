<div class="btn-group dropdown-container mb-3" dropdown>
  <p *ngIf="showLabel" class="text-dark fz-14 mb-2">{{prefix + 'label' | translate}}</p>
  <button id="button-basic-pl" dropdownToggle type="button"
          class="btn dropdown-toggle text-dark dropdown-btn" aria-controls="dropdown-basic">
                              <span
                                *ngIf="selectedProduct">{{selectedProduct.name}}</span>
    <span
      *ngIf="!selectedProduct">{{prefix + 'placeholder' | translate}}</span>
<!--    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path-->
<!--        d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"-->
<!--        fill="#BDBDBD"/>-->
<!--    </svg>-->
  </button>
  <ul id="dropdown-products" *dropdownMenu
      class="dropdown-menu w-100"
      role="menu"
      aria-labelledby="button-basic"
      [ngStyle]="{maxHeight: menuMaxHeight}">
    <ng-container *ngIf="productLines.length; else noPl">
      <div class="product-lines-container">

        <div class="product-lines">
          <li *ngFor="let pl of productLines" class="disabled product-lines" role="menuitem">
            <div>
              <div class="product-line-name">
                <a class="dropdown-item text-dark p-0 disabled name text-overflow-100"
                   href="javascript:void(0)">
                  {{ pl.name }}
                </a>
              </div>

              <div *ngIf="pl.products.length; else noP">
                <a class="dropdown-item product"
                   *ngFor="let p of pl.products"
                   [class.active]="selectedProduct && p.id === selectedProduct.id"
                   (click)="selectProduct(p)">
                  <p class="product-name text-dark pointer m-0 text-overflow-100">{{ p.name }}</p>
                </a>
              </div>
              <ng-template #noP>
                <div class="no-product">
                  <p class="m-0 text-danger error-text">{{prefix + 'no-products' | translate}}</p>
                </div>
              </ng-template>

            </div>
          </li>
        </div>
      </div>
    </ng-container>
    <ng-template #noPl>
      <div class="no-product-lines">
        <p class="m-0 text-danger error-text">{{prefix + 'no-product-lines' | translate}}</p>
      </div>
    </ng-template>
  </ul>
</div>
