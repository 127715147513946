import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {TestKit} from '../../../model/testKit'
import {Router} from '@angular/router'
import {ModalService} from '../modal.service'
import {StockService} from '../../services/stock.service'
import {TestKitModalComponent} from '../test-kit-modal/test-kit-modal.component'
import {Organization} from '../../../model/organization'

@Component({
  selector: 'app-test-kits-table',
  templateUrl: './test-kits-table.component.html',
  styleUrls: ['./test-kits-table.component.scss']
})
export class TestKitsTableComponent implements OnInit {

  @Input() testKits: TestKit[]
  @Input() canCreate: boolean
  @Input() organization: Organization
  @Input() canDelete: boolean
  @Output() onDeleted: EventEmitter<number> = new EventEmitter<number>()
  @Output() onUpdated: EventEmitter<TestKit> = new EventEmitter<TestKit>()

  constructor(
    private router: Router,
    private modals: ModalService,
    private stock: StockService
  ) {
  }

  ngOnInit(): void {
  }

  async orderMore(tk: TestKit) {
    this.router.navigate(['/tickets/new'], {
      queryParams: {
        category: 'order',
        deviceType: tk.type,
        quantity: tk.quantity
      }
    })
  }

  async actionDropdown(action, tk) {
    if (action === 'delete') {
      await this.deleteTestKit(tk)
    } else if (action === 'edit') {
      await this.openStockModal(tk)
    }
  }

  async openStockModal(testKit: TestKit) {
    const result = await this.modals.show(TestKitModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        testKit,
        organization: this.organization,
        canDelete: this.canDelete
      }
    })

    if (!result) return
    this.onUpdated.emit(result)
  }

  async deleteTestKit(testKit?: TestKit) {
    const modalResult = await this.modals.showConfirm()
    if (!modalResult) return
    await this.stock.deleteTestKit(testKit.id)
    this.onDeleted.emit(testKit.id)
  }
}
