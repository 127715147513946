import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscriptions} from '../../../../model/subscriptions'
import {Initiator} from '../../../../model/Initiator'
import {Product} from '../../../../model/Products'
import {TrainingAttempt} from '../../../../model/TrainingAttempt'
import {TrainingSearchCriteria} from '../../../../model/search-criteria'
import {User} from '../../../../model/user'
import {DataService} from '../../../services/data.service'
import {CacheService} from '../../../services/cache.service'
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router'
import {ToastService} from '../../../services/toast.service'
import {UserService} from '../../../services/user.service'
import {TranslateService} from '@ngx-translate/core'
import {ModalService} from '../../../shared/modal.service'
import {AddTrainingDataModalComponent} from '../add-training-data-modal/add-training-data-modal.component'

@Component({
  selector: 'app-training-results',
  templateUrl: './training-results.component.html',
  styleUrls: ['./training-results.component.scss']
})
export class TrainingResultsComponent implements OnInit, OnDestroy {
  prefix = 'training.';

  private _subs = new Subscriptions();
  private init = new Initiator(['products', 'users']);

  products: Product[] = [];
  selectedProduct: Product;// = { id: -1, name: "..." };

  attempts: TrainingAttempt[] = [];
  lastAttempt: TrainingAttempt;
  search: TrainingSearchCriteria;
  bsDateRange: Date[] = [];
  selectedAttempt: TrainingAttempt;

  userFilter: any[] = [];

  anyData = false;

  user: User;

  get viewingMyselfOnly() { return this.search.isAboutMeOnly(this.user.uuid); }

  constructor(
    private dataService: DataService,
    private cache: CacheService,
    private route: ActivatedRoute,
    private router: Router,
    private toasts: ToastService,
    private us: UserService,
    private trx: TranslateService,
  ) {
    //Init Search
    this.search = TrainingSearchCriteria.deserialize(this.route.snapshot.queryParams);
    this.bsDateRange = this.search.getDateRangePickerDates();
    this.user = this.us.userChanged.value;
  }

  async ngOnInit() {
    //Listen for Products
    this._subs.add(this.cache.productLines.subscribe((lines) => {
      const trainingProductLine = lines.find(l => l.training);
      if (!trainingProductLine || trainingProductLine.products.length === 0) {
        this.router.navigate(['/training', 'getting-started']);
        return;
      }
      this.products = trainingProductLine.products;

      this.init.complete('products');
    }));

    this._subs.add(this.cache.users.subscribe(users => {
      //Make user Filter
      this.userFilter = [];
      const me = this.us.userChanged.getValue() || {uuid: 'nope'};

      for (const u of users) {
        let name = u.getName();
        if (u.uuid === me.uuid) {
          name += ` (${this.trx.instant('me')})`;
        }
        this.userFilter.push({name, uuid: u.uuid, selected: this.search.userUuids.indexOf(u.uuid) > -1});
      }
      this.init.complete('users');
    }));

    await this.afterInit();
  }

  async afterInit() {
    await this.init.ready();

    //Listen for Query Params.
    this._subs.add(this.route.queryParams.subscribe(p => {
      //Note: These will always fire on subscribe.
      this.search = TrainingSearchCriteria.deserialize(p);
      if (this.search.productId) {
        this.selectedProduct = this.products.find(p => p.id === this.search.productId);
      } else if (this.selectedProduct) {
        this.search.productId = this.selectedProduct.id;
      }
      this.search.organizationId = p.organizationId
      if (!this.selectedProduct) {
        this.selectFirst();
      }

      this.resetUserFilter();
      this.loadCharts();
    }));
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll();
  }

  resetUserFilter() {
    //Reset Selections
    // Note: Reassigning the variable is needed to make the component recalculate the label.
    this.userFilter = this.userFilter.map(u => {
      u.selected = this.search.userUuids.indexOf(u.uuid) > -1;
      return u;
    });
  }

  userFilterChanged(event: any[]) {
    const users = event.map(i => i.uuid);
    this.router.navigate(['/training'], {queryParamsHandling: 'merge', queryParams: {users}});
  }

  private selectFirst(): Product {
    if (this.products.length > 0) {
      this.search.productId = this.products[0].id;
      this.selectedProduct = this.products[0];
      return;
    }

    //Set some message that we don't have any products.
    //todo send a "create productline command"

    this.toasts.info(this.prefix + 'no-products');
    return;
  }

  private async loadCharts() {
    this.search.limit = (this.search.isAboutMeOnly(this.user.uuid)) ? 3 : 15;
    const data = await this.dataService.getTrainingResults(this.search);
    console.log('this.searchthis.searchthis.search', data)
    this.attempts = data.attempts;
    if (this.attempts.length > 0) {
      this.selectedAttempt = this.attempts[this.attempts.length - 1];
      this.lastAttempt = this.attempts[this.attempts.length - 1];
    }

    this.fixChartsTitle();

    this.anyData = (this.attempts.length > 0);
  }

  private fixChartsTitle() {
    this.attempts.map((a) => {
      a.controlChart.title.align = 'left';
      a.controlChart.legend.layout = 'horizontal';
      a.controlChart.legend.align = 'left';
      a.controlChart.legend.verticalAlign = 'bottom';
      a.controlChart.title.verticalAlign = 'top';
      a.controlChart.subtitle.align = 'left';
    });
  }



}
