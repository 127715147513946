<form [formGroup]="form" (ngSubmit)="submit()">
  <fieldset [disabled]="loading">
<div class="row">

</div>
<!--    &lt;!&ndash; Organization Name &ndash;&gt;-->
<!--    <div class="form-group">-->
<!--      <label for="name">-->
<!--        {{ 'register.org.name' | translate }}-->
<!--      </label>-->
<!--      <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="nameHelp"-->
<!--             placeholder="{{ 'register.org.name-placeholder' | translate }}"-->
<!--             [class.is-invalid]="name.invalid && name.touched">-->
<!--      <ng-container *ngIf="name.invalid && name.touched ">-->
<!--        <small class="invalid-feedback"-->
<!--               *ngIf="name.errors.required">{{ 'register.org.name-required' | translate }}</small>-->
<!--        <small class="form-text text-danger"-->
<!--               *ngIf="!name.errors.required && name.errors.invalidName">{{ 'register.org.name-invalid' | translate }}</small>-->
<!--      </ng-container>-->
<!--    </div>-->
    <app-form-control
      class="col-md-6 mb-2"
      type="new-input"
      [prefix]="prefix + 'name'"
      [control]="name"
      bg="#f7f7f8"
    ></app-form-control>

    <app-form-control
      class="col-md-6 mb-2"
      type="new-input"
      optional="optional"
      [prefix]="prefix + 'phone'"
      [control]="phone"
      bg="#f7f7f8"
    ></app-form-control>

    <app-form-control
      class="col-md-6 mb-2"
      type="address"
      [prefix]="prefix + 'address'"
      [control]="address"
      bg="#f7f7f8"
    ></app-form-control>

    <app-form-control
      class="col-md-6 mb-2"
      type="address"
      [prefix]="prefix + 'address2'"
      [control]="address2"
      optional="optional"
      bg="#f7f7f8"
    ></app-form-control>


    <!-- Address -->
<!--    <div class="form-group">-->
<!--      <label class="" for="address">{{ 'register.org.address' | translate }}</label>-->
<!--      <input #addressInput type="text" class="form-control" id="address" formControlName="address" aria-describedby="addressHelp"-->
<!--             placeholder="{{ 'register.org.address-placeholder' | translate }}"-->
<!--             [class.is-invalid]="address.invalid && address.touched">-->
<!--      <ng-container *ngIf="address.invalid && address.touched">-->
<!--        <small class="form-text text-danger"-->
<!--               *ngIf="address.errors.required">{{ 'register.org.address-required' | translate }}</small>-->
<!--        <small class="form-text text-danger"-->
<!--               *ngIf="!address.errors.required && address.errors.invalidAddress">{{ 'register.org.name-invalid' | translate }}</small>-->
<!--      </ng-container>-->

<!--      <label class="mt-3" for="address2">{{ 'register.org.address2' | translate }}</label>-->
<!--      <input #addressInput2 type="text" class="form-control" id="address2" formControlName="address2"-->
<!--             aria-describedby="address2Help" placeholder="{{ 'register.org.address2-placeholder' | translate }}">-->
<!--    </div>-->

    <!-- Postal Code -->
<!--    <div class="new-input">-->
<!--      <label class="" for="postal">{{ 'register.org.postal' | translate }}</label>-->
<!--      <input type="text" class="form-control" id="postal" formControlName="postalCode" aria-describedby="postalHelp"-->
<!--             placeholder="{{ 'register.org.postal-placeholder' | translate }}"-->
<!--             [class.is-invalid]="postalCode.invalid && postalCode.touched">-->
<!--      <ng-container *ngIf="postalCode.invalid && postalCode.touched">-->
<!--        <small class="form-text text-danger"-->
<!--               *ngIf="postalCode.errors.required">{{ 'register.org.postal-required' | translate }}</small>-->
<!--      </ng-container>-->
<!--    </div>-->

    <!-- Country -->
    <div class="country-postal-code">
      <div class="countries position-relative">
        <label for="country" class="fz-14 text-dark">{{ prefix + 'country.label' | translate }}</label>

        <input id="country" formControlName="country" [typeahead]="countries" [typeaheadOptionsLimit]="10" [typeaheadMinLength]="1"
               placeholder="{{ prefix + 'country.placeholder' | translate }}" class="countries-input"
               [class.is-invalid]="country.invalid && country.touched">
        <ng-container *ngIf="country.invalid && country.touched">
          <small class="form-text text-danger position-absolute"
                 *ngIf="country.errors.required">{{ prefix + 'country.error-required' | translate }}</small>
          <small class="form-text text-danger position-absolute"
                 *ngIf="country.errors.invalidCountry">{{ prefix + 'country.error-invalid' | translate }}</small>
        </ng-container>
      </div>

      <app-form-control
        class="postal-code"
        type="address"
        [prefix]="prefix + 'postal-code'"
        [control]="postalCode"
        optional="optional"
        [marginBottom]="0"
        bg="#f7f7f8"
      ></app-form-control>
    </div>

    <!-- Phone -->
<!--    <div class="form-group">-->
<!--      <label class="" for="phone">{{ 'register.org.phone' | translate }}</label>-->
<!--      <input type="text" class="form-control" id="phone" formControlName="phone" aria-describedby="phoneHelp"-->
<!--             placeholder="{{ 'register.org.phone-placeholder' | translate }}"-->
<!--             [class.is-invalid]="phone.invalid && phone.touched">-->
<!--      <ng-container *ngIf="phone.invalid && phone.touched">-->
<!--            <span *ngIf="phone.errors.pattern">-->
<!--              <small class="form-text text-danger">{{ 'register.org.phone-pattern' | translate }}</small>-->
<!--              <small class="form-text text-danger">{{ 'register.org.phone-pattern-example' | translate }}</small>-->
<!--            </span>-->
<!--      </ng-container>-->
<!--    </div>-->

    <div class="buttons">
      <button type="button" (click)="goBack()" class="back-button">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM3 7H1L1 9H3V7Z"
            fill="#06A3AE"/>
        </svg>
      </button>
      <button type="submit" class="cont-button">{{ prefix + 'continue' | translate }}</button>
    </div>
  </fieldset>

  <div class="spinner" *ngIf="loading" [@fade]>
    <app-spinner></app-spinner>
  </div>

</form>
