import {Organization} from './organization'
import {User} from './user'

export class Notification {
  id?: number
  text: string
  routerLink?: any
  queryParams?: any
  icon?: string
  value?: any
  type?: string
  new?: boolean
  created?: number
  viewed?: boolean
  forSuperAdmin?: boolean
  showUser?: boolean
  showOrganization?: boolean
  user?: User
  organization?: Organization
  notificationType?: any

  static fromApi(data: any) {
    if (!types[data.type]) {
      return types.unknown
    }

    const n = new Notification()
    Object.assign(n, types[data.type])
    n.value = data.value
    n.type = data.type
    n.id = data.id
    n.new = data.new
    n.viewed = data.viewed
    n.created = data.created
    n.showUser = data.showUser
    n.showOrganization = data.showOrganization
    n.user = data.user
    n.organization = data.organization


    return n
  }

  static manyFromApi(data: any[]): Notification[] {
    if (!data) return []
    const nots = []
    for (let d of data) {
      nots.push(Notification.fromApi(d))
    }
    return nots
  }
}

let types: { [p: string]: Notification }
types = {
  // "pending-registrations": {
  //   routerLink: ['/admin/approval'],
  //   text: "notification.pending-registrations",
  //   icon: "fa-user-plus",
  // },
  // "ticket-update": {
  //   routerLink: ['/tickets/mine'],
  //   text: "notification.ticket-update",
  //   icon: "fa-envelope-o",
  // },
  // "support-ticket-update": {
  //   routerLink: ['/support/tickets'],
  //   text: "notification.support-ticket-update",
  //   icon: "fa-bullhorn",
  // },
  // "new-support-tickets": {
  //   routerLink: ['/support/tickets'],
  //   text: "notification.new-support-tickets",
  //   icon: "fa-bullhorn",
  // },
  // "unknown": {
  //   text: "notification.unknown",
  //   icon: "fa-exclamation",
  // },
  'no-data': {
    text: "no-data",
    icon: "fa-check",
  },
  'failed-training-attempts': {
    text: 'failed-training-attempts',
    icon: "fa-check"
  },
  'device-control-is-not-in-range': {
    text: 'device-control-is-not-in-range',
    icon: "fa-bullhorn",
  },
  'test-kits-left': {
    text: 'test-kits-left',
    icon: "fa-bullhorn",
  },
  'successful-training': {
    text: 'successful-training',
    icon: "fa-bullhorn",
  },
  'unsuccessful-training': {
    text: 'unsuccessful-training',
    icon: "fa-bullhorn",
  },
  'precision-middle': {
    text: 'precision-middle',
    icon: "fa-bullhorn",
  },
  'unsuccessful-last-two-trainings': {
    text: 'unsuccessful-last-two-trainings',
    icon: "fa-bullhorn",
  },
  'device-control-not-in-range': {
    text: 'device-control-not-in-range',
    icon: "fa-bullhorn",
  },
  unknown: {
    text: "unknown",
    icon: "fa-exclamation",
  }
}
