import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {

  text: string;
  placeholder: string = "input-modal.placeholder";
  title: string = "input-modal.title";
  @ViewChild('input', {static: true}) input: ElementRef;
  
  get modalResult() { return this.text; }

  constructor(public modal: BsModalRef) { }

  ngOnInit() {
    setTimeout(() => { this.input.nativeElement.focus(); });
    
  }


}
