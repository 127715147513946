import {Component, OnInit} from '@angular/core'
import {documentation} from '../../../../model/documentation'

@Component({
  selector: 'app-manuals',
  templateUrl: './manuals.component.html',
  styleUrls: ['./manuals.component.scss']
})
export class ManualsComponent implements OnInit {
  prefix = 'manuals.'
  links = {
    other: []
  }

  constructor() {
  }

  ngOnInit(): void {
    documentation.forEach(d => {
      if (d.device) {
        if (this.links && this.links.hasOwnProperty(d.device)) {
          this.links[d.device].push(d)
        } else {
          this.links[d.device] = []
          this.links[d.device].push(d)
        }
      } else {
        this.links.other.push(d)
      }
    })

    console.log('links', this.links)
  }


}
