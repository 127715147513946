<!--<div class="modal-header">-->
<!--  <h5 class="modal-title pull-left">{{prefix + 'title' | translate}}</h5>-->
<!--  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">-->
<!--    <span aria-hidden="true">&times;</span>-->
<!--  </button>-->
<!--</div>-->

<div class="head">
  <div class="top">
    <p class="title">{{prefix + 'title' | translate }}</p>
    <button class="close-btn" (click)="modal.hide()">
      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.967773 0.75L8.90346 8.25M0.967773 8.25L8.90346 0.75L0.967773 8.25Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>

</div>


<div class="body">
  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy" class="row">
      <app-form-control type="new-input" class="col-12" [prefix]="prefix + 'product-name'"
                        [control]="form.get('productLine')"></app-form-control>
      <div class="col-12">
        <button type="submit" class="save-btn">
          {{ prefix + 'save' | translate }}
        </button>
      </div>
    </fieldset>
  </form>
</div>
