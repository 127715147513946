import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject} from 'rxjs'

import {LoginResponse, RegisterResponse} from '../../model/api-response'
import {Credentials} from '../../model/credentials'
import {Organization} from '../../model/organization'
import {User} from '../../model/user'
import {ApiService} from './api.service'
import {CacheService} from './cache.service'
import {NotificationService} from './notification.service'
import {StoreService} from './store.service'
import {ToastService} from './toast.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userChanged = new BehaviorSubject<User>(null)
  userpic: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  private _user: User
  private _init: Promise<void>

  constructor(
    private api: ApiService,
    private store: StoreService,
    private router: Router,
    private toasts: ToastService,
    private cache: CacheService,
    private ns: NotificationService,
  ) {
  }

  async init() {
    if (!this._init) {
      this._init = this.checkSession()
    }

    return this._init
  }

  private async checkSession() {
    //Get user from storage
    const user: User = this.store.get('user', null, User)
    if (!user) {
      console.log("No user in storage")
      return
    }
    if (!user.session) {
      console.warn("No user session. That's weird.")
      return
    }

    //Check Session expired
    if (user.session.expires.isBefore()) {
      this.toasts.info("errors.session-expired")
      this.clearSession(user.email)
      return
    }

    try {
      const result = await this.api.get("check-session/" + user.session.token)
      console.log('result', result)
      this.processSessionResponse(result)
      //user = User.deserialize(result.user);
    } catch (err) {
      if (err.status === 422) {
        this.toasts.info("errors.session-expired")
        this.clearSession(user.email)
        return
      }
      console.error("CheckSession Error", err)
      //Else ignore...
      return
    }
  }

  logout() {
    const email = this._user ? this._user.email : null
    this.clearSession(email)
  }

  private clearSession(email?: string) {
    this._user = null
    this.api.unsetToken()
    this.store.remove('user')
    this.store.remove('training-index')
    this.store.remove('training_progress')
    this.store.remove('dashboard_params')
    this.cache.notifications.next(null)
    this.ns.stop()

    if (email) {
      //Save email for login ease.
      this.store.set("expiredEmail", email)
    }

    this.router.navigate(["auth/login"])
    this.userChanged.next(null)
  }

  async isLoggedIn(clear = false): Promise<boolean> {
    await this.init()
    if (!this._user) {
      if (clear) this.clearSession()
      return false
    } else {
      return true
    }
  }

  async findOrgByDomain(email) {
    const res = await this.api.get(`findOrgByDomain?email=${encodeURIComponent(email)}`)
    return res.org
  }

  async register(reg: any): Promise<RegisterResponse> {
    return this.api.post("register", reg)
  }

  async confirmEmail(token: string) {
    return this.api.post('confirm-email', {token})
  }

  async requestPasswordReset(email: string) {
    return this.api.post('request-password-reset', {email})
  }

  async resetPasswordWithToken(data: { password: string, token: string }) {
    return this.api.post('password-reset', data)
  }

  async changePassword(data: { password: string, oldPassword: string }) {
    return this.api.put('u/password', data)
  }

  async registerOrg(user: User, organization: any): Promise<Organization> {
    return this.api.post("organization", {organization, userUuid: user.uuid})
  }

  async addOrgCode(body: { uuid: string, orgCode: string }): Promise<User> {
    const response = await this.api.put("user-organization", body)
    return User.deserialize(response.user)
  }

  async checkOrgExist(organization) {
    return await this.api.post('org-exist', {organization})
  }

  async login(cred: Credentials) {
    const response = await this.api.post("login", cred)

    if (response.result !== "ok") throw response

    this.processSessionResponse(response)
    return this._user
  }

  async checkUserExist(e: string): Promise<boolean> {
    return await this.api.post('check-user', {email: e})
  }

  async updateUser(data): Promise<User> {
    const result = await this.api.put('u/update-user', data)
    await this.checkSession()
    return result.user
  }

  async updateUserPic(ref) {
    const userpic = await this.api.post('u/update-userpic', ref)
    this.userpic.next(userpic.result)
    return userpic.result
  }

  async getUserPic() {
    const userpic = await this.api.get('u/user-pic')
    this.userpic.next(userpic.result)
    return userpic.result
  }

  updateUP(userpic) {
    this.userpic.next(userpic)
  }

  async getNotificationsPage(page) {
    return await this.api.get(`u/notifications-page?page=${page}`);
  }

  private async processSessionResponse(response: LoginResponse) {
    const u = User.deserialize(response.user)
    this._user = u

    this.store.set("user", u)
    this.store.remove("expiredEmail")
    this.store.remove("registerState")
    this.store.remove("registeredUser")
    this.api.setToken(u.session.token)
    this.cache.devices.value = response.devices
    this.cache.productLines.value = response.productLines
    this.cache.organization.value = u.organization
    this.cache.users.value = response.users.map(user => User.deserialize(user))
    this.cache.docTypes.value = response.appSettings.documentTypes
    this.cache.productCategories.value = response.productCategories
    this.cache.deviceTypes.value = response.types.deviceTypes
    this.cache.measurementTypes.value = response.types.measurementTypes
    this.cache.testKitTypes.value = response.types.testKitTypes
    this.cache.productTypes.value = response.types.productTypes

    // Kick-off user-logged-in event.
    this.userChanged.next(u)
    await this.getUserPic()
  }
}
