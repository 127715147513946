<main>
  <h1 class="title text-white text-center">{{ 'intro.welcome' | translate : {fName: user?.firstName} }}</h1>
  <p class="mt-2 subtitle text-white text-center">{{'intro.content' | translate}}</p>

  <div class="steps">
    <div class="step" *ngFor="let s of steps;let idx = index">
      <a [routerLink]="s.r_link" class="image-container">
        <img class="step-img" [src]="s.icon" [alt]="'step' + (idx + 1)">
        <img src="assets/icons/white-arrow-right.svg" *ngIf="idx !== 4" class="arrow">
      </a>

      <div class="text-container">
        <p class="step-title">{{'0' + (idx + 1)}} {{ prefix + 'steps.' + s.name + '.title' | translate }}</p>
        <p class="step-description">{{ prefix + 'steps.' + s.name + '.content' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="text-center mt-5">
    <button type="button" class="btn" routerLink="/products">
      <p class="mr-3 d-inline">{{'intro.get-started' | translate}}</p>
      <img class="ml-3" src="assets/icons/white-small-arrow-right.svg">
    </button>
  </div>


</main>
