import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent {
  @Input() prefix = "search";
  @Output() doSearch = new EventEmitter<string>();
  
  q: FormControl = new FormControl('');

  constructor() { }

  submit() {
    this.doSearch.next(this.q.value);
  }

}
