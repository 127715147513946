import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from 'src/app/shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RegisterComponent } from 'src/app/auth/registration/register/register.component';
import { RegisterUserComponent } from 'src/app/auth/registration/register-user/register-user.component';
import { RegisterOrganizationComponent } from 'src/app/auth/registration/register-organization/register-organization.component';
import { AddOrgToUserComponent } from 'src/app/auth/registration/add-org-to-user/add-org-to-user.component';
import { AuthRoutingModule } from 'src/app/auth/auth-routing.module';
import { PasswordResetComponent } from 'src/app/auth/password-reset/password-reset.component';
import { RequestPasswordResetComponent } from 'src/app/auth/request-password-reset/request-password-reset.component';
import { ConfirmEmailComponent } from 'src/app/auth/confirm-email/confirm-email.component';
import { PasswordResetFormComponent } from 'src/app/auth/shared/password-reset-form/password-reset-form.component'
import { PasswordResetModalComponent } from 'src/app/auth/shared/password-reset-modal/password-reset-modal.component'

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    RegisterUserComponent,
    RegisterOrganizationComponent,
    AddOrgToUserComponent,
    PasswordResetComponent,
    RequestPasswordResetComponent,
    ConfirmEmailComponent,
    PasswordResetFormComponent,
    PasswordResetModalComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    SharedModule,
    TypeaheadModule,
  ],
  entryComponents: [
    PasswordResetModalComponent
  ]
})
export class AuthModule { }
