import { Moment } from 'moment';
import * as moment from 'moment';
import { toObject } from './helpers';

export class Session {
  id: number = null;
  userId: number = null;
  created: Moment = null;
  expires: Moment = null;
  token: string = null;

  static deserialize(data: any): Session {
    let s = toObject<Session>(Session, data);

    if (data.created) s.created = moment.unix(data.created);
    if (data.expires) s.expires = moment.unix(data.expires);

    return s;
  }

  serialize() {
    const o: any = {...this};

    if (this.created) o.created = this.created.unix();
    if (this.expires) o.expires = this.expires.unix();

    return o;
  }
}