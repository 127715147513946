import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  token: string;
  state = "loading";

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private us: UserService,
    private toasts: ToastService,
  ) {
    this.token = route.snapshot.queryParams.token;
  }

  async ngOnInit() {
    if (!this.token) {
      this.state = "no-token";
      return;
    }

    try {
      await this.us.confirmEmail(this.token);
      this.router.navigate(['/auth/login']);
      this.toasts.info("confirm-email.toast-success");
    } catch (err) {
      this.state = "error";
    }

  }

}
