<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate: { name: user.getName() } }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="!loading">

  <app-search-control (doSearch)="doSearch($event)"></app-search-control>

  <div *ngIf="uuid; else noUuid">
    <ul class="list-group mt-3" *ngIf="page">
      <li (click)="select(o)" class="list-group-item" [class.active]="o === selected"
          *ngFor="let o of page.organizations">
        <i [ngClass]="o === selected ? 'fa-dot-circle-o' : 'fa-circle-o'" class="fa fa-fw mr-2"></i> {{ o.name }}  <span style="color: #bcbcbc;"> Code: {{o.code}}</span>
      </li>
    </ul>
  </div>

  <ng-template #noUuid>
    <p>No user uuid</p>
  </ng-template>

  <app-spinner *ngIf="loading"></app-spinner>
</div>

<div class="modal-footer">
  <button (click)="modal.hide()" class="btn btn-light">{{ prefix + 'cancel' | translate }}</button>
  <button (click)="done()" [disabled]="!selected" class="btn btn-primary">{{ prefix + 'select' | translate }}</button>
</div>
