import { Injectable } from '@angular/core';
import { ControlValue } from 'src/model/control-value';
import { HttpClient } from '@angular/common/http';
import { CacheService } from 'src/app/services/cache.service';
import { Product } from 'src/model/Products';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private cacheService: CacheService) { }

  saveSettings(product: Product, removedControlValueIds?: number[]): Observable<{ result: string, product: Product }> {
    return this.http.post<{ result: string, product: Product }>('u/control-values', {product, removedControlValueIds});
  }

  deleteProduct(product: Product) {
    const lineFromCache = this.cacheService.productLines.value.find(i => i.id === product.productLineId);
    if (!lineFromCache) {
      console.error('No product line for product', product);
      return;
    }

    const index = lineFromCache.products.findIndex(i => i.id === product.id);
    if (index === -1) return;
    lineFromCache.products.splice(index, 1);

    //Trigger update
    this.cacheService.productLines.next(this.cacheService.productLines.value);
    return this.http.delete(`u/product/${product.id}`);
  }

  updateProductName(product: Product,): Observable<Product> {
    return this.http.put<Product>('u/product-name', {product});
  }

  saveProduct(product: Product) {
    console.log('product', product)
    return this.http.post('u/product', product).pipe(
      map((response: any) => {
        product.id = response.product.id;

        const lineFromCache = this.cacheService.productLines.value.find(i => i.id === product.productLineId);
        if (!lineFromCache) {
          console.error('No product line for product', product);
          return;
        }
        const productFromCache = lineFromCache.products.find(i => i.id === product.id);
        if (productFromCache) {
          Object.assign(productFromCache, product);
        } else {
          lineFromCache.products.push(product);
        }

        //Trigger update
        this.cacheService.productLines.next(this.cacheService.productLines.value);

        return response
      })
    );
  }
}
