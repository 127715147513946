import {Component, OnInit, ViewChild} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {FileUploadComponent, FileUploadStatus} from '../file-upload/file-upload.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import PackageJson from '../../../../package.json'
import {TicketService} from '../../services/ticket.service'
import {ToastService} from '../../services/toast.service'


@Component({
  selector: 'app-create-bug-report-modal',
  templateUrl: './create-bug-report-modal.component.html',
  styleUrls: ['./create-bug-report-modal.component.scss']
})
export class CreateBugReportModalComponent implements OnInit {
  @ViewChild('upload') uploadComponent: FileUploadComponent


  prefix = 'report-bug.'
  form: FormGroup
  fileUploadInProgress = false
  files: FileUploadStatus[] = []
  busy = false
  appVersion = PackageJson.version

  constructor(
    public modal: BsModalRef,
    private ts: TicketService,
    private toasts: ToastService,
  ) {
    this.form = new FormGroup({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
  }

  resetAll() {
    this.form.reset()
    this.files = []
    this.uploadComponent.delete()
  }

  progress(p) {
    this.fileUploadInProgress = p.done
    if (!p.name) return

    const current = this.files.find(gotten => gotten.name === p.name)
    if (current) {
      Object.assign(current, p)
    } else {
      this.files.push(p)
    }

    this.fileUploadInProgress = !p.done
  }

  async onSubmit() {
    this.form.markAllAsTouched()
    console.log('form valid', !this.form.valid)
    if (!this.form.valid) return

    const formData = {...this.form.value}
    formData.references = this.files.map(f => f.reference)

    this.busy = true
    try {
      const res = await this.ts.reportBug(formData)
      if (res.status === 'ok') {
        this.toasts.success('report-bug.success')
        this.modal.hide()
      }
      console.log(res)
      this.busy = false
    } catch (e) {
      console.log(e)
      this.busy = false
    }
  }

  remove(f: FileUploadStatus) {
    const index = this.files.findIndex(gotten => gotten === f)
    if (index > -1) this.files.splice(index, 1)
    this.fileUploadInProgress = false
  }
}
