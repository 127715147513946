import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output} from '@angular/core'

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

  constructor(private el: ElementRef) {
  }

  @HostBinding('class.file-over') fileOver: boolean
  @Output() fileDropped = new EventEmitter<any>()

  // Dragover listener
  @HostListener('dragenter', ['$event']) onDragOver(e) {
    e.preventDefault()
    e.stopPropagation()
    this.fileOver = true
    console.log('enter')
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(e) {
    if (this.el.nativeElement.contains(e.target)) return
    e.preventDefault()
    e.stopPropagation()
    this.fileOver = false
    console.log('leave')
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(e) {
    e.preventDefault()
    e.stopPropagation()
    this.fileOver = false
    const file = e.dataTransfer.files[0]
    if (file) {
      this.fileDropped.emit(file)
    }
  }
}
