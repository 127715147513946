<div *ngIf="busy; else table" class="text-center">
  <app-spinner></app-spinner>
</div>

<ng-template #table>
  <table class="table table-hover">
    <thead>
    <tr>
      <th scope="col">
        <div class="custom-control custom-checkbox selectable-col-check" (change)="selectAllDocuments($event)">
          <input id="selectAll" type="checkbox" class="custom-control-input">
          <label class="custom-control-label" for="selectAll"></label>
        </div>
      </th>
      <th scope="col" class="sort font-weight-light">
        <img class="mr-2" src="assets/icons/sort-arrows.svg" alt="sort">
        {{ 'docs.table.name' | translate }}
      </th>
      <th></th>
      <th></th>
      <th scope="col" class="sort font-weight-light">
        <img class="mr-2" src="assets/icons/sort-arrows.svg" alt="sort">
        {{ 'docs.table.date' | translate }}
      </th>
      <th scope="col" class="font-weight-light">
        {{ 'docs.table.type' | translate }}
      </th>
      <th scope="col" class="font-weight-light">
        {{ 'docs.table.from' | translate }}
      </th>
      <th class="text-right">
        <button class="btn btn-danger btn-sm" *ngIf="selectedDocuments.length" (click)="deleteSelected()">Delete all</button>
      </th>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let d of page.documents">
        <ng-container *ngIf="shouldShow(d)">
          <tr>
            <td>
              <div class="custom-control custom-checkbox selectable-col-check" (change)="selectDocument($event, d)">
                <input [id]="'document-' + d.id" type="checkbox" class="custom-control-input document-checkbox">
                <label class="custom-control-label" [for]="'document-' + d.id"></label>
              </div>
            </td>
    
            <td class="bet">
              <i class="fa mr-3" [ngClass]="d.icon"></i>
              <span *ngIf="d.description; else justName" class="underline pointer"
                    (click)="descriptions[d.id] = !descriptions[d.id]">
                {{ d.name }}
              </span>
              <ng-template #justName>
                {{ d.name }}
              </ng-template>
            </td>
            <td>
              <img class="text-right" [src]="'assets/icons/extension-' + d.attachment.extension.substring(1) + '.svg'"
                   alt="">
    
            </td>
    
            <td>
              <p class="font-weight-light d-inline m-0">{{formatBytes(d.attachment.size)}}</p>
    
            </td>
            <td>
              {{ d.attachment.created.valueOf() | date : 'MM/dd/yyyy' }}
            </td>
            <td>
              <p class="document-type text-primary px-2 py-1 d-inline font-weight-light">{{ 'document.type.' + d.documentType | translate }}</p>
            </td>
            <td>
              {{ d.organization.name }}
            </td>
            <td class="btn-cell">
              <button class="btn btn-sm mr-2" (click)="download(d)" [disabled]="states[d.id] === 'busy'">
                <img src="assets/icons/download.svg">
              </button>
    
              <button *ngIf="d.isMine(user)" class="btn btn-sm" (click)="remove(d)"
                      [disabled]="states[d.id] === 'removing'">
                <img src="assets/icons/delete.svg">
              </button>
            </td>
    
          </tr>
          <tr *ngIf="descriptions[d.id]">
            <td></td>
            <td class="pre-wrap" colspan="5">{{ d.description }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>

  <app-paging [current]="page.page" [last]="page.lastPage" (pageChanged)="getPage.emit($event)"></app-paging>
</ng-template>
