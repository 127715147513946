<form [formGroup]="form" (ngSubmit)="submit()">
  <fieldset [disabled]="loading">
    <!-- Organization Code -->
<!--    <div class="form-group">-->
<!--      <label class="" for="org-code">{{ 'register.add-org-to-user.org-code' | translate }}</label>-->
<!--      <input #input type="text" class="form-control" id="org-code" formControlName="orgCode"-->
<!--             aria-describedby="orgCodeHelp"-->
<!--             placeholder="{{ 'register.add-org-to-user.org-code-placeholder' | translate }}"-->
<!--             [class.is-invalid]="orgCode.invalid && orgCode.touched">-->
<!--      <ng-container *ngIf="orgCode.invalid && orgCode.touched">-->
<!--        <small class="form-text text-danger"-->
<!--               *ngIf="orgCode.errors.invalid">{{ 'register.add-org-to-user.invalid' | translate }}</small>-->
<!--      </ng-container>-->
<!--    </div>-->

    <app-form-control
      class="col-md-12 mb-2"
      type="new-input"
      [maxLength]="6"
      textTransform="uppercase"
      [prefix]="prefix + 'org-code'"
      [control]="orgCode"
      optional="6 characters"
      bg="#f7f7f8"
    ></app-form-control>

    <div class="buttons">
      <button type="button" (click)="goBack()"
              class="back-button">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM3 7H1L1 9H3V7Z"
            fill="#06A3AE"/>
        </svg>
      </button>
      <button type="submit" class="btn btn-primary cont-button">{{ prefix + 'continue' | translate }}</button>
    </div>
  </fieldset>

  <div class="spinner" *ngIf="loading" [@fade]>
    <app-spinner></app-spinner>
  </div>
</form>
