export class Initiator {

  resolved = false;
  private promise = new Promise<void>((resolve, reject) => { this.resolve = resolve; });
  private resolve: Function;

  constructor(private actions?: string[]) { }

  add(action: string) {
    if (this.resolved) throw "Can't add action. Already resolved.";
    this.actions.push(action);
  }

  complete(action: string) {
    //ignore if we're already done.
    if (this.resolved) return;

    const index = this.actions.indexOf(action);
    if (index === -1) return;
    this.actions.splice(index, 1);

    if (this.actions.length === 0) {
      this.resolved = true;
      this.resolve();
    }
  }

  ready(): Promise<void> {
    return this.promise;
  }
}