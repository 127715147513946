import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core'

import {ProductLine} from '../../../model/Products'
import {CacheService} from '../../services/cache.service'
import {DataService} from '../../services/data.service'
import {ProductModalState} from '../product-modal/product-modal.component'
import {ModalService} from '../modal.service'
import {AddProductModalComponent} from '../add-product-modal/add-product-modal.component'
import {DilutionModalComponent} from '../dilution-modal/dilution-modal.component'
import {ProductsService} from '../../services/products.service'

@Component({
  selector: 'app-product-line-manager',
  templateUrl: './product-line-manager.component.html',
  styleUrls: ['./product-line-manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductLineManagerComponent implements OnInit {
  @Input() productLine: ProductLine;
  @Input() canDeleteProducts = false;
  @Input() canDeleteProductLines = false;
  @Output() stateChange = new EventEmitter<ProductModalState>();
  modalProduct = null
  showConfirmDelete = false;
  test = 'asdfsadfasdf'

  constructor(
    private data: DataService,
    private cache: CacheService,
    private modals: ModalService,
    private productsService: ProductsService,

  ) { }

  ngOnInit() { }

  async addProduct(productLine) {
    await this.openAddProductModal(productLine)
  }

  async openAddProductModal(productLine, shouldSave = false) {
    const modalResult = await this.modals.show(AddProductModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {productLine, product: this.modalProduct},
    })
    if (!modalResult) return

    if (this.modalProduct) {
      this.modalProduct.name = modalResult.name
      this.modalProduct.controlValues = modalResult.controlValues
    } else {
      this.modalProduct = modalResult
    }

    const product = {name: this.modalProduct.name, controlValues: [this.modalProduct.controlValues[0]]}

    if ((this.modalProduct.controlValues[0].unit === 'mg RE/kg' && this.modalProduct.controlValues[0].target > 30) ||
      (this.modalProduct.controlValues[0].unit === 'IU/g' && this.modalProduct.controlValues[0].target > 100)) {
      await this.openDilutModal(productLine, shouldSave)
    } else {
      if (!product) {
        return
      }
      if (!shouldSave) {
        await this.saveProduct(product, productLine)
      }
    }
  }

  async openDilutModal(pl, shouldSave) {
    const res = await this.modals.show(DilutionModalComponent, {
      class: "modal-lg",
      ignoreBackdropClick: true,
      initialState: {product: this.modalProduct, shouldSave}
    })

    if (!res) {
      await this.openAddProductModal(pl)
      return
    }
    this.modalProduct.controlValues[0].fortified = res.fortified
    this.modalProduct.controlValues[0].diluteRatio = res.diluteRatio

    if (!shouldSave) {
      await this.saveProduct(this.modalProduct, pl)
    }
  }

  async saveProduct(product, productLine) {
    product.productLineId = productLine.id
    this.productsService.saveProduct(product).subscribe(r => {
      this.modalProduct = null
    })
  }

  async saveProductLine(e: { old: string, new: string }) {
    if (e.new === "") {
      if (this.productLine.id) {
        this.productLine.name = e.old;
      } else {
        this.forceCacheRefresh();
      }
      return;
    }

    this.stateChange.emit({ state: "saving" });
    try {
      await this.data.saveProductLine(this.productLine);
      this.stateChange.emit({ state: "saved" });
    } catch (err) {
      this.productLine.name = e.old;
      this.stateChange.emit({ state: "error" });
    }
  }

  async delete() {
    if (!this.productLine.id) {
      this.forceCacheRefresh();
      return;
    }

    this.stateChange.emit({ state: "saving" });
    try {
      await this.data.deleteProductLine(this.productLine);
      this.stateChange.emit({ state: "saved" });
    } catch (err) {
      this.stateChange.emit({ state: "error" });
      this.showConfirmDelete = false;
    }
  }

  private forceCacheRefresh() {
    this.cache.productLines.trigger();
  }
}
