<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate : product }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form (ngSubmit)="save()">
    <p [innerHtml]="prefix + 'intro' | translate : {value: product.controlValues[0].unit === 'mg RE/kg' ? '30 mg/kg' : '100 IU/g'}"></p>
    <p [innerHtml]="prefix + 'target' | translate : controlValue"></p>

    <div class="row align-items-end">
      <div class="col">
        <app-form-control [prefix]="prefix + 'fortified'" [control]="form.get('fortified')" type="number" step="50">
        </app-form-control>
        <app-form-control [prefix]="prefix + 'unfortified'" [control]="form.get('unfortified')" type="number" step="50">
        </app-form-control>
      </div>
      <div class="col-6">
        <ng-template #showError>
          <div class="alert alert-warning" *ngIf="form.errors.ratio">
            {{ prefix + 'error-' + form.errors.ratio | translate }}
          </div>
        </ng-template>

        <div *ngIf="form.valid; else showError" class="alert alert-secondary">
          <div [innerHtml]="prefix + 'ratio' | translate : ratioParams"></div>
        </div>
      </div>
    </div>

    <button [disabled]="form.invalid" type="submit" class="btn btn-primary">
      <i class="fa fa-check"></i>
      {{ prefix + 'save' | translate }}
    </button>
  </form>
  <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>

</div>
