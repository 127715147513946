import {Component, EventEmitter, OnInit, Output} from '@angular/core'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {Organization} from '../../../model/organization'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {FormControl, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-select-hierarchy-organization-modal',
  templateUrl: './select-hierarchy-organization-modal.component.html',
  styleUrls: ['./select-hierarchy-organization-modal.component.scss']
})
export class SelectHierarchyOrganizationModalComponent implements OnInit {

  organizations: Organization[]
  searchedOrganizations: Organization[]
  prefix = 'admin.hierarchy.allocate.allocate-organization-modal.'
  selectedOrganizations: Organization[] = []
  form: FormGroup
  modalResult
  q: string

  constructor(
    private as: AdminService,
    public modal: BsModalRef
  ) {
    this.form = new FormGroup({
      status: new FormControl('parent', Validators.required),
      search: new FormControl('')
    })
  }

  async ngOnInit() {
    this.organizations = await this.as.getHierarchyOrgsList()
    this.organizations = this.organizations.map(o => {
      o.selected = false
      return o
    })
  }

  onCheckboxChanged(e, idx) {
    this.searchedOrganizations ? this.searchedOrganizations[idx].selected = e.target.checked : this.organizations[idx].selected = e.target.checked;
    if (e.target.checked) {
      this.selectedOrganizations.push(this.searchedOrganizations ? this.searchedOrganizations[idx] : this.organizations[idx])
    } else {
      this.selectedOrganizations = this.selectedOrganizations.filter(o => o.id !== +e.target.value)
    }
  }


  save() {
    this.selectedOrganizations = this.selectedOrganizations.map(o => {
      o.status = this.form.get('status').value
      o.permission = 'readonly'
      return o
    })
    this.modalResult = {organizations: this.selectedOrganizations, status: this.form.get('status').value}
    this.modal.hide()
  }

  search() {
     if (! this.form.get('search').value) {
       this.ngOnInit();
     }
     this.q = this.form.get('search').value;
     this.searchedOrganizations = this.organizations.filter(o => {
      if (o.name.toLocaleLowerCase().indexOf(this.q.toLocaleLowerCase()) > -1) {
        o.selected = false
        return o
      }
    });
  }
}
