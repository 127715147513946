import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ControlValue } from '../../../model/control-value';
import { round } from '../../../model/helpers';
import { Product } from '../../../model/Products';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-dilution-modal',
  templateUrl: './dilution-modal.component.html',
  styleUrls: ['./dilution-modal.component.scss']
})
export class DilutionModalComponent implements OnInit {
  modalResult: any;
  prefix = "dilute-modal.";
  product: Product;
  shouldSave = true;
  form: FormGroup;
  busy = false;

  get controlValue(): ControlValue {
    if (!this.product || !this.product.controlValues || !this.product.controlValues[0]) return null;
    return this.product.controlValues[0];
  }

  get ratioParams() {
    if (this.form.invalid) return null;
    const fortified = Number(this.form.value.fortified);
    const unfortified = Number(this.form.value.unfortified);

    return this.getRatio(fortified, unfortified);
  }

  constructor(
    public modal: BsModalRef,
    private data: DataService,
  ) {
    this.form = new FormGroup({
      fortified: new FormControl(100, [Validators.required, Validators.min(20), Validators.max(1000)]),
      unfortified: new FormControl(100, [Validators.required, Validators.min(20), Validators.max(1000)])
    });
  }

  ngOnInit() {
    const target = this.controlValue.target;
    if (!target) throw new Error("Things aren't good.");

    this.form.setValidators(this.ratioValidator(target));

    if (this.controlValue.diluteRatio && this.controlValue.fortified) {
      this.form.get('fortified').setValue(this.controlValue.fortified);

      const unfort = round((this.controlValue.fortified * this.controlValue.diluteRatio) - this.controlValue.fortified, 0);
      this.form.get('unfortified').setValue(unfort);
    }
  }

  ratioValidator(target: number) {
    return (ctrl: FormGroup) => {
      const ratioData = this.getRatio(ctrl.value.fortified, ctrl.value.unfortified);

      if (!ratioData || !ratioData.ratio) return { ratio: 'no-ratio' };
      const expectedSampleResult = target / ratioData.ratio;

      if (expectedSampleResult < 5) return { ratio: 'too-low' };
      if (expectedSampleResult > 25) return { ratio: 'too-high' };

      return null;
    }
  }

  async save() {
    const ratioData = this.ratioParams;
    if (!ratioData) return;
    this.modalResult = { diluteRatio: ratioData.ratio, fortified: ratioData.fortified };
    if (this.shouldSave) {
      const cv = { productId: this.controlValue.productId, ...this.modalResult }
      this.busy = true;
      await this.data.updateSettings(cv);
      this.busy = false;
    }
    this.controlValue.fortified = ratioData.fortified;
    this.controlValue.diluteRatio = ratioData.ratio;
    this.modal.hide();
  }

  private getRatio(fortified: number, unfortified: number) {
    fortified = Number(fortified);
    unfortified = Number(unfortified);
    if (isNaN(fortified) || isNaN(unfortified)) return null;

    let ratio: number;
    if (unfortified === 0) {
      ratio = 1;
    } else {
      ratio = round((fortified + unfortified) / fortified, 2);
    }

    return { fortified, unfortified, ratio };
  }

}
