import {Component, OnInit} from '@angular/core'
import {SelectHierarchyOrganizationModalComponent} from '../select-hierarchy-organization-modal/select-hierarchy-organization-modal.component'
import {ModalService} from '../modal.service'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {AdminService} from '../../pages-authenticated/admin/admin.service'

@Component({
  selector: 'app-hierarchy-modal',
  templateUrl: './hierarchy-modal.component.html',
  styleUrls: ['./hierarchy-modal.component.scss']
})
export class HierarchyModalComponent implements OnInit {
  hierarchyId
  showTab = 'parent'
  tabs = [
    'parent',
    'child'
  ]
  orgs


  constructor(
    private ms: ModalService,
    public modal: BsModalRef,
    private as: AdminService
  ) {
  }

  async ngOnInit() {
    this.orgs = await this.as.getHierarchyOrgs(this.hierarchyId)
    console.log('orgs', this.orgs)
  }

  async allocate(isParent) {
    const result = await this.ms.show(SelectHierarchyOrganizationModalComponent, {
      initialState: {
        hierarchyId: this.hierarchyId,
        isParent
      }
    })
    if (result) {
      if (isParent) {
        this.orgs.parent.push(result)
      } else {
        this.orgs.child.push(result)
      }
    }
  }

  tabClicked(tab) {
    this.showTab = tab
  }

  async delete(id) {
    const res = await this.as.removeOrgFromHierarchy(id)
    console.log('this.orgs.parent', this.orgs.parent)
    console.log('this.orgs.child', this.orgs.child)
    console.log('id', id)
    this.orgs.parent = this.orgs.parent.filter(o => o.id !== id)
    this.orgs.child = this.orgs.child.filter(o => o.id !== id)
  }
}
