<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="text-dark">{{ prefix + 'text' | translate }}</p>
  <div class="row">
    <div class="col text-right">
      <button class="btn btn-danger" (click)="modal.hide()">{{ prefix + 'btn-back' | translate }}</button>
      <button class="ml-2 btn btn-primary">{{ prefix + 'btn-confirm' | translate }}</button>
    </div>
  </div>
</div>
