import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AdminService} from "../../pages-authenticated/admin/admin.service";
import {Organization} from "../../../model/organization";

@Component({
  selector: 'app-add-organization-modal',
  templateUrl: './add-organization-modal.component.html',
  styleUrls: ['./add-organization-modal.component.scss']
})
export class AddOrganizationModalComponent implements OnInit {
  prefix = 'add-organizations-modal.'

  organizations: any = []
  loading = true
  scrollLoading = false
  page = 0
  lastPage = 0
  modalResult
  name
  existingOrganizations: number[]

  constructor(
    public modal: BsModalRef,
    private as: AdminService,
  ) {

  }

  async ngOnInit() {
    try {
      const organizationsPage = await this.as.getOrganizationsWithUsersPage('', 0)
      this.lastPage = organizationsPage.lastPage
      this.organizations = organizationsPage.organizations.map((o: Organization) => {
        o.selected = false
        return o
      })
      this.organizations = this.organizations.filter((o: Organization) => o.users.length > 0)
      console.log("existingOrganizations", this.existingOrganizations)
      this.loading = false
    } catch (e) {
      this.loading = false
      console.log(e)
    }

  }

  async onScroll() {
    this.page += 1
    if (this.page === this.lastPage) return
    this.scrollLoading = true
    try {
      const res = await this.as.getOrganizationsWithUsersPage('', this.page)
      const orgs = res.organizations.filter((o: Organization) => o.users.length > 0)
      this.organizations = this.organizations.concat(res.organizations.map((o: Organization) => {
        o.selected = false
        return o
      }))
      this.scrollLoading = false
    } catch (e) {
      this.scrollLoading = false
      console.log("error", e)
    }
  }

  onNext() {
    if (this.validateNextBtn()) return
    this.modalResult = this.organizations
      .filter((o: Organization) => o.selected && !this.existingOrganizations.includes(o.id))
    this.modal.hide()
  }

  validateNextBtn() {
    return (this.organizations.filter((o: Organization) => o.selected).length < 1)
  }
}
