<div class="py-4 mx-4 container-fluid px-5">
  <div class="header">
    <h1 class="mb-3">{{prefix + 'title' | translate }}</h1>
    <p class="sub-title">{{ prefix + 'sub-title' | translate }}</p>
  </div>
  <accordion [isAnimated]="true">
    <accordion-group *ngIf="links['chroma'] && links['chroma'].length" heading="Chroma">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['chroma']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['chroma3'] && links['chroma3'].length" heading="Chroma 3">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['chroma3']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['iron'] && links['iron'].length" heading="Iron">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['iron']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['iodine'] && links['iodine'].length" heading="Iodine">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['iodine']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['carotene'] && links['carotene'].length" heading="Carotene">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['carotene']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['vitamin-e'] && links['vitamin-e'].length" heading="Vitamin E">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['vitamin-e']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
    <accordion-group *ngIf="links['other'].length" heading="Other">
      <div class="documents">
        <div class="document-card" *ngFor="let l of links['other']">
          <a target="_blank" [href]="l.link">
            <svg class="icon" *ngIf="l.icon === 'video'" width="23" height="17" viewBox="0 0 23 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.9758 12.1854C16.06 14.0938 14.5198 15.7076 12.5359 15.7887C12.3898 15.7949 5.26582 15.7805 5.26582 15.7805C3.2915 15.9304 1.56356 14.5116 1.40784 12.6104C1.3961 12.4687 1.3993 4.82487 1.3993 4.82487C1.31184 2.91442 2.84991 1.29654 4.8349 1.21236C4.98316 1.20517 12.0975 1.21852 12.0975 1.21852C14.0815 1.07069 15.8147 2.49968 15.9683 4.41014C15.979 4.5477 15.9758 12.1854 15.9758 12.1854Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M15.9792 6.39599L19.4095 3.5887C20.2595 2.89286 21.5345 3.49911 21.5334 4.59599L21.5209 12.2512C21.5199 13.3481 20.2438 13.9491 19.3959 13.2533L15.9792 10.446"
                stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg class="icon" *ngIf="l.icon === 'document'" width="19" height="21" viewBox="0 0 19 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.3517 0.87796H5.42147C3.25481 0.869626 1.47876 2.59671 1.42772 4.76233V15.9217C1.3798 18.1228 3.1246 19.9467 5.32564 19.9957C5.35793 19.9957 5.38918 19.9967 5.42147 19.9957H13.7433C15.9246 19.9071 17.6433 18.105 17.6277 15.9217V6.37379L12.3517 0.87796Z"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0781 0.865234V3.89544C12.0781 5.37461 13.274 6.57357 14.7531 6.57774H17.6229"
                    stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.8833 13.9984H6.2583" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M9.75316 10.0902H6.25732" stroke="#06A3AE" stroke-width="1.5625" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

            <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
          </a>
        </div>
      </div>
    </accordion-group>
  </accordion>
</div>
