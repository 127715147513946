<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate: { name: user.getName() } }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div
    [ngClass]="{ 'alert-success': user.status === 'ok', 'alert-warning': user.status === 'attention', 'alert-danger': user.status === 'issue' }"
    class="alert">
    {{ prefix + user.status + '-text' | translate : { name: user.firstName } }}
  </div>

  <!-- No Organization -->
  <div class="alert alert-light" *ngIf="issues['user.no-organization']; else otherIssues">
    <p>{{ prefix + 'user.no-organization' | translate }}</p>
    <button (click)="openOrgModal()" class="btn btn-secondary">{{ prefix + 'choose-organization' | translate }}</button>
  </div>

  <!-- Not active -->
  <div class="alert alert-light" *ngIf="issues['user.not-active']">
    {{ prefix + 'user.not-active' | translate }}
  </div>

  <ng-template #otherIssues>
    <!-- Not approved -->
    <div class="alert alert-light" *ngIf="issues['user.not-approved']">
      <p>{{ prefix + 'user.not-approved' | translate }}</p>

      <button (click)="modal.hide()" class="btn btn-secondary" [routerLink]="['/admin/approval']"
        [queryParams]="{ uuid: user.uuid }">
        {{ prefix + 'approve-link' | translate }}
      </button>
    </div>

    <!-- Email not confirmed -->
    <div class="alert alert-light" *ngIf="issues['user.email-not-confirmed']">
      <p>
        {{ prefix + 'user.email-not-confirmed' | translate : { date: user.emailConfirmTokenExpires.format('L') } }}
      </p>
      <p>
        {{ prefix + 'user.email-not-confirmed-2' | translate }}
      </p>

      <div class="row">
        <div class="col-6">
          <button (click)="resendEmail()" class="btn btn-secondary btn-block" *ngIf="!mailSent; else mailSentMessage">
            {{ prefix + 'resend-confirm-email' | translate }}
          </button>
          <ng-template #mailSentMessage>
            <div class="my-2 text-center">{{ prefix + 'mail-resent' | translate }}</div>
          </ng-template>

        </div>

        <div class="col-6">
          <button (click)="confirmEmail()" class="btn btn-secondary btn-block">
            {{ prefix + 'confirm-email' | translate }}
          </button>
        </div>

      </div>
    </div>
  </ng-template>

  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy">
      <div class="row">
        <app-form-control class="col-md-6" [prefix]="prefix + 'first-name'" type="text"
          [control]="form.get('firstName')"></app-form-control>
        <app-form-control class="col-md-6" [prefix]="prefix + 'last-name'" type="text" [control]="form.get('lastName')">
        </app-form-control>
        <app-form-control class="col-md-12" [prefix]="prefix + 'email'" type="text" [control]="form.get('email')" [disabled]="true">
        </app-form-control>
        <app-form-control class="col-md-12" [prefix]="prefix + 'organization'" type="text"
          [control]="form.get('organization')" [disabled]="true">
        </app-form-control>
        <app-form-control class="col-md-12" [prefix]="prefix + 'organization-code'" type="text"
          [control]="form.get('organizationCode')" [disabled]="true">
        </app-form-control>

        <div class="col-md-12 text-right">
          <button (click)="openOrgModal()" class="btn btn-primary">Edit</button>
        </div>
        <!-- Roles -->
        <div class="col-md-12 mb-2">
          <label class="d-block m-0">{{ prefix + 'roles' | translate }}</label>
          <div class="middle">
            <span class="badge badge-light mr-2" *ngFor="let r of user.memberships">{{r.role}}</span>
            <button type="button" (click)="openRoleModal()" class="btn btn-sm btn-link">
              <i class="fa fa-pencil"></i>
              {{ prefix + 'change-roles' | translate }}
            </button>
          </div>
        </div>

        <app-form-control class="col-md-12" [prefix]="prefix + 'active'" type="checkbox" [control]="form.get('active')">
        </app-form-control>

      </div>

      <!-- Normal buttons -->
      <div>
        <button type="submit" class="btn btn-primary btn-block">
          <i class="fa fa-check fa-fw" aria-hidden="true"></i>
          {{ prefix + 'save' | translate }}
        </button>
      </div>
    </fieldset>

    <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>
  </form>

</div>
