import {Moment} from 'moment';
import * as moment from 'moment';

import {toObject} from './helpers';
import {Product} from './Products';
import {Import} from './import'
import {TestKit} from './testKit'
import {Device} from './device'

export class Sample {
  static UNIT_MG_RE_KG = "mg RE/kg";
  static UNIT_IO_MG = "IU/g";

  id: number = null;
  productId?: number = null;
  created: Moment = null;

  mode: string = null;
  deviceBatchId: number = null;
  deviceSampleId: number = null;
  userBatchId?: string = null;
  userSampleId?: string = null;
  result: number = null;
  vial?: number = null;
  blank?: number = null;
  unit?: string = null;
  comment?: string = null;
  discarded: boolean = null;
  processed: boolean = null;
  importId: number = null;
  import: Import = null;
  hash?: string = null;
  selected?: boolean = null;
  diluteRatio: number = null;
  testKitId?: string = null;
  testKit?: TestKit = null
  device?: Device = null
  measurementTypeId?: string = null
  measurementType?: { id: number, name: string } = null

  isDuplicate = false;
  product?: Product = null;
  disabled: boolean = false;
  defaultResult?: any
  defaultUnit?: any

  get undilutedResult(): number {
    if (!this.diluteRatio) return this.result;
    return this.result * this.diluteRatio;
  }

  static deserialize(data: any): Sample {
    let dr = toObject<Sample>(Sample, data);

    if (data.created) dr.created = moment.unix(data.created);

    return dr;
  }

  serialize() {
    const o: any = {...this};

    if (this.created) o.created = this.created.unix();

    return o;
  }
}

export interface MeasurementType {
  id: number;
  name: string;
}

export const units = [
  'mg RE/kg',
  'IU/g',
  'AU'
]
