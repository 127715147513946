<div class="container text-center p-5" *ngIf="loading">
  <app-spinner></app-spinner>
</div>

<div class="container my-4" *ngIf="!loading">
  <app-device-manager [devices]="devices"></app-device-manager>

<!--  <app-test-kit-manager [testKits]="testKits"></app-test-kit-manager>-->

</div>
