<div class="modal-header">
  <h5 class="modal-title pull-left">{{ 'test-kit-modal.title-' + mode | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="hide(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy">
      <div class="row">
        <app-form-control *ngIf="mode === 'add' && selectOptions" class="col-md-6" prefix="test-kit-modal.type" type="select" [selectOptions]="selectOptions" [control]="form.get('type')"></app-form-control>
        <app-form-control #lot class="col-md-6" prefix="test-kit-modal.lot" [control]="form.get('lot')"></app-form-control>
        <app-form-control class="col-md-6" prefix="test-kit-modal.origin" [control]="form.get('origin')"></app-form-control>
        <app-form-control class="col-md-6" prefix="test-kit-modal.quantity" type="number" steps="100" [control]="form.get('quantity')"></app-form-control>
        <app-form-control *ngIf="mode === 'update'" class="col-md-6" prefix="test-kit-modal.used" type="number" [control]="form.get('used')"></app-form-control>
        <app-form-control class="col-md-6" prefix="test-kit-modal.received" type="date" [control]="form.get('received')"></app-form-control>
        <app-form-control class="col-md-6" prefix="test-kit-modal.expires" type="date" [control]="form.get('expires')"></app-form-control>
      </div>

      <!-- Normal buttons -->
      <div class="text-right" *ngIf="!showConfirmDelete">
        <button *ngIf="canDelete && mode === 'update'" type="button" class="btn btn-danger mr-2" (click)="showConfirmDelete = true">
          <i class="fa fa-times fa-fw" aria-hidden="true"></i>
          {{ 'test-kit-modal.delete' | translate }}
        </button>
        <button type="submit" class="btn btn-primary">
          <i class="fa fa-check fa-fw" aria-hidden="true"></i>
          {{ 'test-kit-modal.save' | translate }}
        </button>
      </div>

      <!-- Confirm delete buttons -->
      <div *ngIf="showConfirmDelete">
        <h5 class="text-center">{{ 'test-kit-modal.really-delete' | translate }}</h5>
        <div class="row">
          <div class="col">
            <button type="button" (click)="delete()" class="btn btn-danger btn-block">
              <i class="fa fa-trash fa-fw" aria-hidden="true"></i>
              {{ 'test-kit-modal.delete' | translate }}
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="showConfirmDelete = false" class="btn btn-light btn-block">
              <i class="fa fa-times fa-fw" aria-hidden="true"></i>
              {{ 'test-kit-modal.cancel' | translate }}
            </button>
          </div>
        </div>
      </div>

    </fieldset>

    <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>
  </form>

</div>
