import {Component, OnInit} from '@angular/core'
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {ControlValue} from 'src/model/control-value'
import {Product, ProductLine} from 'src/model/Products'
import {DataService} from 'src/app/services/data.service'
import {ModalService} from 'src/app/shared/modal.service'
import {CacheService} from 'src/app/services/cache.service'
import {ProductsService} from 'src/app/services/products.service'
import {SelectOption} from '../form-control/form-control.component'
import {round} from '../../../model/helpers'

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss']
})
export class AddProductModalComponent implements OnInit {
  prefix = "add-product."
  form: FormGroup
  busy = false
  modalResult: any
  productLine: ProductLine
  product: Product
  isFirst = false
  units = {
    'Vitamin A': [
      {name: 'µg RE/L', value: 'µg RE/L'},
      {name: 'mg RE/kg', value: 'mg RE/kg'},
      {name: 'IU/g', value: 'IU/g'}
    ],
    Iron: [{name: 'mg/L', value: 'mg/L'}],
    'Total carotenoids': [{name: 'mg/L', value: 'mg/L'}],
    Iodine: [{name: 'mg/L', value: 'mg/L'}],
    'Vitamin E': [{name: 'mg/L', value: 'mg/L'}]
  }

  noMeasurements: boolean
  measurements: SelectOption[]
  controlValuesForm: FormArray
  private removeControlValueIds = []
  private controlValueTemplate = {
    id: [null],
    productId: [null],
    label: ['Vitamin A', Validators.required],
    measurementTypeId: [1],
    unit: ['mg RE/kg', Validators.required],
    target: [null, Validators.required],
    minLimit: [null, Validators.required],
    minWarn: [null, Validators.required],
    maxWarn: [null, Validators.required],
    maxLimit: [null, Validators.required],
    nationalStandard: [null],
    productTypeId: [null],
    productCategoryId: [1],
    productWeight: [null],
    productWeightUnit: [null],
    fortified: [null],
    dilutedVolume: [null],
    dilutedVolumeUnit: [null],
    unfortified: [null],
    diluteRatio: [null],
    deviceTypeId: [1]
  }

  showDFFields = false
  usedMeasurements: any = []
  public indexOfForms = 1
  productCategories
  deviceOptions = [
    {name: 'Chroma', value: 1},
    {name: 'Chroma 3', value: 2},
    {name: 'Fluoro', value: 3}
  ]

  productTypes = null
  deviceTypes = null
  loading

  constructor(
    public modal: BsModalRef,
    private data: DataService,
    private modals: ModalService,
    private cache: CacheService,
    private productService: ProductsService,
    private fb: FormBuilder,
  ) {
  }

  async ngOnInit() {
    this.loading = true
    this.form = this.fb.group({
      id: [this.product ? this.product.id : null],
      name: [this.product ? this.product.name : '', [Validators.required, Validators.maxLength(250)]],
      productLineId: [this.productLine.id],
      controlValues: this.product ?
        this.fb.array(this.product.controlValues.map(cv => {
          return this.fb.group(cv)
        })) :
        new FormArray([this.fb.group(this.controlValueTemplate)])
    })
    this.controlValuesForm = (this.form.controls['controlValues'] as FormArray)

    // const measurements = await this.cache.measurementTypes.value
    this.measurements = this.cache.measurementTypes.value.map(({id, name}): SelectOption => ({
      name,
      value: id,
      disabled: false
    }))

    const productTypes = this.cache.productTypes.value
    this.productTypes = {}
    productTypes.forEach(p => {
      const measurementLabel = this.measurements.filter(m => m.value === p.measurementTypeId)[0].name
      if (this.productTypes && this.productTypes.hasOwnProperty(measurementLabel)) {
        this.productTypes[measurementLabel].push({
          name: p.name,
          value: p.id,
          type: p.type,
          productCategoryId: p.productCategoryId
        })
      } else {
        this.productTypes[measurementLabel] = []
        this.productTypes[measurementLabel].push({
          name: p.name,
          value: p.id,
          type: p.type,
          productCategoryId: p.productCategoryId
        })
      }

    })

    // this.data.getProductTypes().then((products): any => {
    //   this.productTypes = {}
    //   products.forEach(p => {
    //     const measurementLabel = this.measurements.filter(m => m.value === p.measurementTypeId)[0].name
    //     if (this.productTypes && this.productTypes.hasOwnProperty(measurementLabel)) {
    //       this.productTypes[measurementLabel].push({
    //         name: p.name,
    //         value: p.id,
    //         type: p.type,
    //         productCategoryId: p.productCategoryId
    //       })
    //     } else {
    //       this.productTypes[measurementLabel] = []
    //       this.productTypes[measurementLabel].push({
    //         name: p.name,
    //         value: p.id,
    //         type: p.type,
    //         productCategoryId: p.productCategoryId
    //       })
    //     }
    //
    //   })
    //
    // })

    this.deviceTypes = this.cache.deviceTypes.value

    this.productCategories = this.cache.productCategories.value


    if (this.product) {
      this.controlValueTemplate.productId[0] = this.product.id
      this.product.controlValues.forEach((m) =>
        // this.productService.selectedValue.next(m.measurementTypeId));
        this.usedMeasurements.push(m.measurementTypeId))
    } else {
    }
    console.log('this.controlValuesForm', this.controlValuesForm.controls[0].value)
    this.loading = false
  }

  public async save() {
    this.extraChecks()
    if (this.form.invalid) return

    this.busy = true

    try {
      this.modalResult = this.saveProduct()
      this.modal.hide()
    } catch (err) {
      console.error("Couldn't save.", err)
    }

    this.busy = false
  }

  private extraChecks() {
    this.form.markAllAsTouched()

    const controls = this.controlValuesForm.controls
    controls.forEach((c, idx) => {
      const v = c.value
      if ((v.maxWarn && v.maxLimit)) {
        if (Number(v.minWarn) < Number(v.minLimit) || Number(v.target) < Number(v.minLimit)) {
          controls[idx].get('minLimit').setErrors({tooHigh: true})
        } else {
          controls[idx].get('minLimit').setErrors(null)
        }

        if (Number(v.maxWarn) > Number(v.maxLimit) || Number(v.maxLimit) < Number(v.target)) {
          controls[idx].get('maxLimit').setErrors({tooLow: true})
        } else {
          controls[idx].get('maxLimit').setErrors(null)
        }
      }
    })
  }

  private saveProduct() {
    const cv: ControlValue[] = this.form.value.controlValues.map(v => {
      const controlValue: ControlValue = v
      const productType = this.getProductType(v.productTypeId)

      if (productType === 'dry') {
        controlValue.diluteRatio = round((+v.fortified / +v.unfortified), 2)
      }

      // if (v.isFluoro && this.getProductType(v.productTypeId) === 'dry') {
      //   controlValue.diluteRatio = round((+v.dilutedVolume / +v.weight), 2)
      //   controlValue.fortified = +v.weight
      // } else if (controlValue.fortified && controlValue.unfortified) {
      //   controlValue.diluteRatio = round((+controlValue.fortified + +controlValue.unfortified) / +controlValue.fortified, 2)
      // }
      // delete controlValue.unfortified
      return controlValue
    })

    const product = {
      name: this.form.get('name').value,
      productLineId: this.productLine.id,
      controlValues: cv
    }
    return product
  }


  addMeasurementType() {
    if (this.controlValuesForm.controls.length >= 5) return
    if (this.noMeasurements) this.noMeasurements = false
    this.controlValuesForm.push(this.fb.group(this.controlValueTemplate))
    const measurementTypeId = this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1].get('measurementTypeId')
    const label = this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1].get('label')
    const unit = this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1].get('unit')
    const productTypeId = this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1].get('productTypeId')
    measurementTypeId.setValue(null)
    label.setValue(null)
    const lastMeasurement = this.getMeasurements(measurementTypeId, true).filter(m => !m.disabled)[0]
    measurementTypeId.setValue(lastMeasurement.value)
    label.setValue(lastMeasurement.name)
    unit.setValue(this.units[lastMeasurement.name][0].value)

    if (measurementTypeId.value !== 1) {
      this.setControlValueAndSetRequired([
        {
          control: 'productTypeId',
          value: this.productTypes[label.value][0].value
        },
        {
          control: 'productCategoryId',
          value: this.productTypes[label.value][0].productCategoryId
        }
      ], this.controlValuesForm.controls.length - 1)
    }
    if (measurementTypeId.value === 1) {
      const controls = ['productCategoryId', 'productTypeId', 'productWeight', 'productWeightUnit', 'dilutedVolume', 'dilutedVolumeUnit']
      this.removeControlValueAndValidators(controls, this.controlValuesForm.controls.length - 1)
      this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1]
        .get('deviceTypeId').setValue(this.getDeviceTypeIdByMeasurementTypeId(1))
    } else {
      this.controlValuesForm.controls[this.controlValuesForm.controls.length - 1]
        .get('deviceTypeId').setValue(this.getDeviceTypeIdByMeasurementTypeId(lastMeasurement.value))
      const controlValue = [
        {
          control: 'productTypeId',
          value: this.productTypes[label.value][0].value
        },
        {
          control: 'productCategoryId',
          value: this.productTypes[label.value][0].productCategoryId
        }
      ]

      if (this.productTypes[label.value][0].type === 'dry') {
        controlValue.push(
          {control: 'productWeight', value: null},
          {control: 'productWeightUnit', value: 'g'},
          {control: 'dilutedVolume', value: null},
          {control: 'dilutedVolumeUnit', value: 'ml'}
        )
      }
      this.setControlValueAndSetRequired(controlValue, this.controlValuesForm.controls.length - 1)
    }
  }


  async removeMeasurementType(index, controlValueId?) {
    const confirm = await this.modals.showConfirm()

    if (confirm) {
      this.measurements.forEach(m => {
        if (m.value === this.controlValuesForm.controls[index].get('measurementTypeId').value) {
          m.disabled = false
        }
      })
      this.controlValuesForm.removeAt(index)
      if (controlValueId) this.removeControlValueIds.push(controlValueId)
      this.modalResult = this.saveProduct()
    }
  }

  getSelectUnits(idx) {
    if (this.getDeviceTypeById(this.controlValuesForm.controls[idx].get('deviceTypeId').value) !== 'fluoro' &&
      +this.controlValuesForm.controls[idx].get('measurementTypeId').value === 1) {
      return [
        {name: 'mg RE/kg', value: 'mg RE/kg'},
        {name: 'IU/g', value: 'IU/g'}
      ]
    } else if (this.measurements) {
      const measurementTypeId = +this.controlValuesForm.controls[idx].get('measurementTypeId').value - 1
      console.log('measurementTypeId', measurementTypeId)
      const unitsArr = this.units[this.measurements[measurementTypeId].name]
      return unitsArr
    } else {
      const res = this.units['Vitamin A']
      return res
    }
  }

  onMeasurementSelected(measurement, idx) {
    const deviseTypeId = this.getDeviceTypeIdByMeasurementTypeId(measurement.value)
    console.log('deviseTypeId', deviseTypeId)
    this.controlValuesForm.controls[idx].get('deviceTypeId').setValue(deviseTypeId)
    // const value = event.target.value
    this.measurements.forEach(m => {
      if (m.value === this.controlValuesForm.controls[idx].get('measurementTypeId').value) {
        m.disabled = false
      }
    })
    if (measurement.value === 1) {
      this.removeControlValueAndValidators(['productTypeId'], idx)
    }

    this.controlValuesForm.controls[idx].get('measurementTypeId').setValue(measurement.value)
    this.controlValuesForm.controls[idx].get('label').setValue(measurement.name)
    const measurementTypeId = +this.controlValuesForm.controls[idx].get('measurementTypeId').value - 1
    const unitsArr = this.units[this.measurements[measurementTypeId].name]
    this.controlValuesForm.controls[idx].get('unit').setValue(unitsArr[0].value)
    if (this.controlValuesForm.controls[idx].get('measurementTypeId').value === 1) {
      const controls = ['productTypeId', 'productWeight', 'productWeightUnit', 'dilutedVolume', 'dilutedVolumeUnit']
      this.removeControlValueAndValidators(controls, idx)
      this.setControlValueAndSetRequired([{control: 'productCategoryId', value: 1}], idx)
      this.controlValuesForm.controls[idx].get('deviceTypeId').setValue(1)
    } else {
      this.controlValuesForm.controls[idx].get('deviceTypeId').setValue(this.getDeviceTypeIdByMeasurementTypeId(measurement.value))
      const controlValue = [
        {
          control: 'productTypeId',
          value: this.productTypes[measurement.name][0].value
        },
        {
          control: 'productCategoryId',
          value: this.productTypes[measurement.name][0].productCategoryId
        }
      ]


      if (this.productTypes[measurement.name][0].type === 'dry') {
        controlValue.push(
          {control: 'productWeight', value: null},
          {control: 'productWeightUnit', value: 'g'},
          {control: 'dilutedVolume', value: null},
          {control: 'dilutedVolumeUnit', value: 'ml'}
        )
      } else {
        const controls = ['productWeight', 'productWeightUnit', 'dilutedVolume', 'dilutedVolumeUnit']
        this.removeControlValueAndValidators(controls, idx)
      }
      this.setControlValueAndSetRequired(controlValue, idx)
    }
  }

  getUsedMeasurement() {
    const values = []
    this.controlValuesForm.controls.forEach(c => {
      values.push(c.get('measurementTypeId').value)
    })

    return values
  }

  close() {
    this.modalResult = null
    this.modal.hide()
  }

  getMeasurements(control, isNew) {
    if (!isNew) {
      const value = control.value
      let used = this.getUsedMeasurement()
      used = used.filter(v => v !== +value)
      const result = this.measurements.map(m => {
        if (used.includes(m.value)) {
          m.disabled = true
        }
        return m
      })
      return result
    } else {
      const result = this.measurements.filter(m => !this.getUsedMeasurement().includes(m.value))
      return result
    }

    // control.setValue(result[0].value)
  }


  getProductType(id) {
    if (!id || !this.productTypes) return false
    let result = null
    Object.keys(this.productTypes).forEach(k => {
      const obj = this.productTypes[k].find(p => p.value === id)
      if (obj) {
        result = obj
      }
    })
    if (result) {
      return result.type
    } else {
      return null
    }
  }

  initForms(idx, pTypeId?) {
    this.controlValuesForm.controls[idx].get('productTypeId').setValue(pTypeId)
    const productType = this.getProductType(this.controlValuesForm.controls[idx].get('productTypeId').value)
    if (productType === 'dry') {

      const controls = [
        {control: 'productWeight', value: null},
        {control: 'dilutedVolume', value: null},
        {control: 'productWeightUnit', value: 'g'},
        {control: 'dilutedVolumeUnit', value: 'ml'}
      ]

      this.setControlValueAndSetRequired(controls, idx)
    } else if (productType === 'liquid') {
      this.removeControlValueAndValidators(['productWeight', 'productWeightUnit', 'dilutedVolume', 'dilutedVolumeUnit'], idx)
    }
  }

  productTypeIdChanged(pTypeId, idx) {
    this.initForms(idx, pTypeId)
    this.controlValuesForm.controls[idx].get('productCategoryId').setValue(this.getCategoryByProductTypeId(pTypeId))
  }

  getCategoryByProductTypeId(id) {
    let types = []
    Object.keys(this.productTypes).forEach(t => {
      types = types.concat(this.productTypes[t])
    })
    const category = types.filter(t => t.value === id)[0].productCategoryId
    return category
  }

  getWaterRatio(target, productTypeId) {
    return target < 5 ? 5 : 10
  }


  // dilutionSettings(label, target, idx): { canShow: boolean, min?: number, max?: number } {
  //   if (!target) return {canShow: false}
  //   switch (label) {
  //     case 'Vitamin A':
  //       if (this.controlValuesForm.controls[idx].get('isFluoro').value) {
  //         return {canShow: false}
  //       } else {
  //         if (target < 3 || target > 30) {
  //           this.updateFortifiedValues(idx, 1.5, 12)
  //           return {canShow: true, min: 1.5, max: 12}
  //         } else {
  //           this.updateFortifiedValues(idx)
  //           return {canShow: false}
  //         }
  //       }
  //     case 'Iron':
  //       if (target < 1.5 || target > 12) {
  //         this.updateFortifiedValues(idx, 1.5, 12)
  //         return {canShow: true, min: 1.5, max: 12}
  //       } else {
  //         this.updateFortifiedValues(idx)
  //         return {canShow: false}
  //       }
  //     case 'Total carotenoids':
  //       if (target < 0.15 || target > 15) {
  //         this.updateFortifiedValues(idx, 0.15, 15)
  //         return {canShow: true, min: 0.15, max: 15}
  //       } else {
  //         this.updateFortifiedValues(idx)
  //         return {canShow: false}
  //       }
  //     case 'Iodine':
  //       if (target < 1 || target > 13) {
  //         this.updateFortifiedValues(idx, 1, 13)
  //         return {canShow: true, min: 1, max: 13}
  //       } else {
  //         this.updateFortifiedValues(idx)
  //         return {canShow: false}
  //       }
  //     case 'Vitamin E':
  //       if (target < 1 || target > 25) {
  //         this.updateFortifiedValues(idx, 1, 25)
  //         return {canShow: true, min: 1, max: 25}
  //       } else {
  //         this.updateFortifiedValues(idx)
  //         return {canShow: false}
  //       }
  //     default:
  //       this.updateFortifiedValues(idx)
  //       return {canShow: false}
  //   }
  // }

  updateFortifiedValues(idx, min?, max?) {
    if (min && max) {
      this.controlValuesForm.controls[idx].get('fortified').setValidators([Validators.required, Validators.min(min), Validators.max(max)])
      this.controlValuesForm.controls[idx].get('unfortified').setValidators([Validators.required, Validators.min(min), Validators.max(max)])
    } else {
      this.controlValuesForm.controls[idx].get('fortified').setValue(null)
      this.controlValuesForm.controls[idx].get('unfortified').setValue(null)
      this.controlValuesForm.controls[idx].get('fortified').clearValidators()
      this.controlValuesForm.controls[idx].get('unfortified').clearValidators()
    }
  }

  removeControlValueAndValidators(controls: string[], idx) {
    controls.forEach(c => {
      this.controlValuesForm.controls[idx].get(c).setValue(null)
      this.controlValuesForm.controls[idx].get(c).clearValidators()
      this.controlValuesForm.controls[idx].get(c).updateValueAndValidity()
    })
  }

  setControlValueAndSetRequired(controls: { control: string, value: any }[], idx) {
    controls.forEach(c => {
      this.controlValuesForm.controls[idx].get(c.control).setValue(c.value)
      this.controlValuesForm.controls[idx].get(c.control).setValidators([Validators.required])
      this.controlValuesForm.controls[idx].get(c.control).updateValueAndValidity()
    })
  }

  getCategoryById(id) {
    if (!id) return 'Not Selected :('
    return this.productCategories.filter(c => c.id === +id)[0].name
  }

  deviceTypeSelected(deviceTypeId, idx) {
    const deviceType = this.getDeviceTypeById(deviceTypeId)
    if (deviceType === 'fluoro') {
      const controlValues = [
        {control: 'productCategoryId', value: 5},
        {control: 'productWeight', value: null},
        {control: 'productWeightUnit', value: 'g'},
        {control: 'dilutedVolume', value: null},
        {control: 'dilutedVolumeUnit', value: 'ml'}
      ]
      this.setControlValueAndSetRequired(controlValues, idx)
      this.controlValuesForm.controls[idx].get('productTypeId').setValue(1)
      this.controlValuesForm.controls[idx].get('productCategoryId').setValue(5)
    } else {
      const controls = ['productWeight', 'productWeightUnit', 'dilutedVolume', 'dilutedVolumeUnit']
      this.removeControlValueAndValidators(controls, idx)
      this.setControlValueAndSetRequired([{control: 'productCategoryId', value: 1}], idx)
    }
  }

  getDeviceTypeById(deviceId) {
    return this.deviceTypes.filter(d => d.id === +deviceId)[0].type
  }

  getDeviceTypeIdByMeasurementTypeId(measurementId) {
    return this.deviceTypes.filter(d => d.measurementTypeId === +measurementId)[0].id
  }
}
