import {Component, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core'
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms'
import * as zxcvbn from 'zxcvbn'

import {User} from 'src/model/user'
import {UserService} from 'src/app/services/user.service'
import {AdminService} from 'src/app/pages-authenticated/admin/admin.service'

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit, OnChanges {
  @Input() mode: "user" | "admin" | "token" = "user"

  @Input() user: User
  @Input() token?: string

  @Output() completed = new EventEmitter<void>()
  @Output() error = new EventEmitter<any>()

  state: "idle" | "no-token" | "done" = "idle"

  passwordStrength: zxcvbn.ZXCVBNResult = zxcvbn("")
  triedSubmit = false
  loading = false
  form: FormGroup

  get oldPassword() {
    return this.form.get('oldPassword')
  }

  get password() {
    return this.form.get('password')
  }

  get password2() {
    return this.form.get('password2')
  }

  constructor(
    private us: UserService,
    private am: AdminService,
  ) {
    this.form = new FormGroup({
      oldPassword: new FormControl(null),
      password: new FormControl('', [Validators.required, this.passwordStrengthValidator(this)]),
      password2: new FormControl('')
    }, this.passwordsMatchValidator)
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.form) return

    const oldPasswordCtrl = this.form.get('oldPassword')
    if (this.mode === "user") {
      oldPasswordCtrl.setValidators([Validators.required])
    } else {
      oldPasswordCtrl.clearValidators()
    }
    oldPasswordCtrl.updateValueAndValidity()
  }

  passwordStrengthValidator(context: PasswordResetFormComponent) {
    return (ctrl: AbstractControl) => {

      const result = zxcvbn(ctrl.value)
      context.passwordStrength = result;
      if (result.score > 0) {
        return null;
      }

      return {weakPassword: result}
    }
  }

  passwordsMatchValidator(passwords: FormGroup) {
    if (passwords.get('password').value === passwords.get('password2').value) return null

    return {notSame: true}
  }

  async submit() {
    this.triedSubmit = true
    this.form.markAllAsTouched()

    if (this.form.invalid) return
    this.loading = true

    const data: any = {
      password: this.password.value
    }

    try {
      if (this.mode === "token") {
        data.token = this.token
        await this.us.resetPasswordWithToken(data)
      } else if (this.mode === "user") {
        data.oldPassword = this.form.value.oldPassword
        await this.us.changePassword(data)
      } else {
        data.uuid = this.user.uuid
        await this.am.resetPassword(data)
      }

      this.state = 'done'
      this.completed.next()
    } catch (res) {
      if (res.error.code === 'invalid-password') {
        this.form.get('oldPassword').setErrors({invalidPassword: true})
      }
      this.error.next(res)
    }
    this.loading = false
  }
}
