<div class="content-container">
  <p class="title">{{prefix + "title" | translate}}</p>

  <div class="content">
    <div class="level-container">
      <div class="level-left level">
        <div class="side-one side-left side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.1.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.1.content" | translate }}</p>
          </div>
        </div>
        <img src="./assets/img/transfer-data/step-1.png" alt="" class="step-image">
      </div>

      <div class="level-left level">
        <img src="./assets/img/transfer-data/step-2.png" alt="" class="step-image">
        <div class="side-two side-right side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.2.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.2.content" | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="level-container">
      <div class="level-left level">
        <div class="side-three side-left side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.3.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.3.content" | translate }}</p>
          </div>
        </div>
        <img src="./assets/img/transfer-data/step-3.png" alt="" class="step-image">
      </div>

      <div class="level-left level">
        <img src="./assets/img/transfer-data/step-4.png" alt="" class="step-image">
        <div class="side-four side-right side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.4.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.4.content" | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="level-container">
      <div class="level-left level">
        <div class="side-five side-left side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.5.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.5.content" | translate }}</p>
          </div>
        </div>
        <img src="./assets/img/transfer-data/step-5.png" alt="" class="step-image">
      </div>

      <div class="level-left level">
        <img src="./assets/img/transfer-data/step-6.png" alt="" class="step-image">
        <div class="side-six side-right side">
          <div class="step-description">
            <p class="title">{{ prefix + "steps.6.title" | translate }}</p>
            <p class="description">{{ prefix + "steps.6.content" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
