<div class="modal-header">
  <p>{{prefix + 'list-modal.title' | translate}}</p>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col">
    <div class="card border-0 form">
      <div class="card-header bg-white">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col text-center" *ngFor="let tab of tabs"
              (click)="tabClicked(tab)">
            <a class="nav-link border-top-0 border-left-0 border-right-0 bg-transparent"
               [ngClass]="{'active': showTab ===tab}">{{ (prefix + 'list-modal.tabs.' + tab + '.title') | translate }}</a>
          </li>
        </ul>
      </div>

      <div class="card-body bg-white border-bottom">
        <div class="tab-content" [ngSwitch]="showTab">
          <div class="tab-pane fade active show" *ngSwitchCase="'open'">

            <div *ngIf="openBugs && openBugs.length; else bugsNoData">
              <div class="card" *ngFor="let b of openBugs" [ngClass]="selected === b ? 'shadow my-1' : ''">
                <span *ngIf="selected === b"></span>
                <div class="card-body">
                  <!-- Header -->
                  <div>
                    <a href="javascript:void(0)" (click)="select(b)">
                      {{b.title}}
                    </a>
                    <span class="pull-right">
                      <p class="mb-0">Status: <span
                        [ngClass]="{'text-success': b.closed, 'text-danger': !b.closed}">{{b.closed ? 'Closed' : 'Open'}}</span></p>
                  </span>
                  </div>

                  <!-- Expanse -->
                  <ul class="list-unstyled mt-1 mb-0 data-list" [collapse]="selected !== b" [isAnimated]="true">
                    <li><strong>{{ b.user.firstName + ' ' + b.user.lastName}}</strong></li>
                    <li>{{b.created}}</li>
                    <hr>
                    <li>{{b.description}}</li>
                    <hr>
                    <li>
                      <div *ngIf="b.documents && b.documents.length > 0">
                        <h6>{{ 'tickets.thread.documents' | translate }}</h6>
                        <ul class="list-unstyled">
                          <li *ngFor="let d of b.documents">
                            <button class="btn btn-outline-secondary mr-2" (click)="download(d)">
                              <i class="fa" [ngClass]="d.icon"></i> {{ d.name }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li class="mt-3 text-right">
                      <a href="javascript:void(0)" (click)="closeBug(b)">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                             aria-hidden="true" focusable="false" width="1.5em" height="1.5em"
                             style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                             preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                          <g fill="#06A3AE">
                            <path
                              d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093l3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                          </g>
                        </svg>
                        {{prefix + 'list-modal.tabs.open.close-bug' | translate}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>


          <div class="tab-pane fade active show" *ngSwitchCase="'closed'">

            <div *ngIf="closedBugs && closedBugs.length; else bugsNoData">
              <div class="card" *ngFor="let b of closedBugs" [ngClass]="selected === b ? 'shadow my-1' : ''">
                <span *ngIf="selected === b"></span>
                <div class="card-body">
                  <!-- Header -->
                  <div>
                    <a href="javascript:void(0)" (click)="select(b)">
                      {{b.title}}
                    </a>
                    <span class="pull-right">
                    <p class="mb-0">Status: <span
                      [ngClass]="{'text-success': b.closed, 'text-danger': !b.closed}">{{b.closed ? 'Closed' : 'Open'}}</span></p>
                  </span>
                  </div>

                  <!-- Expanse -->
                  <ul class="list-unstyled mt-1 mb-0 data-list" [collapse]="selected !== b" [isAnimated]="true">
                    <li><strong>{{ b.user.firstName + ' ' + b.user.lastName}}</strong></li>
                    <li>{{b.created}}</li>
                    <hr>
                    <li>{{b.description}}</li>
                    <hr>
                    <li>
                      <div *ngIf="b.documents && b.documents.length > 0">
                        <h6>{{ 'tickets.thread.documents' | translate }}</h6>
                        <ul class="list-unstyled">
                          <li *ngFor="let d of b.documents">
                            <button class="btn btn-outline-secondary mr-2" (click)="download(d)">
                              <i class="fa" [ngClass]="d.icon"></i> {{ d.name }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <ng-template #bugsNoData>
            <app-no-data class="col" content="report-bug.list-modal.no-data.title"></app-no-data>
          </ng-template>

        </div>
      </div>


    </div>
  </div>

</div>
