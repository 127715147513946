<div class="report-container p-0 m-0" id="contentToConvert">
  <div class="brand-container header">
    <a [routerLink]="['/']" class="navbar-brand d-flex">
      <img class="logo-img m-2" src="../../../assets/icons/logo-white.svg" alt="logo" title="logo"/>
    </a>
  </div>

  <div class="body m-4">
    <h1 class="ml-4">QC <br/>Report</h1>

    <div class="report-details mt-4 ml-4">
      <p class="mb-0">Product Type: Soy and sunflower oil blend Sunny Bean</p>
      <p class="mb-0">Brand: Sunny Bean</p>
      <p class="mb-0">Batch: August 2020_004</p>
      <p class="mb-0">Producer: The Good Oil</p>
    </div>

    <div class="device-details mt-3 ml-4">
      <p class="mb-0">Test Method: iCheck Chroma 3</p>
      <p class="mb-0">Analyte: VitimanA</p>
    </div>

    <div class="tolerance mt-3 ml-4">
      <p>Target and Tolerance Limits: 10 mg RE/kg ± 20%</p>
    </div>

    <div class="averages mt-3 ml-4">
      <p>Average results over the period of 20.07.2020 – 20.08.2020:</p>
    </div>

    <div class="averages mt-3 ml-4">
      <p>Compliance of results over the period of 20.07.2020 – 20.08.2020:</p>
    </div>

    <div class="chart m-auto w-50 h-50 ml-4">
      <img [src]="chartData" class="w-100 h-100 m-auto" alt="charting data image"/>
    </div>

  </div>
</div>
