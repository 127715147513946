import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TrainingAttempt } from '../../../../model/TrainingAttempt';

@Component({
  selector: 'app-training-data-table',
  templateUrl: './training-data-table.component.html',
  styleUrls: ['./training-data-table.component.scss']
})
export class TrainingDataTableComponent implements OnInit, OnChanges {
  prefix = "training.table.";

  @Input() attempts: TrainingAttempt[] = [];
  @Input() showNames: boolean;
  rows = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    let max = 0;
    for (let a of this.attempts) {
      if (a.samples.length > max) max = a.samples.length;
    }

    this.rows = [];
    for (let i = 0; i < max; i++) {
      this.rows.push(i);
    }
  }

  getPrecisionClass(a: TrainingAttempt): string {
    if (a.precision >= TrainingAttempt.SUCCESS_THRESHOLD) return 'success';

    if (a.precision >= TrainingAttempt.FAILURE_THRESHOLD) return 'meh';

    return 'fail';
  }
}
