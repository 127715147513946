import {Component, OnDestroy, OnInit,} from '@angular/core'
import {Subscription} from 'rxjs'
import * as moment from 'moment'

import {User} from '../../../../model/user'
import {ToastService} from '../../../services/toast.service'
import {UserService} from '../../../services/user.service'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {CacheService} from '../../../services/cache.service'
import {Device} from '../../../../model/device'
import {ModalService} from '../../../shared/modal.service'
import {FileUploadStatus} from '../../../shared/file-upload/file-upload.component'
import {Router} from '@angular/router'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', './profile.media.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  showTab = 'account'
  prefix = 'profile.'

  // tabs = ['account', 'company', 'password', 'notifications'];
  tabs = ['account']
  accountFields = ['fName', 'lName', 'phone', 'email', 'city', 'state', 'zipcode', 'country']

  accountForm: FormGroup
  companyForm: FormGroup
  passwordForm: FormGroup
  notificationsForm: FormGroup
  canManage = false
  devices: Device[]
  device: Device
  busy = false
  file: FileUploadStatus
  fileUploadInProgress = false
  userpic: string = null

  get referralLink(): string {
    if (!this.user) return ''
    return this._linkBase + this.user.organization.code
  }

  user: User
  passwordChanged = false

  private _subs = new Subscription()
  private _linkBase = 'https://icheck.bioanalyt.com/register?orgCode='

  constructor(
    private us: UserService,
    private toasts: ToastService,
    private cache: CacheService,
    private modals: ModalService,
  ) {
  }

  ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe((u) => {
      this.user = u
      console.log('Users - ', this.user)
      this.setupAccountForm()
      this.setupCompanyForm()
    }))

    this._subs.add(this.us.userChanged.subscribe(u => {
      //todo ADD MANAGER \/
      this.canManage = u.hasRole('superAdmin')
    }))

    this._subs.add(this.us.userpic.subscribe(up => {
      this.userpic = up
    }))

    this._subs.add(this.cache.devices.subscribe(d => {
      const max = d.reduce((acc, item) => acc = acc > moment(item.maintained).unix() ? acc : moment(item.maintained).unix(), 0)
      this.device = d.filter(dv => moment(dv.maintained).unix() === max)[0]
    }))
  }

  ngOnDestroy() {
    if (this._subs) {
      this._subs.unsubscribe()
      this._subs = undefined
    }
  }

  setupAccountForm() {
    this.accountForm = new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      phone: new FormControl(this.user.phone, [Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      email: new FormControl(this.user.email, Validators.required),
      city: new FormControl(this.user.city, Validators.required),
      // state: new FormControl('',),
      // zipcode: new FormControl(this.user.organization.postalCode, Validators.required),
      // country: new FormControl(this.user.organization.country, Validators.required),
    })
  }

  setupCompanyForm() {
    const org = this.user.organization
    this.companyForm = new FormGroup({
      name: new FormControl(org.name, Validators.required),
      code: new FormControl(org.code),
      phone: new FormControl(org.phone,),
      state: new FormControl('',),
      address: new FormControl(org.address, Validators.required),
      address2: new FormControl(org.address2,),
      zipcode: new FormControl(org.postalCode,),
      city: new FormControl('',),
      country: new FormControl(org.country, Validators.required),
    })
  }

  setupPasswordForm() {

  }

  setupNotificationsForm() {

  }

  passwordResetSuccess() {
    this.toasts.success('profile.password-reset-toast')
    this.passwordChanged = true
  }

  passwordResetError(err) {
    //todo respond to "incorrect password" error.
    console.log('Nope', err)
  }

  tabClicked(tab) {
    this.showTab = tab
  }

  async upload(u?) {
    const uuid = u ? u.uuid : null
    // if (this.status.done) return this.status
    // this.status = {status: "uploading", done: false, fileSelected: true, name: this._file.name}
    try {
      // const reference = await this.api.postFile(this._file, uuid)
      // this.status = {status: "success", done: true, reference, fileSelected: true, name: this._file.name}
    } catch (err) {
      // this.status = {status: "error", done: true, fileSelected: true, name: this._file.name}
    }
    // return this.status
  }

  async saveAccount() {
    this.accountForm.markAllAsTouched()
    if (!this.accountForm.valid) return

    const result = await this.modals.showConfirm()

    if (!result) return
    const usr = this.accountForm.value
    try {
      this.busy = true
      const updatedUser = await this.us.updateUser(usr)
      this.busy = false
      this.toasts.success(this.prefix + 'tabs.account.success-toast')
    } catch (err) {
      this.busy = false
      console.error(err)
      this.toasts.danger(this.prefix + 'tabs.account.error-toast')
    }
  }

  // uploadProgress(p) {
  //   this.fileUploadInProgress = p.done
  //   console.log(p)
  //   if (!p.name) return
  //
  //   const current = this.files.find(gotten => gotten.name === p.name)
  //   if (current) {
  //     Object.assign(current, p)
  //   } else {
  //     this.files.push(p)
  //   }
  //
  //   this.fileUploadInProgress = !p.done
  // }
  async uploadProgress(e) {
    this.busy = true
    if (!e.name || e.status !== 'success') return
    console.log('eeeeee', e)

    const res = await this.us.updateUserPic({ref: e.reference})
    this.busy = false
    console.log('resresres', res)
  }
}
