import { Component, Input } from '@angular/core';
import {TrainingService} from '../../../services/training.service'

@Component({
  selector: 'app-training-chart',
  templateUrl: './training-chart.component.html',
  styleUrls: ['./training-chart.component.scss'],
})
export class TrainingChartComponent {
  prefix = 'dashboard.charts.training-chart.'
  public steps = [1, 2, 3, 4, 5, 6, 7];
  trainingIndex
  @Input() options: any;

  constructor(private trainService: TrainingService) {
    this.trainingIndex = trainService.trainingProgressIndex.getValue()
  }

}
