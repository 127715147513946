<div class="btn-group" dropdown (onHidden)="hidden()">
  <button dropdownToggle type="button" class="btn btn-light dropdown-toggle" aria-controls="dropdown-basic">
    <i class="fa mr-2" [ngClass]="icon"></i>
    <span>{{ getLabel() | translate : { name: getItemName(), c: selectCount } }}</span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu">

    <ng-template [ngIf]="showClearItem">
      <li role="menuitem">
        <a href="javascript:void(0)" class="dropdown-item" [class.disabled]="selectCount === 0" (click)="clearSelection($event)">
          <i class="fa fa-check fa-square-o"></i>
          {{ translateBase + '.clear' | translate }}
        </a>
      </li>
      <li class="divider dropdown-divider"></li>
    </ng-template>

    <li *ngFor="let i of items" role="menuitem">
      <a href="javascript:void(0)" class="dropdown-item" (click)="toggle(i, $event)">
        <i [class.visible]="i.selected" class="fa fa-check select-icon"></i>
        {{ i[property] }}
      </a>
    </li>
  </ul>
</div>
