<div class="modal-header">
  <p class="m-0 text-dark font-weight-bold">{{ prefix + 'title' | translate}}</p>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"
      stroke-linejoin="round"/>
</svg>
    </span>
  </button>
</div>

<app-parent-org *ngIf="step === 'parent'" (parentSelected)="parentSelected($event)"></app-parent-org>
<app-child-org *ngIf="step === 'child'" [inputOrganizations]="this.data.organizations" (childSelected)="childSelected($event)"></app-child-org>
