import {Component, OnDestroy, OnInit} from '@angular/core'
import {CacheService} from '../../../services/cache.service'
import {NotificationService} from '../../../services/notification.service'
import {UserService} from '../../../services/user.service'
import {User} from '../../../../model/user'
import {Notification} from '../../../../model/notification'
import {Subscriptions} from '../../../../model/subscriptions'
import * as moment from 'moment'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  prefix = 'notification.'
  user: User
  notifications: Notification[] = []
  page = 0;
  results

  private _subs = new Subscriptions()

  notificationType = null

  //todo temporary solution
  trainingNotificationsTypes = [
    'successful-training',
    'unsuccessful-training',
    'precision-middle',
    'unsuccessful-last-two-trainings',
    'failed-training-attempts'
  ]

  testKitsNotificationsTypes = ['test-kits-left']

  deviceControlNotificationsTypes = [
    'device-control-is-not-in-range',
    'device-control-not-in-range'
  ]

  constructor(
    private cache: CacheService,
    private ns: NotificationService,
    private us: UserService
  ) {
  }

  async ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe(u => this.user = u))
    this.results = await this.us.getNotificationsPage(this.page)
    this.processResponse()
  }

  ngOnDestroy() {
    if (this._subs) {
      this._subs.unsubscribeAll()
      this._subs = undefined
    }
  }

  async markAllAsViewed() {
    const ids = this.notifications.map(n => n.id)
    const result = await this.ns.markAsViewed(ids)
    if (result.status === 'ok') {
      this.notifications.forEach(n => {
        n.viewed = true
      })
    }
  }

  async delete(id: any) {
    this.notifications = this.notifications.filter(n => n.id !== id)
    await this.ns.deleteNotification(id)
  }

  getDate(created) {
    return moment.unix(created)
  }


  processResponse() {
    this.results.notifications = this.results.notifications.map(n => Notification.fromApi(n))
    this.notifications = this.results.notifications.map(n => {
      const notification = Notification.fromApi(n)
      if (this.trainingNotificationsTypes.includes(notification.type)) {
        notification.notificationType = 'training'
      } else if (this.testKitsNotificationsTypes.includes(notification.type)) {
        notification.notificationType = 'test-kit'
      } else if (this.deviceControlNotificationsTypes.includes(notification.type)) {
        notification.notificationType = 'device-control'
      }
      return notification
    })
  }

  async toPage(page: number) {
    this.page = page;
    this.results = await this.us.getNotificationsPage(this.page)
    this.processResponse()
  }
}
