import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'

@Component({
  selector: 'app-edit-delete-dropdown',
  templateUrl: './edit-delete-dropdown.component.html',
  styleUrls: ['./edit-delete-dropdown.component.scss']
})
export class EditDeleteDropdownComponent implements OnInit {
  prefix = 'dropdowns.edit-delete-dropdown.'
  @Input() canCreate
  @Input() canDelete
  @Output() action: EventEmitter<string> = new EventEmitter<string>()

  constructor() {
  }

  ngOnInit(): void {
  }

  clicked(action) {
    this.action.emit(action)
  }
}
