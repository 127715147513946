import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Organization} from "../../../../model/organization";
import {User} from "../../../../model/user";
import {SelectOption} from "../../form-control/form-control.component";
import {FileUploadComponent} from "../../file-upload/file-upload.component";
import {MultiSelectDropdownComponent} from "../../multi-select-dropdown/multi-select-dropdown.component";
import {DataSharingOrganization} from "../data-sharing-groups-advanced-modal.component";

@Component({
  selector: 'app-data-sharing-groups-advanced-modal-organization',
  templateUrl: './data-sharing-groups-advanced-modal-organization.component.html',
  styleUrls: ['./data-sharing-groups-advanced-modal-organization.component.scss']
})
export class DataSharingGroupsAdvancedModalOrganizationComponent implements OnInit, OnChanges {

  prefix = 'data-sharing-groups.advanced-modal.organization.';
  @ViewChild('organizationsSelect') organizationsSelectRef: MultiSelectDropdownComponent;
  @ViewChild('usersSelect') usersSelectRef: MultiSelectDropdownComponent;
  @Input() organization: DataSharingOrganization
  // @Input() organizations: Organization[];
  @Input() isFirst: boolean;
  // @Input() usersSelect: SelectOption[] = [];
  // @Input() organizationsSelect: SelectOption[] = [];
  // @Input() allOrganizations: Organization[] = []
  @Output() onDeleteClicked: EventEmitter<number> = new EventEmitter<number>()
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>()
  @Output() selectionGroupChanged: EventEmitter<any> = new EventEmitter<any>()
  // allOrganizationsSelect: any = []
  // usersSelect: any = []
  // organizationsSelect: any = []
  expanded = false;

  // loading = true

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnInit(): void {
    if (!this.organization.sharing) this.organization.sharing = 'general';
    if (!this.organization.receiving) this.organization.receiving = 'general';
  }


  changeReceiving(state: 'general' | 'custom') {
    this.organization.receiving = state;
    this.organizationsSelectRef.selectAll(state === 'general' ? true : false)
  }

  changeSharing(state: 'general' | 'custom') {
    this.usersSelectRef.selectAll(state === 'general' ? true : false)
    this.organization.sharing = state;
  }

  // getOrgUsers() {
  // return this.organizationsSelect.filter(o => this.organization.id !== o.value)
  // }

  itemSelected($event: any, orgId: number) {
    this.selectionChanged.emit({item: $event, orgId})
  }

  groupSelected($event: any, orgId: number) {
    this.selectionGroupChanged.emit({item: $event, orgId})
  }
}
