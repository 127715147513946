<div class="toast-container">

  <div class="card-container" *ngFor="let t of toasts" [@slideIn]="toasts.length">
    <div (click)="onClick(t)" class="card text-white elevation-2dp" [ngClass]="'bg-' + t.style">
      <div *ngIf="t.title" class="card-header">{{ t.title | translate : t.params }}</div>
      <div class="card-body">
        <p class="card-text">{{ t.message | translate : t.params }}</p>
      </div>
    </div>
  </div>

</div>