import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Organization} from '../../../model/organization'
import {User} from '../../../model/user'

@Component({
  selector: 'app-dropdown-user-organizations',
  templateUrl: './dropdown-user-organizations.component.html',
  styleUrls: ['./dropdown-user-organizations.component.scss']
})
export class DropdownUserOrganizationsComponent implements OnInit {

  prefix = 'dropdowns.user-organization.'

  @Input() organizations: Organization[]
  @Input() menuMaxHeight = '400px'
  @Output() userSelected: EventEmitter<User> = new EventEmitter<User>()
  selectedUser: User

  constructor() { }

  ngOnInit(): void {
  }

  selectUser(user: User) {
    this.selectedUser = user
    this.userSelected.emit(user)
  }
}
