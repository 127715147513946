import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Device } from '../../../model/device';
import { Subscriptions } from '../../../model/subscriptions';
import { CacheService } from '../../services/cache.service';
import { DeviceModalComponent } from '../device-modal/device-modal.component';
import { ModalService } from '../modal.service';
import { Organization } from '../../../model/organization';

@Component({
  selector: 'app-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss']
})
export class DeviceManagerComponent implements OnInit {
  @Input() devices: Device[] = [];
  @Input() organization?: Organization; //Used when admin creates devices for other device.
  @Output() refresh = new EventEmitter<void>();
  @Output() advancedAdd = new EventEmitter<void>();

  constructor(
    private modals: ModalService,
  ) { }

  ngOnInit() {
  }

  async addDevice(addType?: string) {
    console.log('add')
    if (addType === 'advanced') {
      return this.advancedAdd.emit();
    }

    const result = await this.modals.show(DeviceModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        newDevice: true,
        organization: this.organization,
      }
    });

    if (result) this.refresh.emit();
  }
}
