<div class="modal-container">
  <header class="header">
    <!--    <p class="header__title">{{prefix + 'title' | translate}}</p>-->

    <div class="header__back" *ngIf="isNew; else old">
      <app-buttons
        class="header__back__btn"
        type="invisible-icon"
        width="auto"
        height="auto"
        (onClick)="goBack()"
      >
        <div class="header__back__btn__container">
          <img class="header__back__btn__container__icon" src="assets/icons/back-arrow.svg">
          <p class="header__back__btn__container__text">{{prefix + 'back' | translate}}</p>
        </div>
      </app-buttons>
    </div>
    <ng-template #old>
      <p class="header__title">{{prefix + 'title' | translate}}</p>
    </ng-template>

    <app-buttons
      type="invisible-icon"
      width="auto"
      height="auto"
      (onClick)="close()"
    >
      <img src="assets/icons/cross.svg">
    </app-buttons>
  </header>

  <main class="main">
    <div class="main__data-group-name">
      <app-form-control
        class="main__data-group-name__input"
        type="new-input"
        [control]="form.get('name')"
        [prefix]="prefix + 'name'"
      ></app-form-control>
    </div>

    <div class="main__organizations">
      <p class="main__organizations__title">{{prefix + 'organizations-title' | translate}}</p>
      <app-data-sharing-groups-advanced-modal-organization
        *ngFor="let organization of organizations; let idx = index"
        [isFirst]="idx === 0"
        [organization]="organization"
        (onDeleteClicked)="onDelete($event)"
        (selectionChanged)="onUserSelect($event)"
        (selectionGroupChanged)="onOrgUserSelect($event)"
        class="main__organizations__organization"
      >
      </app-data-sharing-groups-advanced-modal-organization>
      <div class="main__organizations__add-organization-btn-container">
        <app-buttons
          class="main__organizations__add-organization-btn"
          type="text"
          fontSize="14px"
          color="#038E9B"
          [text]="prefix + 'add-org-btn' | translate"
          (onClick)="addOrganization()"
        ></app-buttons>
      </div>
    </div>

    <div class="main__controls">
      <app-buttons
        class="main__controls__cancel-btn"
        type="outline-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        [disabled]="loading"
        [text]="prefix + 'cancel-btn' | translate"
        (onClick)="close()"
      ></app-buttons>


      <app-buttons
        class="main__controls__next-btn"
        type="filled-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        [disabled]="validateSaveBtn()"
        [text]="prefix + 'save-btn' | translate"
        (onClick)="save()"
      ></app-buttons>
    </div>
  </main>
  <div *ngIf="loading" class="loader-wrapper">
    <div class="loader-container">
      <app-dots-loader></app-dots-loader>
    </div>
  </div>
</div>
