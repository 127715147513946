/// <reference types="@types/googlemaps" />
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Renderer2,
  Output,
  EventEmitter, AfterViewInit
} from '@angular/core'
import { FormControl } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover/public_api';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('textareaInput') textareaInput: ElementRef;
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('infoPopover') infoPopover: PopoverDirective;
  @ViewChild('city', {static: false}) cityRef: ElementRef;
  @ViewChild('address', {static: false}) addressRef: ElementRef;

  @Input() control: any;
  @Input() prefix = "form-control";
  @Input() type = "text";
  @Input() steps = 1;
  @Input() hideLabel = false;
  @Input() disabled = false;
  @Input() subTitle = '';
  @Input() bg = ''
  @Input() marginBottom = 16
  @Input() textTransform = 'capitalize'
  @Input() labelFontSize = 14
  @Input() placeholder = ''
  @Input() labelValue = ''
  @Input() optional = null
  @Input() maxLength = null
  @Input() hideArrows = false
  @Input() selected = false
  @Input() checkboxLabel
  @Input() checkboxSubLabel = ''
  @Input() disableSelectEffect = false

  @Input() popoverOptions: PopoverOptions = { enabled: false, placement: 'top' };
  @Input() set showInfo(v: boolean) { this.popoverOptions.enabled = v; }
  @Input() set popoverParams(v: any) { this.popoverOptions.params = v; }

  @Input() typeaheadValues: string[] = [];

  @Input() valueProp = "value";
  @Input() nameProp = "name";
  @Input() set selectOptions(options: string[] | any[]) {
    this._selectOptions = [];
    for (let o of options) {
      if (typeof o === 'string') {
        this._selectOptions.push({ name: o, value: o });
      } else if (typeof o === 'object') {
        this._selectOptions.push({ name: o[this.nameProp], value: o[this.valueProp],
        disabled: o.disabled});
      }
    }
  }
  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedMeasurement: EventEmitter<any> = new EventEmitter<any>();
  @Output() radioSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnterPressed: EventEmitter<void> = new EventEmitter<void>();

  passType = 'password'

  get options(): SelectOption[] {
    return this._selectOptions;
  }

  private _dispose: Function;
  private _selectOptions: SelectOption[] = [];

  id = "control-" + Math.floor(Math.random() * 1000000);

  get errors() {
    if (!this.control || !this.control.errors) return [];
    return Object.getOwnPropertyNames(this.control.errors);
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.hidePopover();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
    if (this.maxLength) {
      this.textInput.nativeElement.setAttribute('maxLength', this.maxLength)
    }
  }

  popoverShown(e) {
    //Register click handler
    this._dispose = this.renderer.listen('document', 'click', e => {
    //Check to see if we are clicking outside of the element.
    if (!this.el.nativeElement.contains(e.target)) {
        this.hidePopover();
    }
});
  }

  focus() {
    setTimeout(() => {
      if (this.textInput) {
        this.textInput.nativeElement.focus();
      }
      if (this.textareaInput) {
        this.textareaInput.nativeElement.focus();
      }
      //todo do this with date as well.
    });
  }

  hidePopover() {
    if (this.infoPopover) this.infoPopover.hide();
    if (this._dispose) this._dispose();
  }

  onCheckboxChanged(e) {
    this.checkboxChanged.emit(e.target.checked)
  }

  onSelect($event, value) {
    this.selectedMeasurement.emit(value)
    this.onSelected.emit($event.target.value)
  }

  showPass() {
    this.passType = this.passType === 'text' ? 'password' : 'text';
  }

  private getPlaceAutocomplete() {
    if (this.cityRef) {
      const autocomplete = new google.maps.places.Autocomplete(this.cityRef.nativeElement, {types: ['(cities)']});
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.control.setValue(place.vicinity);
      });
    }

    if (this.addressRef) {
      const autocomplete = new google.maps.places.Autocomplete(this.addressRef.nativeElement, {types: ['address']});
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.control.setValue(place.name);
      });
    }
  }

  onRadioSelected(value) {
    this.radioSelected.emit(value)
  }
}

export interface SelectOption {
  id?: number;
  name: string;
  value: any;
  disabled?: boolean;
  type?: string;
  subLabel?: string;
  selected?: boolean
}

interface PopoverOptions {
  placement?: string;
  enabled: boolean;
  text?: string;
  params?: any;
}
