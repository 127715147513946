<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate : params }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="content">{{prefix + 'text' | translate : {optionalText} }}</p>
</div>
<div class="modal-footer">
  <button type="button" (click)="close(false)" class="btn btn-light"
          data-dismiss="modal">{{ cancelButton | translate : params }}</button>
  <button type="button" (click)="close(true)" class="btn btn-primary">{{ okButton | translate : params }}</button>
</div>
