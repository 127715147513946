import {Injectable} from '@angular/core'
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router'
import {ApiService} from './services/api.service'
import {UserService} from './services/user.service'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private us: UserService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const loggedIn = await this.us.isLoggedIn(true);
    if (loggedIn) return true;

    await this.router.navigate(['/landing']);
    return false;
  }
}

@Injectable()
export class RoleGaurd implements CanActivate {
  constructor(private us: UserService, private router: Router, private apiService: ApiService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // this.apiService.ready()
    await this.us.init();

    const role = route.data.role || 'admin';

    const u = this.us.userChanged.getValue();
    if (u && u.hasRole(role)) return true;

    if (u) {
      //Logged in, go to dashboard.
      this.router.navigate['/'];
      return false;
    }

    //Else, go to login.
    this.router.navigate(['/landing']);
    return false;
  }
}

