import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AllDataTable} from "../../pages-authenticated/user/all-data/all-data.component";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-all-data-table',
  templateUrl: './all-data-table.component.html',
  styleUrls: ['./all-data-table.component.scss']
})
export class AllDataTableComponent implements OnInit, OnChanges {
  prefix = 'all-data.table.';
  @Input() data: AllDataTable[] = []
  @Input() currentPage
  @Input() type
  @Input() loadingRows = []
  @Output() onSelected: EventEmitter<AllDataTable[]> = new EventEmitter<AllDataTable[]>()
  @Output() onSelectColumn: EventEmitter<any> = new EventEmitter<any>()
  columns = [
    'date',
    'time',
    'productLineName',
    'productName',
    'productBatch',
    'analyst',
    'analyte',
    'result',
    'unit',
    'organizationName',
    'organizationCountry',
    'userId',
    'organizationCode',
    'icheckBatch',
    'deviceType',
    'deviceId',
    'standard',
    'standardLot',
    'standardReading',
    'standardReadingDate',
    'testKitType',
    'testKitLot',
  ]

  disabledRows = [
    'date',
    'time',
    'analyst',
    'result',
    'unit',
    'organizationCode',
    'organizationName',
    'productLineName',
    'productName',
  ]
  selectedColumns = []

  selected = []
  tableItemLoading = []

  constructor(
    private ds: DataService
  ) {
  }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("datazxczxczxc", this.data)
    // this.selected = []
  }

  toggleAll(e) {
    this.selected = []
    this.data.forEach(item => {
      item.selected = e.target.checked
      item.selected ? this.selected.push(item) : this.selected = this.selected.filter(i => i !== item)
    })
    this.onSelected.emit(this.selected)
  }

  toggle(e, d: AllDataTable) {
    d.selected = e.target.checked
    d.selected ? this.selected.push(d) : this.selected = this.selected.filter(i => i !== d)
    this.onSelected.emit(this.selected)
  }

  async changeStatus(d: AllDataTable) {
    this.tableItemLoading.push(d.id)
    try {
      const res = d.disabled ? await this.ds.enableSamples([d.id]) : await this.ds.disableSamples([d.id])
      if (res.result === 'ok') {
        d.disabled = !d.disabled
        this.tableItemLoading = this.tableItemLoading.filter(id => id !== d.id)
      }
    } catch (e) {
      console.log(e)
      this.tableItemLoading = this.tableItemLoading.filter(id => id !== d.id)
    }
  }


  selectRow(column: string) {
    this.selectedColumns.includes(column) ?
      this.selectedColumns = this.selectedColumns.filter(r => r !== column) :
      this.selectedColumns.push(column)
    console.log("this.selectedColumnsthis.selectedColumns", this.selectedColumns, 'type=', this.type)
    this.onSelectColumn.emit(this.selectedColumns)
  }

  check(d: AllDataTable, col: string) {
    if (d.selected) {
      console.log('asdas', {
        all: (d.selected && this.selectedColumns.includes(col) || d.disabled),
        'd.selected': d.selected,
        'this.selectedColumns.includes(col)': this.selectedColumns.includes(col),
        'd.disabled': d.disabled,
        'col': col,
        'this.selectedColumns': this.selectedColumns,
        type: this.type
      })
    }
  }
}
