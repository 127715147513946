<div dropdown [insideClick]="true" placement="top right">
  <button class="position-relative btn btn-link text-light my-2 my-sm-0" type="button" dropdownToggle (click)="checkNotifications()">
    <span *ngIf="getNewNotificationsLength() > 0">
      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.345 17.5077C17.2803 17.4658 16.7936 17.1248 16.3062 16.0933C15.4112 14.1992 15.2234 11.5309 15.2234 9.62607C15.2234 9.61778 15.2232 9.60957 15.2229 9.60132C15.2132 7.08383 13.7057 4.91365 11.5485 3.93993V2.45077C11.5485 1.0994 10.4509 0 9.10175 0H8.89898C7.54985 0 6.45226 1.0994 6.45226 2.45077V3.93985C4.28783 4.9167 2.77735 7.09796 2.77735 9.62607C2.77735 11.5309 2.58949 14.1992 1.6945 16.0933C1.20719 17.1248 0.720482 17.4657 0.655771 17.5077C0.383263 17.6339 0.2489 17.9252 0.311591 18.2204C0.374884 18.5186 0.65358 18.7241 0.957927 18.7241H5.68221C5.7086 20.5349 7.18646 22 9.00039 22C10.8143 22 12.2922 20.5349 12.3186 18.7241H17.0429C17.3472 18.7241 17.6259 18.5186 17.6892 18.2204C17.7518 17.9252 17.6175 17.6338 17.345 17.5077ZM7.74136 2.45077C7.74136 1.81135 8.26068 1.29117 8.89902 1.29117H9.10179C9.74014 1.29117 10.2595 1.81135 10.2595 2.45077V3.52142C9.85263 3.43733 9.43149 3.39307 9.00022 3.39307C8.56907 3.39307 8.14806 3.43728 7.7414 3.52129V2.45077H7.74136ZM9.00039 20.7088C7.89725 20.7088 6.99744 19.8229 6.97127 18.7242H11.0295C11.0033 19.8229 10.1035 20.7088 9.00039 20.7088ZM11.5957 17.4329C11.5956 17.4329 2.42681 17.4329 2.42681 17.4329C2.53832 17.2592 2.6518 17.0624 2.76459 16.8401C3.62844 15.1366 4.06646 12.7095 4.06646 9.62607C4.06646 6.90112 6.27974 4.68424 9.00017 4.68424C11.7206 4.68424 13.9339 6.90112 13.9339 9.62822C13.9339 9.63617 13.934 9.64408 13.9343 9.65198C13.9368 12.723 14.3747 15.1415 15.2362 16.8401C15.349 17.0625 15.4625 17.2592 15.574 17.4329H11.5957Z"
        fill="#68686D"/>
      </svg>
      <span class="notifier">
        <p>{{getNewNotificationsLength()}}</p>
      </span>
    </span>

    <span *ngIf="getNewNotificationsLength() === 0">
      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.345 17.5077C17.2803 17.4658 16.7936 17.1248 16.3062 16.0933C15.4112 14.1992 15.2234 11.5309 15.2234 9.62607C15.2234 9.61778 15.2232 9.60957 15.2229 9.60132C15.2132 7.08383 13.7057 4.91365 11.5485 3.93993V2.45077C11.5485 1.0994 10.4509 0 9.10175 0H8.89898C7.54985 0 6.45226 1.0994 6.45226 2.45077V3.93985C4.28783 4.9167 2.77735 7.09796 2.77735 9.62607C2.77735 11.5309 2.58949 14.1992 1.6945 16.0933C1.20719 17.1248 0.720482 17.4657 0.655771 17.5077C0.383263 17.6339 0.2489 17.9252 0.311591 18.2204C0.374884 18.5186 0.65358 18.7241 0.957927 18.7241H5.68221C5.7086 20.5349 7.18646 22 9.00039 22C10.8143 22 12.2922 20.5349 12.3186 18.7241H17.0429C17.3472 18.7241 17.6259 18.5186 17.6892 18.2204C17.7518 17.9252 17.6175 17.6338 17.345 17.5077ZM7.74136 2.45077C7.74136 1.81135 8.26068 1.29117 8.89902 1.29117H9.10179C9.74014 1.29117 10.2595 1.81135 10.2595 2.45077V3.52142C9.85263 3.43733 9.43149 3.39307 9.00022 3.39307C8.56907 3.39307 8.14806 3.43728 7.7414 3.52129V2.45077H7.74136ZM9.00039 20.7088C7.89725 20.7088 6.99744 19.8229 6.97127 18.7242H11.0295C11.0033 19.8229 10.1035 20.7088 9.00039 20.7088ZM11.5957 17.4329C11.5956 17.4329 2.42681 17.4329 2.42681 17.4329C2.53832 17.2592 2.6518 17.0624 2.76459 16.8401C3.62844 15.1366 4.06646 12.7095 4.06646 9.62607C4.06646 6.90112 6.27974 4.68424 9.00017 4.68424C11.7206 4.68424 13.9339 6.90112 13.9339 9.62822C13.9339 9.63617 13.934 9.64408 13.9343 9.65198C13.9368 12.723 14.3747 15.1415 15.2362 16.8401C15.349 17.0625 15.4625 17.2592 15.574 17.4329H11.5957Z"
        fill="#68686D"/>
      </svg>
    </span>

  </button>

  <div class="dropdown-menu dropdown-menu-right py-0" *dropdownMenu #dropdown>
    <app-notification-card *ngFor="let n of notifications; let idx = index"
                           [border]="idx !== (notifications.length - 1)" [notification]="n"></app-notification-card>
    <app-notification-card [border]="false" *ngIf="notifications.length === 0"
                           [notification]="noData"></app-notification-card>
  </div>
</div>
