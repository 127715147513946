import {Component} from '@angular/core'
import {ApiService} from 'src/app/services/api.service'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss', './user.media.scss'],
})
export class UserComponent {

  navLinks = [
    {
      title: 'menu.menu',
      links: [
        {
          route: '/introduction',
          icon: 'icon-intro.svg',
          translate: 'menu.introduction',
        },
        {
          route: '/dashboard',
          icon: 'icon-home.svg',
          translate: 'menu.dashboard',
        },
        {
          route: '/devices',
          icon: 'icon-devices.svg',
          translate: 'menu.devices',
        },
        {
          route: '/consumables',
          icon: 'icon-tests.svg',
          translate: 'menu.consumables',
        },
        {
          route: '/products',
          icon: 'icon-product.svg',
          translate: 'menu.product',
        },
        {
          route: '/training/',
          icon: 'icon-training.svg',
          translate: 'menu.training',
        },
        {
          route: '/all-data',
          icon: 'icon-data.svg',
          translate: 'menu.all-data',
        },
      ],
    },
    {
      title: 'menu.assistance',
      links: [
        {
          route: '/docs',
          icon: 'icon-docs.svg',
          translate: 'menu.documents',
        },
        {
          icon: 'icon-manuals.svg',
          translate: 'menu.manuals',
          subLinks: [
            {
              route: '/manuals',
              translate: 'menu.devices-instruction',
            },
            {
              route: '/transfer-data',
              translate: 'menu.transfer-data',
            }
          ]
        },
        {
          route: '/tickets/new',
          icon: 'icon-contact.svg',
          translate: 'menu.contact',
        },
        {
          route: '/tickets/mine',
          icon: 'icon-ticket.svg',
          translate: 'tickets.menu.mine',
        },
        {
          route: '/profile',
          icon: 'icon-settings.svg',
          translate: 'menu.settings',
        },
        {
          route: '/notifications',
          icon: 'icon-outline-notif.svg',
          translate: 'menu.notifications'
        },
      ],
    },
  ]

  constructor() {
  }


}
