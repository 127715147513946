import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {User} from '../../../model/user'

@Component({
  selector: 'app-permissions-dropdown',
  templateUrl: './permissions-dropdown.component.html',
  styleUrls: ['./permissions-dropdown.component.scss']
})
export class PermissionsDropdownComponent implements OnInit {

  prefix = 'admin.hierarchy.'
  @Input() user: User
  @Output() outputUser: EventEmitter<User> = new EventEmitter<User>()
  permissionOptions = [
    'all',
    'canUploadInstead',
    'canViewInstead'
  ]

  constructor() {
  }

  ngOnInit(): void {
    if (!this.user.hierarchyPermissions) this.user.hierarchyPermissions = ['all']
  }

  onPermissionsChanged(e, uuid, permission) {
    if (e.target.checked) {
      const permissions = this.user.hierarchyPermissions.concat(permission)
      if (permission === 'all') {
        this.user.hierarchyPermissions = ['all']
      } else if (permissions.includes(this.permissionOptions[1]) && permissions.includes(this.permissionOptions[2])) {
        this.user.hierarchyPermissions = ['all']
        e.target.checked = false
      } else if (
        permissions.includes(this.permissionOptions[0]) &&
        permissions.includes(this.permissionOptions[1]) || permissions.includes(this.permissionOptions[2])
      ) {
        this.user.hierarchyPermissions = this.user.hierarchyPermissions
          .filter(p => p !== 'all')
        this.user.hierarchyPermissions.push(permission)
      } else {
        this.user.hierarchyPermissions.push(permission)
      }
    } else {
      if (permission === 'all') {
        this.user.hierarchyPermissions = []
      } else if (permission !== 'all' && this.user.hierarchyPermissions.includes('all')) {
        this.user.hierarchyPermissions = this.user.hierarchyPermissions
          .filter(p => p !== 'all').filter(p => p !== permission)
        this.user.hierarchyPermissions = this.permissionOptions
          .filter(p => p !== 'all').filter(p => p !== permission)
      } else {
        this.user.hierarchyPermissions = this.user.hierarchyPermissions
          .filter(p => p !== permission)
      }
    }
    console.log('this.user', this.user)
    this.outputUser.emit(this.user)
  }


}
