<div class="loader-wrapper" *ngIf="loading">
  <div class="loader-container">
    <app-dots-loader></app-dots-loader>
  </div>
</div>
<div class="container-fluid" *ngIf="!loading">
  <div class="my-4 f-center pt-2" *ngIf="unprocessed.length === 0">
    <div class="d-inline-block text-center">
      <h3>{{ 'import.empty-title' | translate }}</h3>
      <p>{{ 'import.empty-content' | translate }}</p>
      <p class="done-icon fa fa-check-square-o my-5"></p>
      <div>
        <button [routerLink]="['/dashboard']"
                class="btn btn-primary">{{ 'import.to-dashboard-button' | translate }}</button>
        <button [routerLink]="['/upload']"
                class="ml-2 btn btn-light">{{ 'import.to-upload-button' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="mb-3 pt-3">
    <p *ngIf="processed.length" [innerHtml]="'import.processed-explanation' | translate"></p>
    <app-processed-samples (processAgain)="addToUnprocessed($event)" #processedView
                           [samples]="processed"></app-processed-samples>
  </div>

  <app-onboarding-message text="onboarding.import.title" name="import-title" placement="bottom">
    <h3 class="mt-3" *ngIf="unprocessed.length > 0">{{ 'import.title' | translate : {c: selectCount} }}</h3>
  </app-onboarding-message>
  <div *ngIf="unprocessed.length > 0">
    <app-samples-table #samplesTable [tableData]="unprocessed" (onDiscard)="discard($event)"
                       (onProcess)="process($event)" (onSelectedCountChanged)="selectCount = $event"></app-samples-table>
  </div>
</div>
