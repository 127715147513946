import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core'
import {Organization} from '../../../../model/organization'
import {AdminService} from '../../../pages-authenticated/admin/admin.service'

@Component({
  selector: 'app-child-org',
  templateUrl: './child-org.component.html',
  styleUrls: ['./child-org.component.scss']
})
export class ChildOrgComponent implements OnInit, OnChanges {

  @Input() inputOrganizations: Organization[]
  @Output() childSelected = new EventEmitter()

  prefix = 'admin.hierarchy.create-hierarchy-modal.'
  organizations: Organization[]
  selectedOrganizations: Organization[] = []

  constructor(
    private as: AdminService
  ) {
  }

  async ngOnInit() {

  }

  async ngOnChanges() {
    this.organizations = await this.as.getHierarchyOrgsList()
    if (this.inputOrganizations.length) {
      const ids = this.inputOrganizations.map(o => o.id)
      this.organizations = this.organizations.filter(o => {
        return !ids.includes(o.id)
      })
    }
    this.organizations = this.organizations.map(o => {
      o.selected = false
      o.permission = 'readonly'
      o.status = 'child'
      return o
    })
    console.log(this.organizations)
  }

  onCheckboxChanged(e, idx) {
    this.organizations[idx].selected = e.target.checked
    if (e.target.checked) {
      this.selectedOrganizations.push(this.organizations[idx])
    } else {
      this.selectedOrganizations = this.selectedOrganizations.filter(o => o.id !== +e.target.value)
    }
  }

  save() {
    this.childSelected.emit(this.selectedOrganizations)
  }

}
