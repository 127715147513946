import * as moment from 'moment';
import { Moment } from 'moment';
import { User } from './user';
import { toObject } from './helpers';
import { Document } from './document';

export class Ticket {
  id: number = null;
  uuid: string = null;
  category: string = null;
  subject: string = null;
  userUuid: string = null;
  created: Moment = null;
  updated: Moment = null;
  status: TicketStatus = null;
  bugId?: string = null;

  messagesLoaded?: boolean = null;

  //Order details
  deviceType: string = null;
  quantity: number = null;

  messages: Message[] = [];
  user: User = null;

  static deserialize(data: any): Ticket {
    const mapping = { created: 'moment',
    updated: 'moment',
    user: User.deserialize,
    messages: ((data: any[]) => data.map((m: any) => Message.deserialize(m)))
  };
    return toObject<Ticket>(Ticket, data, mapping);
  }
}

export type TicketStatus = 'new' | 'waiting-for-user' | 'waiting-for-support' | 'closed';

export class Message {
  id: number = null;
  ticketId: number = null;
  userUuid: string = null;
  created: Moment = null;
  text: string = null;
  user: User = null;

  documents: Document[] = [];

  static newMessage(user: User, text: string, ticketId?: number): Message {
    const m = new Message();
    if (ticketId) m.ticketId = ticketId;
    m.user = user;
    m.userUuid = user.uuid;
    m.created = moment();
    m.text = text;

    return m;
  }

  static deserialize(data: any): Message {
    return toObject<Message>(Message, data, { created: 'moment', documents: (docs: any[]) => docs.map(d => Document.deserialize(d)) });
  }
}

export interface TicketsPage {
  tickets: Ticket[];
  lastPage: number;
  perPage: number;
  currentPage: number;
}
