import {Component, OnInit, Input} from '@angular/core'
import {Notification} from '../../../model/notification'
import {NotificationService} from '../../services/notification.service'
import * as moment from 'moment'

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: Notification
  @Input() border = true
  prefix = 'notification.'
  notificationType = null

  //todo temporary solution
  trainingNotificationsTypes = [
    'successful-training',
    'unsuccessful-training',
    'precision-middle',
    'unsuccessful-last-two-trainings',
    'failed-training-attempts'
  ]

  testKitsNotificationsTypes = ['test-kits-left']

  deviceControlNotificationsTypes = [
    'device-control-is-not-in-range',
    'device-control-not-in-range'
  ]

  constructor(
    private ns: NotificationService
  ) {
  }

  ngOnInit() {
    if (this.trainingNotificationsTypes.includes(this.notification.type)) {
      this.notificationType = 'training'
    } else if (this.testKitsNotificationsTypes.includes(this.notification.type)) {
      this.notificationType = 'test-kit'
    } else if (this.deviceControlNotificationsTypes.includes(this.notification.type)) {
      this.notificationType = 'device-control'
    }
  }

  async delete(id: any) {
    await this.ns.deleteNotification(id)
  }

  getDate(created) {
    return moment.unix(created)
  }
}
