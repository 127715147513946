<ul class="list-group mb-3">
  <ng-container *ngFor="let a of attempts" [ngSwitch]="a.precisionScore">
    <li class="list-group-item list-group-item-success" *ngSwitchCase="'good'">
      <span [innerHtml]="prefix + 'success' | translate : { name: a.user.getName(), cv: a.cv }"></span>
    </li>
    <li class="list-group-item list-group-item-warning" *ngSwitchCase="'meh'">
      <span [innerHtml]="prefix + 'meh' | translate : { name: a.user.getName(), cv: a.cv }"></span>
    </li>
    <li class="list-group-item list-group-item-danger" *ngSwitchCase="'poor'">
      <span [innerHtml]="prefix + 'poor' | translate : { name: a.user.getName(), cv: a.cv }"></span>
    </li>
  </ng-container>
</ul>
