<div class="modal-container">
  <header class="header">
    <p class="header__title">{{prefix + 'title' | translate}}</p>

    <app-buttons
      class="header__close"
      type="invisible-icon"
      (onClick)="modal.hide()"
      width="auto"
      height="auto"
    >
      <img src="/assets/icons/cross.svg">
    </app-buttons>
  </header>

  <main class="main">
    <p *ngIf="samples.length === 1; else multiple" class="main__question">{{prefix + 'single-sample' | translate : {
      state,
      date: samples[0].date + ' ' + samples[0].time,
      result: samples[0].result,
      unit: samples[0].unit
    } }}</p>

    <ng-template #multiple>
      <p class="main__multiple-title">{{prefix + 'multiple-title' | translate : {state} }}</p>
      <ul class="main__list">
        <li class="main__list__item" *ngFor="let s of samples">{{prefix + 'multiple-result' | translate : {
          date: s.date + ' ' + s.time,
          result: s.result,
          unit: s.unit
        } }}</li>
      </ul>
    </ng-template>

  </main>
    <div class="controls">
      <app-buttons
        class="main__controls__cancel-btn"
        type="outline-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        (onClick)="modal.hide()"
        [text]="prefix + 'no-btn' | translate"
      ></app-buttons>


      <app-buttons
        class="main__controls__next-btn"
        type="filled-gradient"
        width="190px"
        height="65px"
        fontSize="18px"
        (onClick)="confirm()"
        [text]="prefix + 'yes-btn' | translate"
      ></app-buttons>
    </div>
</div>
