<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" [checked]="permission === 'upload'" type="checkbox" value="upload" [id]="'upload'" (change)="onCheckboxChange($event)">
    <label class="text-dark custom-control-label" [for]="'upload'">Allow upload sample result</label>
  </div>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="col text-right">
      <button class="btn btn-primary" (click)="save()">{{prefix + 'save-btn' | translate}}</button>
    </div>
  </div>
</div>
