import { Moment } from 'moment';
import { toObject } from './helpers';
import * as moment from 'moment';
import { User } from './user';
import { Organization } from './organization';

export class Document {

  get icon(): string {
    if (!this.attachment) return _icons.default;

    return _icons[this.attachment.extension] || _icons.default;
  }
  id: number = null;
  name: string = null;
  description: string = null;
  fileReference: string = null;
  organizationId: number = null;
  authorUuid: string = null;
  documentType: string = null;

  attachment: Attachment = null;
  author: User = null;
  organization: Organization = null;

  static deserialize(data: any): Document {
    const d = toObject<Document>(Document, data);

    if (data.attachment) d.attachment = Attachment.deserialize(data.attachment);
    if (data.author) d.author = User.deserialize(data.author);

    return d;
  }

  isMine(user: User): boolean {
    //Are you the author?
    if (this.authorUuid === user.uuid) return true;

    //Are you in the same organization?
    if (this.organizationId !== user.organizationId) return false;

    //Are you managing?
    // if (user.hasRole('manager')) return true;

    //Are you an admin?
    if (user.hasRole('superAdmin')) return true;

    return false;
  }

  serialize() {
    const o: any = {...this};

    return o;
  }
}

const _icons = {
  default: 'fa-file-o',
  '.csv': 'fa-file-excel-o',
  '.xls': 'fa-file-excel-o',
  '.xlsx': 'fa-file-excel-o',
  '.pdf': 'fa-file-pdf-o',
  '.doc': 'fa-file-word-o',
  '.docx': 'fa-file-word-o',
  '.jpg': 'fa-file-image-o',
  '.jpeg': 'fa-file-image-o',
  '.png': 'fa-file-image-o',
  '.zip': 'fa-file-archive-o',
};

export interface DocumentsPage {
  documents: Document[];
  page: number;
  lastPage: number;
}

export class Attachment {
  originalFilename: string = null;
  reference: string = null;
  extension: string = null;
  created: Moment = null;
  userUuid: string = null;
  hash: string = null;
  public: boolean = null;
  size: number = null

  static deserialize(data: any): Attachment {
    const a = toObject<Attachment>(Attachment, data);
    if (data.created) a.created = moment.unix(data.created);
    return a;
  }

  serialize() {
    const o: any = { ...this };
    if (this.created) o.created = this.created.unix();

    return o;
  }
}
