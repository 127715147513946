<div class="btn-group mb-3 dropdown-container" dropdown>
  <p *ngIf="!hideLabel" class="text-dark fz-14 mb-2">{{getPrefix + 'label' | translate}}</p>
  <button id="button-basic" dropdownToggle type="button"
          class="text-left btn btn-light dropdown-toggle dropdown-btn"
          aria-controls="dropdown-basic">
              <span
                *ngIf="selectedItem">{{selectedItem.name}}</span>
    <span
      *ngIf="!selectedItem" [ngStyle]="{fontSize: fontSize + 'px'}">{{getPrefix + 'placeholder' | translate}}</span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
    <ng-container *ngIf="items.length; else noItems">
      <div class="dropdown-item-container"
           *ngFor="let i of items">
        <a class="dropdown-item"
           [class.active]="selectedItem && selectedItem.value === i.value"
           (click)="selectItem(i)">
          <p
            [ngStyle]="{fontSize: fontSize + 'px'}"
            class="text-dark pointer m-0">{{ i.name }}</p>
        </a>
      </div>
    </ng-container>

    <ng-template #noItems>
      <div class="dropdown-item-container">
        <div class="dropdown-item">
          <p
            class="product-name text-dark pointer m-0"
            [ngStyle]="{fontSize: fontSize + 'px'}">{{ getPrefix + 'no-items' | translate }}</p>
        </div>
      </div>
    </ng-template>
  </ul>
</div>
