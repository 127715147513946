import { Sample } from './sample';
export interface PageResult {
  currentPage: number;
  totalItems: number;
  lastPage: number;
  items: any[];
}

export class Page<T> {
  currentPage: number;
  totalItems: number;
  lastPage: number;
  items: T[];

  constructor(result: PageResult, type?: any) {
    Object.assign(this, result);
    
    if (type && typeof type.deserialize === 'function') {
      this.items = [];
      result.items.forEach(d => {
        this.items.push(type.deserialize(d));
      });
    }
  }
}

