import { ControlValue } from './control-value';

export interface ProductLine {
  id?: number;
  name: string;
  products: Product[];
  organizationId: number;
  training: boolean;
  createdBy?: string;
}

export interface Product {
  id?: number;
  name: string;
  productLineId?: number;
  controlValues?: ControlValue[]
  productLine?: ProductLine
  createdBy?: string
}

export interface ProductCategory {
  id: number
  name: string
}

export interface ProductType {
  id: number;
  name: string;
  type: string;
  measurementTypeId: number;
  productCategoryId: number;
}
