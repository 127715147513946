<div class="content-container">
  <div class="cntr">
    <div class="header">
      <h1 class="title">{{prefix + 'title' | translate}}</h1>
      <p class="sub-title">{{prefix + 'sub-title' | translate}}</p>
    </div>


    <div class="content-desktop">
      <div class="product-lines">

        <button class="add-product-line-btn" *ngIf="canCreateProductLines" (click)="addProductLine()">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2228 7H1.35742M7.79011 1V13V1Z" stroke="url(#paint0_linear_4912_452)" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4912_452" x1="12.8982" y1="4.23999" x2="2.78536" y2="4.22875"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#06A3AE"/>
                <stop offset="1" stop-color="#008C96"/>
              </linearGradient>
            </defs>
          </svg>
          {{prefix + 'add-product-line' | translate }}
        </button>

        <hr class="product-lines-line">

        <ul class="product-lines-list">
          <li class="product-lines-list-item" [ngClass]="{'active': (currentProductLine | async)?.name == l.name}"
              *ngFor="let l of productLines" (click)="changeCurrentLine(l)">
            <p class="text-overflow-100-60 m-0">{{l.name}}</p>
            <span class="upper bottom-curve1 active-effect"></span>
            <span class="under bottom-curve2 active-effect"></span>
            <span class="upper top-curve1 active-effect"></span>
            <span class="under top-curve2 active-effect"></span>
          </li>

        </ul>
      </div>
      <div class="products-container">
        <div class="head">
          <p class="product-line-name text-overflow-100-60"
          >{{(currentProductLine | async)?.name}}</p>

          <button class="add-product-btn" (click)="addProduct()" *ngIf="canDeleteProducts">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.2223 7H1.35696M7.78966 1V13V1Z" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            {{prefix + 'add-product-btn' | translate}}
          </button>
        </div>

        <ng-container *ngIf="(currentProductLine | async) as pl">
          <ul class="products-list" *ngIf="!!(currentProductLine | async)">
            <ng-container *ngFor="let p of (currentProductLine | async)?.products; let i=index;">
              <li *ngIf="i >= currentIdx - 4 && i < currentIdx" class="products-item">
                <div class="product">
                  <img src="../../../../assets/img/product-img.webp" alt="product" class="product-image">
                  <p class="product-name text-overflow-100-60">{{p.name}}</p>

                  <div class="controls">
                    <div class="target f-center">
                      <img class="target-chart" src="../../../../assets/icons/product-target-chart.svg" alt="chart">
                      <p
                        class="target-value">{{prefix + 'target-chart' | translate : {target: p.controlValues[0].target} }}</p>
                    </div>

                    <div class="buttons">
                      <div class="edit-btn" (click)="editProduct(p)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.29529 2.48822L11.6526 4.84555L9.29529 2.48822ZM10.2953 1.48822C10.6079 1.17562 11.0319 1 11.474 1C11.916 1 12.34 1.17562 12.6526 1.48822C12.9652 1.80082 13.1408 2.2248 13.1408 2.66689C13.1408 3.10897 12.9652 3.53295 12.6526 3.84555L3.47396 13.0242H1.14062V10.6429L10.2953 1.48822V1.48822Z"
                            stroke="url(#paint0_linear_11_14)" stroke-width="1.52381" stroke-linecap="round"
                            stroke-linejoin="round"/>
                          <defs>
                            <linearGradient id="paint0_linear_11_14" x1="11.9053" y1="4.24653" x2="2.47254" y2="4.23677"
                                            gradientUnits="userSpaceOnUse">
                              <stop stop-color="#06A3AE"/>
                              <stop offset="1" stop-color="#008C96"/>
                            </linearGradient>
                          </defs>
                        </svg>
                        {{prefix + 'edit-btn' | translate}}
                      </div>

                      <div class="delete-btn ml-2" (click)="delete(p)" *ngIf="canDeleteProducts">
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.5625 1.875H8.98438V1.40625C8.98438 0.63085 8.35353 0 7.57812 0H5.70312C4.92772 0 4.29688 0.63085 4.29688 1.40625V1.875H1.71875C1.07258 1.875 0.546875 2.4007 0.546875 3.04688V4.6875C0.546875 4.94637 0.756758 5.15625 1.01562 5.15625H1.2718L1.67677 13.6606C1.71254 14.4117 2.32953 15 3.08141 15H10.1998C10.9517 15 11.5687 14.4117 11.6045 13.6606L12.0095 5.15625H12.2656C12.5245 5.15625 12.7344 4.94637 12.7344 4.6875V3.04688C12.7344 2.4007 12.2087 1.875 11.5625 1.875ZM5.23438 1.40625C5.23438 1.14779 5.44467 0.9375 5.70312 0.9375H7.57812C7.83658 0.9375 8.04688 1.14779 8.04688 1.40625V1.875H5.23438V1.40625ZM1.48438 3.04688C1.48438 2.91765 1.58952 2.8125 1.71875 2.8125H11.5625C11.6917 2.8125 11.7969 2.91765 11.7969 3.04688V4.21875C11.6524 4.21875 2.083 4.21875 1.48438 4.21875V3.04688ZM10.668 13.616C10.6561 13.8664 10.4504 14.0625 10.1998 14.0625H3.08141C2.83077 14.0625 2.62511 13.8664 2.61321 13.616L2.21035 5.15625H11.0709L10.668 13.616Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M6.64062 13.125C6.89949 13.125 7.10938 12.9151 7.10938 12.6562V6.5625C7.10938 6.30363 6.89949 6.09375 6.64062 6.09375C6.38176 6.09375 6.17188 6.30363 6.17188 6.5625V12.6562C6.17188 12.9151 6.38173 13.125 6.64062 13.125Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M8.98438 13.125C9.24324 13.125 9.45312 12.9151 9.45312 12.6562V6.5625C9.45312 6.30363 9.24324 6.09375 8.98438 6.09375C8.72551 6.09375 8.51562 6.30363 8.51562 6.5625V12.6562C8.51562 12.9151 8.72548 13.125 8.98438 13.125Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M4.29688 13.125C4.55574 13.125 4.76562 12.9151 4.76562 12.6562V6.5625C4.76562 6.30363 4.55574 6.09375 4.29688 6.09375C4.03801 6.09375 3.82812 6.30363 3.82812 6.5625V12.6562C3.82812 12.9151 4.03798 13.125 4.29688 13.125Z"
                            fill="#BDBDBD"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>

        <div class="paging-container f-center" *ngIf="showPagination">
          <app-paging [marginBottom]="0" (pageChanged)="pageSelected($event)" [current]="currentPage"
                      [last]="lastPage"></app-paging>
        </div>
      </div>
    </div>

    <div class="content-mobile">
      <button class="btn m-add-product-line-btn" *ngIf="canCreateProductLines" (click)="addProductLine()">
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.0817 7H1.21631M7.649 1V13V1Z" stroke="url(#paint0_linear_4962_595)" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <defs>
            <linearGradient id="paint0_linear_4962_595" x1="12.7571" y1="4.23999" x2="2.64425" y2="4.22875"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#06A3AE"/>
              <stop offset="1" stop-color="#008C96"/>
            </linearGradient>
          </defs>
        </svg>
        {{prefix + 'add-product-line' | translate}}
      </button>

      <ul class="m-product-lines-list">
        <li class="m-product-lines-list-item" [ngClass]="{'active': (currentProductLine | async)?.name == l.name}"
            *ngFor="let l of productLines" (click)="changeCurrentLine(l)">
          <p class="m-product-line-name">{{l.name}}</p>
        </li>
      </ul>

      <div class="m-products-container">
        <div class="m-head">
          <p class="m-product-line-name text-overflow-100-60">{{(currentProductLine | async)?.name}}</p>

          <button class="add-product-btn" [ngStyle]="{width: '150px'}" (click)="addProduct()">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.2223 7H1.35696M7.78966 1V13V1Z" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            {{prefix + 'add-product-btn' | translate}}
          </button>
        </div>

        <div class="m-paging-container f-center" *ngIf="showPagination">
          <app-paging [marginBottom]="0" (pageChanged)="pageSelected($event)" [current]="currentPage"
                      [last]="lastPage"></app-paging>
        </div>

        <ng-container *ngIf="(currentProductLine | async) as pl">
          <ul class="m-products-list" *ngIf="!!(currentProductLine | async)">
            <ng-container *ngFor="let p of (currentProductLine | async)?.products; let i=index;">
              <li *ngIf="i >= currentIdx - 4 && i < currentIdx" class="m-products-item">
                <div class="m-product">
                  <div class="m-product-head">
                    <img src="../../../../assets/img/product-img.webp" alt="product" class="m-product-image">

                    <div class="m-controls">
                      <div class="m-target-container f-center">
                        <img class="m-target-chart" src="../../../../assets/icons/product-target-chart.svg" alt="chart">
                        <p class="m-target-value">{{prefix + 'target-chart' | translate : {target: p.controlValues[0].target} }}</p>
                      </div>

                      <div class="delete-btn" (click)="delete(p)">
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.5625 1.875H8.98438V1.40625C8.98438 0.63085 8.35353 0 7.57812 0H5.70312C4.92772 0 4.29688 0.63085 4.29688 1.40625V1.875H1.71875C1.07258 1.875 0.546875 2.4007 0.546875 3.04688V4.6875C0.546875 4.94637 0.756758 5.15625 1.01562 5.15625H1.2718L1.67677 13.6606C1.71254 14.4117 2.32953 15 3.08141 15H10.1998C10.9517 15 11.5687 14.4117 11.6045 13.6606L12.0095 5.15625H12.2656C12.5245 5.15625 12.7344 4.94637 12.7344 4.6875V3.04688C12.7344 2.4007 12.2087 1.875 11.5625 1.875ZM5.23438 1.40625C5.23438 1.14779 5.44467 0.9375 5.70312 0.9375H7.57812C7.83658 0.9375 8.04688 1.14779 8.04688 1.40625V1.875H5.23438V1.40625ZM1.48438 3.04688C1.48438 2.91765 1.58952 2.8125 1.71875 2.8125H11.5625C11.6917 2.8125 11.7969 2.91765 11.7969 3.04688V4.21875C11.6524 4.21875 2.083 4.21875 1.48438 4.21875V3.04688ZM10.668 13.616C10.6561 13.8664 10.4504 14.0625 10.1998 14.0625H3.08141C2.83077 14.0625 2.62511 13.8664 2.61321 13.616L2.21035 5.15625H11.0709L10.668 13.616Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M6.64062 13.125C6.89949 13.125 7.10938 12.9151 7.10938 12.6562V6.5625C7.10938 6.30363 6.89949 6.09375 6.64062 6.09375C6.38176 6.09375 6.17188 6.30363 6.17188 6.5625V12.6562C6.17188 12.9151 6.38173 13.125 6.64062 13.125Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M8.98438 13.125C9.24324 13.125 9.45312 12.9151 9.45312 12.6562V6.5625C9.45312 6.30363 9.24324 6.09375 8.98438 6.09375C8.72551 6.09375 8.51562 6.30363 8.51562 6.5625V12.6562C8.51562 12.9151 8.72548 13.125 8.98438 13.125Z"
                            fill="#BDBDBD"/>
                          <path
                            d="M4.29688 13.125C4.55574 13.125 4.76562 12.9151 4.76562 12.6562V6.5625C4.76562 6.30363 4.55574 6.09375 4.29688 6.09375C4.03801 6.09375 3.82812 6.30363 3.82812 6.5625V12.6562C3.82812 12.9151 4.03798 13.125 4.29688 13.125Z"
                            fill="#BDBDBD"/>
                        </svg>
                      </div>
                    </div>
                  </div>

                  <p class="m-product-name text-overflow-100-60" [ngStyle]="{width: '200px'}">{{p.name}}</p>

                  <div class="edit-btn" (click)="editProduct(p)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.29529 2.48822L11.6526 4.84555L9.29529 2.48822ZM10.2953 1.48822C10.6079 1.17562 11.0319 1 11.474 1C11.916 1 12.34 1.17562 12.6526 1.48822C12.9652 1.80082 13.1408 2.2248 13.1408 2.66689C13.1408 3.10897 12.9652 3.53295 12.6526 3.84555L3.47396 13.0242H1.14062V10.6429L10.2953 1.48822V1.48822Z"
                        stroke="url(#paint0_linear_11_14)" stroke-width="1.52381" stroke-linecap="round"
                        stroke-linejoin="round"/>
                      <defs>
                        <linearGradient id="paint0_linear_11_14" x1="11.9053" y1="4.24653" x2="2.47254" y2="4.23677"
                                        gradientUnits="userSpaceOnUse">
                          <stop stop-color="#06A3AE"/>
                          <stop offset="1" stop-color="#008C96"/>
                        </linearGradient>
                      </defs>
                    </svg>
                    {{prefix + 'edit-btn' | translate}}
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>

    </div>
  </div>
</div>
