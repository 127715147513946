import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DataService} from "../../services/data.service";
import {Organization} from "../../../model/organization";
import {User} from "../../../model/user";
import {state} from "@angular/animations";
import {DisableSamplesModalComponent} from "../disable-samples-modal/disable-samples-modal.component";
import {AllDataShareAproveModalComponent} from "../all-data-share-aprove-modal/all-data-share-aprove-modal.component";
import {initialState} from "ngx-bootstrap/timepicker/reducer/timepicker.reducer";
import {ModalService} from "../modal.service";

@Component({
  selector: 'app-all-data-share-modal',
  templateUrl: './all-data-share-modal.component.html',
  styleUrls: ['./all-data-share-modal.component.scss']
})
export class AllDataShareModalComponent implements OnInit {
  prefix = 'all-data.share-modal.';
  userUuid
  loading = true
  organizations: Organization[] = []
  samples
  selected = []

  constructor(
    public modal: BsModalRef,
    private ds: DataService,
    private modals: ModalService,
  ) {
  }

  async ngOnInit() {
    console.log('asdasdas', {
      samples: this.samples,
      userId: this.userUuid,
    })
    try {
      this.organizations = await this.ds.getReceivingUsers(this.userUuid)
      this.loading = false
    } catch (e) {
      this.loading = false
      console.log(e)
    }
  }

  async next() {
    if (!this.selected.length) return
    const res = await this.modals.show(AllDataShareAproveModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        users: this.selected
      }
    })

    if (!res) return
    try {
      await this.ds.shareSamples(this.samples, this.selected)
      this.loading = false
      this.modal.hide()
    } catch (e) {
      this.loading = false
      console.log(e)
    }
  }

  validateSelectAll(org: Organization) {
    const selectedUsers = org.users.map(u => u.selected).filter(s => !s)
    if (selectedUsers.length === 0) {
      return true
    } else {
      return false
    }
  }

  selectUser(e, u: User) {
    u.selected = e
    u.selected ? this.selected.push(u) : this.selected = this.selected.filter(user => user.id !== u.id)
  }

  selectAll(org: Organization, checked: boolean) {
    org.users.forEach(u => {
      this.selected = this.selected.filter(user => user.id !== u.id)
      u.selected = checked
      u.selected ? this.selected.push(u) : this.selected = this.selected.filter(user => user.id !== u.id)
    })
  }
}
