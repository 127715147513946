import {Injectable, Inject} from '@angular/core'
import {CarouselCell} from '../pages-authenticated/training-wizard2/carousel/carousel.component'
import {BehaviorSubject, from} from 'rxjs'
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from '@angular/router'
import {CacheService} from './cache.service'
import {ApiService} from './api.service'
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service'
import {filter} from 'rxjs/operators'
import {UserService} from './user.service'

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  deviceSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  sampleSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  user
  STORAGE_KEY = 'training_progress'
  pages = ['product', 'device-control', 'measurements', 'export', 'import', 'choose-samples']

  selectedDevice: BehaviorSubject<CarouselCell> = new BehaviorSubject<CarouselCell>(null)
  selectedSample: BehaviorSubject<CarouselCell> = new BehaviorSubject<CarouselCell>(null)

  reloadCarousel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  carouselOptions: BehaviorSubject<CarouselCell[]> = new BehaviorSubject<CarouselCell[]>(null)

  showCarousel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  productCells: CarouselCell[] = [
    {
      tagText: null,
      cellText: 'Fortified Oil',
      available: true,
      cellImg: 'assets/img/product_categories/oil.webp',
      id: '1',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 1
    },
    {
      tagText: null,
      cellText: 'Fortified Sugar',
      available: true,
      cellImg: 'assets/img/product_categories/sugar.webp',
      id: '2',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 3
    },
    {
      tagText: null,
      cellText: 'Flour',
      available: true,
      cellImg: 'assets/img/product_categories/flour.webp',
      id: '3',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 2
    },
    {
      tagText: null,
      cellText: 'Food',
      available: true,
      cellImg: 'assets/img/product_categories/food.webp',
      id: '4',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 5
    },
    {
      tagText: null,
      cellText: 'Biological fluids',
      available: true,
      cellImg: 'assets/img/product_categories/biological_fluids.webp',
      id: '5',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 4
    },
    {
      tagText: null,
      cellText: 'Salt',
      available: true,
      cellImg: 'assets/img/product_categories/salt.webp',
      id: '6',
      type: 'Sample',
      deviceId: null,
      productCategoryId: 6
    }
    // {
    //   tagText: null,
    //   cellText: 'Animal Nutrition',
    //   available: false,
    //   cellImg: 'assets/img/white-bg.jpg',
    //   id: '3',
    //   type: 'Sample',
    //   deviceId: null,
    // },
  ]

  trainingProgress: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  trainingProgressIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  constructor(private us: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private cache: CacheService, private api: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.carouselOptions.next(this.productCells)
    from(this.checkTrainingProgress()).subscribe()
    // const tIdx = +this.storage.get('training-index')
    this.us.userChanged.subscribe(u => {
      from(this.checkTrainingProgress()).subscribe(() => {
        this.user = u
      })
    })
    this.router.events.pipe(filter(e => (e instanceof NavigationEnd)))
      .pipe(filter((e: any) => e.url.indexOf('training') > -1))
      .subscribe((e: any) => {
        let childURL = e.urlAfterRedirects.split('/training/')[1]
        childURL = childURL.split('?')[0]
        console.log('childURL', childURL)
        if (childURL !== 'welcome' && childURL !== 'results') {
          const currentProgress = this.trainingProgress.getValue()
          if (currentProgress !== null) {
            const currIndex = this.pages.indexOf(currentProgress)
            const newIndex = this.pages.indexOf(childURL)
            if (newIndex >= currIndex) {
              this.trainingProgress.next(childURL)
            }
          } else {
            this.trainingProgress.next(childURL)
          }
          this.updateTrainingProgress()
        } else {
          this.getTrainingProgress()
        }
      })
    const storage_progress = this.getTrainingProgress()
    if (storage_progress !== undefined) {
      this.selectedDevice.next(storage_progress.selectedDevice)
      this.selectedSample.next(storage_progress.selectedSample)
      this.trainingProgress.next(storage_progress.step)
    }
  }

  public async carouselCellSelected($event: CarouselCell) {
    console.log('$event', $event)
    if ($event === null) return
    // this.carouselOptions.next([])
    if ($event.type === 'Sample') {
      // this.selectedSample.next($event)
      // this.carouselOptions.next(this.differentDeviceCells)
      // this.sampleSelected.next(true)
      // this.updateIndex(1)
      // this.selectedSample.next($event)
      // this.carouselOptions.next(false)
      // this.showCarousel.next(false)
      // this.sampleSelected.next(true)
    } else if ($event.type === 'Device Differences') {
      ////this.carouselOptions.next(this.deviceCells);
      // this.selectedDevice.next($event)
      // this.showCarousel.next(false)
      // this.deviceSelected.next(true)
      // this.updateIndex(2)
    } /*else if ($event.type === 'iCheck Device') {
      this.selectedDevice.next($event);
      this.showCarousel.next(false);
    }*/
    this.reloadCarousel.next(true)
  }

  public carouselBackClicked($event: any) {
    if ($event == null) return

    const eType = $event.type

    if (eType === 'Sample') {
      this.router.navigateByUrl('/training/welcome')
      this.selectedSample.next(null)
    } else if (eType === 'Device Differences') { // Go back to Sample carousel.
      this.carouselOptions.next(this.productCells)
    }
    // else if (eType === 'iCheck Device') {
    //   this.carouselOptions.next(this.differentDeviceCells)
    //   this.selectedDevice.next(null)
    // }
    this.reloadCarousel.next(eType !== 'Sample')
  }

  public getTrainingProgress() {
    const tp = this.storage.get(this.STORAGE_KEY)

    if (tp !== undefined  && this.route.snapshot.queryParams.produtId !== tp.productId &&
      this.router.url.indexOf('dashboard') === -1) {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            productId: tp.productId,
            concentration: tp.concentration,
            importId: tp.importId,
            measurementTypeId: tp.measurementTypeId,
            deviceTypeId: tp.deviceTypeId,
            unit: tp.unit,
            productCategoryId: tp.productCategoryId,
            deviceId: tp.deviceId
          },
          queryParamsHandling: 'merge'
        }
      ).catch((e) => console.error(e))
    }

    return tp
  }

  public updateTrainingProgress() {
    let storage_progress = this.storage.get(this.STORAGE_KEY)
    if (storage_progress === undefined) {
      storage_progress = {
        step: null,
        selectedDevice: null,
        selectedSample: null,
        productId: null,
        concentration: null,
        importId: null,
        measurementTypeId: null,
        deviceTypeId: null,
        unit: null,
        productCategoryId: null,
        deviceId: null
      }
    }

    this.storage.set(this.STORAGE_KEY, {
      step: this.trainingProgress.getValue() || storage_progress.step || null,
      selectedDevice: this.selectedDevice.getValue() || storage_progress.selectedDevice || null,
      selectedSample: this.selectedSample.getValue() || storage_progress.selectedSample || null,
      productId: this.route.snapshot.queryParams.productId || storage_progress.productId || null,
      concentration: this.route.snapshot.queryParams.concentration || storage_progress.concentration || null,
      importId: this.route.snapshot.queryParams.importId || storage_progress.importId || null,
      measurementTypeId: this.route.snapshot.queryParams.measurementTypeId || storage_progress.measurementTypeId || null,
      deviceTypeId: this.route.snapshot.queryParams.deviceTypeId || storage_progress.deviceTypeId || null,
      unit: this.route.snapshot.queryParams.unit || storage_progress.unit || null,
      productCategoryId: this.route.snapshot.queryParams.productCategoryId || storage_progress.productCategoryId || null,
      deviceId: this.route.snapshot.queryParams.deviceId || storage_progress.deviceId || null,
    })
  }

  async updateIndex(idx) {
    let tP = this.storage.get('training-index')

    if (idx === 0) {
      if (tP) await this.deleteTP(tP)
      this.storage.remove('training-index')
      this.trainingProgressIndex.next(idx)
      return
    }
    if (idx === 1) {
      tP = await this.createTP(idx, this.trainingProgress.getValue() || null)
    } else {
      tP.stepIndex = idx
      tP.step = this.trainingProgress.getValue()
      tP = await this.updateTP(tP)
    }
    this.storage.set('training-index', tP)
    this.trainingProgressIndex.next(tP.stepIndex)
  }


  public deleteTrainingProgress() {
    this.storage.remove(this.STORAGE_KEY)
    this.trainingProgress.next(null)
  }

  resetCarousels() {
    this.showCarousel.next(true)
    this.carouselOptions.next(this.productCells)
  }


  async checkTrainingProgress() {
    const tP = await this.trainingProgressExist()
    let tIdx = null
    if (tP.trainingProgress) {
      this.storage.set('training-index', tP.trainingProgress)
      tIdx = tP.trainingProgress.stepIndex
    } else {
      tIdx = 0
    }
    this.trainingProgressIndex.next(tIdx)
  }

  async trainingProgressExist() {
    return this.api.get('u/check-training-progress')
  }

  async getUnsuccessfulTrainings() {
    return this.api.get('u/training-progress')
  }

  async createTP(stepIndex, step) {
    const data = {
      stepIndex,
      step
    }
    return await this.api.post('u/training-progress', data)
  }

  async updateTP(trainingProgress) {
    return await this.api.put('u/training-progress', trainingProgress)
  }

  async deleteTP(trainingProgress) {
    return await this.api.delete(`u/training-progress?id=${trainingProgress.id}`)
  }

  async getTrainingStatus() {
    return await this.api.get('u/user-training-attempts')
  }
}
