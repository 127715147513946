<app-menu>
  <a class="nav-link" [routerLink]="['/dashboard']">
    <i class="fa fa-arrow-left mr-2"></i>
    {{ 'admin.menu.back' | translate }}
  </a>

  <div *ngIf="user.hasRole('superAdmin')">
    <hr>
    <a class="nav-link disabled" href="javascript:void(0)" tabindex="-1"
      aria-disabled="true">{{ 'admin.menu.admin-tools' | translate }}</a>

    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/approval']">
      <i class="fa fa-fw fa-clock-o"></i>
      <p class="mb-0 ml-2">{{ 'admin.menu.approval' | translate }}</p>
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/organizations']">
      <i class="fa fa-fw fa-building-o"></i>
      <p class="mb-0 ml-2">{{ 'admin.menu.organizations' | translate }}</p>
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/hierarchy/main']">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1673 13.751H17.0163V10.1388C17.0163 9.67755 16.6449 9.30612 16.1837 9.30612H10.8327V6.24898H12.9837C13.4449 6.24898 13.8163 5.87755 13.8163 5.41633V0.832653C13.8163 0.371429 13.4449 0 12.9837 0H7.01633C6.5551 0 6.18367 0.371429 6.18367 0.832653V5.41633C6.18367 5.87755 6.5551 6.24898 7.01633 6.24898H9.16735V9.30612H3.81633C3.3551 9.30612 2.98367 9.67755 2.98367 10.1388V13.751H0.832653C0.371429 13.751 0 14.1224 0 14.5837V19.1673C0 19.6286 0.371429 20 0.832653 20H6.8C7.26122 20 7.63265 19.6286 7.63265 19.1673V14.5837C7.63265 14.1224 7.26122 13.751 6.8 13.751H4.64898V10.9755H15.351V13.751H13.2C12.7388 13.751 12.3673 14.1224 12.3673 14.5837V19.1673C12.3673 19.6286 12.7388 20 13.2 20H19.1673C19.6286 20 20 19.6286 20 19.1673V14.5837C20 14.1224 19.6286 13.751 19.1673 13.751ZM5.96735 18.3347H1.66531V15.4163H5.96327V18.3347H5.96735ZM7.85306 4.58367V1.66531H12.151V4.58367H7.85306ZM18.3388 18.3347H14.0367V15.4163H18.3347V18.3347H18.3388Z" fill="#F3F3F3"/>
      </svg>
      <p class="mb-0 ml-2">{{ 'admin.menu.hierarchies' | translate }}</p>
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/users']">
      <i class="fa fa-fw fa-user-o"></i>
      <p class="mb-0 ml-2">{{ 'admin.menu.users' | translate }}</p>
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/devices']">
      <img src="assets/icons/icon-devices.svg" class="p-0">
      <p class="mb-0 ml-2">{{ 'admin.menu.devices' | translate }}</p>
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/emails/user-approve']">
      <img src="assets/icons/icon-devices.svg" class="p-0 ">
      <p class="ml-2">{{ 'admin.menu.emails' | translate }}</p>
    </a>
<!--    <a class="nav-link" routerLinkActive="active" [routerLink]="['/admin/data-sharing-groups']">-->
<!--      <img src="assets/icons/data-sharing-groups.svg" class="p-0 ">-->
<!--      <p class="ml-2">{{ 'admin.menu.data-sharing-groups' | translate }}</p>-->
<!--    </a>-->
  </div>

  <div *ngIf="user.hasRole('support')">
    <hr>
    <a class="nav-link disabled" href="javascript:void(0)" tabindex="-1"
      aria-disabled="true">{{ 'admin.menu.support-tools' | translate }}</a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/support/tickets']">
      <i class="fa fa-fw fa-ticket"></i> {{ 'admin.menu.support-tickets' | translate }}
    </a>
  </div>

  <div *ngIf="user.hasRole('tester')">
    <hr>
    <a class="nav-link disabled" href="javascript:void(0)" tabindex="-1"
      aria-disabled="true">{{ 'admin.menu.test-tools' | translate }}</a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/tester/emails']">
      <i class="fa fa-fw fa-envelope-o"></i> {{ 'tester.menu.emails' | translate }}
    </a>
    <a class="nav-link" routerLinkActive="active" [routerLink]="['/tester/doc-store-tools']">
      <i class="fa fa-fw fa-file-pdf-o"></i> {{ 'tester.menu.doc-store-tools' | translate }}
    </a>
  </div>
</app-menu>
