<div class="password-reset-container">
  <nav class="navbar navbar-expand-md navbar-dark bg-primary">
    <a class="navbar-brand" href="#">{{ 'title' | translate }}</a>
  </nav>

  <!-- Email form -->
  <div class="holder" *ngIf="state === 'idle'">
    <div class="card card-register align-middle shadow">
      <h4 class="card-header">
        {{ 'request-password-reset.title' | translate }}
      </h4>
      <div class="card-body">
        <p>{{ 'request-password-reset.content' | translate }}</p>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <fieldset [disabled]="loading">

            <!-- Email address -->
            <div class="form-group">
              <label class="sr-only" for="email">{{ 'request-password-reset.email' | translate }}</label>
              <input type="text" class="form-control" id="email" [formControl]="emailControl"
                     placeholder="{{ 'request-password-reset.email-placeholder' | translate }}"
                     [class.is-invalid]="emailControl.invalid && emailControl.touched && triedSubmit">
              <ng-container *ngIf="emailControl.invalid && emailControl.touched && triedSubmit">
                <small class="form-text text-danger"
                       *ngIf="emailControl.errors.invalidEmail && triedSubmit">{{ 'request-password-reset.invalidEmail' | translate }}</small>
                <small class="form-text text-danger"
                       *ngIf="emailControl.errors.emailNotExist && triedSubmit">{{ 'request-password-reset.email-not-exist' | translate }}</small>
                <small class="form-text text-danger"
                       *ngIf="emailControl.errors.required && triedSubmit">{{ 'request-password-reset.email-required' | translate }}</small>
              </ng-container>
            </div>

            <button type="submit"
                    class="btn btn-block btn-primary">{{ 'request-password-reset.submit' | translate }}</button>
            <button type="button" [routerLink]="['/auth/login']"
                    class="btn btn-block btn-light">{{ 'request-password-reset.back-button' | translate }}</button>
          </fieldset>

          <div class="spinner" *ngIf="loading">
            <app-spinner></app-spinner>
          </div>
        </form>

      </div>
    </div>
  </div>

  <!-- Done -->
  <div class="holder" *ngIf="state === 'done'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'request-password-reset.done' | translate }}</h4>
      <div class="card-body">
        <p>{{ 'request-password-reset.done-content' | translate }}</p>
        <p>{{ 'request-password-reset.done-content-2' | translate }}</p>
      </div>
    </div>
  </div>
</div>
