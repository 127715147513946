import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Device} from '../../../model/device'
import {ModalService} from '../modal.service'
import {DataService} from '../../services/data.service'
import {DeviceDetailedModalComponent} from '../device-detailed-modal/device-detailed-modal.component'

@Component({
  selector: 'app-devices-mobile-table',
  templateUrl: './devices-mobile-table.component.html',
  styleUrls: ['./devices-mobile-table.component.scss']
})
export class DevicesMobileTableComponent implements OnInit {

  @Input() devices: Device[]
  @Input() canCreate: boolean
  @Input() canDelete: boolean
  @Output() onDeleted: EventEmitter<any> = new EventEmitter<any>()
  @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPending: EventEmitter<boolean> = new EventEmitter<boolean>()


  prefix = 'devices.mobile-table.'
  constructor(
    private modals: ModalService,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    console.log('devices', this.devices)
  }

  async delete(d: Device) {
    const modalResult = await this.modals.showConfirm()
    if (!modalResult) return
    try {
      this.onPending.emit(true)
      await this.ds.removeDevice(d)
      d.active = false
      this.onDeleted.emit(d.id)
      this.onPending.emit(false)
    } catch (err) {
      console.error("Nope", err)
      this.onPending.emit(false)
    }
  }

  async toDeviceDetails(device: any) {
    if (!this.canCreate) return
    const result = await this.modals.show(DeviceDetailedModalComponent, {
      ignoreBackdropClick: true,
      initialState: {device, canDelete: this.canDelete}
    })
    if (!result) return
    this.onUpdated.emit(result)
  }
}
