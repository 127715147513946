<div class="table-container">
  <table class="table-container__table">
    <thead class="table-container__table__thead">
      <tr class="table-container__table__thead__tr">
        <th class="table-container__table__thead__tr__th__select-all">
          <div class="table-container__table__thead__tr__th__select-all__select-all-checkbox">
            <input
              id="select-all"
              type="checkbox"
              [disabled]="loadingRows.length"
              [checked]="selected.length === data.length"
              (change)="toggleAll($event)">
            <label
              for="select-all"
            ></label>
          </div>
        </th>
        <th class="table-container__table__thead__tr__th" *ngFor="let col of columns">
          {{prefix + col | translate}}
          <div
            class="table-container__table__thead__tr__th__disable-checkbox"
          >
            <input [disabled]="disabledRows.includes(col)" type="checkbox" [id]="col" (change)="selectRow(col)">
            <label [for]="col"></label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table-container__table__tbody">
      <tr [ngClass]="{selected: d.selected, disabled: d.disabled}" class="table-container__table__tbody__tr" *ngFor="let d of data">
        <td class="table-container__table__tbody__tr__td">
          <div class="checkbox-container">
            <input
              [id]="d.id"
              [disabled]="loadingRows.includes(d.id)"
              type="checkbox"
              [checked]="d.selected"
              (change)="toggle($event, d)">
            <label
              [for]="d.id"
            ></label>
          </div>
        </td>
        <td class="table-container__table__tbody__tr__td" *ngFor="let col of columns">
          <p [ngStyle]="{
          'text-decoration': (d.selected && selectedColumns.includes(col) || d.disabled) ? 'line-through' : 'none'}">{{d[col]}}{{check(d, col)}}</p>
        </td>
        <div *ngIf="loadingRows.includes(d.id)" class="table-container__table__tbody__tr__column-loader"></div>
      </tr>
    </tbody>
  </table>
</div>
