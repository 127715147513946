import {animate, style, transition, trigger} from '@angular/animations'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {Subscription} from 'rxjs'

import {ProductLine, Product} from 'src/model/Products'
import {CacheService} from 'src/app/services/cache.service'

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  animations: [
    trigger('onSave', [
      transition(':leave', [
        style({opacity: 1}),
        animate('.4s ease-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class ProductModalComponent implements OnInit, OnDestroy {
  prefix = 'my-products.product-lines-modal.'
  modalResult: Product = null
  productLines: ProductLine[] = []
  private _sub: Subscription
  canDeleteProductLines
  canDeleteProducts

  state: 'busy' | 'saved' | 'idle' | 'error' | 'saving' = 'idle'

  set busy(v: boolean) {
    this.state = v ? 'busy' : 'idle'
  }

  get busy() {
    return this.state !== 'idle'
  }


  constructor(
    private modal: BsModalRef,
    private cache: CacheService,
  ) {
  }

  ngOnInit() {
    this._sub = this.cache.productLines.subscribe(lines => {
      this.productLines = lines
    })
  }

  ngOnDestroy() {
    this.productLines = this.productLines.filter(line => line.name.trim())
    this.cache.productLines.next(this.productLines)
    if (this._sub) {
      this._sub.unsubscribe()
      this._sub = undefined
    }
  }

  dismiss() {
    this.modal.hide()
  }

  setState(newState: ProductModalState) {
    this.state = newState.state
    if (newState.state === 'saved') {
      setTimeout(() => {
        this.state = 'idle'
      }, 2500)
    }
    if (newState.product) this.modalResult = newState.product
  }

  addProductLine() {
    this.productLines.push({ name: '', products: [], organizationId: this.cache.organization.value.id, training: false });
  }

}

export interface ProductModalState {
  state: 'saved' | 'idle' | 'error' | 'saving';
  product?: Product;
}
