<div class="modal-body name">
  <div class="row">
    <div class="col-9">
      <app-form-control type="text" [prefix]="prefix + 'name'" [control]="form.get('name')"></app-form-control>
    </div>
  </div>
</div>
<div class="modal-body organizations-container">
  <p class="subtitle m-0">{{prefix + 'parent-subtitle' | translate}}</p>

  <div class="organizations">
    <div *ngFor="let o of organizations; let idx = index"
         class="row organization" [ngClass]="{selected: o.selected}">
      <div class="col px-4">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" [value]="o.id" [id]="o.id"
                 (change)="onCheckboxChanged($event, idx)">
          <label class="text-dark custom-control-label" [for]="o.id">{{o.name}}</label>
        </div>

        <div class="px-4 permissions">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" value="upload" [id]="o.id + 'upload'"
                   (change)="addPermission($event, idx)">
            <label class="text-dark custom-control-label" [for]="o.id + 'upload'">Allow upload sample result</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="next-btn" [disabled]="!form.valid" (click)="next()">{{prefix + 'next-button' | translate}}</button>
</div>
