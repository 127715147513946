import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Product } from 'src/model/Products';
import { DataService } from '../../services/data.service';
import { CacheService } from '../../services/cache.service';
import { ProductModalState } from '../product-modal/product-modal.component';

@Component({
  selector: 'app-product-row',
  templateUrl: './product-row.component.html',
  styleUrls: ['./product-row.component.scss']
})
export class ProductRowComponent implements OnInit {
  @Input() product: Product;
  @Input() canDeleteProducts: Product;
  @Input() canDeleteProductLines: Product;
  @Output() stateChange = new EventEmitter<ProductModalState>();

  showConfirmDelete = false;
  canDelete = true;

  constructor(
    private data: DataService,
    private cache: CacheService,
  ) { }

  ngOnInit() {
  }

  async delete() {
    if (!this.product.id) {
      this.forceCacheRefresh();
      return;
    }

    this.stateChange.emit({ state: "saving" });
    try {
      await this.data.deleteProduct(this.product);
      this.stateChange.emit({ state: "saved" });
    } catch (err) {
      if (err.error && err.error.reason && err.error.reason.match(/(has samples)/)) {
        this.canDelete = false;
        this.stateChange.emit({ state: "idle" });
      } else {
        this.stateChange.emit({ state: "error" });
      }
      this.showConfirmDelete = false;
    }
  }

  async saveProduct(e: { old: string, new: string }) {
    if (e.new === "") {
      if (this.product.id) {
        this.product.name = e.old;
      } else {
        this.forceCacheRefresh();
      }
      return;
    }

    this.stateChange.emit({ state: "saving", product: this.product });
    try {
      await this.data.saveProduct(this.product);
      this.stateChange.emit({ state: "saved", product: this.product });
    } catch (err) {
      this.product.name = e.old;
      this.stateChange.emit({ state: "error", product: this.product });
    }
  }

  private forceCacheRefresh() {
    this.cache.productLines.next(this.cache.productLines.value);
  }

}
