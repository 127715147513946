<table class="table table-hover text-center">
  <thead>
  <tr>
    <th scope="col" class="text-left">{{ 'stock.table.type' | translate }}</th>
    <th scope="col" class="text-left">{{ 'stock.table.lot' | translate }}</th>
    <th scope="col" class="text-left">{{ 'stock.table.origin' | translate }}</th>
    <th scope="col" class="text-left">{{ 'stock.table.received' | translate }}</th>
    <th scope="col" class="text-left">{{ 'stock.table.expires' | translate }}</th>
    <th scope="col" class="text-left">{{ 'stock.table.quantity' | translate }}</th>
    <th></th>
  </tr>
  </thead>

  <tbody *ngFor="let tk of testKits">
  <tr [class.table-light]="tk.active === false">
    <td class="text-capitalize text-dark text-left">
      {{ tk.type }}
    </td>
    <td class="text-dark text-left">
      {{ tk.lot }}
    </td>
    <td class="text-left">
      <img src="assets/icons/icon-shield.png" class="img-fluid mr-1" *ngIf="tk.origin == 'BioAnalyt'"/>
      <span [ngStyle]="{color: tk.origin == 'BioAnalyt' ? '#0B779A' : '#343a40'}">{{ tk.origin }}</span>
    </td>
    <td class="text-grey text-left">
      {{ tk.received | date : 'MM/dd/yyyy' }}
    </td>

    <td class="text-grey text-left">
      {{ tk.expires | date : 'MM/dd/yyyy' }}
      <span *ngIf="tk.state === 'almost-expired'">
              <i container="body" [tooltip]="'stock.tooltip.almost-expired' | translate"
                 class="text-warning fa fa-exclamation-circle"></i>
            </span>
    </td>

    <td class="text-left">
      <div class="progress-container d-flex">
        <div
          class="progress"
          [tooltip]="'stock.available' | translate: { available: tk.quantity - tk.used, total: tk.quantity}"
          [ngClass]="{
                    'in-stock': tk.state !== 'almost-empty' || tk.state !== 'empty',
                    'out-of-stock': tk.state == 'empty' || tk.state == 'empty'
                    }"
        >
          <p
            class="progress_bar"
          >
            {{ tk.quantity - tk.used }} / {{ tk.quantity }}
          </p>
        </div>

        <div class="ml-2 almost-icon" [class.invisible]="tk.state !== 'almost-empty'">
          <i container="body" [tooltip]="'stock.tooltip.almost-empty' | translate"
             class="text-warning fa fa-exclamation-circle"></i></div>
      </div>
    </td>

    <td class="btn-cell text-right">
      <button (click)="orderMore(tk)" *ngIf="canCreate"
              class="btn btn-md btn-outline-light text-black-50 order-btn">
        <svg width="106" height="103" viewBox="0 0 106 103" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.8298 36.6835C4.06383 36.6835 3.00003 36.6835 3 44.2212C3 51.7589 8.31912 50.7538 12.5744 50.7538H93.4255C102.468 50.7538 103 48.2412 103 42.7136C103 36.6835 97.1489 36.6835 93.4255 36.6835H16.8298Z"
            stroke="black" stroke-width="6"/>
          <path
            d="M43.7327 3.11327L17.5831 32.309C15.2002 34.9696 17.0885 39.196 20.6603 39.196C21.8804 39.196 23.0382 38.6566 23.823 37.7224L49.0356 7.71377C50.1055 6.44035 50.135 4.59093 49.1062 3.28405C47.7571 1.57011 45.188 1.48846 43.7327 3.11327Z"
            fill="black"/>
          <path
            d="M61.744 3.03576L88.2593 32.4737C90.4187 34.8712 88.7173 38.6935 85.4907 38.6935C84.3904 38.6935 83.3464 38.2072 82.6384 37.3649L57.1456 7.03733C56.0046 5.67995 56.2585 3.63892 57.6973 2.60248C58.9592 1.69344 60.7031 1.88017 61.744 3.03576Z"
            fill="black"/>
          <path
            d="M9.38298 50.2513C11.6879 62.4791 15.766 84.4221 16.8298 87.9397C17.8936 91.4573 17.3617 100 29.5957 100C38.1064 100 72.6809 100 76.4043 100C80.1277 100 88.1064 98.995 89.7021 91.4573C90.9787 85.4271 94.4894 61.474 96.0851 50.2513"
            stroke="black" stroke-width="6"/>
          <path
            d="M37.9523 84.3817L34.516 64.043C34.2491 62.4631 32.8808 61.3065 31.2784 61.3065C29.2718 61.3065 27.7345 63.0905 28.0309 65.0751L31.0777 85.4757C31.3323 87.1805 32.7965 88.4422 34.5202 88.4422C36.6742 88.4422 38.3111 86.5056 37.9523 84.3817Z"
            fill="black"/>
          <path
            d="M67.9803 84.4973L71.1644 64.1931C71.425 62.5312 72.8567 61.3065 74.5388 61.3065C76.6363 61.3065 78.2382 63.1793 77.9133 65.2514L74.7293 85.5557C74.4687 87.2175 73.0369 88.4422 71.3548 88.4422C69.2574 88.4422 67.6554 86.5694 67.9803 84.4973Z"
            fill="black"/>
          <path
            d="M56.2874 84.7482V64.498C56.2874 62.7354 54.8585 61.3065 53.0959 61.3065C51.3333 61.3065 49.9044 62.7354 49.9044 64.498V84.7482C49.9044 86.5108 51.3333 87.9397 53.0959 87.9397C54.8585 87.9397 56.2874 86.5108 56.2874 84.7482Z"
            fill="black"/>
        </svg>
      </button>

      <!--            <button *ngIf="canDelete" (click)="deleteTestKit(tk)"-->
      <!--                    class="btn btn-outline-light d-inline-block ml-2 bg-transparent text-black-50 border-0 btn-lg">-->
      <!--              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--                <path d="M14.7031 2.5H11.2656V1.875C11.2656 0.841133 10.4245 0 9.39062 0H6.89062C5.85676 0 5.01562 0.841133 5.01562 1.875V2.5H1.57812C0.716563 2.5 0.015625 3.20094 0.015625 4.0625V6.25C0.015625 6.59516 0.295469 6.875 0.640625 6.875H0.982188L1.52215 18.2142C1.56984 19.2156 2.3925 20 3.395 20H12.8863C13.8888 20 14.7114 19.2156 14.7591 18.2142L15.2991 6.875H15.6406C15.9858 6.875 16.2656 6.59516 16.2656 6.25V4.0625C16.2656 3.20094 15.5647 2.5 14.7031 2.5ZM6.26562 1.875C6.26562 1.53039 6.54602 1.25 6.89062 1.25H9.39062C9.73523 1.25 10.0156 1.53039 10.0156 1.875V2.5H6.26562V1.875ZM1.26562 4.0625C1.26562 3.8902 1.40582 3.75 1.57812 3.75H14.7031C14.8754 3.75 15.0156 3.8902 15.0156 4.0625V5.625C14.823 5.625 2.06379 5.625 1.26562 5.625V4.0625ZM13.5105 18.1547C13.4946 18.4885 13.2204 18.75 12.8863 18.75H3.395C3.06082 18.75 2.7866 18.4885 2.77074 18.1547L2.23359 6.875H14.0477L13.5105 18.1547Z" fill="#68686D"/>-->
      <!--                <path d="M8.14062 17.5C8.48578 17.5 8.76562 17.2202 8.76562 16.875V8.75C8.76562 8.40484 8.48578 8.125 8.14062 8.125C7.79547 8.125 7.51562 8.40484 7.51562 8.75V16.875C7.51562 17.2202 7.79543 17.5 8.14062 17.5Z" fill="#68686D"/>-->
      <!--                <path d="M11.2656 17.5C11.6108 17.5 11.8906 17.2202 11.8906 16.875V8.75C11.8906 8.40484 11.6108 8.125 11.2656 8.125C10.9205 8.125 10.6406 8.40484 10.6406 8.75V16.875C10.6406 17.2202 10.9204 17.5 11.2656 17.5Z" fill="#68686D"/>-->
      <!--                <path d="M5.01562 17.5C5.36078 17.5 5.64062 17.2202 5.64062 16.875V8.75C5.64062 8.40484 5.36078 8.125 5.01562 8.125C4.67047 8.125 4.39062 8.40484 4.39062 8.75V16.875C4.39062 17.2202 4.67043 17.5 5.01562 17.5Z" fill="#68686D"/>-->
      <!--              </svg>-->
      <!--            </button>-->
      <app-edit-delete-dropdown *ngIf="canCreate"
                                [canCreate]="canCreate"
                                [canDelete]="canDelete"
                                (action)="actionDropdown($event, tk)"></app-edit-delete-dropdown>
    </td>

    <!--          <td class="btn-cell">-->

    <!--          </td>-->

  </tr>
  </tbody>
</table>
