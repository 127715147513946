<table class="table">
  <thead class="table__header">
  <tr class="table__header__row">
    <th class="table__header__row__head">{{prefix + 'groups' | translate}}</th>
    <th class="table__header__row__head">{{prefix + 'organizations' | translate}}</th>
    <th class="table__header__row__head"></th>
  </tr>
  </thead>
  <tbody class="table__body">
  <tr class="table__body__row" *ngFor="let group of data">
    <td class="table__body__row__group-name">{{group.name}}</td>
    <td class="table__body__row__organizations">{{getOrganizationsName(group.organizations)}}</td>
    <td class="table__body__row__controls">
      <app-buttons
        class="table__body__row__controls__edit-btn"
        type="invisible-icon"
        width="auto"
        height="auto"
        [disabled]="loadingIds.includes(group.id)"
        (onClick)="edit(group)"
      >
        <img src="/assets/icons/edit.svg">
      </app-buttons>
      <app-buttons
        class="table__body__row__controls__delete-btn"
        type="invisible-icon"
        width="auto"
        height="auto"
        [disabled]="loadingIds.includes(group.id)"
        (onClick)="delete(group.id)"
      >
        <img src="/assets/icons/delete.svg">
      </app-buttons>
    </td>
  </tr>
  </tbody>
</table>
