import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {

  @ViewChild('chart', {static: true}) chart: ElementRef;
  @Input() options: any;
  @Input() translateParams: any;

  constructor(
    private translate: TranslateService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.apply();
  }

  ngOnInit() {
  }

  private apply() {
    if (this.options && this.chart) {
      this.translateStrings();
      Highcharts.chart(this.chart.nativeElement, this.options);
    }
  }

  private translateStrings() {
    if (this.options.title && this.options.title.text) {
      this.options.title.text = this.translate.instant(this.options.title.text, this.translateParams);
    }
    if (this.options.subtitle && this.options.subtitle.text) {
      this.options.subtitle.text = this.translate.instant(this.options.subtitle.text, this.translateParams);
    }
    if (this.options.xAxis && this.options.xAxis.title && this.options.xAxis.title.text) {
      this.options.xAxis.title.text = this.translate.instant(this.options.xAxis.title.text, this.translateParams);
    }

    if(typeof(this.options.xAxis) === typeof([]) && this.options.xAxis.length === 3) {
      this.options.xAxis.map((xAxis) => {
        xAxis.title.text = this.translate.instant(xAxis.title.text, this.translateParams);
      });
    }

    if (this.options.yAxis && this.options.yAxis.title && this.options.yAxis.title.text) {
      this.options.yAxis.title.text = this.translate.instant(this.options.yAxis.title.text, this.translateParams);
    }

    if (this.options.series) {
      for (let s of this.options.series) {
        if (s.name) {
          s.name = this.translate.instant(s.name, this.translateParams);
        }
      }
    }

    if (this.options.xAxis && this.options.xAxis.categories) {
      for (let i in this.options.xAxis.categories) {
        this.options.xAxis.categories[i] = this.translate.instant(this.options.xAxis.categories[i], this.translateParams);
      }
    }
  }

}
