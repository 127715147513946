import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, RoleGaurd } from './auth-guard';
import { LayoutTestComponent } from './shared/layout-test/layout-test.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { LandingComponent } from './landing/landing.component';
import {NewUserComponent} from './pages-authenticated/new-user/new-user.component'


const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  { path: 'auth', loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule)},
  //The user section
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./pages-authenticated/user/user.module').then(m => m.UserModule) },
  { path: 'admin', canActivate: [RoleGaurd], data: { role: 'superAdmin' }, loadChildren: () => import('./pages-authenticated/admin/admin.module').then(m => m.AdminModule) },
  { path: 'tester', canActivate: [RoleGaurd], data: { role: 'tester' }, loadChildren: () => import('./pages-authenticated/tester/tester.module').then(m => m.TesterModule) },
  { path: 'tickets', canActivate: [AuthGuard], loadChildren: () => import('./pages-authenticated/tickets/tickets.module').then(m => m.TicketsModule) },
  { path: 'welcome', canActivate: [AuthGuard], loadChildren: () => import('./pages-authenticated/new-user/new-user.module').then(m => m.NewUserModule)},
  { path: 'support', canActivate: [RoleGaurd], data: { role: 'support' }, loadChildren: () => import('./pages-authenticated/support/support.module').then(m => m.SupportModule) },

  { path: 'layout-test', component: LayoutTestComponent, data: { title: 'Layout test' } },
  { path: '404', component: NotFoundComponent, data: { title: 'Not Found' } },
  { path: '**', component: NotFoundComponent, data: { title: 'Not Found' } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
