<div class="btn-group mr-2" dropdown>
  <button dropdownToggle type="button" class="dropdown-btn" aria-controls="dropdown-basic">
    <span class="text-left text-overflow">{{ getLabel() | translate : {name: getItemName(), c: selectCount} }}</span>
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow">
      <path
        d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"
        fill="#68686D"/>
    </svg>
  </button>

  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu">

    <li *ngFor="let o of organizations" role="menuitem" class="users-container">
      <p
        class="text-primary font-weight-bold org-name text-overflow"
        [tooltip]="o.name"
        [ngStyle]="{width: 222 + 'px'}"
        tooltipPlacement="top">{{o.name}}</p>
      <div *ngIf="o.users.length; else noUsers" class="users">
        <div *ngFor="let u of o.users">
          <a *ngIf="!u.disabled; else disabledUser" href="javascript:void(0)" class="dropdown-item user"
             (click)="toggle(u, o.id, $event)">
            <div class="arrow-container">
              <svg *ngIf="u.selected" class="arrow" width="10" height="8" viewBox="0 0 10 8" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                  fill="black"/>
              </svg>
            </div>
            <p class="mb-0 ml-2 text-overflow">{{ u.firstName + ' ' + u.lastName }}</p>
          </a>

          <ng-template #disabledUser>
            <a href="javascript:void(0)" class="dropdown-item user">
              <div class="arrow-container">
                <svg *ngIf="u.selected" class="arrow" width="10" height="8" viewBox="0 0 10 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                    fill="black"/>
                </svg>
              </div>
              <p class="mb-0 ml-2 text-light text-overflow">{{ u.getName() }}</p>
            </a>
          </ng-template>
        </div>
      </div>

      <ng-template #noUsers>
        <div class="users">
          <div class="user">
            <div class="arrow-container">
            </div>
            <p class="mb-0">No users</p>
          </div>
        </div>
      </ng-template>
    </li>

    <div *ngIf="sharedOrganizations.length">
      <p class="shared-organizations-title">Shared organizations</p>
      <li *ngFor="let o of sharedOrganizations" role="menuitem" class="users-container">
        <p
          class="text-primary font-weight-bold org-name text-overflow"
          [tooltip]="o.name"
          [ngStyle]="{width: 222 + 'px'}"
          tooltipPlacement="top">{{o.name}}</p>
        <div *ngIf="o.users.length; else noUsers" class="users">
          <div *ngFor="let u of o.users">
            <a *ngIf="!u.disabled; else disabledUser" href="javascript:void(0)" class="dropdown-item user"
               (click)="selectOne(u)">
              <div class="arrow-container">
                <svg *ngIf="u.selected" class="arrow" width="10" height="8" viewBox="0 0 10 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                    fill="black"/>
                </svg>
              </div>
              <p class="mb-0 ml-2 text-overflow">{{ u.firstName + ' ' + u.lastName }}</p>
            </a>

            <ng-template #disabledUser>
              <a href="javascript:void(0)" class="dropdown-item user">
                <div class="arrow-container">
                  <svg *ngIf="u.selected" class="arrow" width="10" height="8" viewBox="0 0 10 8" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                      fill="black"/>
                  </svg>
                </div>
                <p class="mb-0 ml-2 text-light text-overflow">{{ u.getName() }}</p>
              </a>
            </ng-template>
          </div>
        </div>

        <ng-template #noUsers>
          <div class="users">
            <div class="user">
              <div class="arrow-container">
              </div>
              <p class="mb-0">No users</p>
            </div>
          </div>
        </ng-template>
      </li>
    </div>


    <a *ngIf="page < lastPage"
       href="javascript:void(0)"
       class="load-more"
       (click)="loadMore($event)">
      {{'multi-select.load-more' | translate}}
    </a>
  </ul>
</div>
