import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core'

import {Notification} from '../../../model/notification'
import {CacheService} from '../../services/cache.service'
import {NotificationService} from '../../services/notification.service'
import {ToastService} from '../../services/toast.service'
import {UserService} from '../../services/user.service'
import {Subscriptions} from '../../../model/subscriptions'
import {User} from '../../../model/user'

@Component({
  selector: 'app-notification-nav',
  templateUrl: './notification-nav.component.html',
  styleUrls: ['./notification-nav.component.scss']
})
export class NotificationNavComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdown: ElementRef
  noData: Notification = Notification.fromApi({type: "no-data"})
  user: User
  notifications: Notification[] = []

  private _subs = new Subscriptions()

  constructor(
    private cache: CacheService,
    private ns: NotificationService,
    private us: UserService,
    private toast: ToastService
  ) {
    ns.start()
  }

  ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe(u => this.user = u))
    this._subs.add(this.cache.notifications.subscribe((nots) => {
      this.notifications = nots.map(n => {
        const notification = Notification.fromApi(n)
        if (notification.new && !this.user.hasRole('superAdmin')) {
          let values: any = {}
          let text = null
          if (!notification.showOrganization && !notification.showUser) {
            text = notification.text
            if (notification.value) {
              text += '-value'
            }
            if (notification.value) {
              values.value = notification.value
            }
          } else if (!notification.showOrganization && notification.showUser) {
            text = notification.text + '-user'
            if (notification.value) {
              text += '-value'
            }
            values = {
              user: notification.user.firstName + ' ' + notification.user.lastName
            }
            if (notification.value) {
              values.value = notification.value
            }
          } else if (notification.showOrganization && notification.showUser) {
            text = notification.text + '-user-org'
            if (notification.value) {
              text += '-value'
            }
            values = {
              user: notification.user.firstName + ' ' + notification.user.lastName,
              org: notification.organization.name
            }
            if (notification.value) {
              values.value = notification.value
            }
          }

          this.toast.info('notification.' + text, null, values)
          notification.new = false
          this.ns.toastShown(notification.id)
        }
        return notification
      })
    }))
  }

  ngOnDestroy() {
    if (this._subs) {
      this._subs.unsubscribeAll()
      this._subs = undefined
    }
  }

  getNewNotificationsLength() {
    const notViewed = this.notifications.filter(n => !n.viewed)
    return notViewed.length
  }

  checkNotifications() {
    setTimeout(() => {
      this.markAllAsViewed()
    }, 100)
  }

  async markAllAsViewed() {
    if (this.dropdown.nativeElement.classList.contains('show') && this.getNewNotificationsLength() > 0) {
      const ids = this.notifications.map(n => n.id)
      const result = await this.ns.markAsViewed(ids)
      if (result.status === 'ok') {
        this.notifications.forEach(n => {
          n.viewed = true
        })
      }
    }
  }
}
