import { AfterViewInit, Component, ContentChild, HostListener, Input, ViewEncapsulation, OnChanges } from '@angular/core';

import { FoldoutContentDirective } from '../foldout-content.directive';
import { FoldoutToggleDirective } from '../foldout-toggle.directive';

@Component({
  selector: 'app-foldout',
  templateUrl: './foldout.component.html',
  styleUrls: ['./foldout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FoldoutComponent implements AfterViewInit, OnChanges {
  @ContentChild(FoldoutToggleDirective) toggle: FoldoutToggleDirective;
  @ContentChild(FoldoutContentDirective) content: FoldoutContentDirective;
  @Input() watchRoute?: string;

  constructor() { 
  }

  ngAfterViewInit() {
    if (!this.toggle || !this.content) {
      console.warn("Foldout doesn't have a toggle or content", this.toggle, this.content);
      return;
    }

    this.toggle.content = this.content;
    this.content.watchRoute = this.watchRoute;
  }

  ngOnChanges() {
    if (!this.content) return;
    this.content.watchRoute = this.watchRoute;
  }

  @HostListener('mouseover', ['$event'])
  mouseOver(event: MouseEvent) {
    if (!this.content) return;
    this.content.mouseOver();
  }
  @HostListener('mouseout', ['$event'])
  mouseOut(event: MouseEvent) {
    if (!this.content) return;
    this.content.mouseOut();
  }

}
