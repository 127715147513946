<div class="btn-group" dropdown>
  <button id="edit-delete-btn" dropdownToggle type="button" class="dropdown-btn">
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="18" height="18"
         preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
      <path d="M12 20a2 2 0 1 1 0-4a2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4a2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4a2 2 0 0 1 0 4z"
            fill="#06a3ae" fill-rule="evenodd"/>
    </svg>
  </button>
  <ul id="edit-delete-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
      role="menu" aria-labelledby="button-basic">

    <li role="menuitem" *ngIf="canCreate">
      <a class="dropdown-item edit" href="javascript:void(0)" (click)="clicked('edit')">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16"
             preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
          <g fill="none">
            <path
              d="M4.333 16.048L16.57 3.81a2.56 2.56 0 0 1 3.62 3.619L7.951 19.667a2 2 0 0 1-1.022.547L3 21l.786-3.93a2 2 0 0 1 .547-1.022z"
              stroke="#b4b4b4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5 6.5l3 3" stroke="#b4b4b4" stroke-width="2"/>
          </g>
        </svg>
        {{prefix + 'edit' | translate}}
      </a>
    </li>

    <li role="menuitem" *ngIf="canDelete">
      <a class="dropdown-item delete" href="javascript:void(0)" (click)="clicked('delete')">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16"
             preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
          <path d="M2 5v10c0 .55.45 1 1 1h9c.55 0 1-.45 1-1V5H2zm3 9H4V7h1v7zm2 0H6V7h1v7zm2 0H8V7h1v7zm2 0h-1V7h1v7z"
                fill="#b4b4b4"/>
          <path
            d="M13.25 2H10V.75A.753.753 0 0 0 9.25 0h-3.5A.753.753 0 0 0 5 .75V2H1.75a.752.752 0 0 0-.75.75V4h13V2.75a.752.752 0 0 0-.75-.75zM9 2H6v-.987h3V2z"
            fill="#b4b4b4"/>
        </svg>
        {{prefix + 'delete' | translate}}
      </a>
    </li>
  </ul>
</div>
