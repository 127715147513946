<div class="modal-header">
  <ng-container *ngIf="product">
    <!--    "title-edit": "Edit {{line}} Product {{product}} settings",-->
    <!--    line:-->
    <!--    productLine.name, product: product.name-->
    <h5
      *ngIf="!isFirst; else firstTitle"
      class="modal-title pull-left">
      {{ prefix + 'title-edit' | translate }}
      <span class="text-gradient">{{productLine.name}}</span>
      {{ prefix + 'title-product' | translate }}
      <span class="text-gradient">{{product.name}}</span>
      {{ prefix + 'title-settings' | translate }}
    </h5>
  </ng-container>
  <ng-container *ngIf="!product">
    <h5 *ngIf="!isFirst; else firstTitle"
        class="fz-18 modal-title pull-left">{{ prefix + 'title' | translate}} <span
      class="text-gradient">{{productLine.name}}</span></h5>
  </ng-container>
  <ng-template #firstTitle>
    <h5 class="modal-title pull-left">{{ prefix + 'first-title' | translate }}</h5>
  </ng-template>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="!loading; else loader">
  <p *ngIf="isFirst">{{ prefix + 'first-content' | translate }}</p>

  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy">
      <div class="row">
        <div class="col-lg-7">
          <app-form-control
            [prefix]="prefix + 'product-name'"
            [control]="form.get('name')"
            [marginBottom]="0"
            type="new-input"
          ></app-form-control>
        </div>

        <div class="col-lg-5">
          <button *ngIf="this.controlValuesForm.controls.length <= 4" type="button" class="add-btn"
                  (click)="addMeasurementType()">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.0817 7H1.21631M7.649 1V13V1Z" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            {{ prefix + 'add-form' | translate }}
          </button>
        </div>
      </div>


      <div class="mb-2" *ngFor="let controlValue of controlValuesForm.controls; let i = index">
        <hr class="line">
        <small>Product category
          - {{getCategoryById(controlValue.get('productCategoryId').value)}}</small><br>
        <div class="d-flex justify-content-between">
          <p class="fz-18 text-gradient">{{ prefix + 'form-title' | translate : {index: i + 1} }}</p>
          <button
            *ngIf="controlValuesForm.controls.length > 1"
            type="button"
            class="delete-btn"
            (click)="removeMeasurementType(i, controlValue.value.id)"
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.4219 1.875H9.84375V1.40625C9.84375 0.63085 9.2129 0 8.4375 0H6.5625C5.7871 0 5.15625 0.63085 5.15625 1.40625V1.875H2.57812C1.93195 1.875 1.40625 2.4007 1.40625 3.04688V4.6875C1.40625 4.94637 1.61613 5.15625 1.875 5.15625H2.13117L2.53614 13.6606C2.57191 14.4117 3.18891 15 3.94078 15H11.0592C11.8111 15 12.4281 14.4117 12.4639 13.6606L12.8688 5.15625H13.125C13.3839 5.15625 13.5938 4.94637 13.5938 4.6875V3.04688C13.5938 2.4007 13.068 1.875 12.4219 1.875ZM6.09375 1.40625C6.09375 1.14779 6.30404 0.9375 6.5625 0.9375H8.4375C8.69596 0.9375 8.90625 1.14779 8.90625 1.40625V1.875H6.09375V1.40625ZM2.34375 3.04688C2.34375 2.91765 2.4489 2.8125 2.57812 2.8125H12.4219C12.5511 2.8125 12.6562 2.91765 12.6562 3.04688V4.21875C12.5118 4.21875 2.94237 4.21875 2.34375 4.21875V3.04688ZM11.5274 13.616C11.5155 13.8664 11.3098 14.0625 11.0592 14.0625H3.94078C3.69015 14.0625 3.48448 13.8664 3.47259 13.616L3.06973 5.15625H11.9303L11.5274 13.616Z"
                fill="#BDBDBD"/>
              <path
                d="M7.5 13.125C7.75887 13.125 7.96875 12.9151 7.96875 12.6562V6.5625C7.96875 6.30363 7.75887 6.09375 7.5 6.09375C7.24113 6.09375 7.03125 6.30363 7.03125 6.5625V12.6562C7.03125 12.9151 7.2411 13.125 7.5 13.125Z"
                fill="#BDBDBD"/>
              <path
                d="M9.84375 13.125C10.1026 13.125 10.3125 12.9151 10.3125 12.6562V6.5625C10.3125 6.30363 10.1026 6.09375 9.84375 6.09375C9.58488 6.09375 9.375 6.30363 9.375 6.5625V12.6562C9.375 12.9151 9.58485 13.125 9.84375 13.125Z"
                fill="#BDBDBD"/>
              <path
                d="M5.15625 13.125C5.41512 13.125 5.625 12.9151 5.625 12.6562V6.5625C5.625 6.30363 5.41512 6.09375 5.15625 6.09375C4.89738 6.09375 4.6875 6.30363 4.6875 6.5625V12.6562C4.6875 12.9151 4.89735 13.125 5.15625 13.125Z"
                fill="#BDBDBD"/>
            </svg>
          </button>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <p class="text-dark fz-14 mb-2">{{'settings.control-value.label.label' | translate}}</p>
            <div class="btn-group mr-2 w-100" dropdown>
              <button id="button-basic" dropdownToggle type="button"
                      class="text-left btn btn-light dropdown-toggle dropdown-btn"
                      aria-controls="dropdown-basic">
              <span>
                {{ controlValue.get('label').value }} <span class="caret"></span>
              </span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container
                  *ngFor="let m of getMeasurements(controlValue.get('measurementTypeId'), false)">
                  <div *ngIf="!m.disabled || controlValue.get('measurementTypeId').value === m.value"
                       class="dropdown-item-container">
                    <a class="dropdown-item"
                       [class.active]="controlValue.get('measurementTypeId').value === m.value"
                       (click)="onMeasurementSelected(m, i)">
                      <p class="product-name text-dark pointer m-0">{{ m.name }}</p>
                    </a>
                  </div>
                </ng-container>
              </ul>
            </div>
          </div>

          <div
            *ngIf="controlValue.get('label').value === 'Vitamin A' &&
            ((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            class="col-md-6 mb-3"></div>

<!--          <div *ngIf="controlValue.get('label').value === 'Vitamin A'"-->
<!--               class="col-md-6">-->
<!--            <p-->
<!--              class="checkbox-label text-dark mb-2 fz-14">{{'settings.control-value.device-type.label-2' | translate}}</p>-->
<!--            <div class="mt-3">-->
              <app-form-control
                *ngIf="controlValue.get('label').value === 'Vitamin A'"
                class="col-md-6"
                type="radio"
                [selectOptions]="deviceOptions"
                (radioSelected)="deviceTypeSelected($event, i)"
                prefix="settings.control-value.device-type"
                [control]="controlValue.get('deviceTypeId')">
              </app-form-control>
<!--            </div>-->
<!--          </div>-->

          <!--          FLUORO SETTINGS-->


          <app-form-control
            *ngIf="((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            type="new-select"
            class="col-md-6"
            [selectOptions]="productTypes[controlValue.get('label').value]"
            prefix="settings.control-value.product-type"
            (selectedMeasurement)="productTypeIdChanged($event, i)"
            [control]="controlValue.get('productTypeId')"
          ></app-form-control>


          <app-form-control
            *ngIf="getProductType(controlValue.get('productTypeId').value) === 'dry' &&
            ((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            class="col-md-6"
            steps=".25"
            type="new-number"
            prefix="settings.control-value.weight"
            [control]="controlValue.get('productWeight')"
          ></app-form-control>

          <app-form-control
            *ngIf="getProductType(controlValue.get('productTypeId').value) === 'dry' &&
            ((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            type="new-input"
            class="col-md-6"
            textTransform="none"
            prefix="settings.control-value.productWeight-unit"
            [control]="controlValue.get('productWeightUnit')"
            [disabled]="true"
          ></app-form-control>

          <div class="col-md-6"
               *ngIf="getProductType(controlValue.get('productTypeId').value) === 'liquid'"
          ></div>
          <div class="col-md-6"
               *ngIf="getProductType(controlValue.get('productTypeId').value) === 'liquid'"
          ></div>

          <app-form-control
            type="new-number"
            class="col-md-6"
            prefix="settings.control-value.target"
            [control]="controlValue.get('target')"
          ></app-form-control>


          <app-form-control
            *ngIf="getSelectUnits(i).length > 1; else oneUnit"
            textTransform="none"
            type="new-select"
            class="col-md-6"
            [selectOptions]="getSelectUnits(i)"
            prefix="settings.control-value.expected-vitamin-a-unit"
            [control]="controlValue.get('unit')"
          ></app-form-control>

          <ng-template #oneUnit>
            <div class="unit-container col-md-6">
              <p class="label">{{'settings.control-value.expected-vitamin-a-unit.label' | translate}}</p>
              <div class="unit">
                <p>{{getSelectUnits(i)[0].name}}</p>
              </div>
            </div>
          </ng-template>

          <div
            *ngIf="(((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)) &&
            getProductType(controlValue.get('productTypeId').value) === 'dry'"
            class="col-md-6 mb-3">
            <div class="ratio">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="8.5" r="8.5" fill="url(#paint0_linear_2_983)"/>
                <path
                  d="M7.93 10.06C7.86333 10.06 7.80667 10.0367 7.76 9.99C7.71333 9.94333 7.69 9.88667 7.69 9.82V5.24C7.69 5.17333 7.71333 5.11667 7.76 5.07C7.80667 5.02333 7.86333 5 7.93 5H8.74C8.81333 5 8.87 5.02333 8.91 5.07C8.95667 5.11 8.98 5.16667 8.98 5.24V9.82C8.98 9.88667 8.95667 9.94333 8.91 9.99C8.87 10.0367 8.81333 10.06 8.74 10.06H7.93ZM7.87 12C7.80333 12 7.74667 11.9767 7.7 11.93C7.65333 11.8833 7.63 11.8267 7.63 11.76V10.83C7.63 10.7567 7.65333 10.6967 7.7 10.65C7.74667 10.6033 7.80333 10.58 7.87 10.58H8.8C8.87333 10.58 8.93333 10.6033 8.98 10.65C9.02667 10.6967 9.05 10.7567 9.05 10.83V11.76C9.05 11.8267 9.02667 11.8833 8.98 11.93C8.93333 11.9767 8.87333 12 8.8 12H7.87Z"
                  fill="white"/>
                <defs>
                  <linearGradient id="paint0_linear_2_983" x1="15.2497" y1="4.58998" x2="1.88684" y2="4.57613"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#06A3AE"/>
                    <stop offset="1" stop-color="#008C96"/>
                  </linearGradient>
                </defs>
              </svg>

              <p>{{prefix + 'water-ratio' | translate : {value: getWaterRatio(controlValue.get('target').value, controlValue.get('productTypeId').value)} }}</p>
            </div>
          </div>

          <app-form-control
            class="col-md-6"
            steps=".25" type="new-number"
            [labelValue]="controlValue.get('unit').value"
            prefix="settings.control-value.national-standard"
            [control]="controlValue.get('nationalStandard')"
          ></app-form-control>

          <app-form-control
            class="col-md-3"
            steps=".25"
            type="new-number"
            prefix="settings.control-value.min-warn"
            [control]="controlValue.get('minWarn')"
          ></app-form-control>

          <app-form-control
            class="col-md-3"
            steps=".25" type="new-number"
            prefix="settings.control-value.max-warn"
            [control]="controlValue.get('maxWarn')"
          ></app-form-control>

          <app-form-control
            class="col-md-3"
            steps=".25"
            type="new-number"
            prefix="settings.control-value.min-limit"
            [control]="controlValue.get('minLimit')"
          ></app-form-control>

          <app-form-control
            class="col-md-3"
            steps=".25"
            type="new-number"
            prefix="settings.control-value.max-limit"
            [control]="controlValue.get('maxLimit')"
          ></app-form-control>

          <app-form-control
            *ngIf="getProductType(controlValue.get('productTypeId').value) === 'dry' &&
            ((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            class="col-md-6"
            steps=".25"
            type="new-number"
            prefix="settings.control-value.volume"
            [control]="controlValue.get('dilutedVolume')"
          ></app-form-control>

          <app-form-control
            textTransform="none"
            *ngIf="getProductType(controlValue.get('productTypeId').value) === 'dry' &&
            ((getDeviceTypeById(controlValue.get('deviceTypeId').value) === 'fluoro' &&
            controlValue.get('measurementTypeId').value === 1) ||
            controlValue.get('measurementTypeId').value !== 1)"
            type="new-input"
            class="col-md-6"
            [disabled]="true"
            prefix="settings.control-value.dilutedVolume-unit"
            [control]="controlValue.get('dilutedVolumeUnit')"
          ></app-form-control>


          <!--          DILUTION SETTING-->
<!--          <div class="col-md-6" *ngIf="getProductType(controlValue.get('productTypeId').value) === 'liquid' || (controlValue.get('measurementTypeId').value === 1 && !controlValue.get('isFluoro').value)"></div>-->
<!--          <div class="col-md-6">-->
<!--            <div class="row">-->
<!--              <app-form-control-->
<!--                class="col-md-12"-->
<!--                steps=".25"-->
<!--                type="new-number"-->
<!--                prefix="settings.control-value.fortified"-->
<!--                [control]="controlValue.get('fortified')"-->
<!--              ></app-form-control>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <app-form-control-->
<!--                class="col-md-12"-->
<!--                steps=".25"-->
<!--                type="new-number"-->
<!--                prefix="settings.control-value.unfortified"-->
<!--                [control]="controlValue.get('unfortified')"-->
<!--              ></app-form-control>-->
<!--            </div>-->
<!--          </div>-->


<!--          <div class="col-md-6 dilution-info-container">-->
<!--            <div class="dilution-info">-->
<!--              <p class="dilution-info-text">{{prefix + 'dilution-info' | translate}}</p>-->
<!--            </div>-->
<!--          </div>-->


        </div>
      </div>

      <div class="d-flex flex-column align-items-start mb-3">

        <small class="text-danger" *ngIf="noMeasurements">{{ prefix + 'error-no-measurements' | translate }}!</small>
      </div>

      <button type="submit" class="save-btn" [disabled]="form.invalid && controlValuesForm.controls.length <= 1">
        <i class="fa fa-check fa-fw" aria-hidden="true"></i>
        {{ prefix + 'save' | translate }}
      </button>
    </fieldset>
  </form>

</div>

<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loader-container">
      <app-dots-loader></app-dots-loader>
    </div>
  </div>
</ng-template>
