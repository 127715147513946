<div class="modal-container">
  <header class="header">
    <p class="header__title">{{prefix + 'title' | translate}}</p>

    <app-buttons
      class="header__close"
      type="invisible-icon"
      (onClick)="modal.hide()"
      width="auto"
      height="auto"
    >
      <img src="/assets/icons/cross.svg">
    </app-buttons>
  </header>

  <main class="main" *ngIf="!loading; else loader">
    <div *ngIf="organizations.length; else noData">
      <div class="main__organization-container" *ngFor="let org of organizations">
        <div class="main__organization-container__organization-name">
          <app-form-control
            [type]="'non-control-checkbox'"
            [checkboxLabel]="''"
            [selected]="validateSelectAll(org)"
            (checkboxChanged)="selectAll(org, $event)"
            disableSelectEffect="true"
            marginBottom="0"
          ></app-form-control>
          <p class="main__organization-container__organization-name__text">{{org.name}}</p>
        </div>

        <div class="main__organization-container__users-container">
          <div class="main__organization-container__users-container__user" *ngFor="let u of org.users">
            <app-form-control
              [type]="'non-control-checkbox'"
              [checkboxLabel]="u.firstName + ' ' + u.lastName"
              [selected]="u.selected"
              (checkboxChanged)="selectUser($event, u)"
              marginBottom="0"
            ></app-form-control>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noData>
      <div class="main__no-data">
        <p>{{prefix + 'no-users' | translate}}</p>
      </div>
    </ng-template>

  </main>

  <ng-template #loader>
      <div class="loader-wrapper">
        <div class="loader-container">
          <app-dots-loader></app-dots-loader>
        </div>
      </div>
  </ng-template>

  <footer class="footer">
    <app-buttons
      class="main__controls__next-btn"
      type="filled-gradient"
      width="190px"
      height="65px"
      fontSize="18px"
      (onClick)="next()"
      [disabled]="loading || !selected.length"
      [text]="prefix + 'next-btn' | translate"
    ></app-buttons>
  </footer>
</div>
