import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Organization} from "../../../model/organization";

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  @Input() items: any = []
  @Input() label: string
  @Input() type: 'group' | 'list'
  @Input() emptyType: 'user'
  @Input() translateBase = 'multi-select-dropdown'
  @Input() disable = false
  @Input() hideLabel = false
  @Input() subLabel = false
  @Output() selectionChange = new EventEmitter<any>()
  @Output() selectionGroupChange = new EventEmitter<any>()

  prefix = 'multi-select-dropdown.'
  selectCount = 0


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.type === 'group') {
      let count = 0
      this.items.forEach(item => {
        if (item.items.length) {
          item.items.forEach(i => {
            if (i.selected) {
              count++
            }
          })
        }
      })
    } else if (this.type === 'list') {
      let count = 0
      if (this.items.length) {
        this.items.forEach(item => {
          if (item.selected) {
            count++
          }
        })
      }
    }
    this.getLabel()
    this.getItemName()
  }

  ngOnInit() {
    if (this.type === 'group') {
      if (this.items.length) {
        this.items.forEach(g => {
          g.items.forEach(i => {
            if (i.selected) {
              this.selectCount++
            }
          })
        })
      } else {
        this.selectCount = 0
      }
    } else if (this.type === 'list') {
      if (this.items.length) {
        this.items.forEach(i => {
          if (i.selected) {
            this.selectCount++
          }
        })
      } else this.selectCount = 0
    } else this.selectCount = 0
  }


  toggle(item, value?: number) {
    if (this.disable) return
    item.selected = !item.selected
    this.selectCount = item.selected ? this.selectCount + 1 : this.selectCount - 1
    if (this.type === 'group') {
      this.selectionChange.emit(item)
    } else if (this.type === 'list') {
      this.selectionChange.emit(item)
    }
  }

  selectAll(state: boolean) {
    if (this.type === 'group') {
      if (this.items.length) {
        this.items.forEach(item => {
          if (item.items.length) {
            item.items.forEach(i => {
              i.selected = state
              this.selectCount = state ? this.selectCount + 1 : 0
            })
          }
        })
      }
    } else if (this.type === 'list') {
      if (this.items.length) {
        this.items.forEach(item => {
          item.selected = state
          this.selectCount = state ? this.selectCount + 1 : 0
        })
      }
    }
    this.getItemName()
    this.getLabel()
  }

  getItemName(): string {
    if (this.type === 'group') {
      if (!this.items.length) return ''
      const names = []
      this.items.forEach(item => {
        const selected = item.items.length ? item.items.filter(i => i.selected) : []
        if (selected.length) {
         names.push(item.name)
        }
      })
      return names.join(', ')
    } else if (this.type === 'list') {
      const items = this.items.filter(item => item.selected)
      return items.map(item => item.name).join(', ')
    } else return ''
  }

  getLabel(): string {
    if (this.selectCount === 0) return `${this.translateBase}.none-${this.emptyType}`

    return `${this.translateBase}.many`
  }


  isSelectGroupActive(items: any) {
    if (!items.length) return false
    return items.filter(i => i.selected).length === items.length;
  }

  selectGroup(items: any, state: boolean) {
    items.forEach(item => {
      item.selected = state
    })
    this.selectionGroupChange.emit(this.items)
  }
}
