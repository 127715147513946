import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'

import {Initiator} from '../../../../model/Initiator'
import {Product} from '../../../../model/Products'
import {TrainingSearchCriteria} from '../../../../model/search-criteria'
import {Subscriptions} from '../../../../model/subscriptions'
import {TrainingAttempt} from '../../../../model/TrainingAttempt'
import {CacheService} from '../../../services/cache.service'
import {DataService} from '../../../services/data.service'
import {ToastService} from '../../../services/toast.service'
import {UserService} from '../../../services/user.service'
import {ModalService} from '../../../shared/modal.service'
import {AddTrainingDataModalComponent} from '../add-training-data-modal/add-training-data-modal.component'
import {User} from '../../../../model/user'

@Component({
  selector: 'app-training2',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
})
export class TrainingComponent implements OnInit {
  prefix = 'training.results.'

  private _subs = new Subscriptions()
  private init = new Initiator(['products', 'users'])

  products: Product[] = []
  selectedProduct: Product// = { id: -1, name: "..." };

  attempts: TrainingAttempt[] = []
  lastAttempt: TrainingAttempt
  search: TrainingSearchCriteria
  bsDateRange: Date[] = []
  selectedAttempt: TrainingAttempt

  userFilter: any[] = []

  anyData = false

  user: User
  busy = false

  get viewingMyselfOnly() {
    return this.search.isAboutMeOnly(this.user.uuid)
  }

  constructor(
    private dataService: DataService,
    private cache: CacheService,
    private route: ActivatedRoute,
    private router: Router,
    private toasts: ToastService,
    private us: UserService,
    private trx: TranslateService,
    private ms: ModalService,
  ) {
    //Init Search
    this.search = TrainingSearchCriteria.deserialize(this.route.snapshot.queryParams)
    this.bsDateRange = this.search.getDateRangePickerDates()
    this.user = this.us.userChanged.value
  }

  ngOnInit() {
    //Listen for Products
    this._subs.add(this.cache.productLines.subscribe((lines) => {
      const trainingProductLine = lines.find(l => l.training)
      if (!trainingProductLine || trainingProductLine.products.length === 0) {
        // this.router.navigate(['/training', 'getting-started']);
        return
      }
      this.products = trainingProductLine.products

      this.init.complete('products')
    }))

    this._subs.add(this.cache.users.subscribe(users => {
      //Make user Filter
      this.userFilter = []
      const me = this.us.userChanged.getValue() || {uuid: 'nope'}

      for (const u of users) {
        let name = u.getName()
        if (u.uuid === me.uuid) {
          name += ` (${this.trx.instant('me')})`
        }
        this.userFilter.push({name, uuid: u.uuid, selected: this.search.userUuids.indexOf(u.uuid) > -1})
      }
      this.init.complete('users')
    }))

    this.afterInit()
  }

  async afterInit() {
    await this.init.ready()

    //Listen for Query Params.
    this._subs.add(this.route.queryParams.subscribe(p => {
      //Note: These will always fire on subscribe.
      this.search = TrainingSearchCriteria.deserialize(p)
      this.search.organizationId = p.organizationId

    }))
    this.busy = true
    const data = await this.dataService.getTrainingData(this.search)
    this.attempts = data.attempts
    this.lastAttempt = this.attempts[this.attempts.length - 1]
    this.search.productId = this.lastAttempt.productId
    this.search.deviceId = this.lastAttempt.deviceId
    this.anyData = (this.attempts.length > 0)
    this.busy = false

    this.resetUserFilter()
    await this.loadCharts()
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  resetUserFilter() {
    //Reset Selections
    // Note: Reassigning the variable is needed to make the component recalculate the label.
    this.userFilter = this.userFilter.map(u => {
      u.selected = this.search.userUuids.indexOf(u.uuid) > -1
      return u
    })
  }

  userFilterChanged(event: any[]) {
    const users = event.map(i => i.uuid)
    this.router.navigate(['/training'], {queryParamsHandling: 'merge', queryParams: {users}})
  }

  private async loadCharts() {
    this.search.limit = (this.search.isAboutMeOnly(this.user.uuid)) ? 3 : 15
    if (this.attempts.length > 0) {
      this.selectedAttempt = this.attempts[this.attempts.length - 1]
    }
    this.fixChartsTitle()

    this.loadUsageChart()
  }

  private fixChartsTitle() {
    this.attempts.map((a) => {
      a.controlChart.title.align = 'left'
      a.controlChart.legend.layout = 'horizontal'
      a.controlChart.legend.align = 'left'
      a.controlChart.legend.verticalAlign = 'bottom'
      a.controlChart.title.verticalAlign = 'top'
    })
  }

  async loadUsageChart() {
    const data = await this.dataService.getTrainingUsageChart(this.search)
  }

  dateChange(dates: Date[]) {
    if (!dates || dates.length !== 2) {
      return
    }
    if (dates[0] === null || dates[1] === null) {
      return
    }

    if (this.search.from.isSame(dates[0], 'day') && this.search.to.isSame(dates[1], 'day')) {
      return
    }

    const extras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        from: Math.round(+dates[0] / 1000),
        to: Math.round(+dates[1] / 1000),
      },
    }
    this.router.navigate([], extras)
  }

  addTrainingData() {
    //Open modal
    this.ms.show(AddTrainingDataModalComponent, {initialState: {productId: this.search.productId}})
  }

}
