<div class="w-100" [class.hidden]="view !== 'show'">
  <button
    *ngIf="!value"
    (click)="setView('edit', $event)"
    class="btn btn-link btn-sm p-0">
    {{ emptyText | translate }}
  </button>
  <span class="default" *ngIf="value">
    <p class="m-0"
       [ngClass]="{'text-overflow-100-60': textOverflow}"
       [ngStyle]="{'font-size': header ? '20px' : '16px'}">{{value}}</p>
    <button *ngIf="header" (click)="setView('edit', $event)" class="header-edit-btn">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.15467 2.48822L11.512 4.84555L9.15467 2.48822ZM10.1547 1.48822C10.4673 1.17562 10.8912 1 11.3333 1C11.7754 1 12.1994 1.17562 12.512 1.48822C12.8246 1.80082 13.0002 2.2248 13.0002 2.66689C13.0002 3.10897 12.8246 3.53295 12.512 3.84555L3.33333 13.0242H1V10.6429L10.1547 1.48822V1.48822Z"
        stroke="url(#paint0_linear_14_851)" stroke-width="1.52381" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear_14_851" x1="11.7647" y1="4.24653" x2="2.33191" y2="4.23677"
                      gradientUnits="userSpaceOnUse">
      <stop stop-color="#06A3AE"/>
      <stop offset="1" stop-color="#008C96"/>
      </linearGradient>
      </defs>
      </svg>
    </button>

        <button *ngIf="!header" (click)="setView('edit', $event)" class="row-edit-btn">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.15467 2.48822L11.512 4.84555L9.15467 2.48822ZM10.1547 1.48822C10.4673 1.17562 10.8912 1 11.3333 1C11.7754 1 12.1994 1.17562 12.512 1.48822C12.8246 1.80082 13.0002 2.2248 13.0002 2.66689C13.0002 3.10897 12.8246 3.53295 12.512 3.84555L3.33333 13.0242H1V10.6429L10.1547 1.48822V1.48822Z"
        stroke="url(#paint0_linear_14_851)" stroke-width="1.52381" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear_14_851" x1="11.7647" y1="4.24653" x2="2.33191" y2="4.23677"
                      gradientUnits="userSpaceOnUse">
      <stop stop-color="#06A3AE"/>
      <stop offset="1" stop-color="#008C96"/>
      </linearGradient>
      </defs>
      </svg>
    </button>

  </span>
</div>


<form class="form-container" *ngIf="view === 'edit'" [ngClass]="position">
  <div class="input-group input-group-sm">
    <input #input type="text" class="form-control" (keydown)="keyDown($event)"
           placeholder="{{ placeholder | translate }}" name="text-input"
           [(ngModel)]="newValue">
    <div class="input-group-append">
      <button class="btn btn-outline-primary" type="button" (click)="submit($event)">
        <i class="fa fa-check"></i>
      </button>
    </div>
  </div>
</form>
