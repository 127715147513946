<div class="permissions-dropdown-container">
  <div class="btn-group mr-2 dropdown" dropdown *ngIf="user.selected">
    <button id="button-basic" dropdownToggle type="button" class="dropdown-button"
            aria-controls="dropdown-basic">
      <span class="permissions-label" *ngIf="user.hierarchyPermissions.length; else noPermissions">{{prefix + 'permissions-dropdown.' + user.hierarchyPermissions[0] | translate}}</span>
      <ng-template #noPermissions>
        <span class="permissions-label">{{prefix + 'permissions-dropdown.no-permissions' | translate}}</span>
      </ng-template>
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928933C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"
          fill="#E0E0E0"/>
      </svg>

    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
        aria-labelledby="button-basic" (click)="$event.stopPropagation()">
      <li *ngFor="let p of permissionOptions" class="disabled" role="menuitem">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" [id]="user.uuid + p"
                 (change)="onPermissionsChanged($event, user.uuid, p)" [checked]="user.hierarchyPermissions.includes('all') || user.hierarchyPermissions.includes(p)">
          <label class="text-dark custom-control-label" [for]="user.uuid + p"></label>
        </div>
        <p>{{prefix + 'permissions-dropdown.' + p | translate}}</p>
      </li>
    </ul>
  </div>
</div>
