import { Component, OnInit, OnDestroy } from '@angular/core';

import { User } from '../../../model/user';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-nav-dropdown',
  templateUrl: './user-nav-dropdown.component.html',
  styleUrls: ['./user-nav-dropdown.component.scss']
})
export class UserNavDropdownComponent implements OnInit, OnDestroy {

  isTester = false;
  user: User
  private _sub: Subscription;

  constructor(private us: UserService) {

  }

  ngOnInit() {
    this._sub = this.us.userChanged.subscribe(u => {
      this.user = u;
      this.isTester = this.user.hasRole('tester');
    })
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = null;
    }
  }

  logout() {
    this.us.logout();
  }
}
