import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Organization, OrganizationPage } from '../../../model/organization';
import { User } from '../../../model/user';
import { OrganizationService } from '../../organization.service';

@Component({
  selector: 'app-select-org-modal',
  templateUrl: './select-org-modal.component.html',
  styleUrls: ['./select-org-modal.component.scss']
})
export class SelectOrgModalComponent implements OnInit {
  user: User;
  uuid: string
  prefix  = "admin.select-org.";
  loading = false;
  page: OrganizationPage;
  selected: Organization;
  modalResult: Organization;
  limit = true

  constructor(
    public modal: BsModalRef,
    private os: OrganizationService,
  ) {
  }

  async ngOnInit() {
    this.loading = true
    await this.doSearch('');
    this.loading = false
  }

  async doSearch(q: string) {
    this.loading = true;
    this.page = await this.os.searchOrgs(q, 0, this.limit);
    this.loading = false;
  }


  select(o: Organization) {
    this.selected = o;
    console.log('DATA _ ', {
      uuid: this.uuid,
      user: this.user,
    })
  }

  //Note that this will only work if there is no organization yet. Switching organizations is not permitted.
  async done() {
    await this.os.setOrganization({ uuid: this.uuid, orgCode: this.selected.code });
    this.user.organization = this.selected;
    this.user.organizationId = this.selected.id;
    this.modalResult = this.selected;

    this.modal.hide();
  }

}
