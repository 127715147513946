<header class="header">
  <p class="header__title">{{prefix + 'title' | translate}} <span *ngIf="!loading"
                                                                  class="header__title__counter">({{page.totalItems ? page.totalItems : 0}}
    )</span></p>

  <div class="header__controls" [formGroup]="form">
    <div class="header__controls__search-container">
      <div class="header__controls__search-container__icon-container">
        <img src="/assets/icons/search.svg" class="header__controls__search-container__icon-container__icon">
      </div>
      <input formControlName="search" type="text" class="header__controls__search-container__input"
             placeholder="Search..." (keydown.enter)="getPage()">
    </div>

    <div class="header__controls__buttons">
      <div class="header__controls__buttons__unit">
        <app-form-control
          [disabled]="true"
          (selectedMeasurement)="unitSelected($event)"
          [bg]="'transparent'" type="new-select" [selectOptions]="units" [prefix]="prefix + 'created-by'"
                          [control]="form.get('unit')" [hideLabel]="true" marginBottom="0"></app-form-control>
      </div>

      <app-buttons
        type="dark-green"
        [text]="prefix + 'disable-btn' | translate"
        width="145px"
        height="40px"
        [disabled]="!selected.length || loadingRows.length > 0"
        (onClick)="disableAllSamples()"
      >
      </app-buttons>
      <app-buttons
        type="dark-green"
        [text]="prefix + 'enable-btn' | translate"
        width="145px"
        height="40px"
        (onClick)="enableAllSamples()"
        [disabled]="!selected.length || loadingRows.length > 0"
      >
      </app-buttons>
      <app-buttons
        [type]="'outline-gradient'"
        [text]="prefix + 'share-btn' | translate"
        image="gradient-share"
        width="95px"
        height="40px"
        (onClick)="share()"
        [disabled]="loadingRows.length > 0 || activeTab === 'shared'"
      >
      </app-buttons>
      <app-buttons
        [type]="'filled-gradient'"
        [text]="prefix + 'export-pdf-btn' | translate"
        image="export-arrow.svg"
        width="155px"
        height="40px"
        (onClick)="exportPdf()"
        [disabled]="!selected.length || loadingRows.length > 0"
      >
      </app-buttons>
      <app-buttons
        [type]="'filled-gradient'"
        [text]="prefix + 'export-csv-btn' | translate"
        image="export-arrow.svg"
        width="155px"
        height="40px"
        (onClick)="exportCsv()"
        [disabled]="!selected.length || loadingRows.length > 0"
      >
      </app-buttons>
    </div>
  </div>
</header>

<main class="main">
  <div>
    <tabset>
      <tab
        [heading]="prefix + tabs[0].title | translate"
        [active]="tabs[0].active"
        (selectTab)="selectTab(tabs[0])"
        (deselect)="tabs[0].active = false"
        [disabled]="tabs[0].disabled || loading || loadingRows.length > 0"
        customClass="tab">

        <ng-container *ngIf="!loading; else loader">
          <ng-container *ngIf="tableData.length; else noData">
            <app-paging
              *ngIf="page && page.lastPage > 0 && !loading"
              (pageChanged)="getPage($event)"
              [current]="page?.currentPage"
              marginBottom="0"
              [last]="page?.lastPage"></app-paging>
            <div>
              <app-all-data-table
                type="own"
                *ngIf="page && tabs[0].active"
                [data]="tableData"
                [currentPage]="page.currentPage"
                (onSelected)="onSelected($event)"
                (onSelectColumn)="columnSelected($event)"
                [loadingRows]="loadingRows"
              >
              </app-all-data-table>
            </div>
            <div class="mt-3">
              <app-paging
                *ngIf="page && page.lastPage > 0 && !loading"
                (pageChanged)="getPage($event)"
                [current]="page?.currentPage"
                marginBottom="0"
                [last]="page?.lastPage"></app-paging>
            </div>
          </ng-container>
        </ng-container>
      </tab>

      <tab
        [heading]="prefix + tabs[1].title | translate"
        [active]="tabs[1].active"
        (selectTab)="selectTab(tabs[1])"
        (deselect)="tabs[1].active = false"
        [disabled]="tabs[1].disabled || loading || loadingRows.length > 0"
        customClass="tab">
        <ng-container *ngIf="!loading; else loader">
          <ng-container *ngIf="sharedSamplesPage?.items?.length; else noData">
            <app-paging
              *ngIf="sharedSamplesPage && sharedSamplesPage.lastPage > 0 && !loading"
              (pageChanged)="getSharedPage($event)"
              [current]="sharedSamplesPage?.currentPage"
              marginBottom="0"
              [last]="sharedSamplesPage?.lastPage"></app-paging>
            <div>
              <app-all-data-table
                type="shared"
                *ngIf="sharedSamplesPage && tabs[1].active"
                [data]="sharedSamplesPage.items"
                [currentPage]="sharedSamplesPage.currentPage"
                (onSelected)="onSharedSelected($event)"
                (onSelectColumn)="columnSelected($event)"
                [loadingRows]="loadingRows"
              >
              </app-all-data-table>
            </div>
            <div class="mt-3">
              <app-paging
                *ngIf="sharedSamplesPage && sharedSamplesPage.lastPage > 0 && !loading"
                (pageChanged)="getSharedPage($event)"
                [current]="sharedSamplesPage?.currentPage"
                marginBottom="0"
                [last]="sharedSamplesPage?.lastPage"></app-paging>
            </div>
          </ng-container>
        </ng-container>
      </tab>
    </tabset>
  </div>
</main>
<ng-template #noData>
  <app-no-data
    *ngIf="!loading; else loader"
    icon="no-data-book"
    [subtitle]="prefix + 'no-data.subtitle'"
    [content]="prefix + 'no-data.content'">
  </app-no-data>
</ng-template>


<ng-template #loader>
  <div class="loader">
    <div class="loader-wrapper">
      <div class="loader-container">
        <app-dots-loader></app-dots-loader>
      </div>
    </div>
  </div>
</ng-template>
