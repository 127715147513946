import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core'
import html2canvas from 'html2canvas'
import jspdf from 'jspdf'
import 'jspdf-autotable'
import {DataService} from '../../services/data.service'
import {Subscriptions} from '../../../model/subscriptions'
import {UserService} from '../../services/user.service'
import {User} from '../../../model/user'
import * as moment from 'moment'
import {Sample} from '../../../model/sample'
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-fancy-table',
  templateUrl: './fancy-table.component.html',
  styleUrls: ['./fancy-table.component.scss']
})
export class FancyTableComponent implements OnInit, OnChanges, OnDestroy {
  private _subs = new Subscriptions()

  @ViewChild('allCheck') allCheckbox: ElementRef

  @Input() options: FancyTableOptions = {}
  @Input() items: any[] = []
  @Input() prefix = ""
  @Input() exportState: EventEmitter<string>
  private _selectCount = 0
  private checkedCols: any[] = []

  get selectCount() {
    return this._selectCount
  }

  get selected() {
    if (!this.items) return []
    return this.items.filter(i => i.selected)
  }

  get columns() {
    return this._options.columns || []
  }

  user: User
  exporting = false
  allSelected = false
  contextMenu: FancyTableButton[] = []
  visibleButtons: FancyTableButton[] = []

  private _options: FancyTableOptions = {
    columns: [],
    buttons: [],
    maxSelected: 10
  }

  constructor(
    private us: UserService,
    private ds: DataService
  ) {
  }

  ngOnInit() {
    console.log("itemsitemsitemsitems", this.items)
    if (this.exportState) {
      this._subs.add(this.exportState.subscribe((state) => {
        if (state === 'start') {
          this.exporting = true
          this.exportTable()
        } else if (state === 'start-csv') {
          // this.exporting = true
          this.exportCsvTable()
        } else if (state === 'finished') {
          this.exporting = false
        } else if (state === 'disable-samples') {
          if (!this.selected.length) return
          const ids = this.selected.map(s => s.id)
          this.items.forEach(i => {
            if (ids.includes(i.id)) {
              i.disabled = true
            }
          })
          this.ds.disableSamples(ids)
        } else if (state === 'enable-samples') {
          if (!this.selected.length) return
          const ids = this.selected.map(s => s.id)
          this.items.forEach(i => {
            if (ids.includes(i.id)) {
              i.disabled = false
            }
          })
          this.ds.enableSamples(ids)
        }
      }))
    }

    this._subs.add(this.us.userChanged.subscribe(u => {
      this.user = u
    }))

    console.log('options', this.options)
    console.log('items', this.items)
    console.log('selected', this.selected)
  }

  ngOnChanges() {
    this.setOptions()
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  buttonClick(i: any, b: FancyTableButton) {
    if (b.click) b.click(i, b)
  }

  private setOptions() {
    //Copy settings from input
    if (this.options) this._options = Object.assign(this._options, this.options)

    //Setup columns if none have been given
    if (this._options.columns.length === 0 && this.items && this.items.length > 0) {
      //If no columns are set, take them from the first item.
      this._options.columns = Object.getOwnPropertyNames(this.items[0])
    }

    //Make column types
    const cols: FancyTableColumn[] = []
    for (let c of this._options.columns) {
      if (typeof c === "string") {
        cols.push({name: c, type: 'text', property: c})
      } else {
        if (!c.type) c.type = "text"
        cols.push(c)
      }
    }
    this.checkedCols = cols
    this._options.columns = cols

    this._options.columns.map((c: FancyTableColumn) => {
      if (c.selectable) {
        c.checked = true
      }
    })

    //Setup buttons
    this.contextMenu = []
    this.visibleButtons = []
    for (let b of this._options.buttons) {
      if (b.show) {
        this.visibleButtons.push(b)
      } else {
        this.contextMenu.push(b)
      }
    }
    if (this.items && this.items.length > 0) this.toggleAll(false)
  }

  toggleAll(newState?: boolean) {
    if (newState === undefined) {
      if (this.allCheckbox.nativeElement.indeterminate) {
        newState = false
      } else {
        newState = !this.allSelected
      }
    }

    this.allSelected = newState
    this._selectCount = newState ? this.items.length : 0
    for (const i of this.items) {
      i.selected = newState
    }

    if (this.allCheckbox) this.allCheckbox.nativeElement.indeterminate = false
  }

  toggle(item: any) {
    console.log('selected', item)
    if (item.uuid === this.user.uuid) return
    if (!item.selected && this.selectCount >= this._options.maxSelected) return

    item.selected = !item.selected

    const add = item.selected ? 1 : -1
    this._selectCount += add

    if (this._selectCount === 3) {
      // this.exporting = true;
    }
    this.updateAllSelect()
  }

  private updateAllSelect() {
    if (!this.allCheckbox) return
    if (this._selectCount === this.items.length) {
      this.allSelected = true
      this.allCheckbox.nativeElement.indeterminate = false
    } else if (this._selectCount === 0) {
      this.allSelected = false
      this.allCheckbox.nativeElement.indeterminate = false
    } else {
      this.allCheckbox.nativeElement.indeterminate = true
    }
  }

  updateColumnSelect(col) {
    const checkElem: any = document.getElementById('check-col-' + col.name)
    checkElem.checked = !checkElem.checked

    this._options.columns.map((optCol: FancyTableColumn) => {
      if (optCol.name === col.name) {
        optCol.checked = checkElem.checked
      }
    })
  }

  exportTable() {
    if (!this.selected.length) return
    let body = this.getTableData()
    const doc = new jspdf()
    autoTable(doc, {
      columns: [
        {header: 'Date', dataKey: 'Date'},
        {header: 'Time', dataKey: 'Time'},
        {header: 'Type', dataKey: 'Type'},
        {header: 'Product Name', dataKey: 'Product Name'},
        {header: 'Analyst', dataKey: 'Analyst'},
        {header: 'iCheck Batch', dataKey: 'iCheck Batch'},
        {header: 'Batch Name', dataKey: 'Batch Name'},
        {header: 'Result', dataKey: 'Result'},
        {header: 'Unit', dataKey: 'Unit'},
        {header: 'Analyt', dataKey: 'Analyt'},
        {header: 'Device', dataKey: 'Device'},
        {header: 'Test Kit Lot', dataKey: 'Test Kit Lot'},
        {header: 'Company Name', dataKey: 'Company Name'},
        {header: 'Country', dataKey: 'Country'}
      ],
      body,
      styles: {
        fontSize: 5,

      },
      margin: {right:0, left:0, top: 0, bottom: 0},
    })
    doc.save('iCheck-Connect-All-Data-Export.pdf')
  }

  getTableData() {
    const samples = this.selected.filter(s => s.disabled === false)
    const table =samples.length ? samples.map(s => {
      const data = {
        Date: moment(s.created).format('MMM DD, YYYY'),
        Time: moment(s.created).format('HH:mm'),
        Type: s.product ? s.product.productLine.name : '-',
        'Product Name': s.product ? s.product.name : '-',
        Analyst: s.import.user ? `${s.import.user.firstName} ${s.import.user.lastName || ''}`.trim() : '-',
        'iCheck Batch': `Batch: ${s.deviceBatchId} Sample: ${s.deviceSampleId}`,
        'Batch Name': '',
        Result: s.result,
        Unit: (s.product && s.product.controlValues && s.product.controlValues[0]) ? s.product.controlValues[0].unit : '-',
        Analyt: (s.product && s.product.controlValues && s.product.controlValues[0]) ? s.product.controlValues[0].label : '-',
        Device: s.device ? s.device.id : '-',
        'Test Kit Lot': s.testKitId !== null ? s.testKitId : '-',
        'Company Name': s.import.user && s.import.user.organization ? s.import.user.organization.name : '-',
        Country: s.import.user && s.import.user.organization ? s.import.user.organization.country : '-',
      }

      const parts = []
      if (s.userBatchId) {
        parts.push('Batch: ' + s.userBatchId)
      }
      if (s.userSampleId) {
        parts.push('Sample: ' + s.userSampleId)
      }
      if (parts.length === 0) {
        data['Batch Name'] = '-'
      } else {
        data['Batch Name'] = parts.join(' ')
      }
      return data
    }) : []

    return table
  }

  exportCsvTable() {
    const table = this.getTableData()
    console.log("tabletable", table)
    this.ds.exportTpCsv('iCheck-Connect-All-Data-Export.csv', table)
  }
}

export interface FancyTableOptions {
  columns?: (string | FancyTableColumn)[];
  buttons?: FancyTableButton[];
  maxSelected?: number;
}

export interface FancyTableColumn {
  name: string;
  property?: string;
  type?: "text" | "tags" | "icon" | "function" | "date" | "time";
  getValue?: Function;
  selectable?: boolean;
  checked?: boolean;
}

export interface FancyTableButton {
  label: string;
  icon: string;
  click?: Function;
  show?: boolean;
  active?: Function;
}
