import { User } from './user';
import { ControlChartData } from './api-response';
import * as moment from 'moment';

export class ReportData {
  averageResults: any = null;

  user: User = null;
  controlChart: ControlChartData = null;
  resData = null;

  constructor(res) {
    console.log('Report data!', res)
    this.resData = res;
    this.user = this.resData.search.user;
    this.controlChart = this.resData.controlChart;

    console.log(res);

    this.processAverageResults();
  }

  get tolerance() {
    return this.controlChart.controlValue.target + ' ' + this.controlChart.controlValue.unit;
  }

  get timePeriod() {
    const from = this.resData.search.from.format('DD.MM.YYYY');
    const to = this.resData.search.to.format('DD.MM.YYYY');
    return from + ' - ' + to;
  }

  private processAverageResults() {
    const data = this.controlChart.series[0].data;
    data.map((d: any) => {
      this.averageResults += d.y;
    });
    this.averageResults /= data.length;
    this.averageResults = this.averageResults.toFixed(2);
    console.log(this.averageResults);
  }

  get resultsAverage() {
    return this.averageResults + ' ' + this.controlChart.controlValue.unit;
  }

  get manager() {
    return this.user.firstName + ' ' + this.user.lastName;
  }

  get analyt() {
    return this.resData.cv.label;
  }

  get productLineName() {
    return this.resData.productLineName;
  }

  get testMethod() {
    return 'iCheck Chroma';
  }

  get target() {
    return `${this.resData.cv.target} ${this.resData.cv.unit}`;
  }

  get productName() {
    return this.resData.product.name
  }

  get measurementType() {
    return this.resData.cv.label
  }


  get productCategory() {
    return this.resData.productCategory
  }

  get organization() {
    return this.user.organization.name;
  }

  get batch() {
    for (let i=0; i < this.controlChart.series.length; i++) {
      const batchData: any = this.controlChart.series[i].data;
      if (batchData.length) {
        return batchData[0].batchId ? batchData[0].batchId : '-';
      } else {
        return '-'
      }
    }
  }

  get productType() {
    return '';
  }

  get brand() {
    return '';
  }

  get device() {
    return this.resData.deviceName
  }

  get date() {
    return moment().format('DD MMMM YYYY h:mm a');
  }
}
