import { Injectable, EventEmitter } from '@angular/core';
import { Toast, TOAST_STYLES } from 'src/model/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  onToastAdded = new EventEmitter<Toast>();
  onToastRemoved = new EventEmitter<Toast>();

  default: Toast = {
    message: '',
    delay: 4000,
    style: 'success',
    params: {},
  };

  constructor() { }

  success(message: string, title?: string, params?: object) {
    return this.show({
      ...this.default,
      message, title,
      params,
      style: TOAST_STYLES.success
    });
  }

  danger(message: string, title?: string, params?: object) {
    return this.show({
      ...this.default,
      message, title,
      params,
      style: TOAST_STYLES.danger
    });
  }

  warning(message: string, title?: string, params?: object) {
    return this.show({
      ...this.default,
      message, title,
      style: TOAST_STYLES.warning
    });
  }

  info(message: string, title?: string, params?: object) {
    return this.show({
      ...this.default,
      message, title,
      params,
      style: TOAST_STYLES.info
    });
  }

  light(message: string, title?: string, params?: object) {
    return this.show({
      ...this.default,
      message, title,
      params,
      style: TOAST_STYLES.light
    });
  }

  show(t: Toast) {
    t = { ...this.default, ...t };

    this.onToastAdded.next(t);

    if (t.delay) {
      setTimeout(() => {
        this.onToastRemoved.next(t);
      }, t.delay);
    }
  }

}
