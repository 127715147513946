import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { User, UsersPage } from '../../../model/user';
import { FancyTableOptions, FancyTableComponent } from '../fancy-table/fancy-table.component';
import { UserDetailsModalComponent } from '../user-details-modal/user-details-modal.component';
import { ToastService } from '../../services/toast.service';
import { ModalService } from '../modal.service';
import { AdminService } from '../../pages-authenticated/admin/admin.service';
import { PasswordResetModalComponent } from '../../auth/shared/password-reset-modal/password-reset-modal.component';
import { EditUserGroupsModalComponent } from '../edit-user-groups-modal/edit-user-groups-modal.component';
import { ConfirmModalOptions } from '../confirm-modal/confirm-modal.component';
import { BulkActionModalComponent } from '../bulk-action-modal/bulk-action-modal.component';
import { UserSearchCriteria } from '../../../model/search-criteria';

@Component({
  selector: 'app-users-manager',
  templateUrl: './users-manager.component.html',
  styleUrls: ['./users-manager.component.scss']
})
export class UsersManagerComponent implements OnInit, OnChanges {
  @Input() search: UserSearchCriteria;
  @Output() stateChanged = new EventEmitter<"busy" | "idle">();
  @ViewChild('table') table: FancyTableComponent;
  prefix = "admin.users.";

  page: UsersPage;

  options: FancyTableOptions = {
    columns: [
      { name: 'status', type: 'icon', property: 'statusIcon' },
      'firstName', 'lastName', 'email', 'organization.name', 'memberships.role'
    ],
    buttons: [
      { icon: "fa-binoculars", label: 'view', show: true, click: (u: User) => this.viewUser(u) },
      { icon: "fa-users", label: 'edit-membership', show: false, click: (u: User) => this.editMemberships(u) },
      { icon: "fa-asterisk", label: 'reset-password', show: false, click: (u: User) => this.resetPassword(u) },
      { icon: "fa-ban", label: 'disable', show: false, active: (u: User) => u.active, click: (u: User) => { this.enableUser(u, false) } },
      { icon: "fa-check-circle-o", label: 'enable', show: false, active: (u: User) => !u.active, click: (u: User) => { this.enableUser(u) } },
      { icon: "fa-times", label: 'remove', show: false, click: (u: User) => this.deleteUser(u) },
    ]
  }

  constructor(
    private as: AdminService,
    private ms: ModalService,
    private toasts: ToastService,
  ) { }

  ngOnInit() {
    // this.doSearch();
  }

  ngOnChanges() {
    if (this.search) this.doSearch();
  }

  toPage(number: number) {
    this.search.page = number;
    this.doSearch();
  }

  async doSearch() {
    this.stateChanged.emit("busy");
    try {
      this.page = await this.as.getUsers(this.search);
      console.log('page', this.page)
    } catch (err) {

    }
    this.stateChanged.emit("idle");
  }

  async viewUser(u: User) {
    const result = await this.ms.show(UserDetailsModalComponent, { initialState: { user: u } });
    if (!result) return

    const idx = this.page.users.findIndex(usr => usr.uuid === result.uuuid)

    this.page.users = this.page.users.map((usr) => {
        if (usr.uuid === result.uuid) {
          usr = result
        }
        return usr
    })

    // console.log('resultresulaaat', {
    //   result,
    //   users: this.page.users,
    //   usr: this.page.users[idx]
    // })
    this.page.users[idx] = result
  }

  async resetPassword(u: User) {
    await this.ms.show(PasswordResetModalComponent, { initialState: { user: u } });
  }

  async editMemberships(u: User) {
    await this.ms.show(EditUserGroupsModalComponent, { initialState: { user: u } });
  }

  async deleteUser(u: User, force = false) {
    const prefix = force ? 'force-delete' : 'delete-confirm';
    const options: ConfirmModalOptions = {
      title: `admin.users.${prefix}-title`,
      content: `admin.users.${prefix}-content`,
      params: { name: u.getName() },
    };
    const confirm = await this.ms.showConfirm(options);

    if (!confirm) return;

    this.stateChanged.emit("busy");
    try {
      await this.as.purgeUser(u, force);
      this.doSearch();
    } catch (err) {
      this.stateChanged.emit("idle");
      if (err && err.error && err.error.code === 'user-has-imports') {
        return this.deleteUser(u, true);
      }
    }
  }

  async enableUser(u: User, enabled = true) {
    const body: Partial<User> = {
      uuid: u.uuid,
      active: enabled
    }

    await this.as.updateUser(body);
    const message = enabled ? 'admin.users.user-enabled-toast' : 'admin.users.user-disabled-toast';
    this.toasts.success(message);
    u.active = enabled;
  }

  async bulkDelete() {
    const state = {
      params: this.table.selected,
      action: (u: User) => this.as.purgeUser(u) ,
      itemLabelProp: 'getName',
      actionLabel: 'admin.users.bulk-delete-action'
    };
    const result = await this.ms.show(BulkActionModalComponent, { initialState: state });
    //Result should have "allGood" , and "responses[]"
    if (!result) return;

    //Refresh page.
    if (result) this.doSearch();
  }
}
