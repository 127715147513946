import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Device } from '../../../../model/device';
import { Subscriptions } from '../../../../model/subscriptions';
import { TestKit } from '../../../../model/testKit';
import { CacheService } from '../../../services/cache.service';
import { StockService } from '../../../services/stock.service';
import { ModalService } from '../../../shared/modal.service';
import { TestKitModalComponent } from '../../../shared/test-kit-modal/test-kit-modal.component';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit, OnDestroy {
  private _subs = new Subscriptions();
  loading = true;
  devices: Device[] = [];
  testKits: TestKit[] = [];

  constructor(
    private stockService: StockService,
    private modals: ModalService,
    private router: Router,
    private cache: CacheService,
  ) { }

  ngOnInit() {
    this.getStock();
    this._subs.add(this.cache.devices
      .subscribe(d => this.devices = d.filter(dev => dev.active) ));
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll();
  }

  async getStock() {
    this.loading = true;
    const result = await this.stockService.getStock();
    this.testKits = result.testKits;

    this.loading = false;
  }

  async orderMore(tk: TestKit) {
    this.router.navigate(['/tickets/new'], { queryParams: { category: 'order', deviceType: tk.type, quantity: tk.quantity } });
  }

  async openStockModal(testKit?: TestKit) {
    const result = await this.modals.show(TestKitModalComponent, {ignoreBackdropClick: true, initialState: { testKit } });

    if (!result) return;

    const kit = this.testKits.find(tk => tk.id === result.id);
    if (kit) {
      //update
      Object.assign(kit, result);
    } else {
      //Add
      this.testKits.push(result);
    }
  }
}
