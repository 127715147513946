import { Moment } from 'moment';
import * as moment from 'moment';

import { TicketStatus } from './ticket';
import { User } from './user';

export class SearchCriteria {
  from: Moment = null;
  to: Moment = null;
  productId: number = null;
  organizationId: number = null
  userUuids: string[] = [];
  measurementTypeId?: number = null
  user?: User;

  static deserialize(data: any) {
    const sc = new SearchCriteria();

    if (data.from) {
      // sc.from = moment()
      sc.from = moment.unix(data.from).startOf('day');
    } else {
      sc.from = null
      // sc.from = moment().subtract(1, 'day').startOf('day');
    }

    if (data.to) {
      sc.to = moment.unix(data.to).endOf('day');
    } else {
      sc.to = null
      // sc.to = moment().endOf('day');
    }

    if (data.productId) sc.productId = Number(data.productId);
    if (data.users) sc.userUuids = data.users.split(',');
    if (data.organizationId) sc.organizationId = Number(data.organizationId)
    if (data.measurementTypeId) sc.measurementTypeId = Number(data.measurementTypeId)

    if (data.user) sc.user = User.deserialize(data.user);
    return sc;
  }

  getDateRangePickerDates(): Date[] {
    //ngx dateragepicker does not like timezones, so here's a workaround.
    return [
      // null,
      // this.from.clone().utc(true).toDate(),
      // null
      // this.to.clone().utc(true).toDate(),
    ];
  }

  toQueryParams(): string {
    const params = [];

    if (this.from) params.push("from=" + encodeURIComponent(this.from.unix()));
    if (this.to) params.push("to=" + encodeURIComponent(this.to.unix()));
    if (this.productId) params.push("productId=" + encodeURIComponent(this.productId));
    if (this.organizationId) params.push("organizationId=" + encodeURIComponent(this.organizationId));
    if (this.measurementTypeId) params.push("measurementTypeId=" + encodeURIComponent(this.measurementTypeId));
    if (this.userUuids) {
      for (const id of this.userUuids) {
        params.push("users=" + encodeURIComponent(id));
      }
    }

    return `?${params.join('&')}`;
  }

  reset() {
    this.from = null
    this.to = null
    this.productId = null
    this.organizationId = null
    this.measurementTypeId = null
    this.userUuids = null
    this.user = null
  }
}

export class TicketSearchCriteria {
  for: "me" | "all" = "me";

  statuses: statusOption[] = [
    { selected: true, name: "new" },
    { selected: true, name: "waiting-for-user" },
    { selected: true, name: "waiting-for-support" },
    { selected: false, name: "closed" },
  ];

  organizationId?: number;
  perPage?: number;

  private hash: string;

  update(queryParams: ticketSearchQueryParams): boolean {

    //Make a copy so we can mutate values
    const params = { ...queryParams };
    //If there's only 1 value it'll be a string rather than an array. Deal with that.
    if (typeof params.statuses === "string") params.statuses = [params.statuses];

    if (params.for) this.for = params.for;

    if (!params.statuses) params.statuses = [];

    for (const s of this.statuses) {
      s.selected = params.statuses.indexOf(s.name) > -1;
    }

    const oldHash = this.hash;
    this.hash = this.getHash();

    return (this.hash !== oldHash);
  }

  private getHash(): string {
    return [
      this.for,
      this.statuses.map((s) => String(s.selected)).join("#")
    ].join(';');
  }

  toQueryParams(): string {
    const params = [];
    params.push("for=" + encodeURIComponent(this.for));

    for (const s of this.statuses) {
      if (!s.selected) continue;
      params.push("statuses=" + encodeURIComponent(s.name));
    }

    if (this.organizationId) params.push(`organizationId=${this.organizationId}`);
    if (this.perPage) params.push(`perPage=${this.perPage}`);

    return `?${params.join('&')}`;
  }
}

interface ticketSearchQueryParams {
  for?: "me" | "all";
  statuses?: TicketStatus[];
}

interface statusOption {
  selected: boolean;
  name: TicketStatus;
}

export class UserSearchCriteria {
  q?: string;
  page = 0;
  perPage = 25;

  orderBy?: string;

  constructor(public organizationId?: number) {

  }

  toQueryParams(): string {
    const params = [];

    for (const p of Object.getOwnPropertyNames(this)) {
      if (!p) continue;
      if (this[p] === undefined || this[p] == null) continue;

      params.push(`${p}=${encodeURIComponent(this[p])}`);
    }

    if (params.length === 0) return '';

    return `?${params.join('&')}`;
  }
}

export class TrainingSearchCriteria {
  from: Moment = null;
  to: Moment = null;
  limit: number = null;
  productId: number = null;
  deviceId: number = null;
  organizationId: number = null
  userUuids: string[] = [];
  user?: User;

  static deserialize(data: any): TrainingSearchCriteria {
    const sc = new TrainingSearchCriteria();

    if (data.from) {
      sc.from = moment.unix(data.from).startOf('day');
    } else {
      sc.from = moment().subtract(3, 'months').startOf('day');
    }

    if (data.to) {
      sc.to = moment.unix(data.to).endOf('day');
    } else {
      sc.to = moment().endOf('day');
    }

    if (data.productId) sc.productId = Number(data.productId);
    if (Array.isArray(data.users)) {
      sc.userUuids = data.users;
    } else if (typeof data.users === "string") {
      sc.userUuids = [data.users];
    }

    if (data.user) sc.user = User.deserialize(data.user);

    return sc;
  }

  toQueryParams(): string {
    const params = [];

    if (this.limit) params.push(`limit=${this.limit}`);
    if (this.productId) params.push("productId=" + encodeURIComponent(this.productId));
    if (this.deviceId) params.push("deviceId=" + encodeURIComponent(this.deviceId));
    if (this.organizationId) params.push("organizationId=" + encodeURIComponent(this.organizationId));
    if (this.userUuids) {
      for (const id of this.userUuids) {
        params.push("users=" + encodeURIComponent(id));
      }
    }
    params.push(`from=${this.from.unix()}`);
    params.push(`to=${this.to.unix()}`);

    return `?${params.join('&')}`;
  }

  isAboutMeOnly(uuid?: string): boolean {
    if (!uuid && this.user) uuid = this.user.uuid;
    if (!uuid) throw new Error(("I need a UUID for this"));

    //No userid filter is set, so it's about me.
    if (!this.userUuids) return true;
    if (this.userUuids.length === 0) return true;

    //More than 1 userid filter is set, it it's not (just) about me
    if (this.userUuids.length > 1) return false;

    //1 is is set, and it's me. It's about me.
    if (this.userUuids.length === 1 && this.userUuids[0] === uuid) return true;

    return false;
  }

  getDateRangePickerDates(): Date[] {
    //ngx dateragepicker does not like timezones, so here's a workaround.
    return [
      this.from.clone().utc(true).toDate(),
      this.to.clone().utc(true).toDate(),
    ];
  }
}
