import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DocsComponent } from './docs/docs.component';
import { ImportComponent } from './import/import.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { StockComponent } from './stock/stock.component';
import { TrainingComponent } from './training/training.component';
import { UploadComponent } from './upload/upload.component';
import { UserComponent } from './user.component';
import { ImportTrainingDataComponent } from './import-training-data/import-training-data.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { DevicesComponent } from './devices/devices.component';
import { ConsumablesComponent } from './consumables/consumables.component';
import { ProductsComponent } from './products/products.component';
import { ManualsComponent } from './manuals/manuals.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AllDataComponent } from './all-data/all-data.component';
import { ReportsComponent } from './reports/reports.component';
import {TrainingResultsComponent} from './training-results/training-results.component'
import {TransferDataGuideComponent} from "./transfer-data-guide/transfer-data-guide.component";

const routes: Routes = [
  {
    path: '', component: UserComponent, children: [
      {path: '', redirectTo: 'introduction', pathMatch: 'full'},
      {path: 'introduction', component: IntroductionComponent, data: {title: 'Introduction'}},
      {path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard'}},
      {path: 'devices', component: DevicesComponent, data: {title: 'Devices'}},
      {path: 'consumables', component: ConsumablesComponent, data: {title: 'Consumables'}},
      {path: 'products', component: ProductsComponent, data: {title: 'Products'}},
      // {path: 'training', component: TrainingComponent, data: {title: 'Training'}},
      {path: 'training/results', component: TrainingComponent, data: {title: 'Training'}},
      {path: 'training/training-results', component: TrainingResultsComponent, data: {title: 'Training Results'}},
      {path: 'training', loadChildren: () => import('../training-wizard2/training-wizard.module').then
        (m => m.TrainingWizardModule)},
      {path: 'docs', component: DocsComponent, data: {title: 'Documents'}},
      {path: 'manuals', component: ManualsComponent, data: {title: 'Manuals'}},
      {path: 'transfer-data', component: TransferDataGuideComponent, data: {title: 'Transfer Data'}},
      {path: 'settings', component: SettingsComponent, data: {title: 'Organization Settings'}},
      {path: 'notifications', component: NotificationsComponent, data: {title: 'Notifications'}},
      {path: 'all-data', component: AllDataComponent, data: {title: 'All Data'}},
      {path: 'profile', component: ProfileComponent, data: {title: 'Profile'}},
      {path: 'stock', component: StockComponent, data: {title: 'Vial Stock'}},
      {path: 'upload', component: UploadComponent, data: {title: 'Upload'}},
      {path: 'import-training-data', component: ImportTrainingDataComponent, data: {title: 'Import Training Data'}},
      {path: 'import/:id', component: ImportComponent, data: {title: 'Import data', onboarding: 'delayed'}},
      {path: 'reports', component: ReportsComponent, data: {title: 'Reports'}},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {
}
