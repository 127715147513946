import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export class Gazable<T> extends BehaviorSubject<T> {

  constructor(_value: T) {
    super(_value);
  }

  get value(): T {
    return this.getValue();
  }

  set value(v: T) {
    this.next(v);
  }

  //Re-trigger the observable. Useful when some internal property has changed that subs should know about.
  trigger() {
    this.next(this.getValue());
  }

  /**
   * Emits a new value if the given value is different
   *
   * Only triggers when there's a difference between the old and the new value
   *
   * @param {T} v
   * @memberof Gazable
   */
  update(v: T) {
    console.log('check gazable update')
    if (JSON.stringify(v) === JSON.stringify(this.getValue())) {
      this.next(v);
    }
  }
}
