import {Component, OnInit} from '@angular/core'
import {Subscriptions} from '../../../../model/subscriptions'
import {TestKit} from '../../../../model/testKit'
import {StockService} from '../../../services/stock.service'
import {ModalService} from '../../../shared/modal.service'
import {TestKitModalComponent} from '../../../shared/test-kit-modal/test-kit-modal.component'
import {Router} from '@angular/router'
import {BehaviorSubject} from 'rxjs'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {StoreService} from '../../../services/store.service'
import * as moment from 'moment'
import {Organization} from '../../../../model/organization'
import {SelectOption} from '../../../shared/form-control/form-control.component'
import {UserService} from '../../../services/user.service'

@Component({
  selector: 'app-consumables',
  templateUrl: './consumables.component.html',
  styleUrls: ['./consumables.component.scss', './consumables.media.scss'],
})
export class ConsumablesComponent implements OnInit {
  loading = false
  testKits: TestKit[] = []
  private _subs = new Subscriptions()
  prefix = 'stock.'
  organization?: Organization
  showAdvanced = false
  measurementTypes: SelectOption[]
  testKitTypes: any[]
  expiredDate = moment().format('MM/DD/YYYY')
  canCreate = false
  canDelete = false


  constructor(
    private stockService: StockService,
    private modals: ModalService,
    private router: Router,
    private us: UserService
  ) {
    this.formInit()
  }

  formInit() {

  }

  async ngOnInit() {
    this.loading = true
    this._subs.add(this.us.userChanged.subscribe(u => {
      this.canCreate = u.canCreateTestKits()
      this.canDelete = u.canDeleteTestKits()
    }))
    await this.getStock()
    this.loading = false
  }

  async getStock() {
    this.loading = true
    const result = await this.stockService.getStock()
    this.testKits = result.testKits
    this.loading = false
  }

  async orderMore(tk: TestKit) {
    this.router.navigate(['/tickets/new'], {
      queryParams: {
        category: 'order',
        deviceType: tk.type,
        quantity: tk.quantity,
      },
    })
  }


  async openStockModal(addType, testKit?: TestKit) {

    const result = await this.modals.show(TestKitModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        testKit,
        organization: this.organization,
        canDelete: this.canDelete
      }
    })

    if (!result) return

    const kit = this.testKits.find(tk => tk.id === result.id)
    if (kit) {
      //update
      Object.assign(kit, result)
    } else {
      //Add
      this.testKits.push(result)
    }
  }

  onDeleted(id) {
    this.testKits = this.testKits.filter(tk => tk.id !== id)
  }

  onUpdated(testKit: TestKit) {
    const idx = this.testKits.findIndex(tk => tk.id === testKit.id)
    this.testKits[idx] = testKit
  }

  onTestKitCreated(tk: TestKit) {
    this.testKits.push(tk)
  }
}
