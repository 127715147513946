export interface Toast {
  message: string;
  title?: string;
  style?: string;

  delay?: number;
  onClick?: "dismiss"|CallableFunction;
  // Translate Params
  params?: any;
}

export const TOAST_STYLES = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  light: 'light'

};