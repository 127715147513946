import { AnimationBuilder } from '@angular/animations';
import { Directive, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { foldIn, foldOut } from '../../model/shared-animations';

@Directive({
  selector: '[appFoldoutContent]',
  exportAs: 'app-foldout-content',
})
export class FoldoutContentDirective {
  private show = false;
  private hovering = false;

  lastRoute: string;

  private timeoutFunc = null;

  private _watchRoute: string;
  set watchRoute(v: string) {
    this._watchRoute = v;
    this.toggle(this.lastRoute === this._watchRoute);
  }

  constructor(
    private builder: AnimationBuilder,
    private el: ElementRef,
    public router: Router,
  ) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => {
      this.lastRoute = e.url;
      this.toggle(e.url == this._watchRoute);
    });
  }

  animate(shouldShow: boolean) {
    const animation = shouldShow ? foldIn : foldOut;
    const factory = this.builder.build(animation);
    const player = factory.create(this.el.nativeElement);
    player.play();
  }

  shouldKeepOpen() {
    let route = this.router.url;
    if (!this.lastRoute) {
     this.lastRoute = route;
    }
    return route.includes('tickets');
  }

  toggle(shouldShow?: boolean) {
    if (shouldShow === undefined) shouldShow = this.shouldKeepOpen();
    if (shouldShow === this.show) return;

    shouldShow = this.shouldKeepOpen() || this.hovering;

    this.animate(shouldShow);
    this.show = shouldShow;
  }

  mouseOver() {
    clearTimeout(this.timeoutFunc);
    if(this.show) return;

    this.hovering = true;
    this.toggle(true);
  }

  mouseOut() {
    if (!this.hovering || this.lastRoute === this._watchRoute) return;
    this.timeoutFunc = setTimeout(() => {
      //Just in case
      if (!this.hovering || this.lastRoute === this._watchRoute) return;

      this.hovering = false;
      this.toggle(false);
      this.timeoutFunc = null;
    }, 1000);
  }
}
