import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscriptions } from '../../../model/subscriptions';
import { UserService } from '../../services/user.service';
import { User } from '../../../model/user';
import { NavService } from '../../services/nav.service';
import { OnboardingService } from '../../services/onboarding.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {ModalService} from '../modal.service'
import {CreateBugReportModalComponent} from '../create-bug-report-modal/create-bug-report-modal.component'
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component'


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss', './nav-bar.media.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  private _subs = new Subscriptions();

  user: User;
  showAdminButton = false;
  showSupportButton = false;
  userpic: string
  sideState = false

  hideNavBarOnPages = [
    '/introduction'
  ];

  hideNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private us: UserService,
    public nav: NavService,
    public os: OnboardingService,
    public router: Router,
    private modals: ModalService,
  ) { }

  ngOnInit() {
    // Call this manually at first. Because the component isn't initialized before the route resolves on start up.
    this.updateMenuSubject(this.router.url);

    this._subs.add(this.us.userChanged.subscribe(u => {
      this.user = u;
      this.showAdminButton =  u.hasRole('superAdmin')
      this.showSupportButton = !this.showAdminButton && u.hasRole('support')
    }));

    this._subs.add(this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((e:any) => this.updateMenuSubject(e.url)));

    this._subs.add(this.us.userpic.subscribe(up => {
      this.userpic = up
    }))

    this.nav.toggleMenu.subscribe(state => this.sideState = state)
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll();
  }

  toggleMenu() {
    this.sideState = !this.sideState
    this.nav.toggleMenu.next(this.sideState);
  }

  updateMenuSubject(url: string) {
    this.hideNavBar.next(this.hideNavBarOnPages.includes(url));
  }

  async openBugModal() {
    const result = await this.modals.show(CreateBugReportModalComponent, {ignoreBackdropClick: true})
  }
}
