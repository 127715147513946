import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {Subscription} from 'rxjs'

import {fade} from 'src/model/shared-animations'
import {UserService} from 'src/app/services/user.service'
import {StoreService} from 'src/app/services/store.service'
import {User} from 'src/model/user'
import {ModalService} from '../../../shared/modal.service'
import {ToastService} from '../../../services/toast.service'
import {AddOrgToUserComponent} from '../add-org-to-user/add-org-to-user.component'
import {RegisterOrganizationComponent} from '../register-organization/register-organization.component'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [fade]
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('code') orgCodeComponent: AddOrgToUserComponent
  @ViewChild('orgReg') orgRegisterComponent: RegisterOrganizationComponent
  prefix = 'register.'
  private _sub: Subscription
  private _state: registerState = "open"
  get state(): registerState {
    return this._state
  }

  set state(v: registerState) {
    this._state = v
    // "open" | "complete" | "no-org" | "add-org" | "new-org"
    if (v === 'open') {
      this.step = 0
    } else if (v === 'add-org' || v === 'new-org' || v === 'no-org') {
      this.step = 1
    } else if (v === 'complete') {
      this.step = 2
    }
  }

  user: User = null
  userCred = null
  step = 0

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private store: StoreService,
    private modals: ModalService,
    private toasts: ToastService
  ) {
    this.user = this.store.get<User>('registeredUser', null, User)
    this.state = this.store.get<registerState>('registerState', 'open')
  }

  public switchState(state, checkUser = false) {
    if (state === 'open' && this.user) return
    if (checkUser && !this.user || this.state === 'complete') return
    this.state = state
  }

  ngOnInit() {
    this._sub = this.userService.userChanged.subscribe((u) => {
      if (!u) return
      this.router.navigate(['/'])
    })

  }

  ngOnDestroy() {
    localStorage.clear()
    if (this._sub) {
      this._sub.unsubscribe()
      this._sub = undefined
    }
  }

  onOrganizationAdded(user: User) {
    this.store.set('registeredUser', user)
    this.user = user
    this.state = 'complete'
  }

  async onRegistered(data) {
    this.userCred = data.cred
    const localCred = data.cred
    localStorage.setItem('userCred', JSON.stringify(localCred))

    if (data.domainOrg) {
      const user = await this.registerUser()
      this.state = "complete"
      return
    }

    this.state = "new-org"
  }

  async registerUser() {
    if (!this.userCred) {
      console.error('No user data!')
      return
    }
    try {
      const result = await this.userService.register(this.userCred)
      if (result.result !== "ok") {
        console.error("Registration failed", result)
        return
      }
      this.user = result.user
      return result.user
    } catch (res) {
      const reason = res.error.reason || null
    }

  }

  async onOrgRegistered(org) {
    try {
      const orgExist = await this.userService.checkOrgExist(org)
      if (!orgExist) {
        this.toasts.danger("register.org.toasts.invalid-org")
        this.orgRegisterComponent.loading = false
        return
      }
      const user = await this.registerUser()
      if (!this.user) {
        console.error('No user!')
        return
      }
      if (user.organization) {
        this.switchState('complete')
        return
      }
      const result = await this.userService.registerOrg(this.user, org)
      this.user.organizationId = result.id
      this.user.organization = result
      this.store.set('registeredUser', this.user)
      this.orgRegisterComponent.loading = false
      this.state = "complete"
    } catch (err) {
      //todo deal with errors
      this.orgRegisterComponent.loading = false
      console.error(err)
    }
  }

  async onOrgCode(org) {
    try {
      await this.registerUser()
      if (!this.user) {
        console.error('No user!')
        return
      }
      org.uuid = this.user.uuid
      const user = await this.userService.addOrgCode(org)
      this.onOrganizationAdded(user)
      this.orgCodeComponent.loading = false
    } catch (res) {
      if (res.error) {
        const reason = res.error.reason || null
        if (reason === "User already assigned to Organization") {
          this.toasts.danger("register.add-org-to-user.already-assigned")
        } else if (reason === "Organization code incorrect") {
          this.orgCodeComponent.orgCode.setErrors({invalid: true})
        } else {
          console.error("Reason: " + reason, res)
        }
      } else {
        this.toasts.danger("register.add-org-to-user.failed")
        console.error(res)
      }
      this.orgCodeComponent.loading = false
    }
  }

  resetForms() {
    this.router.navigate(['auth/register'])
    this.store.remove('registeredUser')
    this.store.remove('registerState')
    this.state = "open"
    this.user = null
    this.clearStorage()
  }

  public goBack() {
    this.clearStorage()
    this.router.navigateByUrl('/auth/login')
  }

  clearStorage() {
    const userCred = localStorage.getItem('userCred')
    localStorage.clear()
    localStorage.setItem('userCred', userCred)
  }

  async noOrgRegister() {
    // try {
    //   await this.registerUser()
    //   if (!this.user) {
    //     console.error('No user!')
    //     return
    //   }
    //   this.switchState('complete')
    // } catch (e) {
    //   console.log(e)
    // }

    // Temporary solution
    try {
      const user = await this.registerUser()
      if (!this.user) {
        console.error('No user!')
        return
      }
      if (user.organization) {
        this.switchState('complete')
        return
      }
      const result = await this.userService.registerOrg(this.user, {
        name: this.userCred.email + '\'s organization',
        address: this.userCred.city,
        country: this.userCred.city,
        postalCode: '22-638'
      })
      this.user.organizationId = result.id
      this.user.organization = result
      this.store.set('registeredUser', this.user)
      this.state = "complete"
    } catch (err) {
      console.error(err)
    }
  }

  goToUser() {
    this.switchState('open')
  }

  toLogin() {
    this.router.navigate(['/auth', 'login'])
  }
}

type registerState = "open" | "complete" | "no-org" | "add-org" | "new-org";
