import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscriptions} from '../../../../model/subscriptions'
import {Device} from '../../../../model/device'
import {TestKit} from '../../../../model/testKit'
import {StockService} from '../../../services/stock.service'
import {ModalService} from '../../../shared/modal.service'
import {ActivatedRoute, Router} from '@angular/router'
import {CacheService} from '../../../services/cache.service'
import {ControlChartOptions, DeviceControlChartOptions} from '../../../../model/chart-options'
import {SearchCriteria} from '../../../../model/search-criteria'
import {ControlValue} from '../../../../model/control-value'
import {DataService} from '../../../services/data.service'
import {BehaviorSubject} from 'rxjs'
import {FormGroup} from '@angular/forms'
import {StoreService} from '../../../services/store.service'
import {UserService} from '../../../services/user.service'
import {SelectOption} from '../../../shared/form-control/form-control.component'
import {DeviceModalComponent} from '../../../shared/device-modal/device-modal.component'

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss', './devices.media.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy {
  loading = true
  blockingLoader = false
  devices: Device[] = []
  private _subs = new Subscriptions()
  canCreate = false
  canDelete = false
  deviceTypes

  prefix = 'devices.'
  typeOptions = [
    {name: 'iCheck Chroma', value: 'chroma'}
  ]

  selectedDevice

  deviceControlChart: DeviceControlChartOptions = null

  measurementTypes: SelectOption[]
  controlValue: ControlValue
  controlChartLoading = false
  controlChart: ControlChartOptions = null
  controlDataEmpty = false

  deviceSelected: BehaviorSubject<boolean> = new BehaviorSubject(false)

  search: SearchCriteria

  form: FormGroup
  showAdvanced = false


  constructor(
    private stockService: StockService,
    private dataService: DataService,
    private modals: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private cache: CacheService,
    private store: StoreService,
    private ds: DataService,
    private us: UserService
  ) {
  }

  ngOnInit() {
    this.loading = true
    this.deviceTypes = this.cache.deviceTypes.value

    this._subs.add(this.cache.devices.subscribe(d => {
      this.devices = d.filter(dev => dev.active)
    }))

    this._subs.add(this.us.userChanged.subscribe(u => {
      this.canCreate = u.canCreateDevices()
      this.canDelete = u.canDeleteDevices()
    }))


    this.measurementTypes = this.cache.measurementTypes.value.map(({id, name}): SelectOption => ({
      name,
      value: id
    }))

    this.loading = false
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  async orderMore(tk: TestKit) {
    this.router.navigate(['/tickets/new'], {
      queryParams: {
        category: 'order',
        deviceType: tk.type,
        quantity: tk.quantity,
      },
    })
  }


  close() {
    this.showAdvanced = false
  }

  onDeleted(id) {
    this.devices = this.devices.filter(d => d.id !== id)
  }

  async addDevice() {
    const result = await this.modals.show(DeviceModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        newDevice: true,
      }
    })

    console.log('result', result)
  }


  onUpdated(device: Device) {
    const idx = this.devices.findIndex(d => d.id === device.id)
    this.devices[idx] = device
  }
}
