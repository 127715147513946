<div class="password-reset-container">
  <nav class="navbar navbar-expand-md navbar-dark bg-primary">
    <a class="navbar-brand" href="#">{{ 'title' | translate }}</a>
  </nav>

  <!-- Form -->
  <div class="holder" *ngIf="state === 'idle'">
    <div class="card card-register align-middle shadow">
      <h4 class="card-header">{{ 'password-reset.title' | translate }}</h4>
      <div class="card-body">
        <app-password-reset-form (completed)="onComplete()" (error)="onError($event)" [token]="token" mode="token"></app-password-reset-form>
      </div>
    </div>
  </div>


  <!-- Done -->
  <div class="holder" *ngIf="state === 'done'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'password-reset.done' | translate }}</h4>
      <div class="card-body">
        {{ 'password-reset.done-content' | translate }}
      </div>
    </div>
  </div>

  <!-- No token -->
  <div class="holder" *ngIf="state === 'no-token'">
    <div class="card align-middle shadow">
      <h4 class="card-header">{{ 'password-reset.missing-token' | translate }}</h4>
      <div class="card-body">
        {{ 'password-reset.missing-token-content' | translate }}

      </div>
      <div class="card-body">
        <button [routerLink]="['/auth/login']" class="btn btn-block btn-primary"> {{ 'password-reset.login-button' | translate }} </button>
        <button [routerLink]="['/auth/forgot-password']" class="btn btn-block btn-light"> {{ 'password-reset.password-reset-button' | translate }} </button>
      </div>
    </div>
  </div>

</div>
