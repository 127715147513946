import {Component, OnInit, ViewChild, OnDestroy, Input} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {FileUploadStatus, FileUploadComponent} from '../../../shared/file-upload/file-upload.component'
import {Device} from '../../../../model/device'
import {DataService} from '../../../services/data.service'
import {CacheService} from '../../../services/cache.service'
import {Router, ActivatedRoute} from '@angular/router'
import {Import} from '../../../../model/import'
import {ToastService} from '../../../services/toast.service'
import {Subscriptions} from '../../../../model/subscriptions'
import {Organization} from '../../../../model/organization'
import {User} from '../../../../model/user'
import {Product, ProductLine} from '../../../../model/Products'
import {UserService} from '../../../services/user.service'
import {SelectOption} from '../../../shared/form-control/form-control.component'
import {FileUploadSamplesComponent} from '../../../shared/file-upload-samples/file-upload-samples.component'
import { from } from 'rxjs'

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss', './upload.media.scss']
})
export class UploadComponent implements OnInit, OnDestroy {
  prefix = 'upload.'
  private _subs = new Subscriptions()
  @Input() card = true

  @ViewChild('fileUpload') uploader: FileUploadSamplesComponent

  form: FormGroup
  devices: Device[] = []
  busy = false
  selectedUser: User
  showSelectedUserDevices = false
  showSelectedUserProductLines = false
  organization: Organization
  organizations: Organization[] = []
  productLines: ProductLine[] = []
  selectedProduct: Product
  user: User
  isTester = false
  deviceSelectOptions: SelectOption[]
  userDeviceOptions: SelectOption[]
  loading = true


  constructor(
    private ds: DataService,
    private cache: CacheService,
    private router: Router,
    private route: ActivatedRoute,
    private toasts: ToastService,
    private us: UserService
  ) {
    this.form = new FormGroup({
      fileReference: new FormControl(''),
      hasFile: new FormControl(false, [Validators.requiredTrue]),
      deviceId: new FormControl(null, [Validators.required]),
      productId: new FormControl(''),
      sendByUser: new FormControl(false),
      useHashes: new FormControl(true)
    })

  }

  async ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe(u => {
      this.user = u
      this.isTester = this.user.hasRole('tester')
    }))
    this._subs.add(this.cache.organization.subscribe(o => {
      this.organization = o
      if (o.status === 'parent' && this.user.canUploadInstead()) {
        from(this.ds.getOrgWithUsersByHierarchyId(this.organization.hierarchyId)).subscribe(orgs => {
          this.organizations = orgs.filter(organization => organization.id !== this.organization.id)
        })
      }
    }))

    this._subs.add(this.cache.devices.subscribe(d => {
      this.devices = d.filter(dev => dev.active)
      this.deviceSelectOptions = this.getDeviceSelectOptions(this.devices)
      // if (this.devices[0] && !this.form.value.deviceId) this.form.get('deviceId').setValue(this.devices[0].id)
    }))
    this._subs.add(this.cache.devices.subscribe(d => {
      this.devices = d.filter(dev => dev.active)
      if (!this.devices.length) {
        this.toasts.danger('training.no-device')
        this.router.navigate(['/devices'])
      }
    }))
    this.loading = false
  }

  getDeviceId() {
    return this.form.controls.deviceId.value
  }

  async ngOnDestroy() {
    if (this._subs) {
      this._subs.unsubscribeAll()
      this._subs = undefined
    }
  }

  uploadProgress(e: FileUploadStatus) {
    this.form.get('hasFile').setValue(e.fileSelected)
  }

  async selectUser(u: User) {
    this.loading = true
    this.selectedUser = u
    const devices = await this.ds.getDevicesByOrgId(u.organizationId)
    this.userDeviceOptions = this.getDeviceSelectOptions(devices)
    this.productLines = await this.ds.getProductLinesByOrgId(u.organizationId)
    this.showSelectedUserDevices = true
    this.showSelectedUserProductLines = true
    this.selectedProduct = null
    this.loading = false
  }

  selectProduct(p) {
    this.selectedProduct = p
    console.log('p', p)
    console.log('this.selectedProduct', this.selectedProduct)
    this.form.get('productId').setValue(p.id)
  }

  async submit() {
    if (this.form.invalid) return
    this.busy = true

    const uploadResult = await this.uploader.upload(false, false)
    if (!uploadResult.i) {
      console.error("Error uploading file.")
      this.busy = false
      return
    }
    // this.form.get('fileReference').setValue(uploadResult.reference)
    const i: Import = uploadResult.i

    try {
      const data = this.form.value
      if (this.selectedUser) {
        data.uuid = this.selectedUser.uuid
        data.orgId = this.selectedUser.organizationId
      }
      // const i: Import = await this.ds.processCsv(this.form.value)
      this.busy = false
      const params = this.route.snapshot.queryParams
      if (params.mode === "training") {
        await this.router.navigate(['import-training-data'], {
          queryParams: {
            importId: i.id,
            productId: params.productId,
            deviceId: this.form.get('deviceId').value
          }
        })
      } else if (params.mode === "training-wizard3") {
        await this.router.navigate(['../import'], {
          relativeTo: this.route,
          queryParams: {importId: i.id, productId: params.productId, deviceId: this.form.get('deviceId').value}
        })
      } else {
        await this.router.navigate(['import', i.id], {
          queryParams: {
            organizationId: data.orgId,
            productId: data.productId,
            deviceId: this.form.get('deviceId').value
          }
        })
      }
    } catch (e) {
      this.busy = false
    }
  }

  validateUploadButton() {
    if (this.form.get('sendByUser').value) {
      // console.log('this.form.valid', this.form.valid, this.form)
      // console.log('!this.selectedUser', !this.selectedUser)
      // console.log('!this.selectedProduct', !this.selectedProduct)
      return !this.form.valid || !this.selectedUser || !this.selectedProduct
    } else {
      // console.log('deviceId', !this.form.get('deviceId').value)
      // console.log('hasFile', !this.form.get('hasFile').value)
      return !this.form.get('deviceId').value || !this.form.get('hasFile').value
    }
  }

  getDeviceSelectOptions(devices) {
    return devices.map(device => {
      return {
        name: `${device.type} ${device.lot}`,
        value: device.id
      }
    })
  }
}
