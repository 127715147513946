import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Product, ProductLine} from '../../../model/Products'

@Component({
  selector: 'app-dropdown-products-product-lines',
  templateUrl: './dropdown-products-product-lines.component.html',
  styleUrls: ['./dropdown-products-product-lines.component.scss']
})
export class DropdownProductsProductLinesComponent implements OnInit {
  prefix = 'dropdowns.products-product-lines.'
  @Input() productLines: ProductLine[]
  @Input() showLabel = false
  @Input() menuMaxHeight = '400px'
  @Output() productSelected: EventEmitter<Product> = new EventEmitter<Product>()
  selectedProduct: Product

  constructor() { }

  ngOnInit(): void {
    console.log('INSIDE', this.productLines)
  }

  selectProduct(p: Product) {
    this.selectedProduct = p
    this.productSelected.emit(p)
  }
}
