<div class="content-container">
  <a routerLink="/training/welcome" class="go-back" *ngIf="!busy">
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3932 20.7902L0.587448 10.9837C0.447192 10.8436 0.376954 10.6825 0.376954 10.5001C0.376954 10.3178 0.447192 10.1563 0.587448 10.0161L10.3932 0.210494C10.5337 0.070017 10.6949 -4.5101e-07 10.8773 -4.58984e-07C11.0596 -4.66954e-07 11.221 0.0702381 11.3612 0.210494L12.413 1.26238C12.5533 1.40263 12.6234 1.56404 12.6234 1.74638C12.6234 1.92872 12.5533 2.09013 12.413 2.23039L4.14345 10.5001L12.4135 18.7701C12.5538 18.9103 12.6234 19.0718 12.6234 19.2538C12.6234 19.4365 12.5534 19.5979 12.4135 19.7381L11.3613 20.7901C11.2211 20.9303 11.0596 20.9999 10.8774 20.9999C10.6949 21 10.5335 20.9304 10.3932 20.7902Z"
        fill="#CDCDCD"/>
    </svg>
    <p class="text-dark ">Training</p>
  </a>

  <div class="content" *ngIf="!busy" [ngStyle]="{'align-items': anyData ? 'flex-start' : 'center'}">

    <div class="data" *ngIf="anyData; else empty">
      <p class="title">{{prefix + 'title' | translate}}</p>
      <p class="sub-title">{{prefix + 'sub-title' | translate}}</p>
      <div class="score" *ngIf="selectedAttempt.precisionScore == 'good'">
        <div class="text-container">
          <p class="score-title">{{prefix + 'score.success.title' | translate}}</p>
          <p class="score-sub-title"
             [innerHTML]="prefix + 'score.success.sub-title' | translate: { value: selectedAttempt.cv }"></p>
        </div>

        <div class="buttons">
          <a routerLink="/training/type" class="outline-button">
            <p>{{prefix + 'score.success.again-btn' | translate }}</p>
          </a>

          <a routerLink="/dashboard" class="filled-button">
            <p>{{prefix + 'score.success.dashboard-btn' | translate }}</p>
            <svg class="arrow" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538407 1.31946 0.538407 0.928933 0.928932C0.538408 1.31946 0.538408 1.95262 0.928933 2.34315L6.58579 8L0.928932 13.6569C0.538407 14.0474 0.538407 14.6805 0.928931 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM6 9L8 9L8 7L6 7L6 9Z"
                fill="#0BA69B"/>
            </svg>
          </a>
        </div>
      </div>

      <div class="score fail" *ngIf="selectedAttempt.precisionScore == 'poor'">
        <div class="text-container">
          <p class="score-title">{{prefix + 'score.fail.title' | translate}}</p>
          <p class="score-sub-title"
             [innerHTML]="prefix + 'score.fail.sub-title' | translate: { value: selectedAttempt.cv }"></p>
        </div>

        <div class="buttons">
          <a routerLink="/training/type" class="outline-button">
            <p>{{prefix + 'score.fail.repeat-btn' | translate }}</p>
          </a>

          <a routerLink="/dashboard" class="filled-button">
            <p>{{prefix + 'score.fail.dashboard-btn' | translate }}</p>
            <svg class="arrow" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538407 1.31946 0.538407 0.928933 0.928932C0.538408 1.31946 0.538408 1.95262 0.928933 2.34315L6.58579 8L0.928932 13.6569C0.538407 14.0474 0.538407 14.6805 0.928931 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM6 9L8 9L8 7L6 7L6 9Z"
                fill="#0BA69B"/>
            </svg>
          </a>
        </div>
      </div>

      <div class="score fail" *ngIf="selectedAttempt.precisionScore == 'meh'">
        <div class="text-container">
          <p class="score-title">{{prefix + 'score.meh.title' | translate}}</p>
          <p class="score-sub-title"
             [innerHTML]="prefix + 'score.meh.sub-title' | translate: { value: selectedAttempt.cv }"></p>
        </div>

        <div class="buttons">
          <a routerLink="/training/type" class="outline-button">
            <p>{{prefix + 'score.meh.repeat-btn' | translate }}</p>
          </a>

          <a routerLink="/dashboard" class="filled-button">
            <p>{{prefix + 'score.meh.dashboard-btn' | translate }}</p>
            <svg class="arrow" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538407 1.31946 0.538407 0.928933 0.928932C0.538408 1.31946 0.538408 1.95262 0.928933 2.34315L6.58579 8L0.928932 13.6569C0.538407 14.0474 0.538407 14.6805 0.928931 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM6 9L8 9L8 7L6 7L6 9Z"
                fill="#0BA69B"/>
            </svg>
          </a>
        </div>
      </div>

      <div class="chart-container">
        <div class="chart-background position-relative">
          <div class="attempts-btns-container">
            <div class="btn-group" role="group" aria-label="Choose Attempt">

              <div class="special-pointer text-center btn" *ngFor="let a of attempts; let i = index"
                   [style.z-index]="999 - i"
                   [class.selected]="a === selectedAttempt" (click)="selectedAttempt = a; ">
                <span>{{ prefix + 'attempt' | translate : {index: i + 1} }}</span>
              </div>
            </div>
          </div>
          <div class="chart">
            <app-chart [translateParams]="{ name: '' }"
                       [options]="selectedAttempt?.controlChart"></app-chart>
          </div>
        </div>

        <div class="table-container">
          <p class="table-title">{{prefix + 'table-title' | translate}}</p>

          <div class="table">
            <app-training-data-table [showNames]="!viewingMyselfOnly" [attempts]="attempts"></app-training-data-table>
          </div>
        </div>

        <div class="chart-background position-relative mt-30">
          <div class="chart">
            <app-chart [translateParams]="{ name: '' }"
                       [options]="selectedAttempt?.usageChart"></app-chart>
          </div>
        </div>
      </div>
    </div>

    <ng-template #empty>
      <div class="no-data">
        <p class="title">{{prefix + 'no-data.title' | translate }}</p>

        <div class="text-container">
          <img class="book" src="../../../../assets/icons/book.svg" alt="book">
          <div class="text">
            <p class="sub-title">{{prefix + 'no-data.sub-title' | translate }}</p>
            <p class="description" [innerHTML]="prefix + 'no-data.description' | translate"></p>
          </div>
        </div>

        <div class="buttons">
          <a class="again-btn" routerLink="/training/type">
            <p>{{prefix + 'no-data.again-btn' | translate }}</p>
          </a>
          <a class="dashboard-btn" routerLink="/dashboard">
            <p>{{prefix + 'no-data.dashboard-btn' | translate }}</p>
            <svg class="arrow" width="12" height="20" viewBox="0 0 12 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8839 10.8839C11.372 10.3957 11.372 9.60427 10.8839 9.11612L2.92893 1.16116C2.44078 0.673009 1.64932 0.673009 1.16117 1.16116C0.673011 1.64932 0.673011 2.44078 1.16117 2.92893L8.23223 10L1.16116 17.0711C0.673009 17.5592 0.673009 18.3507 1.16116 18.8388C1.64932 19.327 2.44078 19.327 2.92893 18.8388L10.8839 10.8839ZM8 11.25L10 11.25L10 8.75L8 8.75L8 11.25Z"
                fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </ng-template>

  </div>

  <div class="loader-container" *ngIf="busy">
    <app-dots-loader></app-dots-loader>
  </div>
</div>
