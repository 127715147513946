<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <p [innerHtml]="prefix + 'content' | translate"></p>
  <div class="row">
    <div class="col">
      <button class="btn btn-block btn-primary" [routerLink]="['/training/import']"
        [queryParams]="{mode: 'training', productId: productId}" (click)="modal.hide()">
        <i class="fa fa-upload fa-fw"></i>
        {{ prefix + 'new' | translate }}
      </button>
    </div>
    <div class="col">
      <button class="btn btn-block btn-primary" [routerLink]="['/training/choose-samples']"
        [queryParams]="{productId: productId}" (click)="modal.hide()">
        <i class="fa fa-list fa-fw"></i>
        {{ prefix + 'existing' | translate }}
      </button>
    </div>
  </div>
</div>
