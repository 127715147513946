import { Injectable } from '@angular/core';
import { TicketsPage, Ticket } from '../../model/ticket';
import { ApiService } from './api.service';
import { TicketSearchCriteria } from '../../model/search-criteria';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private api: ApiService,
  ) { }

  async getTickets(page = 0): Promise<TicketsPage> {
    const result = await this.api.get(`u/tickets?page=${page}`);
    result.tickets = result.tickets.map((t: any) => Ticket.deserialize(t));

    return result;
  }

  async getTicket(uuid: string): Promise<Ticket> {
    const result = await this.api.get(`support/ticket/${uuid}`);
    const ticket = Ticket.deserialize(result.ticket);

    return ticket;
  }

  async getSupportTickets(search: TicketSearchCriteria, page = 0, includeMessages = false): Promise<TicketsPage> {
    const includeParam = includeMessages ? '&includeMessages=1' : '';
    const result = await this.api.get(`support/tickets${search.toQueryParams()}&page=${page}${includeParam}`);
    result.tickets = result.tickets.map((t: any) => Ticket.deserialize(t));

    return result;
  }

  async newTicket(formData: any): Promise<Ticket> {
    const result = await this.api.post('u/ticket', formData);
    return Ticket.deserialize(result.ticket);
  }

  async reply(formData: any): Promise<Ticket> {
    const result = await this.api.post('u/message', formData);
    return Ticket.deserialize(result.ticket);
  }

  async closeTicket(ticketUuid: string): Promise<void> {
    const body = {
      uuid: ticketUuid,
      status: 'closed'
    };

    await this.api.put(`u/ticket`, body);
  }

  async reportBug(fd) {
    return await this.api.post('u/report-bug', fd)
  }

  async closeBug(id) {
    return await this.api.put('u/close-bug', {id})
  }

  async getBugs() {
    return await this.api.get('u/bug-reports')
  }
}
