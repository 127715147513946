import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-test',
  templateUrl: './layout-test.component.html',
  styleUrls: ['./layout-test.component.scss']
})
export class LayoutTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
