import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {
  state: 'done' | 'idle' = "idle";
  loading = false;
  triedSubmit = false;
  form: FormGroup;

  get emailControl() { return this.form.get('email'); }

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toasts: ToastService,

  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, this.emailValidator], this.checkEmailExist(this))
    });

    if (this.route.snapshot.queryParamMap.has('email')) {
      this.emailControl.setValue(this.route.snapshot.queryParamMap.get('email'));
    }
  }

  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/);
      return matches ? null : {invalidEmail: true};
    } else {
      return null;
    }
  }

  checkEmailExist(context: RequestPasswordResetComponent) {
    return (control: AbstractControl) => {
      return context.userService.checkUserExist(control.value)
        .then(result => {
          return result ? null : {emailNotExist: true}
        })
    }
  }

  async submit() {
    this.triedSubmit = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) return;
    this.loading = true;

    try {
      await this.userService.requestPasswordReset(this.emailControl.value);
      this.loading = false;
      this.state = 'done';
    } catch (res) {
      this.loading = false;
      this.toasts.warning("request-password-reset.error-toast");
    }

  }

}
