import * as moment from 'moment'

import {StockChartData, UsageChartData, ControlChartData, TrainingUsageChartData} from './api-response'
import {ControlValue} from './control-value'
import {SearchCriteria} from './search-criteria'

export interface Series {
  name: string;
  data: (number | DataPoint)[];
  showInLegend?: boolean;
  color?: any;
  dashStyle?: string;
  marker?: { enabled?: boolean, states: any };
  states?: any;
  lineWidth?: number;
  tooltip?: any;
  innerSize?: string;
  maxPointWidth?: number;
  fillOpacity?: number;
  fillColor?: any,
  enableMouseTracking?: boolean,
  zIndex?: number
}

export interface DataPoint {
  x?: number;
  y: number;
  name: string | number;
  sampleId?: string;
  batchId?: string;
  user?: string;
  color?: string;
}

export class ControlChartOptions {
  //private pointFormatDefault = '<tr><td style="color: {series.color}">{series.name}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>';

  private hasControlSeries = false

  unit = ""


  styledMode?: boolean
  title?: {
    text?: string;
    align?: string;
    verticalAlign?: string;
  }
  subtitle?: {
    text?: string;
    align?: string;
  }
  yAxis = {
    title: {
      text: ''
    }
  }
  xAxis = {
    title: {
      text: ''
    }
  }

  tooltip = {
    shared: true,
    useHTML: true,
    valueDecimals: 2,
    formatter() {
      const series = this.points[0].series
      const point = this.points[0]
      const w: any = window

      const header = `<div style="text-align: left; margin-bottom: 4px;"><div>${point.key}</div> <div>${point.point.batchId || ""} ${point.point.sampleId || ""}</div><div>${point.point.user}</div></div><table>`
      const body = `<tr><td>${series.name}: </td><td style="text-align: right"><b>${point.y}</b> ${w.__controlChartUnit}</td></tr>`
      const footer = "</table>"

      return `${header}${body}${footer}`
    }
  }

  legend = {
    style: {'font-weight': 'normal'},
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    labelFormatter() {
      //Super hacky. Not proud of this, but I know of no easier way.
      const skipValue = this.name[0] === "±"
      let v = ""
      if (this.index > 0 && !skipValue) {
        const w: any = window
        v = `${this.userOptions.data[0]} ${w.__controlChartUnit} `
      }

      return `${v}${this.name}`
    }
  }

  plotOptions: any = {
    series: {
      pointPlacement: 'on',
      label: {connectorAllowed: false},
      lineWidth: 0,
      pointStart: 1,
      marker: {radius: 6, enabledThreshold: 0},
      states: {
        hover: {
          lineWidthPlus: 0,
        }
      },
      color: {
        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
        stops: [
          [0, '#06A3AE'],
          [1, '#008C96']
        ]
      }
    }
  }

  series: Series[] = []
  responsive?: any
  credits = {enabled: false}

  constructor(options?: any) {
    if (options) {
      Object.assign(this, options)
    }
  }

  setUnit(unit: string) {
    this.unit = unit
    //todo fix this
    //this.tooltip.pointFormat = `<tr><td style="color: {series.color}">{series.name}: </td><td style="text-align: right"><b>{point.y} ${unit}</b></td></tr>`;
    //super ugly workaround to get it to show up in the Highchart Legend
    const w: any = window
    w.__controlChartUnit = unit
  }

  addControlSeries(values: iControlSeries) {
    console.log("this.series", this.series)
    if (this.series.length === 0) {
      console.error("Can't add control series without at least one real series.")
    }
    if (this.hasControlSeries) {
      console.warn("Already created control series. Not doing it again.")
    }

    const len = this.getSeriesLength()

    for (const series of values.options) {
      if (series.value) this.series.push(this.createControlSeries(series, len))
    }

    this.hasControlSeries = true
  }

  private createControlSeries(cv: ControlSeriesOptions, length: number): Series {
    const s: Series = {
      name: cv.name,
      data: [],
      color: cv.color,
      dashStyle: cv.dashStyle,
      marker: {enabled: false, states: {hover: {enabled: false}}},
      lineWidth: 2,
      showInLegend: cv.showInLegend
    }

    for (let i = 0; i < length; i++) {
      s.data.push(cv.value)
    }

    return s
  }

  private getSeriesLength(): number {
    let highest = 0
    for (const s of this.series) {
      if (s.data.length > highest) highest = s.data.length
    }

    return highest
  }

}

export class DeviceControlChartOptions {

  chart: {
    type: 'scatter',
    zoomType: 'xy'
  }

  unit = ""

  styledMode?: boolean
  title?: {
    text?: string;
    align?: string;
    verticalAlign?: string;
    enabled: false,
  }
  subtitle?: {
    text?: string;
    align?: string;
  }
  yAxis = []
  xAxis = {
    title: {
      text: ''
    }
  }
  legend: {
    enabled: false
  }
  tooltip: {
    enabled: false,
  }

  plotOptions: any = {
    series: {
      label: {connectorAllowed: false},
      lineWidth: 0,
      pointStart: 1,
      marker: {radius: 6, enabledThreshold: 0},
      states: {
        hover: {
          lineWidthPlus: 0,
        }
      }
    }
  }

  series: Series[] = []
  responsive?: any
  credits = {enabled: false}

  constructor(options?: any) {
    if (options.xAxis.length > 1) {
      options.xAxis.map((xAxis, index) => {
        xAxis.gridLineWidth = 0
        xAxis.lineWidth = 0
        xAxis.minorGridLineWidth = 0
        xAxis.lineColor = 'transparent'

        if (xAxis.id === 'x2') {
          xAxis.left = 450
          xAxis.opposite = true
        } else if (xAxis.id === 'x3') {
          xAxis.left = 450
          xAxis.offset = 22
        }
      })
    }

    if (options) {
      Object.assign(this, options)
    }
  }

  addControlSeries(devices) {
    console.log(devices)

    if (devices.length === 0) {
      return console.error('No Devices Found')
    }
    // let minRange = 0;
    // let maxRange = 0;
    // let maxStd = 0;
    // let minStd = 0;
    // devices.sort((a,b) => a.maxRange > b.maxRange);
    // maxRange = devices[0].maxRange;
    // devices.sort((a,b) => a.minRange > b.minRange).reverse();
    // minRange = devices[0].minRange;
    //
    // devices.map((d) => {
    //   maxStd += d.maxRange;
    //   minStd += d.minRange;
    // });
    //
    // maxStd = maxStd / devices.length;
    // minStd = minStd / devices.length;
    //
    // console.log(minRange, maxRange);

    devices.map((d) => {

    })
  }

}

export interface iControlSeries {
  options: ControlSeriesOptions[];
}

export class DefaultControlSeries implements iControlSeries {
  static WARN_COLOR = '#FFCE67'
  static LIMIT_COLOR = '#E98DA8'
  static STANDARD_COLOR = '#52ABB3'
  static LIGHT_GREEN = '#56CC9D'

  maxLimit: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.LIMIT_COLOR,
    dashStyle: 'solid',
    name: 'charts.control-chart.max-limit'
  }
  maxWarn: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.LIMIT_COLOR,
    dashStyle: 'DashDot',
    name: 'charts.control-chart.max-warn'
  }
  minWarn: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.LIMIT_COLOR,
    dashStyle: 'DashDot',
    name: 'charts.control-chart.min-warn'
  }
  minLimit: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.LIMIT_COLOR,
    dashStyle: 'solid',
    name: 'charts.control-chart.min-limit'
  }
  nationalStandard: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.LIGHT_GREEN,
    dashStyle: 'solid',
    name: 'charts.control-chart.national-standard'
  }
  target: ControlSeriesOptions = {
    value: 0,
    color: DefaultControlSeries.STANDARD_COLOR,
    dashStyle: 'DashDot',
    name: 'charts.control-chart.target'
  }

  get options() {
    return [
      this.target,
      this.nationalStandard,
      this.maxLimit,
      this.maxWarn,
      this.minWarn,
      this.minLimit,
    ].filter(sco => sco.value)
  }

  constructor(controls?: ControlValue) {
    this.maxLimit.value = controls ? controls.maxLimit : 10
    this.maxWarn.value = controls ? controls.maxWarn : 10
    this.minWarn.value = controls ? controls.minWarn : 10
    this.minLimit.value = controls ? controls.minLimit : 10
    this.nationalStandard.value = controls ? controls.nationalStandard : 10
    this.target.value = controls ? controls.target : 10
  }
}

export interface ControlSeriesOptions {
  name: string;
  value: number;
  color: any;
  dashStyle: string;
  showInLegend?: boolean;
  hidePointValueInLegend?: boolean;
  enableMouseTracking?: boolean
  zIndex?: number;
}

export class ToleranceChartOptions {
  credits = {enabled: false}
  chart = {
    type: 'pie',
  }
  colors = ['#0399A3', '#C6CFD0', '#F3AA4B', '#910000', '#1aadce',
    '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
  title = {
    text: null,
    align: 'left',
  }
  legend = {
    align: 'right',
    layout: 'vertical',
    verticalAlign: 'middle',
    width: '30%',
    itemMarginTop: 8,
    itemMarginBottom: 8,
    itemStyle: {
      color: "#333333",
      cursor: "pointer",
      fontSize: "10px",
      fontWeight: "bold",
      textOverflow: "wrap"
    }
  }
  plotOptions = {
    pie: {
      allowPointSelect: true,
      showInLegend: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        distance: -5,
        shape: 'circle',
        borderRadius: 10,
        borderWidth: 10,
        position: 'center',
        shadow: true,
        padding: 5,
        format: '{percentage:.0f}%',
        backgroundColor: '#fff',
      }
    }
  }
  series: Series[] = []


  constructor(data: ControlChartData) {
    let within = 0
    let tooHigh = 0
    let tooLow = 0

    const upperLimit = data.controlValue.maxLimit
    const lowerLimit = data.controlValue.minLimit

    // console.log(lowerLimit, upperLimit);
    for (const d of data.series[0].data) {
      const dp: any = d
      if (dp.y > upperLimit) {
        tooHigh++
      } else if (dp.y < lowerLimit) {
        tooLow++
      } else {
        within++
      }
    }

    const s: Series = {
      name: 'Tolerances',
      innerSize: '40%',
      data: [
        {name: 'Within the tolerance limits', y: within},
        {name: 'Above Upper Limit', y: tooHigh},
        {name: 'Below Lower Limit', y: tooLow},
      ]
    }

    this.series = [s]

    // console.log(s);
  }
}

export class trainingChartOptions {
  get hasData() {
    return true
  }

  attempts: any[] = []

  constructor(data: any, search: SearchCriteria) {
    const labels = Object.keys(data)
    for (const label of labels) {
      const labelData = data[label]
      if (labelData.data.length) {
        this.attempts.push(labelData)
      }
    }
  }
}

export class usageChartOptions {
  get hasData() {
    return this.series.length > 0
  }

  chart = {type: 'areaspline'}
  title = {text: null, align: 'left',}
  subtitle = {text: ''}
  series: Series[] = []
  credits = {enabled: false}
  plotOptions = {
    series: {fillOpacity: 0.1}
  }

  xAxis = {categories: [], title: {text: ''}}
  yAxis = {
    min: 0,
    title: {text: 'charts.usage-chart.y-title'},
  }
  tooltip = {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}:<br> Used {point.y} times'
  }

  constructor(data: UsageChartData, search: SearchCriteria) {


    this.series = data.series
    this.series.forEach(s => {
      s.color = '#06A3AEFF'
      s.fillColor = {
        linearGradient: {
          x1: 1,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, 'rgba(6,163,174,0.6)'],
          [1, 'rgba(0,140,150,0.02)'],
        ]
      }
    })
    this.setLabels(data)
    this.subtitle.text = `${search.from.format('DD.MM.YYYY')} - ${search.to.format('DD.MM.YYYY')}`
    this.xAxis.title.text = 'charts.usage-chart.x-title.' + data.interval
  }

  private setLabels(data: UsageChartData) {
    const dates = data.labels.map(t => moment(t))
    const interval = data.interval

    const labels = []
    for (const d of dates) {
      if (interval === 'day') {
        labels.push(d.format('DD.MM.YYYY'))
      } else if (interval === 'week') {
        labels.push(`${d.format('DD.MM')} - ${d.clone().add(1, 'week').format("DD.MM")}`)
      } else {
        labels.push(d.format('MMMM YYYY'))
      }
    }

    this.xAxis.categories = labels
  }
}

export class StockChartOptions {
  get hasData() {
    return this.xAxis.categories.length > 0
  }

  chart = {type: 'bar'}
  title = {text: 'charts.stock-chart.title', align: 'left',}
  xAxis = {categories: [], title: {text: ''}}
  yAxis = {
    min: 0,
    title: {text: 'charts.stock-chart.y-title', position3d: ''},
  }
  tooltip = {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  }
  plotOptions = {
    series: {stacking: 'normal',}
  }

  legend = {reversed: true}
  colors = ['#FFCE67', '#56CC9D']
  series: Series[] = []
  credits = {enabled: false}

  constructor(data: StockChartData) {
    this.series = data.series.map(s => {
      s.name = 'charts.stock-chart.' + s.name
      s.maxPointWidth = 100
      return s
    })
    this.xAxis.categories = data.labels.map(l => 'charts.stock-chart.devices.' + l)
  }
}

export class TrainingUsageChartOptions {
  get hasData() {
    return this.series.length > 0
  }

  private hasControlSeries = false
  title = {text: 'charts.control-chart.training-device-usage.title', align: 'left',}
  subtitle = {text: ''}
  series: Series[] = []
  controlSeries: ControlSeriesOptions[] = []
  credits = {enabled: false}

  plotOptions = {
    series: {
      pointPlacement: 'on',
      label: {connectorAllowed: false},
      lineWidth: 0,
      pointStart: 1,
      marker: {radius: 6, enabledThreshold: 0, symbol: 'circle'},
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          opacity: 1
        }
      }
    },
  }

  xAxis = {
    categories: [],
    title: {text: ''},
    labels: {
      enabled: false
    }
  }
  yAxis = {
    min: 0,
    title: {text: ''}
  }
  tooltip = {
    useHTML: true,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    formatter() {
      return `
      <div style="background-color: ${this.series.color}; border-radius: 5px; padding: 5px 5px;position:relative;">
        <p style="margin: 0;font-size: 8px; color: #ffffff;">${this.point.name}</p>
        <p style="margin: 0;text-align: center; font-size: 10px; color: #ffffff;">Target: ${this.y}</p>
      </div>
      `
    }
  }

  constructor(data: TrainingUsageChartData) {
    if (data.seriesGroup.within) {
      this.series.push({
        name: 'Within usage',
        data: data.seriesGroup.within.data,
        color: '#008C96',
      })
    }
    if (data.seriesGroup.beyond) {
      this.series.push({
        name: 'Beyond usage',
        data: data.seriesGroup.beyond.data,
        color: '#ED7E59',
      })
    }
    // this.setLabels(data)
    // this.xAxis.title.text = 'charts.usage-chart.x-title.' + data.interval
    this.yAxis.title.text = 'charts.training-usage-chart.y-title.' + data.deviceRanges.deviceType


  }

  private setLabels(data: TrainingUsageChartData) {
    const dates = data.labels.map(t => moment(t))

    const labels = []
    for (const d of dates) {
      labels.push(d.format('DD.MM.YYYY'))
    }

    this.xAxis.categories = labels
  }

  addControlSeries(values: iControlSeries) {
    if (this.series.length === 0) {
      console.error("Can't add control series without at least one real series.")
    }
    if (this.hasControlSeries) {
      console.warn("Already created control series. Not doing it again.")
    }

    const len = this.getSeriesLength()

    for (const series of values.options) {
      if (series.value) this.series.push(this.createControlSeries(series, len))
    }

    this.hasControlSeries = true
  }

  private getSeriesLength(): number {
    let highest = 0
    for (const s of this.series) {
      if (s.data.length > highest) highest = s.data.length
    }

    return highest
  }

  private createControlSeries(cv: ControlSeriesOptions, length: number): Series {
    const s: Series = {
      name: cv.name,
      data: [],
      color: cv.color,
      dashStyle: cv.dashStyle,
      marker: {enabled: false, states: {hover: {enabled: false}}},
      lineWidth: 2,
      showInLegend: cv.showInLegend,
      enableMouseTracking: cv.enableMouseTracking
    }

    for (let i = 0; i < length; i++) {
      s.data.push(cv.value)
    }

    return s
  }
}
