
import {Moment} from 'moment'
import {toObject} from './helpers'
import {Session} from './session'
import {Organization} from './organization'
import * as moment from 'moment'
import {UserSearchCriteria} from './search-criteria'
import {Permissions} from './Permissions'

export class User {

  get status() {
    if (!this.active || !this.approved || !this.organizationId) return "issue"

    //Email not confirmed on time?
    if (this.emailConfirmed === null && this.emailConfirmTokenExpires && this.emailConfirmTokenExpires.isBefore()) return "issue"

    //Email not confirmed, but not locked out yet
    if (this.emailConfirmed === null && this.emailConfirmTokenExpires && this.emailConfirmTokenExpires.isAfter()) return "attention"

    return "ok"
  }

  get statusMessages(): string[] {
    const m = []
    if (!this.active) m.push("user.not-active")
    if (!this.approved) m.push("user.not-approved")
    if (!this.organizationId) m.push("user.no-organization")
    if (!this.emailConfirmed) m.push("user.email-not-confirmed")
    return m
  }

  get statusIcon() {
    const status = this.status
    if (status === "attention") {
      return "fa-exclamation text-warning"
    }
    if (status === "issue") {
      return "fa-times text-danger"
    }

    return 'fa-user'
  }

  id?: number
  uuid: string = null
  username: string = null
  firstName?: string = null
  lastName?: string = null
  email: string = null
  emailConfirmed?: Moment = null
  emailConfirmToken?: string = null
  emailConfirmTokenExpires?: Moment = null
  session?: Session = null
  city: string = null
  phone: string = null
  created: Moment = null
  lastActivity: Moment = null
  active: boolean = null
  approved?: boolean = null
  organization?: Organization = null
  organizationId?: number = null
  profileImageUrl?: string = null
  disableTips: boolean = null
  tipsShown: string[] = []
  selected?: boolean
  disabled?: boolean
  permissions?: Permissions
  hierarchyPermissions?: string[]
  sharing: boolean
  receiving: boolean
  sharingId: number
  receivingId: number
  customId: string = null

  memberships: Membership[] = []

  // getProfileRole(): string {
  //   const rolePrio = ['user', 'manager', 'support', 'admin']
  //   let highestRole = ''
  //   rolePrio.map((r) => {
  //     if (this.hasRole(r)) {
  //       highestRole = r
  //     }
  //   })
  //   return highestRole
  // }

  static deserialize(data: any): User {
    const u = toObject<User>(User, data)

    if (data.created) u.created = moment.unix(data.created)
    if (data.lastActivity) u.lastActivity = moment.unix(data.lastActivity)
    if (data.emailConfirmTokenExpires) u.emailConfirmTokenExpires = moment.unix(data.emailConfirmTokenExpires)
    if (data.emailConfirmed) u.emailConfirmed = moment.unix(data.emailConfirmed)

    if (data.session) u.session = Session.deserialize(data.session)
    if (data.tipsShown) u.tipsShown = data.tipsShown.map(t => t.name)
    if (data.permissions) u.permissions = data.permissions

    return u
  }

  // hasRole(role: string): boolean {
  //   return Boolean(this.memberships.find(m => m.role === role))
  // }

  hasRole(role: string): boolean {
    return Boolean(this.memberships.find(m => m.role === role))
  }

  getRole(): string {
    const superAdmin = this.hasRole('superAdmin')
    const admin = this.hasRole('admin')
    const manager = this.hasRole('manager')
    const user = this.hasRole('user')
    if (superAdmin) return 'superAdmin'
    if (admin) return 'admin'
    if (manager) return 'manager'
    if (user) return 'user'
  }

  canManageUsers() {
    return this.permissions.canManageUsers
  }

  canViewInstead() {
    return this.permissions.canViewInstead
  }

  canUploadInstead() {
    return this.permissions.canUploadInstead
  }

  canViewAllResults() {
    return this.permissions.canViewAllResults
  }

  canViewOrganizationResults() {
    return this.permissions.canViewOrganizationResults
  }

  canCreateProductLines() {
    return this.permissions.canCreateProductLines
  }

  canDeleteProductLines() {
    return this.permissions.canDeleteProductLines
  }

  canCreateTestKits() {
    return this.permissions.canCreateTestKits
  }

  canDeleteTestKits() {
    return this.permissions.canDeleteTestKits
  }

  canCreateDevices() {
    return this.permissions.canCreateDevices
  }

  canDeleteDevices() {
    return this.permissions.canDeleteDevices
  }

  canCreateProducts() {
    return this.permissions.canCreateProducts
  }

  canDeleteProducts() {
    return this.permissions.canDeleteProducts
  }


  getName() {
    let name = `${this.firstName} ${this.lastName || ''}`.trim()
    if (!name && this.email) {
      name = this.email.split("@")[0]
    }
    return name
  }

  serialize() {
    const o: any = {...this}

    if (this.created) o.created = +this.created
    if (this.lastActivity) o.lastActivity = +this.lastActivity
    if (this.session) o.session = this.session.serialize()

    return o
  }
}

export interface Membership {
  id?: number;
  created?: Moment;
  app: string;
  permissions: Permissions
  role: string
}

export interface UsersPage {
  criteria: UserSearchCriteria;
  users: User[];
  lastPage: number;
}
