import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataSharingGroup} from "../../../model/dataSharingGroup";
import {
  DataSharingGroupsAdvancedModalComponent
} from "../data-sharing-groups-advanced-modal/data-sharing-groups-advanced-modal.component";
import {ModalService} from "../modal.service";
import {AdminService} from "../../pages-authenticated/admin/admin.service";
import {result} from "lodash";

@Component({
  selector: 'app-data-sharing-groups-table',
  templateUrl: './data-sharing-groups-table.component.html',
  styleUrls: ['./data-sharing-groups-table.component.scss']
})
export class DataSharingGroupsTableComponent implements OnInit {
  prefix = 'data-sharing-groups.table.';
  @Input() data: DataSharingGroup[] = [];
  @Output() needUpdatePage: EventEmitter<void> = new EventEmitter<void>();
  loadingIds = []
  constructor(
    private modals: ModalService,
    private as: AdminService
  ) { }

  ngOnInit(): void {
  }

  getOrganizationsName(organizations: any) {
    if (!organizations.length) return 'No organizations'
    return organizations.map((o: any) => o.name).join(', ')
  }

  async edit(group: DataSharingGroup) {
    const organizations = group.organizations.map(o => {
      const sharingIds = o.sharingUsers.filter(su => su.sharing).map(su => su.id)
      console.log("sharingIds list", sharingIds)
      o.users.forEach(u => {
        console.log('sharingIds id',u.id)
        u.sharing = sharingIds.includes(u.id)
      })
      // o.sharingUsers.forEach(u => {
      //   u.sharing = true
      //   o.users.push(u)
      // })
      // o.receivingUsers.forEach(u => {
      //   u.receiving = true
      //   o.users.push(u)
      // })
      return o
    })
    const res = await this.modals.show(DataSharingGroupsAdvancedModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        name: group.name,
        defaultOrganizations: organizations,
        isNew: false,
        dataSharingGroupId: group.id,
      }
    })

    if (res === true) this.needUpdatePage.emit()
  }

  async delete(id: number) {
    const res = await this.modals.showConfirm()
    if (!res) return
    this.loadingIds.push(id)
    try {
      await this.as.deleteDataSharingGroup(id)
      this.loadingIds = this.loadingIds.filter(i => i !== id)
      this.needUpdatePage.emit()
    } catch (e) {
      this.loadingIds = this.loadingIds.filter(i => i !== id)
      console.log(e)
    }
  }
}
