import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms'
import {ActivatedRoute, Router} from '@angular/router'

import {fade} from 'src/model/shared-animations'
import {User} from 'src/model/user'
import {ToastService} from 'src/app/services/toast.service'
import {UserService} from 'src/app/services/user.service'
import {OrganizationService} from '../../../organization.service'

@Component({
  selector: 'app-add-org-to-user',
  templateUrl: './add-org-to-user.component.html',
  styleUrls: ['./add-org-to-user.component.scss'],
  animations: [fade]
})
export class AddOrgToUserComponent implements OnInit {
  triedSubmit = false
  loading = false
  form: FormGroup
  prefix = 'register.add-org-to-user.'
  @Output() onComplete = new EventEmitter<any>()
  @Output() goToUser = new EventEmitter<void>()
  @Input() user: User
  @ViewChild('input') input: ElementRef

  get orgCode() {
    return this.form.get('orgCode')
  }

  constructor(
    private userService: UserService,
    private toasts: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private orgService: OrganizationService
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      orgCode: new FormControl('', [Validators.required, Validators.maxLength(6), this.isEmptyValidator]),
    })

    if (this.route.snapshot.queryParamMap.has('orgCode')) {
      this.orgCode.setValue(this.route.snapshot.queryParamMap.get('orgCode'))
      this.submit()
    }

    setTimeout(() => {
      if (this.input) this.input.nativeElement.focus()
    })
  }

  private isEmptyValidator(c: AbstractControl) {
    const v = c.value
    if (!/\S/.test(v)) {
      return {invalidName: true}
    }
  }

  public goBack() {
  this.goToUser.emit()
  }

  async submit() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return
    this.loading = true
    const org = await this.orgService.checkOrg(this.form.get('orgCode').value)

    if (!org) {
      this.toasts.danger("register.add-org-to-user.failed")
      this.orgCode.setErrors({invalid: true})
      this.loading = false
      return
    }

    this.onComplete.emit(this.form.value)
  }

}
