import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appClipboard]'
})
export class ClipboardDirective {
  @Input() appClipboard: string;
  @Output() copied = new EventEmitter<string>();

  constructor() { }

  @HostListener('click') copy() {
    if (!this.appClipboard) return;

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.appClipboard;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.copied.emit(this.appClipboard);
  }
}
