import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnDestroy {
  shown = 0;
  active = false;

  private intervalId: any;
  private percentage = .03;

  constructor() { 
  }

  ngOnDestroy() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  start(progress: number = 20) {
    this.active = true;
    this.shown = progress;

    this.intervalId = setInterval(() => {

      if (this.shown >= 95) return;

      //add a few percent of what's left. to make it seem stuff is working
      const add = Math.floor((100 - this.shown) * this.percentage);

      this.shown += add;
    }, 2000);
  }

  progress(progress: number) {
    if (progress > this.shown) this.shown = Number(this.progress);

    if (progress >= 100) this.stop();
  }

  stop() {
    this.shown = 100;
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }

    setTimeout(() => {
      this.active = false;
      this.shown = 0;
    }, 500);
  }




}
