import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {Organization} from '../../../../model/organization'
import {AdminService} from '../../../pages-authenticated/admin/admin.service'

@Component({
  selector: 'app-parent-org',
  templateUrl: './parent-org.component.html',
  styleUrls: ['./parent-org.component.scss']
})
export class ParentOrgComponent implements OnInit {

  @Output() parentSelected = new EventEmitter()

  organizations: Organization[]
  form: FormGroup
  prefix = 'admin.hierarchy.create-hierarchy-modal.'
  selectedOrganizations: Organization[] = []

  constructor(
    private as: AdminService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required)
    })
  }

  async ngOnInit() {
    this.organizations = await this.as.getHierarchyOrgsList()
    this.organizations = this.organizations.map(o => {
      o.selected = false
      o.permission = 'readonly'
      o.status = 'parent'
      return o
    })
    console.log(this.organizations)
  }

  onCheckboxChanged(e, idx) {
    this.organizations[idx].selected = e.target.checked
    if (e.target.checked) {
      this.selectedOrganizations.push(this.organizations[idx])
    } else {
      this.selectedOrganizations = this.selectedOrganizations.filter(o => o.id !== +e.target.value)
    }
  }

  addPermission(e, idx) {
    this.organizations[idx].permission = e.target.checked ? 'upload' : 'readonly'
  }

  next() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return
    this.parentSelected.emit({organizations: this.selectedOrganizations, name: this.form.get('name').value})
  }
}
