<div class="chart">
  <div class="chart-header row px-0">
    <p class="col col-4 my-0 text-left">{{prefix + 'product' | translate}}</p>
    <p class="col col-4 my-0 text-center">{{prefix + 'analyst' | translate}}</p>
    <p class="col col-4 my-0 text-right">{{prefix + 'cv' | translate}}</p>
  </div>

  <div class="attempts">
    <ng-container *ngFor="let labeledAttempt of options?.attempts">
      <ng-container *ngFor="let a of labeledAttempt.data; let k = index;">
        <div class="training">
          <div class="training-header row px-0 w-100">
            <!--          <p class="col col-4 d-inline-block text-left pl-3">{{k === 0 ? labeledAttempt.label: ''}}</p>-->
            <p class="col col-4 d-inline-block text-left p-0">{{labeledAttempt.label}}</p>
            <p class="col col-4 d-inline-block text-center p-0">{{a?.user}}</p>
            <p class="col col-4 d-inline-block text-right p-0">{{a?.cv > 0 ? a?.cv + '%' : 'n/a'}}</p>
          </div>
          <ul class="progressbar row">
            <!--          <ng-container *ngFor="let s of steps; let i = index;">-->
            <!--            <li [class.complete]="i < a.step" [class.active]="i == a.step" [class.incomplete]="i >a.step"-->
            <!--                [ngStyle]="{'z-index': steps.length-i}" class="step px-3 col col-auto">{{i + 1}}</li>-->
            <li [ngClass]="{red: i <= 2,
                          yellow: i > 2 && i <= 5,
                          green: i > 5,
                          incomplete: (i + 1) >=a.step}"
                [ngStyle]="{'z-index': steps.length-i}"
                class="step"
                *ngFor="let s of steps; let i = index;">

              <div class="step-label">
                <p class="m-0">{{i + 1}}</p>
              </div>

              <span [ngClass]="{'red-gradient': i === 3 && i < a.step, 'green-gradient': i === 6  && i < a.step}"
                    class="step-line"></span>
            </li>
            <!--          </ng-container>-->
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </div>

</div>
