import { Moment } from 'moment';
import * as moment from 'moment';

import { toObject } from './helpers';

export class TestKit {
  id?: number = null;
  type: string = null;
  lot: string = null;
  origin: string = null;
  quantity: number = null;
  used: number = null;
  status?: string = null;
  received: Moment = null;
  expires: Moment = null;
  active: boolean = null;
  organizationId?: number = null;
  notificationShown?: any = null
  measurementTypeId?: number = null

  percentage?: number;
  state?: string;

  calc() {
    //Set percentage
    if (this.used && this.quantity) {
      this.percentage = this.used / this.quantity;
    } else {
      this.percentage = 0;
    }

    //state
    if (this.percentage === 1) {
      this.state = "empty";
    } else if(this.percentage >= .85) {
      this.state = "almost-empty";
    } else if (this.expires.isBefore()) {
      this.state = "expired";
    } else if (this.expires.isBefore(moment().add(1, 'week'))) {
      this.state = "almost-expired";
    } else {
      this.state = "ok";
    }


  }

  static fromForm(data: any): TestKit {
    let s = toObject<TestKit>(TestKit, data);

    if (data.received) s.received = moment(data.received);
    if (data.expires) s.expires = moment(data.expires);
    if (!s.used) s.used = 0;
    if (data.quantity) s.quantity = Number(data.quantity);

    s.calc();

    return s;
  }

  toForm() {
    const o: any = {...this};

    if (this.received) o.received = this.received.toDate();
    if (this.expires) o.expires = this.expires.toDate();

    delete o.state;
    delete o.percentage;

    return o;
  }

  static deserialize(data: any): TestKit {
    let s = toObject<TestKit>(TestKit, data);

    if (data.received) s.received = moment.unix(data.received);
    if (data.expires) s.expires = moment.unix(data.expires);

    s.calc();
    return s;
  }

  serialize() {
    const o: any = {...this};

    if (this.received) o.received = this.received.unix();
    if (this.expires) o.expires = this.expires.unix();

    return o;
  }
}

export class TestKitType {
  id: number;
  name: string;
  measurementTypeId: number;
  image: string;
  type: string;
}
