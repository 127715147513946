import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Subscriptions } from '../../../model/subscriptions';
import PackageJson from '../../../../package.json'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  menuState = "menu-close";
  private _subs = new Subscriptions();
  appVersion = PackageJson.version

  constructor(private nav: NavService) { }

  ngOnInit() {
    this._subs.add(this.nav.toggleMenu.subscribe(shouldOpen => {
      const state = null;
      if (shouldOpen === true) {
        this.menuState = "menu-open";
      } else if (shouldOpen === false) {
        this.menuState = "menu-close";
      } else {
        this.toggleMenu();
      }
    }));
  }

  toggleMenu() {
    if (this.menuState === "menu-open") {
      this.menuState = "menu-close";
      this.nav.toggleMenu.next(false)
    } else {
      this.menuState = "menu-open";
      this.nav.toggleMenu.next(false)
    }
  }

}
