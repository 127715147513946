<nav aria-label="navigation" *ngIf="last > 0">
  <ul class="pagination pagination-sm justify-content-end" [ngStyle]="{'margin-bottom': marginBottom + 'px'}">
    <!-- <li class="py-1 px-2">
      {{ 'paging.showing' | translate: { current: current + 1, last: last + 1 } }}
    </li> -->

    <!-- First -->
    <li [class.disabled]="current === 0" class="paging-item page-fixed-width">
      <a class="paging-link" href="javascript:void(0)" (click)="toFirst()">
        <svg class="first-page-icon" width="11" height="9" viewBox="0 0 11 9" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.2616 4.00762H2.15802L4.26421 1.91164C4.49923 1.67774 4.50013 1.29762 4.26623 1.06261C4.03232 0.827565 3.65217 0.826695 3.41716 1.06057L0.280153 4.18244L0.279613 4.18301C0.0452019 4.41691 0.0444514 4.79826 0.279553 5.03295L0.280093 5.03352L3.4171 8.15539C3.65209 8.38923 4.03223 8.38842 4.26617 8.15335C4.50007 7.91834 4.49917 7.53822 4.26415 7.30432L2.15802 5.20834H10.2616C10.5932 5.20834 10.862 4.93956 10.862 4.60798C10.862 4.2764 10.5932 4.00762 10.2616 4.00762Z"
            fill="#333333"/>
        </svg>
      </a>
    </li>
    <!-- Prev -->
    <!--    <li [class.disabled]="current === 0" class="page-item page-fixed-width"><a class="page-link"-->
    <!--        href="javascript:void(0)" (click)="prev()">-->
    <!--        <i class="fa fa-angle-left"></i>-->
    <!--      </a></li>-->
    <ng-container *ngFor="let p of pages">
      <li *ngIf="p.type === 'number'; else dots" [class.active]="p.index === current" class="paging-item page-number">
        <a  class="paging-link" href="javascript:void(0)" (click)="select(p.index)">{{p.label}}</a>
      </li>
      <ng-template #dots>
        <p>...</p>
      </ng-template>
    </ng-container>

    <!-- Next -->
    <!--    <li [class.disabled]="current === last" class="page-item page-fixed-width"><a class="page-link"-->
    <!--        href="javascript:void(0)" (click)="next()">-->
    <!--        <i class="fa fa-angle-right"></i>-->
    <!--      </a></li>-->
    <!-- Last -->
    <li [class.disabled]="current === last" class="paging-item page-fixed-width">
      <a class="paging-link" href="javascript:void(0)" (click)="toLast()">
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09969 4.00664H9.2033L7.09711 1.91066C6.8621 1.67676 6.8612 1.29664 7.0951 1.06163C7.329 0.826588 7.70915 0.825718 7.94416 1.05959L11.0812 4.18147L11.0817 4.18204C11.3161 4.41594 11.3169 4.79729 11.0818 5.03197L11.0812 5.03254L7.94422 8.15442C7.70924 8.38826 7.32909 8.38745 7.09516 8.15238C6.86126 7.91736 6.86216 7.53725 7.09717 7.30334L9.2033 5.20736H1.09969C0.768113 5.20736 0.499331 4.93858 0.499331 4.607C0.499331 4.27542 0.768113 4.00664 1.09969 4.00664Z" fill="#333333"/>
        </svg>
      </a>
    </li>

  </ul>

</nav>
