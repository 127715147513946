import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {User} from "../../../model/user";

@Component({
  selector: 'app-all-data-share-aprove-modal',
  templateUrl: './all-data-share-aprove-modal.component.html',
  styleUrls: ['./all-data-share-aprove-modal.component.scss']
})
export class AllDataShareAproveModalComponent implements OnInit {
  prefix = 'all-data.share-modal.approve-modal.'
  users: User[] = []
  modalResult
  constructor(
    public modal: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  share() {
    this.modalResult = true
    this.modal.hide()
  }
}
