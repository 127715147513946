<table class="table table-hover text-center">
  <thead>
  <tr>
    <th scope="col" class="text-left">{{'devices.device' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.id' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.origin' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.received' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.last-used' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.min-range' | translate }}</th>
    <th scope="col" class="text-left">{{'devices.max-range' | translate }}</th>
    <th scope="col"></th>
  </tr>
  </thead>
  <tbody *ngIf="devices.length === 0">
  </tbody>
  <tbody *ngFor="let d of devices">
  <tr>
    <td class="text-dark text-left">{{ d.type | titlecase }}</td>
    <td class="text-dark text-left">{{d.lot}}</td>
    <td class="text-dark text-left">{{ d.origin }}</td>
    <td class="text-dark text-left">{{d?.received}}</td>
    <td class="text-dark text-left">{{fromUnix(d?.lastUsed)}}</td>
    <td class="text-dark text-left">{{d.minRange}}</td>
    <td class="text-dark text-left">{{d.maxRange}}</td>
    <td class="btn-cell text-right" *ngIf="canDelete">
<!--      <button routerLink="/tickets/new"-->
<!--              class="btn btn-md btn-outline-light text-black-50 order-btn">-->
<!--        <svg width="106" height="103" viewBox="0 0 106 103" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M16.8298 36.6835C4.06383 36.6835 3.00003 36.6835 3 44.2212C3 51.7589 8.31912 50.7538 12.5744 50.7538H93.4255C102.468 50.7538 103 48.2412 103 42.7136C103 36.6835 97.1489 36.6835 93.4255 36.6835H16.8298Z" stroke="black" stroke-width="6"/>-->
<!--          <path d="M43.7327 3.11327L17.5831 32.309C15.2002 34.9696 17.0885 39.196 20.6603 39.196C21.8804 39.196 23.0382 38.6566 23.823 37.7224L49.0356 7.71377C50.1055 6.44035 50.135 4.59093 49.1062 3.28405C47.7571 1.57011 45.188 1.48846 43.7327 3.11327Z" fill="black"/>-->
<!--          <path d="M61.744 3.03576L88.2593 32.4737C90.4187 34.8712 88.7173 38.6935 85.4907 38.6935C84.3904 38.6935 83.3464 38.2072 82.6384 37.3649L57.1456 7.03733C56.0046 5.67995 56.2585 3.63892 57.6973 2.60248C58.9592 1.69344 60.7031 1.88017 61.744 3.03576Z" fill="black"/>-->
<!--          <path d="M9.38298 50.2513C11.6879 62.4791 15.766 84.4221 16.8298 87.9397C17.8936 91.4573 17.3617 100 29.5957 100C38.1064 100 72.6809 100 76.4043 100C80.1277 100 88.1064 98.995 89.7021 91.4573C90.9787 85.4271 94.4894 61.474 96.0851 50.2513" stroke="black" stroke-width="6"/>-->
<!--          <path d="M37.9523 84.3817L34.516 64.043C34.2491 62.4631 32.8808 61.3065 31.2784 61.3065C29.2718 61.3065 27.7345 63.0905 28.0309 65.0751L31.0777 85.4757C31.3323 87.1805 32.7965 88.4422 34.5202 88.4422C36.6742 88.4422 38.3111 86.5056 37.9523 84.3817Z" fill="black"/>-->
<!--          <path d="M67.9803 84.4973L71.1644 64.1931C71.425 62.5312 72.8567 61.3065 74.5388 61.3065C76.6363 61.3065 78.2382 63.1793 77.9133 65.2514L74.7293 85.5557C74.4687 87.2175 73.0369 88.4422 71.3548 88.4422C69.2574 88.4422 67.6554 86.5694 67.9803 84.4973Z" fill="black"/>-->
<!--          <path d="M56.2874 84.7482V64.498C56.2874 62.7354 54.8585 61.3065 53.0959 61.3065C51.3333 61.3065 49.9044 62.7354 49.9044 64.498V84.7482C49.9044 86.5108 51.3333 87.9397 53.0959 87.9397C54.8585 87.9397 56.2874 86.5108 56.2874 84.7482Z" fill="black"/>-->
<!--        </svg>-->
<!--      </button>-->

      <app-edit-delete-dropdown (action)="actionDropdown($event, d)"></app-edit-delete-dropdown>
    </td>
  </tr>
  </tbody>
</table>
