import { Injectable } from '@angular/core';
import { ApiService } from './services/api.service';
import { OrganizationPage, Organization } from '../model/organization';
import { User } from '../model/user';
import { Gazable } from '../model/gazable';
import { Document } from '../model/document';
import { TestKit } from '../model/testKit';
import { Device } from '../model/device';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  selectedOrgChanged = new Gazable<Organization>(null);

  constructor(private api: ApiService) { }

  async checkOrg(code): Promise<boolean> {
    return this.api.post('check-org-code', {orgCode: code})
  }

  async setOrg(id: number) {
    if (this.selectedOrgChanged.value && id === this.selectedOrgChanged.value.id) return;

    const org = await this.api.get('admin/organization/' + id);
    this.selectedOrgChanged.next(org);
  }

  async updateOrg(o: Organization) {
    if (!o.id) throw new Error("No organizatio Id");

    const result = await this.api.put('admin/organization', o);
    this.selectedOrgChanged.next(result.organization);
    return result.organization;
  }

  async searchOrgs(q: string, page = 0, limit = true): Promise<OrganizationPage> {
    return await this.api.get(`admin/organizations?page=${page}&q=${encodeURIComponent(q)}&limit=${limit}`);
  }

  async getOrgByProductLine(plId) {
    return await this.api.get(`u/getOrgByProductLine?id=${plId}`)
  }

  async getOrgByUuid(uuid) {
    return await this.api.get(`u/getOrgByUuid?uuid=${uuid}`)
  }

  async getUsersByOrgId(id) {
    let result = await this.api.get(`u/get-users-by-organization?id=${id}`)
    result = result.users.map(u => User.deserialize(u))
    return result
  }

  async setOrganization(body: { uuid: string, orgCode: string }): Promise<void> {
    await this.api.put("admin/user-organization", body);
  }

  async getDocuments(organizationId: number, page = 0) {
    const result = await this.api.get(`admin/documents/${organizationId}?page=${page}`);
    result.documents = result.documents.map(data => Document.deserialize(data));
    return result;
  }

  async getStock(organizationId: number): Promise<{ devices: Device[], testKits: TestKit[] }> {
    const result = await this.api.get(`admin/stock/${organizationId}`);
    result.testKits = result.testKits.map(data => TestKit.deserialize(data));
    return result;
  }

}
