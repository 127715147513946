<div class="btn-group dropdown-container mb-3" dropdown>
  <p class="text-dark fz-14 mb-2">{{prefix + 'label' | translate}}</p>
  <button id="button-basic-organization" dropdownToggle type="button"
          class="btn dropdown-toggle text-dark dropdown-btn" aria-controls="dropdown-basic">
                            <span
                              *ngIf="selectedUser">{{selectedUser.firstName + ' ' + selectedUser.lastName}}</span>
    <span
      *ngIf="!selectedUser">{{prefix + 'placeholder' | translate}}</span>
  </button>
  <ul
    id="dropdown-basic-organization"
    *dropdownMenu
    class="dropdown-menu w-100"
    role="menu"
    [ngStyle]="{maxHeight: menuMaxHeight}"
  >
    <li *ngFor="let o of organizations" role="menuitem" class="users-container">
      <p class="text-primary font-weight-bold org-name">{{o.name}}</p>
      <div *ngIf="o.users.length; else noUsers" class="users">
        <div *ngFor="let u of o.users">
          <a *ngIf="!u.disabled; else disabledUser" href="javascript:void(0)" class="dropdown-item user"
             (click)="selectUser(u)">
            <div class="arrow-container">
              <svg *ngIf="selectedUser && u.uuid === selectedUser.uuid" class="arrow" width="10" height="8"
                   viewBox="0 0 10 8" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                  fill="black"/>
              </svg>
            </div>
            <p class="mb-0 ml-2">{{ u.firstName + ' ' + u.lastName }}</p>
          </a>

          <ng-template #disabledUser>
            <a href="javascript:void(0)" class="dropdown-item user">
              <div class="arrow-container">
                <svg *ngIf="u.uuid === selectedUser.uuid" class="arrow" width="10" height="8" viewBox="0 0 10 8"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.12492 2.85968C1.88844 2.63128 1.57171 2.50489 1.24296 2.50775C0.914201 2.51061 0.599719 2.64247 0.367245 2.87495C0.134771 3.10742 0.0029042 3.42191 4.73987e-05 3.75066C-0.0028094 4.07942 0.123572 4.39614 0.351972 4.63262L2.85968 7.14032C3.09481 7.37539 3.41367 7.50744 3.74615 7.50744C4.07863 7.50744 4.39749 7.37539 4.63262 7.14032L9.64803 2.12492C9.87643 1.88844 10.0028 1.57171 9.99995 1.24296C9.9971 0.914201 9.86523 0.599719 9.63276 0.367245C9.40028 0.134771 9.0858 0.0029042 8.75704 4.73987e-05C8.42829 -0.0028094 8.11156 0.123572 7.87508 0.351972L3.74615 4.48091L2.12492 2.85968Z"
                    fill="black"/>
                </svg>
              </div>
              <p class="mb-0 ml-2 text-light">{{ u.getName() }}</p>
            </a>
          </ng-template>
        </div>
      </div>

      <ng-template #noUsers>
        <div class="users">
          <div class="user">
            <div class="arrow-container">
            </div>
            <p class="mb-0">{{prefix + 'no-users' | translate}}</p>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
</div>
