<main>
  <div class="btns">
    <div class="select-all-checkbox" (change)="selectAllDocuments($event)">
      <input type="checkbox" id="all">
      <label for="all">{{prefix + 'select-all' | translate}}</label>
    </div>
    <button (click)="deleteSelected()" class="delete-all" *ngIf="selectedDocuments.length">{{prefix + 'delete-all' | translate}}</button>
  </div>
  <div class="doc" *ngFor="let d of page.documents">
    <ng-container *ngIf="shouldShow(d)">
      <div class="doc-icon">
        <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.1429 8.98482V25.4487C22.1429 26.8266 21.0258 27.9437 19.6479 27.9437H2.49497C1.11706 27.9437 0 26.8266 0 25.4487V2.49497C0 1.11706 1.11706 0 2.49497 0H13.1733C13.4569 0 13.7338 0.0643702 13.9846 0.185002C14.1732 0.275757 21.8824 8.00729 21.9741 8.20814C22.0843 8.44971 22.1429 8.71424 22.1429 8.98482Z"
            fill="#F2F2F2"/>
          <path
            d="M22.1431 8.98501V9.45843H15.6362C14.0182 9.45843 12.7021 8.14046 12.7021 6.5206V0H13.1737C13.6702 0 14.1467 0.197726 14.4979 0.549517L21.5967 7.6633C21.9466 8.01384 22.1431 8.48913 22.1431 8.98501Z"
            fill="#F9F8F9"/>
          <path
            d="M4.3662 27.9437H2.49497C1.11712 27.9437 0 26.8265 0 25.4487V2.49497C0 1.11712 1.11712 0 2.49497 0H4.3662C2.98835 0 1.87123 1.11712 1.87123 2.49497V25.4487C1.87123 26.8265 2.98835 27.9437 4.3662 27.9437Z"
            fill="#E3E0E4"/>
          <path
            d="M11.2961 9.35602H3.5804C3.32205 9.35602 3.11259 9.14657 3.11259 8.88822C3.11259 8.62986 3.32205 8.42041 3.5804 8.42041H11.2961C11.5545 8.42041 11.7639 8.62986 11.7639 8.88822C11.7639 9.14657 11.5545 9.35602 11.2961 9.35602Z"
            fill="#A29AA5"/>
          <path
            d="M11.2961 20.5836H3.5804C3.32205 20.5836 3.11259 20.3742 3.11259 20.1158C3.11259 19.8575 3.32205 19.648 3.5804 19.648H11.2961C11.5545 19.648 11.7639 19.8575 11.7639 20.1158C11.7639 20.3742 11.5545 20.5836 11.2961 20.5836Z"
            fill="#A29AA5"/>
          <path
            d="M17.4276 13.0988H3.45574C3.19738 13.0988 2.98793 12.8894 2.98793 12.631C2.98793 12.3727 3.19738 12.1632 3.45574 12.1632H17.4276C17.6859 12.1632 17.8954 12.3727 17.8954 12.631C17.8954 12.8894 17.6859 13.0988 17.4276 13.0988Z"
            fill="#A29AA5"/>
          <path
            d="M17.4276 16.8407H3.45574C3.19738 16.8407 2.98793 16.6313 2.98793 16.3729C2.98793 16.1145 3.19738 15.9051 3.45574 15.9051H17.4276C17.6859 15.9051 17.8954 16.1145 17.8954 16.3729C17.8954 16.6313 17.6859 16.8407 17.4276 16.8407Z"
            fill="#A29AA5"/>
          <path
            d="M22.1426 8.98501V10.0822H15.6357C13.6741 10.0822 12.0779 8.48414 12.0779 6.5206V0H13.1732C13.4442 0 13.7092 0.0588813 13.9511 0.169596C14.1525 0.261722 21.8865 8.01347 21.9785 8.21818C22.0857 8.45701 22.1426 8.71805 22.1426 8.98501Z"
            fill="#E3E0E4"/>
          <path
            d="M21.9752 8.21097H15.6361C14.7049 8.21097 13.9495 7.45374 13.9495 6.52062V0.169678C14.1516 0.260744 14.3381 0.389235 14.4978 0.549537L21.5966 7.66332C21.7557 7.823 21.8841 8.00887 21.9752 8.21097Z"
            fill="#A29AA5"/>
        </svg>
      </div>

      <div class="controls">
        <div class="custom-control custom-checkbox selectable-col-check" (change)="selectDocument($event, d)">
          <input [id]="d.id" type="checkbox" class="custom-control-input document-checkbox">
          <label class="custom-control-label" [for]="d.id"></label>
        </div>

        <div class="buttons">
          <button (click)="download(d)">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.35594 10.7345C8.09296 10.4806 7.67608 10.4806 7.41315 10.7345L5.17724 12.969V-0.321416C5.17724 -0.696194 4.87344 -1 4.49866 -1C4.12388 -1 3.82008 -0.696194 3.82008 -0.321416V12.9691L1.58552 10.7345C1.31595 10.4742 0.886385 10.4817 0.626037 10.7512C0.37205 11.0142 0.37205 11.431 0.626037 11.694L4.01888 15.0869C4.28356 15.3522 4.71321 15.3527 4.97852 15.0881C4.97892 15.0877 4.97932 15.0873 4.97976 15.0869L8.3726 11.694C8.63298 11.4244 8.62551 10.9949 8.35594 10.7345Z"
                fill="#68686D"/>
            </svg>
          </button>

          <button (click)="remove(d)">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5625 2.5H12.125V1.875C12.125 0.841133 11.2839 0 10.25 0H7.75C6.71613 0 5.875 0.841133 5.875 1.875V2.5H2.4375C1.57594 2.5 0.875 3.20094 0.875 4.0625V6.25C0.875 6.59516 1.15484 6.875 1.5 6.875H1.84156L2.38152 18.2142C2.42922 19.2156 3.25187 20 4.25437 20H13.7456C14.7482 20 15.5708 19.2156 15.6185 18.2142L16.1584 6.875H16.5C16.8452 6.875 17.125 6.59516 17.125 6.25V4.0625C17.125 3.20094 16.4241 2.5 15.5625 2.5ZM7.125 1.875C7.125 1.53039 7.40539 1.25 7.75 1.25H10.25C10.5946 1.25 10.875 1.53039 10.875 1.875V2.5H7.125V1.875ZM2.125 4.0625C2.125 3.8902 2.2652 3.75 2.4375 3.75H15.5625C15.7348 3.75 15.875 3.8902 15.875 4.0625V5.625C15.6824 5.625 2.92316 5.625 2.125 5.625V4.0625ZM14.3699 18.1547C14.354 18.4885 14.0798 18.75 13.7456 18.75H4.25437C3.9202 18.75 3.64598 18.4885 3.63012 18.1547L3.09297 6.875H14.907L14.3699 18.1547Z"
                fill="#68686D"/>
              <path
                d="M9 17.5C9.34516 17.5 9.625 17.2202 9.625 16.875V8.75C9.625 8.40484 9.34516 8.125 9 8.125C8.65484 8.125 8.375 8.40484 8.375 8.75V16.875C8.375 17.2202 8.6548 17.5 9 17.5Z"
                fill="#68686D"/>
              <path
                d="M12.125 17.5C12.4702 17.5 12.75 17.2202 12.75 16.875V8.75C12.75 8.40484 12.4702 8.125 12.125 8.125C11.7798 8.125 11.5 8.40484 11.5 8.75V16.875C11.5 17.2202 11.7798 17.5 12.125 17.5Z"
                fill="#68686D"/>
              <path
                d="M5.875 17.5C6.22016 17.5 6.5 17.2202 6.5 16.875V8.75C6.5 8.40484 6.22016 8.125 5.875 8.125C5.52984 8.125 5.25 8.40484 5.25 8.75V16.875C5.25 17.2202 5.5298 17.5 5.875 17.5Z"
                fill="#68686D"/>
            </svg>
          </button>
        </div>
      </div>

      <div class="top">

        <p class="name text-overflow-100">{{d.name}}</p>

        <div class="file-data">
          <img [src]="'assets/icons/extension-' + d.attachment.extension.substring(1) + '.svg'">
          <p>{{formatBytes(d.attachment.size)}}</p>
        </div>
      </div>

      <div class="bottom">
        <div class="cell-container">
          <div class="cell">
            <p class="label">{{prefix + 'labels.date' | translate}}</p>
            <p class="value">{{d.attachment.created.valueOf() | date : 'MM/dd/yyyy'}}</p>
          </div>
        </div>

        <div class="cell-container center">
          <div class="cell">
            <p class="label">{{prefix + 'labels.type' | translate}}</p>
            <div class="document-type">
              <p>{{ 'document.type.' + d.documentType | translate }}</p>
            </div>
          </div>
        </div>

        <div class="cell-container right">
          <div class="cell">
            <p class="label">{{prefix + 'labels.from' | translate}}</p>
            <p class="value">{{ d.organization.name }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</main>
