import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {OnboardingService} from '../../services/onboarding.service'
import {Sample} from '../../../model/sample'
import {Product, ProductLine} from '../../../model/Products'
import {CacheService} from '../../services/cache.service'
import {Subscriptions} from '../../../model/subscriptions'
import {Device} from '../../../model/device'
import {DataService} from '../../services/data.service'
import {ActivatedRoute, Router} from '@angular/router'
import {Import} from '../../../model/import'
import {InputModalComponent} from '../input-modal/input-modal.component'
import {ModalService} from '../modal.service'
import * as moment from 'moment'
import { from } from 'rxjs'

@Component({
  selector: 'app-samples-table',
  templateUrl: './samples-table.component.html',
  styleUrls: ['./samples-table.component.scss', './samples-table.media.scss']
})
export class SamplesTableComponent implements OnInit, OnDestroy {
  private _subs = new Subscriptions()

  @ViewChild('allCheck') allCheckbox: ElementRef
  @Input() tableData: Sample[] = []
  @Input() product = null
  @Input() canEditSample = true
  @Input() trainingImportId = null
  @Output() onDiscard = new EventEmitter<any>()
  @Output() onProcess = new EventEmitter<any>()
  @Output() productId = new EventEmitter<any>()
  @Output() onSelectedCountChanged = new EventEmitter<any>()
  measurementTypes

  deviceAndProductTypes = {
    chroma: 'Vitamin A',
    chroma3: 'Vitamin A',
    fluoro: 'Vitamin A',
    iron: 'Iron',
    carotene: 'Total carotenoids',
    iodine: 'Iodine',
    'vitamin-e': 'Vitamin E'
  }

  pId = null
  currentIndex = -1
  allSelected = false
  selectCount = 0
  productInfo: any
  import: Import
  productLines: ProductLine[]
  devices: Device[] = []
  modalOpen = false
  dateSortType = null
  resultSortType = null
  showHierarchy = false
  hierarchyProduct: Product
  importId
  device: Device
  deviceId: number


  constructor(
    private os: OnboardingService,
    private cache: CacheService,
    private data: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private modals: ModalService,
  ) {
  }

  async ngOnInit() {
    this.measurementTypes = await this.data.getMeasurementTypes()
    if (this.trainingImportId) {
      this.importId = this.trainingImportId
    } else {
      this.importId = this.route.snapshot.paramMap.get('id')
    }
    this.route.queryParams.subscribe(q => {
      this.deviceId = +q.deviceId
      this.device = this.cache.devices.value.filter(d => d.id === this.deviceId)[0]
      if (q.organizationId) {
        this.showHierarchy = true
        this._subs.add(from(this.data.getProductLinesByOrgId(+q.organizationId)).subscribe(lines => {
          lines.forEach(pl => {
            pl.products.forEach(p => {
              if (p.id === +q.productId) {
                this.hierarchyProduct = p
              }
            })
          })
          this.selectProduct(this.hierarchyProduct)
        }))
      } else {
        this._subs.add(this.cache.productLines.subscribe(lines => {
          const cloneLines = JSON.parse(JSON.stringify(lines))
          const withoutTraining = cloneLines.filter(l => !l.training)
          this.productLines = withoutTraining.map(pl => {
            const resPl = pl
            resPl.products = pl.products.filter(p => {
              const resP = p.controlValues.find(cv => {
                return this.deviceAndProductTypes[this.device.type] === cv.label
              })
              return !!resP
            })
            return resPl
          })
          //
          this.productLines = this.productLines.filter(pl => pl.products.length)
          this.productInfo = {}
          for (const l of lines) {
            for (const p of l.products) {
              this.productInfo[p.id] = {name: p.name, line: l.name}
            }
          }
        }))
      }

      if (!this.device) {
        this.router.navigate(['/devices'])
      }
    })
    // this.productLines =

    setTimeout(() => this.os.showAll(), 1000)
    setTimeout(() => this.toggleAll(true))
    this.onSelectedCountChanged.emit(this.selectCount)
  }

  ngOnDestroy() {
    if (this._subs) {
      this._subs.unsubscribeAll()
      this._subs = undefined
    }
  }

  async openBatchNameModal() {
    let batchName = ""
    //let modal: BsModalRef;
    if (this.tableData[this.currentIndex] && this.tableData[this.currentIndex].userBatchId) {
      batchName = this.tableData[this.currentIndex].userBatchId
    }

    this.modalOpen = true

    const text = await this.modals.show(InputModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        title: 'import.set-batch-title',
        placeholder: 'import.batch-name-placeholder',
        text: batchName
      }
    })
    if (text) await this.setBatchName(text)
  }

  async setBatchName(name: string) {
    //Check to see if we're overwriting a batch name anywhere
    const selected = this.tableData.filter(s => s.selected)

    const withBatchName = selected.filter(s => s.userBatchId && s.userBatchId !== name)
    if (withBatchName.length > 0 && withBatchName.length < selected.length) {
      const confirmed = await this.modals.showConfirm({
        title: "import.overwrite-batch.title",
        content: "import.overwrite-batch.content"
      })
      if (!confirmed) return
    }

    for (const s of selected) {
      s.userBatchId = name
    }
  }

  toggleAll(newState?: boolean) {
    if (this.tableData.length === 0) return

    if (this.currentIndex < 0 || this.currentIndex > this.tableData.length - 1) this.currentIndex = this.tableData.length - 1

    if (newState === undefined) {
      if (this.allCheckbox.nativeElement.indeterminate) {
        newState = false
      } else {
        newState = !this.allSelected
      }
    }

    this.allSelected = newState
    this.selectCount = newState ? this.tableData.length : 0
    for (const s of this.tableData) {
      s.selected = newState
    }

    this.allCheckbox.nativeElement.indeterminate = false
    this.onSelectedCountChanged.emit(this.selectCount)
  }


  toggle(s: Sample, index: number, event?) {
    if (event && event.target) {
      const el = event.target
      if (el.tagName === "INPUT") return
    }

    //select / deselect range
    if (event && event.shiftKey && index !== this.currentIndex) {
      this.selectRange(index)
      return
    }

    s.selected = !s.selected
    this.currentIndex = index

    //Update selectCount
    if (s.selected) {
      this.selectCount++
    } else {
      this.selectCount--
    }

    //Update allSelect
    this.updateAllSelect()
    this.onSelectedCountChanged.emit(this.selectCount)
  }

  selectProduct(product: Product) {
    for (const s of this.tableData) {
      if (s.selected) {
        s.productId = product.id
        s.measurementTypeId = this.measurementTypes.filter(t => t.name === this.deviceAndProductTypes[this.device.type])[0].id
        this.productId.emit(product)
        this.pId = product.id
      }
    }
  }

  private selectRange(end: number) {
    const low = (end > this.currentIndex) ? this.currentIndex : end
    const high = (end > this.currentIndex) ? end : this.currentIndex
    const newState = this.tableData[this.currentIndex].selected

    for (let i = low; i <= high; i++) {
      this.tableData[i].selected = newState
    }
    this.selectCount = this.tableData.filter(s => s.selected).length
    this.updateAllSelect()
  }

  private updateAllSelect() {
    if (this.selectCount === this.tableData.length) {
      this.allSelected = true
      this.allCheckbox.nativeElement.indeterminate = false
    } else if (this.selectCount === 0) {
      this.allSelected = false
      this.allCheckbox.nativeElement.indeterminate = false
    } else {
      this.allCheckbox.nativeElement.indeterminate = true
    }
  }

  discard() {
    this.onDiscard.emit(this.tableData)
  }

  process() {
    this.onProcess.emit(this.tableData)
    this.toggleAll(true)
  }

  discardOne(i) {
    this.tableData.forEach(s => s.selected = false)
    this.tableData[i].selected = true
    this.onDiscard.emit(this.tableData)
  }

  sortDate() {
    this.resultSortType = null
    switch (this.dateSortType) {
      case 'increase':
        this.dateSortType = 'decrease'
        this.tableData = this.tableData.sort((a, b) => moment(a.created).unix() - moment(b.created).unix())
        break
      case 'decrease':
        this.dateSortType = 'increase'
        this.tableData = this.tableData.sort((a, b) => moment(a.created).unix() - moment(b.created).unix()).reverse()
        break
      default:
        this.dateSortType = 'decrease'
        this.tableData = this.tableData.sort((a, b) => moment(a.created).unix() - moment(b.created).unix())
        break
    }
  }

  sortResult() {
    this.dateSortType = null
    switch (this.resultSortType) {
      case 'increase':
        this.resultSortType = 'decrease'
        this.tableData = this.tableData.sort((a, b) => a.result - b.result)
        break
      case 'decrease':
        this.resultSortType = 'increase'
        this.tableData = this.tableData.sort((a, b) => a.result - b.result).reverse()
        break
      default:
        this.resultSortType = 'decrease'
        this.tableData = this.tableData.sort((a, b) => a.result - b.result)
        break
    }
  }
}
