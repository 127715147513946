<form (submit)="submit()">
  <div class="input-group">

    <input #textInput type="text" class="form-control" id="search-input" [formControl]="q"
      placeholder="{{ prefix + '.placeholder' | translate }}">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="submit">
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
</form>