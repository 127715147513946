import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from 'src/app/landing/landing.component';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { RegisterComponent } from 'src/app/auth/registration/register/register.component';
import { ConfirmEmailComponent } from 'src/app/auth/confirm-email/confirm-email.component'
import { RequestPasswordResetComponent } from 'src/app/auth/request-password-reset/request-password-reset.component'
import { PasswordResetComponent } from 'src/app/auth/password-reset/password-reset.component'

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Registration' },
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailComponent,
    data: { title: 'Welcome to iCheck Connect!' },
  },
  {
    path: 'forgot-password',
    component: RequestPasswordResetComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'reset-password',
    component: PasswordResetComponent,
    data: { title: 'Reset Your Password' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class AuthRoutingModule {
}
