import { Injectable } from '@angular/core';

import { ControlValue } from '../../model/control-value';
import { Device } from '../../model/device';
import { Gazable } from '../../model/gazable';
import { Notification } from '../../model/notification';
import { Organization } from '../../model/organization';
import {ProductCategory, ProductLine, ProductType} from '../../model/Products'
import { User } from '../../model/user';
import {DeviceType} from '../../model/deviceType'
import {MeasurementType} from '../../model/sample'
import {TestKitType} from '../../model/testKit'

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  productLines = new Gazable<ProductLine[]>([]);

  devices = new Gazable<Device[]>([]);
  organization = new Gazable<Organization>(null);
  notifications = new Gazable<Notification[]>([]);
  users = new Gazable<User[]>([]);
  docTypes = new Gazable<string[]>([]);
  productCategories = new Gazable<ProductCategory[]>(null);
  deviceTypes = new Gazable<DeviceType[]>(null);
  measurementTypes = new Gazable<MeasurementType[]>(null);
  testKitTypes = new Gazable<TestKitType[]>(null);
  productTypes = new Gazable<ProductType[]>(null);


  constructor() { }

  updateControlValue(cv: ControlValue) {
    for (let l of this.productLines.value) {
      const p = l.products.find(p => p.id === cv.productId);
      if (!p) continue;

      p.controlValues = [cv];
      this.productLines.trigger();
      return;
    }
  }
}
