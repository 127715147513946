import {Component, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {Device} from '../../../model/device'
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms'
import {units} from '../../../model/sample'
import {DataService} from '../../services/data.service'
import {Organization} from '../../../model/organization'
import * as moment from 'moment'
import { DeviceType } from 'src/model/deviceType'

@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.scss']
})
export class DeviceModalComponent implements OnInit {
  newDevice = false
  busy = false
  device: Device
  modalResult: Device = null
  prefix = "device-modal."
  form: FormGroup
  typeOptions: any[] = []
  deviceTypes: { [deviceType: string]: DeviceType } = {}
  unitOptions = units
  organization?: Organization
  deviceType = 'chroma'

  minRanges = {
    chroma: 3,
    chroma3: 3,
    fluoro: 50,
    iron: 1.5,
    carotene: 0.15,
    iodine: 1,
    'vitamin-e': 1,
  }
  maxRanges = {
    chroma: 30,
    chroma3: 30,
    fluoro: 3000,
    iron: 12,
    carotene: 15,
    iodine: 13,
    'vitamin-e': 25,
  }

  get title(): string {
    if (this.newDevice) return 'new-title'
    return 'edit-title'
  }

  get saveButton(): string {
    if (this.newDevice) return 'new-save'
    return 'edit-save'
  }

  constructor(
    private modal: BsModalRef,
    private ds: DataService,
    private dataService: DataService
  ) {
    this.form = new FormGroup({
      id: new FormControl(''),
      organizationId: new FormControl(''),
      type: new FormControl('', Validators.required),
      lot: new FormControl('', Validators.required),
      range: new FormGroup({
        minRange: new FormControl('', Validators.required),
        maxRange: new FormControl('', Validators.required),
      }, this.range),
      unit: new FormControl('mg RE/kg', Validators.required),
    })
  }

  range(range: FormGroup) {
    if (+range.get('minRange').value < +range.get('maxRange').value) return null
    return {invalidRange: true}
  }

  async ngOnInit() {
    this.busy = true
    const deviceTypes = await this.dataService.getDeviceTypes()
    this.typeOptions = deviceTypes.map(d =>
      ({name: d.name, value: d.type})
    )
    deviceTypes.forEach(d =>
      this.deviceTypes[d.type] = d
    )
    this.busy = false
    if (this.newDevice) {
      this.device = this.getDefaultDevice(deviceTypes[0])
    }
    this.form.patchValue(this.device)
    this.form.get('range').get('minRange').setValue(this.device.minRange)
    this.form.get('range').get('maxRange').setValue(this.device.maxRange)
    this.form.get('type').valueChanges.subscribe(v => {
      this.form.get('unit').setValue(this.deviceTypes[v]?.unit)
      this.form.get('range').get('minRange').setValue(this.deviceTypes[v]?.min)
      this.form.get('range').get('maxRange').setValue(this.deviceTypes[v]?.max)
    })
  }

  private getDefaultDevice(device?: DeviceType): Device {
    return {
      id: null,
      type: device.type || this.deviceType,
      lot: '',
      minRange: device.min || 3,
      maxRange: device.max || 30,
      unit: device.unit || 'mg RE/kg',
      active: true,
      received: moment().format('YYYY-MM-DD')
    }
  }

  close(save = false) {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    if (save) {
      Object.assign(this.device, data)
      this.modalResult = this.device
    }
    this.modal.hide()
  }

  async save() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.busy = true
    try {
      if (this.newDevice) {
        await this.saveNew()
      } else {
        await this.update()
      }
      this.close(true)
    } catch (err) {
      console.error("Not happening", err)
    }
    this.busy = false
  }

  private saveNew() {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    if (this.organization) this.form.get('organizationId').setValue(this.organization.id)
    return this.ds.addDevice(data)
  }

  private update() {
    const data = this.form.value
    data.minRange = this.form.get('range').get('minRange').value
    data.maxRange = this.form.get('range').get('maxRange').value
    return this.ds.updateDevice(data)
  }

  async remove() {
    this.busy = true

    try {
      await this.ds.removeDevice(this.device)
      this.device.active = false
      this.close(true)
    } catch (err) {
      console.error("Nope", err)
    }

    this.busy = false
  }

  getDeviceType() {
    switch (this.form.get("type").value) {
      case "chroma":
      case "chroma3":
        return "CR";
      case "iron":
        return "IR";
      case "carotene":
        return "CA";
      case "fluoro":
        return "FL";
      case "vitamin-e":
        return "VE";
      case "iodine":
        return "IO";
    }
  }
}
