import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deepValue',
  pure: false
})
export class DeepValuePipe implements PipeTransform {

  transform(value: any, arg: string): any {
    if (typeof value !== "object") return value;

    return this.getVal(value, arg.split('.'));
  }

  private getVal(obj: any, props: string[]): string {
    if (typeof obj !== "object") return obj;

    if (obj === null || obj === undefined) return '';

    if (Array.isArray(obj)){
      const values = obj.map(o => this.getVal(o, props));
      return values.join(', ');
    }

    if (props.length === 0) return "wutno.";

    if (props.length === 1) return obj[props[0]];

    const newObj = obj[props[0]];
    //Pop one off the beginning
    props.splice(0, 1);
    return this.getVal(newObj, props);

  }

}
