import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-invalid-date-modal',
  templateUrl: './invalid-date-modal.component.html',
  styleUrls: ['./invalid-date-modal.component.scss']
})
export class InvalidDateModalComponent implements OnInit {

  prefix = 'invalid-date-modal.'

  constructor(public modal: BsModalRef) { }

  ngOnInit(): void {
  }

}
