<div class="dropdown-container">
  <p class="dropdown-container__label">{{label}}</p>
  <div class="btn-group mr-2 dropdown-container__dropdown" dropdown>
    <button dropdownToggle type="button" class="dropdown-container__dropdown__button" aria-controls="dropdown-basic">
    <span class="text-left text-overflow-100 dropdown-container__dropdown__button__label">{{ getLabel() | translate : {
      name: getItemName()
    } }}</span>
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"
           class="dropdown-container__dropdown__button__icon">
        <path
          d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM7 7L7 8L9 8L9 7L7 7Z"
          fill="#68686D"/>
      </svg>
    </button>

    <div *ngIf="type === 'group'" (click)="$event.stopPropagation()">
      <ul id="dropdown-basic-group" *dropdownMenu class="dropdown-menu dropdown-container__dropdown__menu-group"
          role="menu">
        <div *ngIf="items.length; else noData">
          <li *ngFor="let item of items" role="menuitem" class="dropdown-container__dropdown__menu-group__item">
            <div class="dropdown-container__dropdown__menu-group__item__name-container">
              <p
                class="dropdown-container__dropdown__menu-group__item__name-container__name text-overflow-100">{{item.name}}</p>

              <div class="dropdown-container__dropdown__menu-group__item__name-container__checkbox-container">
                <app-form-control
                  [type]="'non-control-checkbox'"
                  [checkboxLabel]="prefix + 'select-all' | translate"
                  [selected]="isSelectGroupActive(item.items)"
                  (checkboxChanged)="selectGroup(item.items, $event)"
                  [disabled]="!item.items.length"
                  disableSelectEffect="true"
                  marginBottom="0"
                ></app-form-control>
              </div>
            </div>

            <div class="dropdown-container__dropdown__menu-group__item__sub-items-container">
              <div *ngIf="item.items.length; else noData"
                   class="dropdown-container__dropdown__menu-group__item__sub-items-container__sub-item">
                <a *ngFor="let i of item.items" href="javascript:void(0)"
                   class="dropdown-container__dropdown__menu-group__item__sub-items-container__sub-item__item">
                  <app-form-control
                    [type]="'non-control-checkbox'"
                    [checkboxLabel]="i.name"
                    [checkboxSubLabel]="i.name"
                    [selected]="i.selected"
                    (checkboxChanged)="toggle(i, item.value)"
                    disableSelectEffect="true"
                    marginBottom="0"
                  ></app-form-control>
                </a>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>

    <div *ngIf="type === 'list'" (click)="$event.stopPropagation()">
      <ul id="dropdown-basic-list" *dropdownMenu class="dropdown-menu dropdown-container__dropdown__menu-list"
          role="menu">
        <div *ngIf="items.length; else noData">
          <li *ngFor="let item of items" role="menuitem"
              class="dropdown-container__dropdown__menu-list__item-container">
            <a href="javascript:void(0)"
               class="dropdown-container__dropdown__menu-list__item-container__item">
              <app-form-control
                [type]="'non-control-checkbox'"
                [checkboxLabel]="item.name"
                [selected]="item.selected"
                (checkboxChanged)="toggle(item)"
                disableSelectEffect="true"
                marginBottom="0"
                [checkboxSubLabel]="item.subLabel"
              ></app-form-control>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>

<ng-template #noData>
  <p class="no-data">{{prefix + 'no-data' | translate}}</p>
</ng-template>
