export const documentation = [
  {
    name: 'chroma-measure-tutorial',
    icon: 'video',
    link: 'https://youtu.be/s2Kyg90qyz0',
    device: 'chroma3',
  },
  {
    name: 'chroma3-mode',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Chroma%203_Mode%20d_Emploi_FR.pdf',
    device: 'chroma3',
  },
  {
    name: 'chroma3-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Chroma%203_User%20Manual_EN_A5_V1.2_ISO%202015.pdf',
    device: 'chroma3',
  },
  {
    name: 'chroma3-product-flyer',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/Product%20flyer_iCheck%20Chroma%203_2020.pdf',
    device: 'chroma3',
  },
  {
    name: 'chroma3-quick-guide',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/Quick%20Guide%20to%20iCheck%20Chroma%203_EN_2020.pdf',
    device: 'chroma3',
  },
  {
    name: 'transfer-program-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/Transfer%20Program%20Instructions_EN_11.2018.pdf',
    device: null,
  },
  {
    name: 'vitamin-a-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Vitamin%20E_User%20Manual_EN.pdf',
    device: 'vitamin-a',
  },
  {
    name: 'iodine-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Iodine_User%20Manual_EN.pdf',
    device: 'iodine',
  },
  {
    name: 'carotene-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Carotene_User%20Manual_EN.pdf',
    device: 'carotene',
  },
  {
    name: 'iron-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Iron_User%20Manual_EN.pdf',
    device: 'iron',
  },
  {
    name: 'chroma-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Chroma_User%20Manual_EN.pdf',
    device: 'chroma',
  },
  {
    name: 'fluoro-user-manual',
    icon: 'document',
    link: 'https://icheck-static.fra1.digitaloceanspaces.com/training-content/iCheck%20Fluoro_User%20Manual_EN.pdf',
    device: 'fluoro',
  }

  // NOTE: THERE IS NO CHROMA MANUAL ON FILE. WAITING ON ANTHONY TO PROVIDE IT. USING PLACEHOLDER IN THE MEANTIME.
];
