import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  @Input() set prefix(v: string) {
    this.header = v + '.title';
    this.content = v + '.content';
  }

  //Deprecated in favor of prefix
  @Input() header = null;
  @Input() labelPosition = 'left';
  @Input() subtitle = "";
  //Deprecated in favor of prefix
  @Input() content = "";

  //Title is deprecated, because it messes with the HTML title attribute. So once all usages have been refactored, remove this getter/setter
  @Input() set title(v: string) { this.header = v } ;
  get title() { return this.header };

  @Input() params: any;
  @Input() icon;

  constructor() { }

  ngOnInit() {
  }

}
