<main>
  <div class="item" *ngFor="let tk of testKits">
    <div class="buttons">
      <button class="update-btn" (click)="openStockModal(tk)">{{prefix + 'update-btn' | translate}}</button>
      <button class="order-btn" (click)="orderMore(tk)">{{prefix + 'order-btn' | translate}}</button>
      <button class="delete-btn" (click)="deleteTestKit(tk)">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5625 2.5H12.125V1.875C12.125 0.841133 11.2839 0 10.25 0H7.75C6.71613 0 5.875 0.841133 5.875 1.875V2.5H2.4375C1.57594 2.5 0.875 3.20094 0.875 4.0625V6.25C0.875 6.59516 1.15484 6.875 1.5 6.875H1.84156L2.38152 18.2142C2.42922 19.2156 3.25187 20 4.25437 20H13.7456C14.7482 20 15.5708 19.2156 15.6185 18.2142L16.1584 6.875H16.5C16.8452 6.875 17.125 6.59516 17.125 6.25V4.0625C17.125 3.20094 16.4241 2.5 15.5625 2.5ZM7.125 1.875C7.125 1.53039 7.40539 1.25 7.75 1.25H10.25C10.5946 1.25 10.875 1.53039 10.875 1.875V2.5H7.125V1.875ZM2.125 4.0625C2.125 3.8902 2.2652 3.75 2.4375 3.75H15.5625C15.7348 3.75 15.875 3.8902 15.875 4.0625V5.625C15.6824 5.625 2.92316 5.625 2.125 5.625V4.0625ZM14.3699 18.1547C14.354 18.4885 14.0798 18.75 13.7456 18.75H4.25437C3.9202 18.75 3.64598 18.4885 3.63012 18.1547L3.09297 6.875H14.907L14.3699 18.1547Z"
            fill="#68686D"/>
          <path
            d="M9 17.5C9.34516 17.5 9.625 17.2202 9.625 16.875V8.75C9.625 8.40484 9.34516 8.125 9 8.125C8.65484 8.125 8.375 8.40484 8.375 8.75V16.875C8.375 17.2202 8.6548 17.5 9 17.5Z"
            fill="#68686D"/>
          <path
            d="M12.125 17.5C12.4702 17.5 12.75 17.2202 12.75 16.875V8.75C12.75 8.40484 12.4702 8.125 12.125 8.125C11.7798 8.125 11.5 8.40484 11.5 8.75V16.875C11.5 17.2202 11.7798 17.5 12.125 17.5Z"
            fill="#68686D"/>
          <path
            d="M5.875 17.5C6.22016 17.5 6.5 17.2202 6.5 16.875V8.75C6.5 8.40484 6.22016 8.125 5.875 8.125C5.52984 8.125 5.25 8.40484 5.25 8.75V16.875C5.25 17.2202 5.5298 17.5 5.875 17.5Z"
            fill="#68686D"/>
        </svg>
      </button>
    </div>

    <p class="name">{{tk.type}}</p>

    <div class="top">

      <div class="cell-container">
        <div class="cell">
          <p class="label">{{prefix + 'labels.lot' | translate}}</p>
          <p class="value">{{tk.lot}}</p>
        </div>
      </div>

      <div class="cell-container center">
        <div class="cell origin">
          <p class="label">{{prefix + 'labels.origin' | translate}}</p>
          <div class="value">
            <img src="assets/icons/icon-shield.png" class="img-fluid mr-1" *ngIf="tk.origin == 'BioAnalyt'"/>
            <p [ngStyle]="{color: tk.origin == 'BioAnalyt' ? '#0B779A' : '#343a40'}">{{ tk.origin }}</p>
          </div>
        </div>
      </div>

      <div class="cell-container right">
        <div class="cell">
          <p class="label">{{prefix + 'labels.received' | translate}}</p>
          <p class="value">{{tk.received | date : 'MM/dd/yyyy'}}</p>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="cell-container">
        <div class="cell">
          <p class="label">{{prefix + 'labels.expires' | translate}}</p>
          <p class="value">{{tk.expires | date : 'MM/dd/yyyy'}}</p>
        </div>
      </div>

      <div class="cell-container">
        <div class="cell progress">
          <p class="label">{{prefix + 'labels.available' | translate}}</p>
          <div class="progress-container d-flex">
            <div
              class="progress"
              [tooltip]="'stock.available' | translate: { available: tk.quantity - tk.used, total: tk.quantity}"
              [ngClass]="{
                    'in-stock': tk.state !== 'almost-empty' || tk.state !== 'empty',
                    'out-of-stock': tk.state == 'empty' || tk.state == 'empty'
                    }"
            >
              <p
                class="progress_bar"
              >
                {{ tk.quantity - tk.used }} / {{ tk.quantity }}
              </p>
            </div>

            <div class="ml-2 almost-icon" [class.invisible]="tk.state !== 'almost-empty'">
              <i container="body" [tooltip]="'stock.tooltip.almost-empty' | translate"
                 class="text-warning fa fa-exclamation-circle"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
