<div class="modal-header">
  <h5 class="modal-title pull-left text-dark">{{prefix + 'title' | translate : {version: appVersion} }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <fieldset [disabled]="busy">
      <app-form-control class="mt-3 col-md-6 d-inline text-dark" [showInfo]="false" [prefix]="prefix +
                                    'title-input'" [control]="form.get('title')">
      </app-form-control>
      <app-form-control class="mt-3 col-md-6 d-inline text-dark" type="textarea" [showInfo]="false" [prefix]="prefix +
                                    'steps-input'" [control]="form.get('description')">
      </app-form-control>
      <app-file-upload [disable]="fileUploadInProgress" #upload (progress)="progress($event)" format="button"
                       prefix="tickets.mine.reply.file"></app-file-upload>
    </fieldset>
  </div>

  <div class="pb-3 col-md-12 attachments">
                  <span class="badge badge-light mr-2 p-2" *ngFor="let f of files">
                    {{f.name}}
                    <span
                      *ngIf="f.status !== 'success'">
                      ({{ 'tickets.mine.reply.upload-status.' + f.status | translate }})
                    </span>
                    <a class="remove-file-button" href="javascript:void(0)" (click)="remove(f)">&times;</a>
                  </span>
  </div>

  <div class="spinner" *ngIf="busy">
    <app-spinner></app-spinner>
  </div>

  <div class="modal-footer text-right">
    <button [disabled]="busy" class="mr-2 btn brn-primary text-primary bg-transparent border-primary" type="button"
            (click)="modal.hide()"
    >{{prefix + 'close-btn' | translate}}</button>
    <button class="btn btn-primary" type="submit"
            [disabled]="!form.valid || fileUploadInProgress || busy">{{prefix + 'send-btn' | translate}}</button>
  </div>
</form>

