<div class="modal-header">
  <p class="m-0 text-dark font-weight-bold">{{ prefix + 'title' | translate}}</p>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"
      stroke-linejoin="round"/>
</svg>
    </span>
  </button>
</div>
<div class="modal-body organizations-container">
  <p class="subtitle m-0">{{prefix + 'select-organization-status' | translate}}</p>
  <div class="mt-3 pb-3 px-5 row border-bottom" [formGroup]="form">
    <div class="col">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" name="status" type="radio" value="parent" id="parent" formControlName="status">
        <label class="text-dark custom-control-label" for="parent">{{prefix + 'parent' | translate}}</label>
      </div>
    </div>
    <div class="col text-right">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" name="status" type="radio" value="child" id="child" formControlName="status">
        <label class="text-dark custom-control-label" for="child">{{prefix + 'child' | translate}}</label>
      </div>
    </div>
  </div>
  <div class="search mt-4">
    <div class="search-input col-8">
      <app-form-control type="search-input" [prefix]="prefix + 'search'" hideLabel="true" [control]="form.get('search')" ></app-form-control>
    </div>
    <button class="search-button" (click)="search()">{{prefix + 'search.search-button' | translate}}</button>
  </div>
  <div class="organizations">
    <div *ngFor="let o of (searchedOrganizations  ? searchedOrganizations : organizations); let idx = index"
         class="row organization" [ngClass]="{selected: o.selected}">
      <div class="col px-4">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" [value]="o.id" [id]="o.id"
                 (change)="onCheckboxChanged($event, idx)">
          <label class="text-dark custom-control-label" [for]="o.id">{{o.name}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="next-btn" (click)="save()">{{prefix + 'save-btn' | translate}}</button>
</div>
