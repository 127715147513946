<div class="register-container">
  <nav class="navbar navbar-expand-md navbar-dark navbar-transparent">
    <a routerLink="/landing">
      <img src="../../../../assets/icons/logo-green-black.svg">
    </a>
  </nav>

  <!-- Steps form -->
  <div class="card align-middle">
    <div class="card-header">
      <div class="steps-form mb-4">
        <ul class="stepper">
          <li class="step" [ngClass]="{'next': step === 0, 'active': step > 0}"
          >
            <div class="step-text">
              <p class="text">1</p>
            </div>
          </li>
          <li class="step" [ngClass]="{'next': step === 1, 'active': step > 1}">
            <div class="step-text">
              <p class="text">2</p>
            </div>
          </li>
          <li class="step" [ngClass]="{'next': step === 2, 'active': step > 2}">
            <div class="step-text">
              <p class="text">3</p>
            </div>
          </li>
        </ul>
      </div>

      <div *ngIf="state !== 'complete'"  class="row text">
        <div class="col title"><h1 class="text-center text-dark m-0">{{ prefix + 'title' | translate }}</h1></div>
      </div>
        <div *ngIf="state !== 'complete'"  class="row text">
          <div class="col subtitle"><p class="text-center m-0">{{ prefix + 'subtitle' | translate }}</p></div>
        </div>

      <div *ngIf="state !== 'complete'"  class="register-type d-block">
        <div class="d-inline-block float-left text-left register-user active" [ngClass]="{'active': state == 'open'}">
          <h4 class="btn" (click)="switchState('open')">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.3389 16.1611C19.9774 14.7997 18.3569 13.7918 16.5888 13.1817C18.4825 11.8775 19.7266 9.69473 19.7266 7.22656C19.7266 3.24185 16.4847 0 12.5 0C8.51528 0 5.27344 3.24185 5.27344 7.22656C5.27344 9.69473 6.51753 11.8775 8.41123 13.1817C6.64316 13.7918 5.02266 14.7997 3.66118 16.1611C1.30024 18.5221 0 21.6611 0 25H1.95312C1.95312 19.1844 6.68442 14.4531 12.5 14.4531C18.3156 14.4531 23.0469 19.1844 23.0469 25H25C25 21.6611 23.6998 18.5221 21.3389 16.1611ZM12.5 12.5C9.59224 12.5 7.22656 10.1344 7.22656 7.22656C7.22656 4.31875 9.59224 1.95312 12.5 1.95312C15.4078 1.95312 17.7734 4.31875 17.7734 7.22656C17.7734 10.1344 15.4078 12.5 12.5 12.5Z" fill="#BEC1C8"/>
            </svg>
            <a class="ml-2 h-btn">{{'register.single-user' | translate }}</a>
          </h4>
        </div>
        <div class="d-inline-block float-right text-right register-org" [ngClass]="{'active': state != 'open'}">
          <h4 class="btn" (click)="switchState('new-org', true)">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.2219 18.9479C21.928 18.2107 22.3633 17.2122 22.3633 16.1133C22.3633 14.1682 21.0018 12.5361 19.1821 12.1171C18.8865 9.83745 17.4471 7.91191 15.4603 6.93613C16.1663 6.19902 16.6016 5.20049 16.6016 4.10156C16.6016 1.83994 14.7616 0 12.5 0C10.2384 0 8.39844 1.83994 8.39844 4.10156C8.39844 5.20049 8.83369 6.19902 9.53979 6.93613C7.55293 7.91191 6.11353 9.83745 5.81797 12.1171C3.99819 12.5361 2.63672 14.1682 2.63672 16.1133C2.63672 17.2122 3.07197 18.2107 3.77808 18.9479C1.54258 20.0457 0 22.3459 0 25H1.95312C1.95312 22.3615 4.09971 20.2148 6.73828 20.2148C9.37686 20.2148 11.5234 22.3615 11.5234 25H13.4766C13.4766 22.3615 15.6231 20.2148 18.2617 20.2148C20.9003 20.2148 23.0469 22.3615 23.0469 25H25C25 22.3459 23.4574 20.0457 21.2219 18.9479ZM12.5 1.95312C13.6847 1.95312 14.6484 2.91689 14.6484 4.10156C14.6484 5.28623 13.6847 6.25 12.5 6.25C11.3153 6.25 10.3516 5.28623 10.3516 4.10156C10.3516 2.91689 11.3153 1.95312 12.5 1.95312ZM6.73828 18.2617C5.55361 18.2617 4.58984 17.2979 4.58984 16.1133C4.58984 14.9286 5.55361 13.9648 6.73828 13.9648C7.92295 13.9648 8.88672 14.9286 8.88672 16.1133C8.88672 17.2979 7.92295 18.2617 6.73828 18.2617ZM15.3015 18.9479C14.1416 19.5175 13.1681 20.4106 12.5 21.5092C11.8319 20.4106 10.8584 19.5175 9.69849 18.9479C10.4046 18.2107 10.8398 17.2122 10.8398 16.1133C10.8398 14.2147 9.54292 12.6138 7.78867 12.1486C8.18672 9.90903 10.1479 8.20312 12.5 8.20312C14.8521 8.20312 16.8133 9.90903 17.2113 12.1486C15.4571 12.6138 14.1602 14.2147 14.1602 16.1133C14.1602 17.2122 14.5954 18.2107 15.3015 18.9479ZM18.2617 18.2617C17.0771 18.2617 16.1133 17.2979 16.1133 16.1133C16.1133 14.9286 17.0771 13.9648 18.2617 13.9648C19.4464 13.9648 20.4102 14.9286 20.4102 16.1133C20.4102 17.2979 19.4464 18.2617 18.2617 18.2617Z" fill="#BEC1C8"/>
            </svg>
            <a class="ml-2 h-btn">{{'register.organization' | translate }}</a>
          </h4>
        </div>
      </div>

      <div class="mt-4 radios" *ngIf="state !== 'open' && state !== 'complete'">
        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" class="custom-control-input" [checked]="state == 'new-org'"
                 (click)="switchState('new-org')" id="new-org" name="defaultExampleRadios">
          <label class="custom-control-label text-dark" for="new-org">{{'register.new-org' | translate }}</label>
        </div>

        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" class="custom-control-input" id="add-org" [checked]="state == 'add-org'"
                 (click)="switchState('add-org')" name="defaultExampleRadios">
          <label class="custom-control-label text-dark" for="add-org">{{'register.existing-org' | translate }}</label>
        </div>

        <div class="custom-control custom-radio d-inline-block">
          <input type="radio" class="custom-control-input" id="no-org" [checked]="state == 'no-org'"
                 (click)="switchState('no-org')" name="defaultExampleRadios">
          <label class="custom-control-label text-dark" for="no-org">{{'register.no-organization' | translate }}</label>
        </div>
      </div>
    </div>

    <div *ngIf="state !== 'complete'"  class="card-body" [ngClass]="{'mt-2': state !== 'open'}">
      <div [ngSwitch]="state">

        <!-- Registration Complete -->
        <div class="holder" [@fade] *ngSwitchCase="'complete'">
          <p>{{ 'register.thanks.content' | translate }}</p>
          <p [innerHtml]="'register.thanks.content2' | translate : user"></p>
          <ul class="list-unstyled">
            <li>{{ 'register.thanks.email' | translate }} <strong>{{ user.email }}</strong></li>
            <li *ngIf="user.organization">{{ 'register.thanks.organization' | translate }}
              <strong>{{ user.organization?.name }}</strong></li>
          </ul>

          <button (click)="goBack()" type="button" class="btn btn-block btn-outline-primary back-button">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </button>
          <button (click)="resetForms()" type="button" class="btn btn-primary cont-button">
            {{ 'register.thanks.another-button' | translate }}
          </button>
        </div>

        <!-- Organization unknown remove-->
        <!-- <div class="holder" *ngSwitchCase="'no-org'">
          <p>{{ 'register.no-org.content' | translate }}</p>
          <p>{{ 'register.no-org.content2' | translate }}</p>
          <p>{{ 'register.no-org.content3' | translate }}</p>

          <div class="row">
            <button class="btn btn-primary col m-1" (click)="state = 'add-org'">
              {{ 'register.no-org.org-code-button' | translate }}
            </button>
            <button class="btn btn-secondary col m-1" (click)="state = 'new-org'">
              {{ 'register.no-org.new-org-button' | translate }}
            </button>
          </div>
        </div> -->

        <!-- Add organization code to user -->
        <div class="holder" *ngSwitchCase="'add-org'" [@fade]>
          <app-add-org-to-user #code [user]="user" (onComplete)="onOrgCode($event)" (goToUser)="goToUser()"></app-add-org-to-user>
        </div>

        <div class="holder" [@fade] *ngSwitchDefault>
          <app-register-organization #orgReg [user]="user" (onRegistered)="onOrgRegistered($event)" (goToUser)="goToUser()"></app-register-organization>
        </div>

        <!-- User registration -->
        <div class="holder" *ngSwitchCase="'open'">
          <app-register-user (onRegistered)="onRegistered($event)"></app-register-user>
        </div>

        <div class="holder" *ngSwitchCase="'no-org'">
          <p>Your registration will be completed without Organization. Please click "Continue" to proceed.</p>
          <div class="buttons">
            <button type="button" (click)="goToUser()"
                    class="back-button">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM3 7H1L1 9H3V7Z"
                  fill="#06A3AE"/>
              </svg>
            </button>
            <button (click)="noOrgRegister()" type="button" class="btn btn-primary cont-button">{{ prefix + 'continue' | translate }}</button>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="state === 'complete'"  class="complete">
      <div class="w-100 m-0 p-0 row">
        <div class="col m-0 p-0 text-center">
          <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_1836)">
              <path d="M102.808 6.98557L64.4151 34.7419L58.1151 39.3071C54.7776 41.7727 50.2221 41.7727 46.8847 39.3071L40.5847 34.7419L2.14551 7.03115C4.28353 4.04122 7.73718 2.2717 11.413 2.28326H93.5868C97.2356 2.28647 100.663 4.03416 102.808 6.98557Z" fill="#FFD54F"/>
              <path d="M2.14582 7.03125L40.585 34.742L3.33285 71.9942C1.19376 69.8497 -0.00525018 66.9428 9.96757e-05 63.9137V13.6965C-0.00995806 11.3032 0.741376 8.96897 2.14582 7.03125Z" fill="#FFC107"/>
              <path d="M105 13.6962V63.9136C105.006 66.9427 103.807 69.8496 101.668 71.9941L64.4155 34.7419L102.809 6.98535C104.234 8.93227 105.002 11.283 105 13.6962Z" fill="#FFC107"/>
              <path d="M68.478 84.4571C65.4468 84.4571 63.9129 82.1744 63.9129 77.6092C63.9876 71.2307 58.8774 65.9996 52.4989 65.9249C46.1203 65.8503 40.8892 70.9604 40.8146 77.339C40.7399 83.7175 45.8501 88.9486 52.2286 89.0233C55.6472 89.0633 58.9078 87.5867 61.1327 84.9912C62.6484 87.5887 65.4729 89.1386 68.478 89.0222C77.6085 89.0222 77.6085 80.4306 77.6085 77.6092C77.6088 63.7422 66.3674 52.5004 52.5004 52.5C38.6333 52.4996 27.3915 63.7411 27.3911 77.6084C27.3909 91.4754 38.6322 102.717 52.4993 102.718C59.0144 102.718 65.2745 100.185 69.9572 95.6558C70.8637 94.7733 70.8829 93.3233 70.0006 92.4168C69.1181 91.5103 67.6681 91.4911 66.7616 92.3734C58.5954 100.25 45.5901 100.015 37.7136 91.8487C29.8371 83.6824 30.072 70.6771 38.2383 62.8006C46.4045 54.9241 59.4098 55.1591 67.2863 63.3253C70.985 67.1601 73.0492 72.2819 73.0434 77.6095C73.0434 82.7269 71.893 84.4571 68.478 84.4571ZM52.4999 84.4571C48.718 84.4571 45.6521 81.3912 45.6521 77.6092C45.6521 73.8273 48.718 70.7614 52.4999 70.7614C56.2819 70.7614 59.3478 73.8273 59.3478 77.6092C59.3478 81.3912 56.2819 84.4571 52.4999 84.4571Z" fill="#455A64"/>
              <path d="M64.4151 34.7412L58.1151 39.3064C54.7776 41.772 50.2221 41.772 46.8847 39.3064L40.5847 34.7412L3.33252 71.9934C5.47696 74.1324 8.38386 75.3315 11.4129 75.3259H22.94C24.1749 59.0018 38.4092 46.7694 54.7336 48.0044C69.3406 49.1094 80.95 60.7188 82.0551 75.3259H93.5868C96.6159 75.3312 99.5228 74.1324 101.667 71.9934L64.4151 34.7412Z" fill="#FFA000"/>
            </g>
            <defs>
              <clipPath id="clip0_2_1836">
                <rect width="105" height="105" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <h1 class="complete-title text-center text-dark m-0">{{ prefix + 'complete-title' | translate }}</h1>
      <p class="complete-subtitle text-center">{{ prefix + 'complete-subtitle' | translate }}</p>
      <div class="col text-center">
        <button (click)="toLogin()" class="sign-in-button">{{prefix + 'sign-in' | translate}}</button>
      </div>
    </div>
  </div>
</div>
