import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TestKit } from '../../model/testKit';
import { StockResponse } from 'src/model/api-response';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private api: ApiService) { }

  async getStock(): Promise<StockResponse> {
    const result = await this.api.get('u/stock');
    result.testKits = result.testKits.map((d) => TestKit.deserialize(d));
    //result.documents = result.documents.map((d) => Document.deserialize(d));

    return result;
  }

  async addTestKit(tk: TestKit): Promise<TestKit> {
    const result = await this.api.post('u/test-kit', tk.serialize());
    return result.testKit
  }

  async updateTestKit(tk: TestKit): Promise<void> {
    await this.api.put('u/test-kit', tk.serialize());
  }

  async deleteTestKit(id: number): Promise<void> {
    await this.api.delete(`u/test-kit/${id}`);
  }
}
