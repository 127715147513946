<div class="modal-header" *ngIf="title">
  <h5 class="modal-title pull-left">{{ title | translate : params }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="content || withComment">
  <p class="content" [innerHtml]="content | translate : params"></p>

  <div class="form-group" *ngIf="withComment">
    <textarea #input [(ngModel)]="text" placeholder="{{ placeholder | translate : params }}" class="form-control" rows="3"></textarea>
  </div>

</div>
<div class="modal-footer">
  <button type="button" (click)="close(false)" class="btn btn-light" data-dismiss="modal">{{ cancelButton | translate : params }}</button>
  <button type="button" (click)="close(true)" class="btn btn-primary">{{ okButton | translate : params }}</button>
</div>