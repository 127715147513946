import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {FormControl} from '@angular/forms'
import {ApiService} from '../../services/api.service'
import bsCustomFileInput from 'bs-custom-file-input'
import {FileUploadStatus} from '../file-upload/file-upload.component'
import {Import} from '../../../model/import'

@Component({
  selector: 'app-file-upload-samples',
  templateUrl: './file-upload-samples.component.html',
  styleUrls: ['./file-upload-samples.component.scss']
})

export class FileUploadSamplesComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef

  @Output() progress = new EventEmitter<FileUploadStatus>()
  @Input() uploadImmediately = true
  @Input() prefix = 'file'
  @Input() control: FormControl
  @Input() bg = ''
  @Input() disable = false
  @Input() format: "default" | "button" | "card" | 'custom-card' | 'userpic' = "default"
  @Input() fileFormats = '*'
  @Input() onlyCsv = false
  @Input() deviceId
  @Input() useHashes = true

  get errors() {
    if (!this.control || !this.control.errors) return []
    return Object.getOwnPropertyNames(this.control.errors)
  }

  private _file: File

  private _status: FileUploadStatus = {
    done: false,
    status: "idle",
    fileSelected: false
  }
  get status() {
    return this._status
  }

  set status(value: FileUploadStatus) {
    this._status = value
    this.progress.emit(value)
  }

  constructor(
    private api: ApiService,
  ) {
  }

  ngOnInit() {
    bsCustomFileInput.init()
  }


  fileSelected(files: File[]) {

    // TODO: The file doesn't re-upload after it's removed from the import form. Need to fix this before pushing to production.
    if (files.length === 0) return
    this.status = {name: files[0].name, done: false, status: "ready", fileSelected: true}

    this._file = files[0]
    if (this.control) this.control.setValue(this._file.name)
    if (this.uploadImmediately) this.upload()
  }

  async upload(forUser?, checkLength = true): Promise<FileUploadStatus> {
    if (!this.deviceId) return
    const uuid = forUser ? forUser.uuid : null
    if (this.status.done) return this.status
    this.status = {status: "uploading", done: false, fileSelected: true, name: this._file.name}
    try {
      const i: Import = await this.api.postFileSamples(this._file, this.deviceId, this.useHashes, uuid, checkLength)
      this.status = {status: "success", done: true, i, fileSelected: true, name: this._file.name}
    } catch (err) {
      this.status = {status: "error", done: true, fileSelected: true, name: this._file.name, message: err.error.reason}
    }
    return this.status
  }

  delete() {
    this.fileInput.nativeElement.value = ''
    this._file = null
    this.status.done = false
  }
}

