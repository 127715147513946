import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-inline-input',
  templateUrl: './inline-input.component.html',
  styleUrls: ['./inline-input.component.scss']
})
export class InlineInputComponent implements OnInit, OnDestroy {
  @ViewChild("input") input: ElementRef;

  private _value: string;
  get value() { return this._value; }
  @Input() set value(v: string) {
    if (v === this._value) return;
    this._value = v;
    this.valueChange.emit(v);
  }
  @Output() valueChange = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @Input() emptyText = "input.empty-text";
  @Input() placeholder = "";
  @Input() position = "right";
  @Input() editIfEmpty = false;
  @Input() header = true;
  @Input() textOverflow = true;

  view = "show"
  newValue: string;

  private dispose: Function;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.editIfEmpty && !this.value) {
      setTimeout(() => {this.setView("edit")});
    }
  }

  ngOnDestroy() {
    if (this.dispose) this.dispose();
  }

  private checkClose(e: any) {
    if (this.view !== "edit") return;

    //Check to see if we are clicking outside of the element.
    // If so, we should go back to "show mode".
    if (!this.el.nativeElement.contains(e.target)) {
      this.submit();
      if (this.dispose) this.dispose();
    }
  }

  submit(e?: Event) {
    const old = this.value;

    this.value = this.newValue;
    this.setView('show');
    this.onSubmit.next({ old, new: this.value });

    if (e) e.stopPropagation();
  }

  keyDown(e: KeyboardEvent) {
    if (e.key === "Enter") this.submit();
    if (e.key === "Escape") {
      this.setView("show");
      e.stopPropagation();
    }
  }

  setView(view: "edit" | "show", e?: Event) {
    if (e) e.stopPropagation();
    console.log("Setting view", view);

    this.view = view;

    //Edit mode
    if (this.view === "edit") {
      //Setup listener to auto-close
      this.dispose = this.renderer.listen('document', 'click', e => this.checkClose(e));

      this.newValue = this.value;
      setTimeout(() => {
        if (this.input) this.input.nativeElement.focus();
      });
    }
  }

}
