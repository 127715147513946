import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core'

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnChanges {
  @Input() current: number
  @Input() last: number
  @Input() marginBottom = 16
  @Output() pageChanged = new EventEmitter<number>()

  pages: iPage[] = []

  options = {
    maxPages: 5,
  }

  constructor() {
    setTimeout(() => {
      console.log('pages', this.pages)
    }, 3000)
  }

  ngOnChanges() {
    this.buildButtons()
  }

  select(index: number) {
    this.current = index
    this.pageChanged.next(index)
    // this.buildButtons();
  }

  next() {
    this.select(this.current + 1)
  }

  prev() {
    this.select(this.current - 1)
  }

  toFirst() {
    this.select(0)
  }

  toLast() {
    this.select(this.last)
  }

  private buildButtons() {
    const pages = []
    let label = 1
    if (this.last <= 4) {
      for (let i = 0; i <= this.last; i++) {
        pages.push(this.addPage('number', i === this.current, label, i))

        label++
      }

      this.pages = pages
    } else {
      // Print first page
      pages.push(this.addPage('number', 0 === this.current, 1, 0))

      // Print "..." if currentPage is > 3
      if (this.current > 1) {
        pages.push(this.addPage('dots'))
      }

      //Print the second page from the end when the last page is selected
      if (this.current === this.last) {
        pages.push(this.addPage('number', false, this.current - 1, this.current - 2))
      }

      // Print previous number button if currentPage > 2
      if (this.current > 2) {
        pages.push(this.addPage('number', false, this.current, this.current - 1))
      }

      //Print current page number button as long as it not the first or last page
      if (this.current !== 0 && this.current !== this.last) {
        pages.push(this.addPage('number', false, this.current + 1, this.current))
      }

      //print next page number button if currentPage < lastPage - 1
      if (this.current < this.last - 1) {
        pages.push(this.addPage('number', false, this.current + 2, this.current + 1))
      }

      // special case where first page is selected...
      // Without this special case, only 2 buttons would be shown before ... if you were on first page
      if (this.current === 0) {
        pages.push(this.addPage('number', false, this.current + 3, this.current + 2))
      }

      //print "..." if currentPage is < lastPage -2
      if (this.current < this.last - 2) {
        pages.push(this.addPage('dots'))
      }

      // Print the last page
      if (this.last > 1) {
        pages.push(this.addPage('number', this.current === this.last, this.last + 1, this.last))
      }

      this.pages = pages
    }
  }

  addPage(type: string, active?: boolean, label?: number, index?: number) {
    if (type === 'dots') {
      console.log('dots')
      return {type: 'dots'}
    } else if (type === 'number') {
      return {
        active,
        label,
        index,
        type: 'number'
      }
    }
  }

  // private buildButtons() {
  //   if (this.current === undefined || this.last === undefined) return;
  //
  //   const pages: iPage[] = [];
  //   const low = this.firstToShow();
  //   const high = this.lastToShow();
  //   let label = low + 1;
  //   for (let i = low; i <= high; i++) {
  //     pages.push({
  //       active: i === this.current,
  //       label,
  //       index: i
  //     });
  //
  //     label++;
  //   }
  //
  //   this.pages = pages;
  // }
  //
  // private firstToShow(): number {
  //   const middle = Math.ceil(this.options.maxPages / 2);
  //   if (this.current <= middle) {
  //     return 0;
  //   }
  //   return this.current - (middle - 1);
  // }
  //
  // private lastToShow(): number {
  //   const middle = Math.floor(this.options.maxPages / 2);
  //
  //   if (this.current >= this.last - middle) {
  //     return this.last;
  //   }
  //
  //   return this.current + middle;
  // }

}

interface iPage {
  active?: boolean;
  label?: number;
  index?: number;
  type: string
}
