import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  state: "idle" | "no-token" | "done" = "idle";
  token: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasts: ToastService,
  ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('token')) {
      this.token = this.route.snapshot.queryParamMap.get('token');
    } else {
      this.state = "no-token";
    }
  }

  onComplete() {
    this.state = 'done';
    this.router.navigate(['/auth/login']);
  }

  onError(res: any) {
    console.log("Something is messed up", res);
    if (res.error && res.error.reason === "Invalid reset token.") {
      this.state = 'no-token';
    } else {
      this.toasts.warning("password-reset.error-toast");
    }
  }

}
