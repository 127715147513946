import { Moment } from 'moment';
import * as moment from 'moment';

import { toObject } from './helpers';
import { Sample } from './sample';
import {User} from './user'
import {Device} from "./device";

export class Import {

  id: number = null;
  fileReference: string = null;
  userUuid: string = null;
  created: Moment = null;
  processed?: boolean = null;
  deviceId: number = null;
  device: Device = null;
  isDuplicate = false;
  user: User = null

  samples: Sample[] = [];

  static deserialize(data: any): Import {
    let i = toObject<Import>(Import, data);

    if (data.created) i.created = moment.unix(data.created);

    i.samples = [];
    if (data.samples){
      for (let s of data.samples) {
        i.samples.push(Sample.deserialize(s));
      }
    }

    return i;
  }

  serialize() {
    const o: any = {...this};

    if (this.created) o.created = this.created.unix();

    delete o._attachment;

    o.samples = [];
    for (let s of this.samples) {
      o.samples.push(s.serialize());
    }

    return o;
  }
}
