import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpClient,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/model/user';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { Notification } from '../../model/notification';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // config: any

  constructor(
    private store: StoreService,
    private cache: CacheService
  ) {
    // fetch('assets/config.json')
    //   .then(r => r.text())
    //   .then(config => this.config = JSON.parse(config))
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // try to use observable and angular interceptor instead of promise and custom interceptor in apiService
    const user: User = this.store.get('user', null, User);
    const url = /assets|localhost:3001|icheck/.test(request.url) ?
      request.url :
      environment.api + request.url;

    request = request.clone({
      url,
      setHeaders: {
        Authorization: user ? `bearer ${user.session.token}` : '',
        'X-Client-Name': 'icheck-connect-pwa',
        'X-Client-Version': '1',
      }
    });

    return next.handle(request)
    .pipe(
      switchMap((response: any) => {
        if (response.notifications) {
          this.cache.notifications.update(Notification.manyFromApi(response.notifications));
        }

        return of(response)
      }),
    //   catchError((error: HttpErrorResponse) => {
    //     promiseApi.handleErrors(error);
    //     return throwError(error);
    //   })
    );
  }
}
