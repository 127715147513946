<div class="modal-header">
  <h5 class="modal-title pull-left">{{ title | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form (ngSubmit)="modal.hide()">
    <div class="input-group mb-3">
      <input #input type="text" class="form-control" placeholder="{{ placeholder | translate }}"
        name="text-input" [(ngModel)]="text">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="submit">
          <i class="fa fa-check"></i>
        </button>
      </div>
    </div>
  </form>
</div>