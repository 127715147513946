import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transfer-data-guide',
  templateUrl: './transfer-data-guide.component.html',
  styleUrls: ['./transfer-data-guide.component.scss']
})
export class TransferDataGuideComponent implements OnInit {
  prefix = "transfer-data."

  constructor() { }

  ngOnInit(): void {
  }

}
