import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {SelectOption} from '../form-control/form-control.component'

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() prefix
  @Input() items: SelectOption[]
  @Input() selectFirst = false
  @Input() hideLabel = false
  @Input() fontSize = 14
  @Input() selectedItem: SelectOption
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>()

  get getPrefix() {
    return `dropdowns.${this.prefix}.`
  }

  constructor() { }

  ngOnInit(): void {
    if (this.selectFirst) {
      console.log('this.items[0]', this.items[0])
      this.selectedItem = this.items[0]
    }
  }

  selectItem(item) {
    this.selectedItem = item
    this.itemSelected.emit(item)
  }

}
