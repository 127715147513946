import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {Injectable} from '@angular/core'

import {environment} from '../../environments/environment'
import {Notification} from '../../model/notification'
import {CacheService} from './cache.service'
import {ToastService} from './toast.service'
import {User} from '../../model/user'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  headers: any = {
    'X-Client-Name': 'icheck-connect-pwa',
    'X-Client-Version': '1',
  }

  // private _ready: Promise<void>

  constructor(
    private http: HttpClient,
    private toasts: ToastService,
    private cache: CacheService,
  ) {
    //Load config
    // this._ready = new Promise(resolve => {
    //   return this.http.get("assets/config.json").toPromise()
    //     .then(c => {
    //       Object.assign(environment, c)
    //       resolve()
    //     })
    //     .catch(err => {
    //       console.warn("Couldn't load supplement config", err)
    //       resolve()
    //     })
    // })
  }

  // public ready() {
  //   return this._ready
  //   // return this.http.get("assets/config.json").subscribe(c => Object.assign(environment, c), console.error)
  // }

  public setToken(token: string) {
    this.headers.Authorization = `bearer ${token}`
  }

  public unsetToken() {
    delete this.headers.Authorization
  }

  public async get(path: string): Promise<any> {
    // await this.ready()
    return this.http
      .get(environment.api + path, {headers: this.headers})
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err))
  }

  public async delete(path: string): Promise<any> {
    // await this.ready()
    return this.http
      .delete(environment.api + path, {headers: this.headers})
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err))
  }

  public async put(path: string, body: any): Promise<any> {
    // await this.ready()
    return this.http
      .put(environment.api + path, body, {headers: this.headers})
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err))
  }

  public async post(path: string, body: any): Promise<any> {
    // await this.ready()
    return this.http
      .post(environment.api + path, body, {headers: this.headers})
      .toPromise()
      .then(response => this.handleCommonResponse(response))
      .catch((err) => this.handleErrors(err))
  }

  public async postFile(file: File, forUserUuid?): Promise<any> {
    const path = "u/upload"
    const form = new FormData()
    form.append('file', file)
    if (forUserUuid) {
      form.append('forUserUuid', forUserUuid)
    }
    return this.http
      .post(environment.api + path, form, {headers: this.headers})
      .toPromise()
      .then((res: any) => res.reference || 'no-reference')
      .catch((err) => this.handleErrors(err))
  }

  public async postFileSamples(file: File, deviceId, useHashes, forUserUuid?, checkLength = true): Promise<any> {
    const path = "u/upload-samples"
    const form = new FormData()
    form.append('file', file)
    // form.append('deviceId', deviceId)
    // form.append('useHashes', useHashes)

    const data: any = {
      deviceId,
      useHashes,
      checkLength
    }
    if (forUserUuid) data.forUserUuid = forUserUuid

    form.append('data', JSON.stringify(data))
    return this.http
      .post(environment.api + path, form, {headers: this.headers})
      .toPromise()
      .then((res: any) => res.import || 'no-import')
      .catch((err) => this.handleErrors(err))
  }

  public async downloadFile(path: string): Promise<number> {
    try {
      const response: any = await this.http
        .get(environment.api + path, {headers: this.headers})
        .toPromise()

      window.location.href = response.link
      return 200
    } catch (err) {
      console.log("An error happened", err)
      return err.status || 0
    }
  }

  handleCommonResponse(response: any) {
    if (response.notifications) {
      this.cache.notifications.update(Notification.manyFromApi(response.notifications))
    }
    return response
  }

  handleErrors(e: HttpErrorResponse) {
    if (e.error.reason === 'INVALID_UNIT') {
      this.toasts.warning("Invalid sample units in the file")
      console.error("Invalid sample units in the file", e)
    } else if (e.error.reason === 'NO_SAMPLES_OR_DAMAGED') {
      this.toasts.warning("There are no samples or they are damaged")
      console.error("There are no samples or they are damaged", e)
    } else if (e.error.reason === 'LESS_THAN_3_SAMPLES') {
      this.toasts.warning("Uploaded file contains less then 3 samples. Please upload 3 and more samples for more accurate results.")
      console.error("Uploaded file contains less then 3 samples. Please upload 3 and more samples for more accurate results.", e)
    }  else if (e.error.reason === 'ATTACHMENT_WITHOUT_IMPORT') {
      this.toasts.warning("I found an attachment but can't find an import for it")
      console.error("I found an attachment but can't find an import for it", e)
    } else if (e.status === 500) {
      if (e.error.error.code === 'ER_WARN_DATA_OUT_OF_RANGE') {
        this.toasts.danger('upload.out-of-range-error')
        console.error("HttpResponse error", e)
      } else {
        this.toasts.warning("errors.unknown")
        console.error("HttpResponse error", e)
      }
    } else if (e.status === 0) {
      this.toasts.warning("errors.service-down")
      console.error("User service is down", e)
    }
    console.log("HttpResponse error", e)
    throw e
  }
}
