import {Component, OnInit, ViewChild} from '@angular/core'
import {FileUploadStatus, FileUploadComponent} from '../file-upload/file-upload.component'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import {DocsService} from '../../services/docs.service'
import {Document} from '../../../model/document'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {CacheService} from '../../services/cache.service'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {User} from '../../../model/user'
import {Subscriptions} from '../../../model/subscriptions'
import {UserService} from '../../services/user.service'
import {DataService} from '../../services/data.service'

@Component({
  selector: 'app-upload-doc-modal',
  templateUrl: './upload-doc-modal.component.html',
  styleUrls: ['./upload-doc-modal.component.scss']
})
export class UploadDocModalComponent implements OnInit {
  @ViewChild('uploader') uploader: FileUploadComponent

  prefix = 'upload-doc.'
  form: FormGroup
  showDescription = false
  showSupport = true
  busy = false
  modalResult: Document = null
  showError = false
  ddFile: File[] = null
  users: User[]
  private _subs = new Subscriptions()

  role = null
  selectOptions = []
  userOptions = []

  constructor(
    private dm: DocsService,
    private modal: BsModalRef,
    private admin: AdminService,
    private us: UserService,
    private ds: DataService,
    cache: CacheService,
  ) {
    this.selectOptions = cache.docTypes.value.map(v => {
      return {name: 'document.type.' + v, value: v}
    })
    this.form = new FormGroup({
      fileReference: new FormControl(''),
      documentType: new FormControl('other', Validators.required),
      user: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      description: new FormControl(''),
      file: new FormControl('', Validators.required),
      forSupport: new FormControl(false),
      toUser: new FormControl(false),
    })

    this.form.get('forSupport').valueChanges.subscribe((value: boolean) => {
      if (value === true) {
        this.showDescription = true
        this.form.get('description').setValidators(Validators.required)
      } else {
        this.showDescription = false
        this.form.get('description').clearValidators()
      }
    })
    this.form.get('toUser').valueChanges.subscribe((value: boolean) => {
      this.form.get('forSupport').setValue(false)
      this.showDescription = false
      if (value === true) {
        this.showSupport = false
        this.form.get('user').setValidators(Validators.required)
      } else {
        this.showSupport = true
        this.form.get('user').clearValidators()
      }
    })
  }

  async ngOnInit() {
    this._subs.add(this.us.userChanged.subscribe(u => {
      this.role = u.getRole()
    }))
    if (this.role === 'superAdmin' ||
      this.role === 'admin' ||
      this.role === 'manager'
    ) {
      this.users = await this.ds.getAllUsers()
      this.userOptions = this.users.map(u => {
        return {
          name: `${u.firstName} ${u.lastName}`,
          value: u.uuid
        }
      })
    }
    setTimeout(() => {
      if (this.ddFile) {
        this.uploader.fileInput.nativeElement.files = this.FileListItems(this.ddFile)
        this.uploader.fileSelected(this.ddFile)
      }
    }, 100)
  }

  FileListItems(files) {
    let b = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i])
    return b.files
  }


  uploadProgress(e: FileUploadStatus) {
    if (!e.name || e.status !== 'ready') return

    //Set the filename
    const nameParts = e.name.split('.')
    nameParts.pop()
    const name = nameParts.join('.')
    this.form.get('name').setValue(name)
  }

  async upload() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.busy = true
    let user = null
    if ((this.role === 'superAdmin' ||
      this.role === 'admin' ||
      this.role === 'manager') ||
      !this.form.get('forSupport').value) {
      user = this.users.find(u => u.uuid === this.form.get('user').value)
    }

    //Upload the file
    const result = await this.uploader.upload(user)
    console.log('result', result)
    if (result.status !== "success") {
      this.showError = true
      throw new Error("File Upload failed.")
    }
    this.form.get('fileReference').setValue(result.reference)

    //Upload the metadata
    const doc = await this.dm.upload(this.form.value, user)

    this.busy = false

    console.log('doc', doc)
    this.modalResult = doc
    this.close()
  }

  close() {
    this.modal.hide()
  }

}
