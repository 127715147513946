<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'edit-title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy">
      <div class="row">

        <app-form-control
          *ngIf="typeOptions.length; else selectedDevice"
          class="col-md-6" [showInfo]="false"
          [prefix]="prefix + 'type'"
          [control]="form.get('type')"
          type="new-select"
          [selectOptions]="typeOptions">
        </app-form-control>

        <ng-template #selectedDevice>
          <div class="selected-device-type col-md-6">
            <p class="m-0 mb-2">{{prefix + 'type.label' | translate}}</p>
            <div class="device-container border rounded">
              <p class="device m-0">{{ deviceType }}</p>
            </div>
          </div>
        </ng-template>

        <app-form-control
          class="col-md-6"
          type="new-input"
          [showInfo]="true"
          [prefix]="prefix + 'lot'"
          [placeholder]="getDeviceType()"
          [control]="form.get('lot')">
        </app-form-control>
      </div>
      <div class="row mb-2">
        <p class="col-md-12 label-header mb-0">{{prefix + 'device-control' | translate}}</p>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <app-form-control
              class="col-6"
              [showInfo]="false"
              [prefix]="prefix + 'min-range'"
              [control]="form.get('range').get('minRange')"
              [placeholder]="minRanges[form.get('type').value]"
              type="new-number">
            </app-form-control>
            <app-form-control
              class="col-6"
              [showInfo]="false"
              [prefix]="prefix + 'max-range'"
              [placeholder]="maxRanges[form.get('type').value]"
              [control]="form.get('range').get('maxRange')"
              type="new-number">
            </app-form-control>
          </div>
          <ng-container *ngIf="form.get('range').errors && form.get('range').get('maxRange').touched">
            <small class="form-text text-danger"
                   *ngIf="form.get('range').errors?.invalidRange">{{ prefix + 'max-range.error-invalidRange' | translate }}</small>
          </ng-container>
        </div>
        <div class="col-md-6">
          <app-form-control
            type="new-input"
            [prefix]="prefix + 'unit'"
            [control]="form.get('unit')"
          ></app-form-control>
        </div>
      </div>

      <div class="text-right">
        <button *ngIf="!newDevice" type="button" class="btn btn-danger mr-2" (click)="remove()">
          <i class="fa fa-times fa-fw" aria-hidden="true"></i>
          {{ prefix + 'remove' | translate }}
        </button>
        <button type="submit" class="btn btn-primary" [ngClass]="{'btn-block': newDevice}">
          <i class="fa fa-check fa-fw" aria-hidden="true"></i>
          {{ prefix + 'edit-save' | translate }}
        </button>
      </div>

      <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>
    </fieldset>
  </form>


</div>
