<div class="form-group">
  <label [class.d-none]="format !== 'default'" for="file-upload-input" class="text-dark fz-14">{{ prefix + '.label' | translate }} <span *ngIf="onlyCsv" class="text-danger"> (Only csv files allowed)</span></label>
  <div class="custom-file" [class.d-none]="format !== 'default'">
    <span class="placeholder-hack p-2" *ngIf="!status.fileSelected">{{ prefix + '.placeholder' | translate }}</span>
    <input [disabled]="disable" [accept]="fileFormats" #fileInput id="file-upload-input" type="file" class="custom-file-input"
           (change)="fileSelected($event.target.files)"
           [class.is-invalid]="control?.invalid && control?.touched"
           [class.is-valid]="control?.valid && control?.touched">
    <label [ngStyle]="{'opacity': disable ? '0.7' : '1'}" class="custom-file-label"
           for="file-upload-input">{{ 'file.browse' | translate }}</label>
  </div>

  <label for="file-upload-input" [ngStyle]="{'opacity': disable ? '0.7' : '1'}" [class.d-none]="format !== 'button'"
         class="btn btn-sm btn-link">
    <i class="fa fa-plus"></i>
    {{ prefix + '.label' | translate }}
  </label>

  <label for="file-upload-input" [ngStyle]="{'opacity': disable ? '0.7' : '1'}" [class.d-none]="format !== 'card'">
    <div class="card custom-button">
      <div class="card-body middle">
        <i class="fa fa-file-text-o mr-2 fa-2x"></i>
        {{ prefix + '.label' | translate }}
      </div>
    </div>
  </label>

  <label for="file-upload-input" [ngStyle]="{'opacity': disable ? '0.7' : '1'}"
         [class.d-none]="format !== 'custom-card'">
    <div class="card custom-card custom-button" [ngStyle]="{'background-color': bg ? bg : 'white'}">
      <div class="card-body middle">
        <i class="fa fa-file-text-o mr-2 fa-2x"></i>
        {{ prefix + '.label' | translate }}
      </div>
    </div>
  </label>


  <label for="file-upload-input" class="m-0 userpic btn btn-primary rounded-circle"
         [ngStyle]="{'opacity': disable ? '0.7' : '1'}" [class.d-none]="format !== 'userpic'">
    <img src="assets/icons/camera.svg">
  </label>


  <ng-container *ngIf="control && control.invalid && control.touched">
    <div *ngFor="let e of errors">
      <small class="form-text text-danger">{{ prefix + '.error-' + e | translate : control.errors }}</small>555
    </div>
  </ng-container>
</div>
