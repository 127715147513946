<div class="devices-container">
  <section class="devices card">
    <div class="card-body">
      <div class="row">
        <!-- New device BIG button -->
        <div class="add-device-btn text-nowrap">
          <div class="card-add-device card card-light shadow pointer" (click)="addDevice('advanced')">
            <div class="text-center card-body pl-1">
              <h5 class="new-device m-0">
                <img class="img-fluid d-inline" src="assets/icons/icon-add-device.svg">
                <p class="d-inline m-0">{{ 'settings.add-device' | translate }}</p>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
