import * as moment from 'moment';

export function round(n: number, decimals = 2) {
  if (!n) return n;

  const factor = Math.pow(10, decimals);
  return Math.round(n * factor) / factor;
}

export function toObject<T>(type: { new(): T }, data: any, mapping: ObjectMapping = {}): T {
  const o = new type();
  for (let key in o) {
    if (!o.hasOwnProperty(key)) continue;
    if (data[key] === undefined) continue;

    o[key] = mapValue(data[key], mapping[key]);
  }

  return o;
}

function mapValue(value: any, action: string | Function ): any {
  //No mapping
  if (!action) return value;

  //Don't do nulls and undefined.
  if (value === null || value === undefined) return value;

  //Deserializable
  if (typeof action['deserialize'] === "function") return action['deserialize'](value);

  //Function
  if (typeof action === "function") return action(value);

  //Possible string values: Moment
  const lower = action.toLowerCase();
  if (lower === "moment") return moment.unix(value);

  if (lower === "number") return Number(value);

  //Add more special cases here

  console.error("Unknown action", action);
  throw "Unknown action";
}

function isClass(value: any) {
  return (value && value.constructor && value.constructor.name === "Function")
}

export function notEmpty(obj: any) {
  return obj !== null && obj !== undefined && obj !== "" && obj.length !== 0;
}


export interface ObjectMapping {
  [prop: string]: string | Function;
}

export interface BetterKeyboardEvent extends KeyboardEvent {
  path: Element[];
  target: Element;
}

export interface BetterMouseEvent extends MouseEvent {
  path: Element[];
  target: Element;
}
