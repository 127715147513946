import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { User } from '../../../model/user';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnChanges {
  static DEFAULT_IMAGE_URL = "assets/img/profile.png";

  @Input() user: User;
  @Input() size: string = 'auto';
  imageUrl: string = null;
  style: any = {};

  constructor() { }

  ngOnChanges(c: SimpleChanges) {
    if (!this.user) {
      this.imageUrl = null;
      return;
    }

    this.imageUrl = this.user.profileImageUrl || ProfileImageComponent.DEFAULT_IMAGE_URL;
    this.style = { width: this.size, height: this.size };
  }

}
