/// <reference types="@types/googlemaps" />
import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core'
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms'
import {ActivatedRoute, Router} from '@angular/router'
import * as zxcvbn from 'zxcvbn'

import {fade} from 'src/model/shared-animations'
import {UserService} from 'src/app/services/user.service'
import {ModalService} from 'src/app/shared/modal.service'

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
  animations: [fade]
})
export class RegisterUserComponent implements OnInit {
  passwordStrength: zxcvbn.ZXCVBNResult = zxcvbn('')
  triedSubmit = false
  loading = false
  form: FormGroup
  prefix = 'register.user.'
  @Output() onRegistered = new EventEmitter<any>()
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef

  get email() {
    return this.form.get('email')
  }

  get lname() {
    return this.form.get('lname')
  }

  get fname() {
    return this.form.get('fname')
  }

  get position() {
    return this.form.get('position')
  }

  get city() {
    return this.form.get('city')
  }

  get password() {
    return this.form.get('passwords').get('password')
  }

  get password2() {
    return this.form.get('passwords').get('password2')
  }

  get passwords() {
    return this.form.get('passwords')
  }

  get organizationCode() {
    return this.form.get('organizationCode')
  }

  get phone() {
    return this.form.get('phone')
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private modals: ModalService,
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, this.emailValidator], this.checkEmailExist(this)),
      fname: new FormControl('', [Validators.required, this.isEmptyValidator]),
      lname: new FormControl('', [Validators.required, this.isEmptyValidator]),
      city: new FormControl('', [Validators.required, this.isEmptyValidator]),
      position: new FormControl('', [Validators.required, this.isEmptyValidator]),
      phone: new FormControl('', [Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      passwords: new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(8), this.passwordStrengthValidator(this)]),
        password2: new FormControl(''),
      }, this.passwordsMatchValidator),
      orgCode: new FormControl(),
    })

    if (this.route.snapshot.queryParamMap.has('orgCode')) {
      this.form.get('orgCode').setValue(this.route.snapshot.queryParamMap.get('orgCode'))
    }

    const userCred = JSON.parse(localStorage.getItem('userCred'))
    if (userCred) {
      this.setForm(userCred)
    }

  }

  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/)
      return matches ? null : {invalidEmail: true}
    } else {
      return null
    }
  }

  checkEmailExist(context: RegisterUserComponent) {
    return (control: AbstractControl) => {
      return context.userService.checkUserExist(control.value)
        .then(result => {
          return result ? {emailExists: true} : null
        })
    }
  }

  setForm(userCred) {
    const keys = Object.keys(userCred)
    for (const k of keys) {
      if (this.form.get(k)) {
        this.form.get(k).setValue(userCred[k])
      }
    }
  }


  public goBack() {
    this.router.navigateByUrl('/auth/login')
  }

  passwordStrengthValidator(context: RegisterUserComponent) {
    return (ctrl: AbstractControl) => {
      const result = zxcvbn(ctrl.value)
      context.passwordStrength = result
      if (result.score > 1) return null

      return {weakPassword: result}
    }
  }

  passwordsMatchValidator(passwords: FormGroup) {
    if (passwords.get('password').value === passwords.get('password2').value) {
      if (passwords.get('password2').hasError('notSame')) {
        passwords.get('password2').updateValueAndValidity()
        // delete passwords.get('password2').errors['notSame']
      }
      return null
    }
    passwords.get('password2').setErrors({notSame: true})
    return {notSame: true}
  }

  async submit() {
    this.triedSubmit = true
    this.form.markAllAsTouched()

    if (this.form.invalid) return
    this.loading = true


    const reg = {...this.form.value}
    reg.name = reg.fname.trim() + ' ' + reg.lname.trim()

    delete reg.passwords
    reg.password = this.password.value

    // const domainOrg = await this.userService.findOrgByDomain(this.form.get('email').value)
    this.onRegistered.emit({cred: reg, domainOrg: null})
    this.loading = false
  }

  private isEmptyValidator(c: AbstractControl) {
    const v = c.value
    if (!/\S/.test(v)) {
      return {invalidName: true}
    }
  }
}
