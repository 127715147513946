import {Moment} from 'moment'

import {ControlChartOptions, iControlSeries, TrainingUsageChartOptions} from './chart-options'
import {TrainingControlValue} from './control-value'
import {round, toObject} from './helpers'
import {Sample} from './sample'
import {User} from './user'
import {UsageChartData} from './api-response'
import * as moment from 'moment'

export class TrainingAttempt {
  static SUCCESS_THRESHOLD = 4
  static FAILURE_THRESHOLD = 2.5

  id: number = null
  userUuid: string = null
  date: Moment = null
  cv: number = null
  sd: number = null
  mean: number = null
  sd2: number = null
  sd3: number = null
  deviceId: number = null
  productId: number = null

  // How clustered the results are (i.e. lower cv = better)
  precision: number = null

  // How close we are to the target
  accuracy: number = null

  user: User = null
  samples: Sample[] = []

  controlChart: ControlChartOptions
  chartData = null
  usageChart = null

  get precisionScore(): "good" | "meh" | "poor" {
    if (this.precision >= TrainingAttempt.SUCCESS_THRESHOLD) return "good"
    if (this.precision >= TrainingAttempt.FAILURE_THRESHOLD) return "meh"
    return "poor"
  }

  setTrainingChart(controlValue: TrainingControlValue) {
    const STANDARD_COLOR = '#008c96'
    //const SD_COLOR = '#52ABB3';
    const SD_COLOR = '#E98DA8'
    const SD2_COLOR = '#E98DA8'

    const controlSeries: iControlSeries = {
      options: [
        {
          value: controlValue.target,
          zIndex: 1,
          color: STANDARD_COLOR,
          dashStyle: 'shortDash',
          name: 'charts.control-chart.target'
        },
        {value: this.mean, color: '#44BF96', dashStyle: 'solid', name: 'charts.control-chart.mean'},
        {
          value: round(this.mean + this.sd, 2),
          zIndex: 1,
          color: SD_COLOR,
          dashStyle: 'dash',
          name: 'charts.control-chart.sd',
          hidePointValueInLegend: true
        },
        {
          value: round(this.mean - this.sd, 2),
          zIndex: 1,
          color: SD_COLOR,
          dashStyle: 'dash',
          name: 'charts.control-chart.sd',
          showInLegend: false
        },
        {
          value: round(this.mean + this.sd2, 2),
          zIndex: 1,
          color: SD2_COLOR,
          dashStyle: 'dash',
          name: 'charts.control-chart.sd2',
          hidePointValueInLegend: true
        },
        {
          value: round(this.mean - this.sd2, 2),
          zIndex: 1,
          color: SD2_COLOR,
          dashStyle: 'dash',
          name: 'charts.control-chart.sd2',
          showInLegend: false
        }
      ]
    }

    const chart = new ControlChartOptions()
    chart.title = {text: "charts.control-chart.training-title"}
    chart.series = [{
      name: controlValue.label,
      zIndex: 2,
      data: this.samples.map(s => {
        let username = this.user.getName()
        return {
          name: s.created.format('DD.MM.YYYY H:mm'),
          y: s.result,
          batchId: s.userBatchId,
          sampleId: s.userSampleId,
          user: username
        }
      })
    }]
    chart.addControlSeries(controlSeries)
    chart.setUnit("")
    this.controlChart = chart
  }

  setUsageChart() {
    // const WITHIN_COLOR = '#008C96'
    // const BEYOND_COLOR = '#ED7E59'

    // const controlSeries: iControlSeries = {
    //   options: [
    //     {
    //       value: controlValue.target,
    //       color: STANDARD_COLOR,
    //       dashStyle: 'shortDash',
    //       name: 'charts.control-chart.target'
    //     },
    //     {value: this.mean, color: '#44BF96', dashStyle: 'solid', name: 'charts.control-chart.mean'},
    //     {
    //       value: round(this.mean + this.sd, 2),
    //       color: SD_COLOR,
    //       dashStyle: 'dash',
    //       name: 'charts.control-chart.sd',
    //       hidePointValueInLegend: true
    //     },
    //     {
    //       value: round(this.mean - this.sd, 2),
    //       color: SD_COLOR,
    //       dashStyle: 'dash',
    //       name: 'charts.control-chart.sd',
    //       showInLegend: false
    //     },
    //     {
    //       value: round(this.mean + this.sd2, 2),
    //       color: SD2_COLOR,
    //       dashStyle: 'dash',
    //       name: 'charts.control-chart.sd2',
    //       hidePointValueInLegend: true
    //     },
    //     {
    //       value: round(this.mean - this.sd2, 2),
    //       color: SD2_COLOR,
    //       dashStyle: 'dash',
    //       name: 'charts.control-chart.sd2',
    //       showInLegend: false
    //     }
    //   ]
    // }
    const controlSeries: iControlSeries = {
      options: [
        {
          value: this.chartData.deviceRanges.min,
          color: '#008C96',
          dashStyle: 'dash',
          name: 'charts.control-chart.training-device-usage.min-range',
          showInLegend: true,
          enableMouseTracking: false
        },
        {
          value: this.chartData.deviceRanges.max,
          color: '#008C96',
          dashStyle: 'dash',
          name: 'charts.control-chart.training-device-usage.max-range',
          showInLegend: true,
          enableMouseTracking: false
        }
      ]
    }

    const chart = new TrainingUsageChartOptions(this.chartData)
    // chart.plotOptions = {
    //   series: {
    //     pointPlacement: 'on',
    //     label: {connectorAllowed: false},
    //     lineWidth: 0,
    //     pointStart: 1,
    //     marker: {radius: 6, enabledThreshold: 0, symbol: 'circle'},
    //     states: {
    //       hover: {
    //         lineWidthPlus: 0,
    //       }
    //     }
    //   }
    // }
    // chart.series = []
    // chart.title = {text: "charts.control-chart.training-device-usage.title"}

    // if (this.chartData.series.within) {
    //   chart.series.push({
    //     name: 'Within usage',
    //     data: this.chartData.series.within.data,
    //     color: '#008C96',
    //   })
    // }
    // if (this.chartData.series.beyond) {
    //   chart.series.push({
    //     name: 'Below usage',
    //     data: this.chartData.series.beyond.data,
    //     color: '#ED7E59',
    //   })
    // }
    // chart.series = chartData.series
    // chart.addControlSeries(controlSeries)
    // chart.setUnit("")
    // chart.xAxis. = this.setLabels(this.chartData.labels)
    this.usageChart = chart
    console.log('chartchartchartchart', chart)
    chart.addControlSeries(controlSeries)
  }

  static deserialize(data: any) {
    const mapping = {
      date: 'moment',
      user: User,
      samples: (sampleData: any[]) => sampleData.map(s => Sample.deserialize(s))
    }

    const a: TrainingAttempt = toObject<TrainingAttempt>(TrainingAttempt, data, mapping)
    a.sd2 = round(a.sd * 2, 2)
    a.sd3 = round(a.sd * 3, 2)

    return a
  }
}

export interface TrainingChartAttempt {
  cv?: number;
  stepIndex?: number;
  label?: string;
  firstName: string;
  lastName: string;
}
