<div dropdown [insideClick]="true" placement="top right">
  <button class="position-relative btn btn-link text-light my-2 my-sm-0 py-0" type="button" dropdownToggle>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19.25C4.892 19.25 0.75 15.109 0.75 10C0.75 4.892 4.892 0.75 10 0.75C15.109 0.75 19.25 4.892 19.25 10C19.25 15.109 15.109 19.25 10 19.25Z" stroke="#68686D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.0049 13.7959V9.3769" stroke="#68686D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.005 6.2041H9.995" stroke="#68686D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>

    <div class="list-group">
      <div class="list-group-item">
        <p class="title">{{ 'help.docs-title' | translate }}</p>
        <p class="subtitle">{{ 'help.docs-content' | translate }}</p>
        <ul class="list-unstyled">
          <li *ngFor="let l of links">
            <a target="_blank" [href]="l.link">
              <svg class="icon" *ngIf="l.icon === 'video'" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1855 7.71144C10.2361 8.85649 9.31196 9.82475 8.1216 9.87341C8.03393 9.87711 3.75954 9.86848 3.75954 9.86848C2.57495 9.95841 1.53819 9.10717 1.44475 7.96644C1.43771 7.88144 1.43963 3.29512 1.43963 3.29512C1.38715 2.14885 2.31 1.17812 3.50099 1.12761C3.58995 1.1233 7.85857 1.13131 7.85857 1.13131C9.04893 1.04261 10.0889 1.9 10.181 3.04628C10.1874 3.12881 10.1855 7.71144 10.1855 7.71144Z" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.1875 4.2374L12.2456 2.55302C12.7556 2.13552 13.5206 2.49927 13.52 3.1574L13.5125 7.75052C13.5119 8.40865 12.7462 8.76927 12.2375 8.35177L10.1875 6.6674" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <svg class="icon" *ngIf="l.icon === 'document'" width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2112 0.726581H3.05308C1.75308 0.721581 0.687453 1.75783 0.656828 3.05721V9.75283C0.628078 11.0735 1.67495 12.1678 2.99558 12.1972C3.01495 12.1972 3.0337 12.1978 3.05308 12.1972H8.0462C9.35495 12.1441 10.3862 11.0628 10.3768 9.75283V4.02408L7.2112 0.726581Z" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.04736 0.71875V2.53687C7.04736 3.42437 7.76486 4.14375 8.65236 4.14625H10.3742" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.93018 8.59863H3.55518" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.65219 6.25391H3.55469" stroke="#06A3AE" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <p class="name">{{ 'help.docs.' + l.name | translate }}</p>
            </a>
          </li>
        </ul>
      </div>

      <a [routerLink]="['/tickets/new']" class="list-group-item list-group-item-action contact-us">
        <div class="row align-items-center contact-card">
          <div class="col-auto">
            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4998 49.0107C12.517 49.0107 1.98942 38.4857 1.98942 25.5003C1.98942 12.5175 12.517 1.98991 25.4998 1.98991C38.4852 1.98991 49.0103 12.5175 49.0103 25.5003C49.0103 38.4857 38.4852 49.0107 25.4998 49.0107Z" stroke="white" stroke-width="3.8125" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.5124 35.1484V23.9168" stroke="white" stroke-width="3.8125" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.5129 15.8522H25.4875" stroke="white" stroke-width="5.08333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="col pl-0">
            <p class="font-weight-bold support-title">{{ 'help.support-title' | translate }}</p>
            <p class="support-content">{{ 'help.support-content' | translate }}</p>
          </div>
        </div>
      </a>

    </div>

  </div>
</div>
