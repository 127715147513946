<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th class="cell-sm border-0">{{ prefix + 'measurement' | translate }}</th>
        <th class="text-center border-0" *ngFor="let a of attempts; let i = index">
          {{ prefix + 'attempt' | translate : { c: i+1 } }}
          <div *ngIf="showNames" class="user-name">{{ a.user.getName() }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of rows">
        <td>
          <!-- Sample number -->
          {{i + 1}}
        </td>
        <td class="text-center" *ngFor="let a of attempts">{{a.samples[i]?.result}}</td>
      </tr>
    </tbody>

    <!-- Summaries -->
    <tfoot>
      <tr class="table-lighter">
        <th>{{ prefix + 'mean' | translate }}</th>
        <td class="text-center" *ngFor="let a of attempts">{{a.mean}}</td>
      </tr>
      <tr class="table-lighter">
        <th>{{ prefix + 'sd' | translate }}</th>
        <td class="text-center" *ngFor="let a of attempts">{{a.sd}}</td>
      </tr>
      <tr class="table-lighter">
        <th class="cv">{{ prefix + 'cv' | translate }}</th>
        <td [ngClass]="getPrecisionClass(a)" class="text-center" *ngFor="let a of attempts">
          <svg class="success-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="url(#paint0_linear_4_8)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5863 5.20495C12.7175 5.33622 12.7912 5.51424 12.7912 5.69985C12.7912 5.88547 12.7175 6.06348 12.5863 6.19475L6.9863 11.7948C6.85503 11.926 6.67701 11.9997 6.4914 11.9997C6.30578 11.9997 6.12777 11.926 5.9965 11.7948L3.1965 8.99475C3.06899 8.86273 2.99843 8.68591 3.00003 8.50237C3.00162 8.31883 3.07524 8.14326 3.20503 8.01348C3.33481 7.88369 3.51038 7.81007 3.69392 7.80848C3.87746 7.80688 4.05428 7.87744 4.1863 8.00495L6.4914 10.3101L11.5965 5.20495C11.7278 5.07372 11.9058 5 12.0914 5C12.277 5 12.455 5.07372 12.5863 5.20495Z" fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_4_8" x1="13.8148" y1="8" x2="1.51852" y2="8" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7BD58A"/>
                <stop offset="1" stop-color="#44BF96"/>
              </linearGradient>
            </defs>
          </svg>


          <svg class="meh-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#FAB462"/>
            <line x1="3.5" y1="8.5" x2="12.5" y2="8.5" stroke="white" stroke-linecap="round"/>
          </svg>


          <svg class="fail-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EB5757"/>
            <path d="M4.5 4.5L11.5 11.5M4.5 11.5L11.5 4.5L4.5 11.5Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>


          <p class="cv-result">{{a.cv}}%</p>
        </td>
      </tr>
      <tr class="table-lighter">
        <th>{{ prefix + 'sd2' | translate }}</th>
        <td class="text-center" *ngFor="let a of attempts">{{a.sd2}}</td>
      </tr>
      <tr class="table-lighter">
        <th>{{ prefix + 'sd3' | translate }}</th>
        <td class="text-center" *ngFor="let a of attempts">{{a.sd3}}</td>
      </tr>
    </tfoot>
  </table>
</div>
