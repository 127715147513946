import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {Product, ProductLine} from '../../../model/Products'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {DataService} from '../../services/data.service'
import {ProductsService} from '../../services/products.service'
import {ModalService} from '../modal.service'
import {CacheService} from '../../services/cache.service'

@Component({
  selector: 'app-add-product-line-modal',
  templateUrl: './add-product-line-modal.component.html',
  styleUrls: ['./add-product-line-modal.component.scss']
})
export class AddProductLineModalComponent implements OnInit {

  prefix = 'add-product-line-modal.'
  form: FormGroup
  busy = false
  modalResult: Product
  productLine: ProductLine

  constructor(
    public modal: BsModalRef,
    private data: DataService,
    private productsService: ProductsService,
    private modals: ModalService,
    private cache: CacheService,
  ) {
    this.form = new FormGroup({
      productLine: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
  }

  async save() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return

    this.busy = true
    this.productLine = {
      name: this.form.get('productLine').value,
      products: [],
      organizationId: this.cache.organization.value.id,
      training: false
    }
    try {
      this.modalResult = await this.data.saveProductLine(this.productLine)
      this.modal.hide()
    } catch (e) {
      console.log(e)
    }
    this.busy = false
  }
}
