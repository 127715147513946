<div class="modal-header">
  <p class="m-0 text-dark font-weight-bold">{{ prefix + 'title' | translate}}</p>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#333333" stroke-width="1.33333" stroke-linecap="round"
      stroke-linejoin="round"/>
</svg>
    </span>
  </button>
</div>

<div class="modal-body organizations-container">
  <p class="subtitle m-0">{{prefix + 'child-subtitle' | translate}}</p>

  <div class="search mt-2 row">
    <div class="search-input col-9">
      <app-form-control type="search-input" [prefix]="prefix + 'search-child'" hideLabel="true" [control]="form.get('search')"></app-form-control>
    </div>
    <button class="search-button" (click)="search()">{{prefix + 'search-child.search-button' | translate}}</button>
  </div>

  <div class="organizations">
    <div *ngFor="let o of (searchedOrganizations  ? searchedOrganizations : organizations); let idx = index"
         class="row organization" [ngClass]="{selected: o.selected}">
      <div class="col px-4">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" [value]="o.id" [id]="o.id"
                 (change)="onCheckboxChanged($event, idx)">
          <label class="text-dark custom-control-label" [for]="o.id">{{o.name}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="next-btn" (click)="save()">{{prefix + 'save-button' | translate}}</button>
</div>
