<main *ngIf="!busy">
  <p class="title">{{'docs.title' | translate}}</p>
  <p class="subtitle" [innerHTML]="'docs.sub-title' | translate"></p>

  <div class="drag-drop" appDragDrop (fileDropped)="fileHandler($event)">
    <input type="file">
    <div class="center">
      <button class="upload-btn" (click)="upload()">
        <i class="fa fa-upload"></i> {{ 'docs.upload-button' | translate }}
      </button>
      <p>{{'docs.drag-drop-title' | translate}}</p>
    </div>
  </div>

  <div class="row mt-3">
    <!-- Document From Select -->
    <div class="form-group col-md-3">
      <select class="custom-select" (change)="docFilter($event, 'author')">
        <option selected="" value="all">All Analysts</option>
        <option *ngFor="let df of docFroms" value="{{df}}">From: {{ df | titlecase }}</option>
      </select>
    </div>

    <!--  Period Select -->
    <div class="form-group col-md-3 d-flex">
      <!--          <select class="custom-select" (change)="docFilter($event, 'documentType')">-->
      <!--            <option selected="" value="all">Period: All</option>-->
      <!--            <option *ngFor="let dt of docTypes" value="{{dt}}">Period: {{ dt | titlecase }}</option>-->
      <!--          </select>-->
      <input name="dash-drp" id="dash-drp" type="text"
             placeholder="{{ 'dashboard.daterange-placeholder' | translate }}" class="form-control mr-sm-2"
             bsDaterangepicker (bsValueChange)="dateChange($event)" [(ngModel)]="bsDateRange"
             #drp="bsDaterangepicker"
             [bsConfig]="{ isAnimated: true, selectFromOtherMonth: true }">
      <button class="clear-date" type="button" (click)="clearDate()">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L9 9M1 9L9 1L1 9Z" stroke="#EB5757" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <!--  Document Type Select -->
    <div class="form-group col-md-3">
      <select class="custom-select" (change)="docFilter($event, 'documentType')">
        <option selected="" value="all">Document Type: All</option>
        <option *ngFor="let dt of docTypes" value="{{dt}}">Document Type: {{ dt | titlecase }}</option>
      </select>
    </div>
  </div>

  <div class="table-container scrollbar">
    <app-docs-table (getPage)="getPage($event)" [busy]="busy" [page]="page" [filters]="filters"></app-docs-table>
  </div>

  <div class="mobile-table">
    <app-docs-mobile-table [page]="page" [filters]="filters">
    </app-docs-mobile-table>
  </div>
</main>


<div class="loader-wrapper" *ngIf="busy">
  <div class="loader-container">
    <app-dots-loader></app-dots-loader>
  </div>
</div>
