import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'

import {ControlValue} from 'src/model/control-value'
import {Product, ProductLine} from 'src/model/Products'
import {Subscriptions} from 'src/model/subscriptions'
import {CacheService} from 'src/app/services/cache.service'
import {DataService} from 'src/app/services/data.service'
import {ToastService} from 'src/app/services/toast.service'
import {AddProductModalComponent} from 'src/app/shared/add-product-modal/add-product-modal.component'
import {DilutionModalComponent} from 'src/app/shared/dilution-modal/dilution-modal.component'
import {ModalService} from 'src/app/shared/modal.service'
import {ProductModalComponent} from 'src/app/shared/product-modal/product-modal.component'
import {BehaviorSubject} from 'rxjs'
import {ProductsService} from 'src/app/services/products.service'
import {UserService} from '../../../services/user.service'
import {AddProductLineModalComponent} from '../../../shared/add-product-line-modal/add-product-line-modal.component'
import {DeleteProductModalComponent} from '../../../shared/delete-product-modal/delete-product-modal.component'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss', './products.mobile.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  prefix = 'my-products.'
  productLines: ProductLine[] = []
  form: FormGroup
  busy = false
  isTrainingProduct = false
  canCreateProductLines = false
  canDeleteProductLines = false
  canCreateProducts = false
  canDeleteProducts = false
  modalProduct = null

  showPagination = false
  currentPage = 0
  currentIdx = 0
  lastPage = 0

  // currentUnit: string

  get productName() {
    return this.form.value.product ? this.form.value.product.name : 'nothing'
  }

  public currentProductLine: BehaviorSubject<ProductLine> = new BehaviorSubject(null)

  private _subs = new Subscriptions()
  showModal = false

  constructor(
    private toasts: ToastService,
    private cache: CacheService,
    private data: DataService,
    private productsService: ProductsService,
    private modals: ModalService,
    private us: UserService
  ) {
    this.form = new FormGroup({
      id: new FormControl(null),
      product: new FormControl(null),
      productId: new FormControl('', [Validators.required]),
      label: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      unit: new FormControl('mg RE/kg', [Validators.required]),
      target: new FormControl('', [Validators.required]),
      minLimit: new FormControl('', [Validators.required]),
      minWarn: new FormControl('', [Validators.required]),
      maxWarn: new FormControl('', [Validators.required]),
      maxLimit: new FormControl('', [Validators.required]),
      nationalStandard: new FormControl(null),
      diluteRatio: new FormControl(null),
      fortified: new FormControl(null),
    })

  }

  async ngOnInit() {
    let productLines = null
    this._subs.add(this.us.userChanged.subscribe(u => {
      this.canCreateProductLines = u.canCreateProductLines()
      this.canDeleteProductLines = u.canDeleteProductLines()
      this.canCreateProducts = u.canCreateProducts()
      this.canDeleteProducts = u.canDeleteProducts()
    }))
    this._subs.add(this.cache.productLines.subscribe((pl) => {
      const trainingProductLine = pl.filter(pL => pL.training)
      productLines = pl

      if (trainingProductLine.length) {
        productLines = productLines.filter(productLine => !productLine.training)
        productLines.push(trainingProductLine[0])
      }
    }))
    await this.processProductLines(productLines)
    this.currentProductLine.next(this.productLines[0])

    this.currentProductLine.subscribe(pl => {
      this.updatePag()
    })

  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  updatePag(currentPage = 0, newProduct = false) {
    const pl = this.currentProductLine.value
    if (!pl.products) return
    this.lastPage = Math.ceil(pl.products.length / 4) - 1
    if (newProduct) {
      this.currentPage = this.lastPage
      this.currentIdx = 4 * (this.currentPage + 1)
    } else {
      if (currentPage > this.lastPage) {
        this.currentPage = this.lastPage
        this.currentIdx = 4 * (this.currentPage + 1)
      } else if (currentPage === 0) {
        this.currentPage = currentPage
        this.currentIdx = 4
      } else {
        this.currentPage = currentPage
        this.currentIdx = 4 * (currentPage + 1)
      }
    }
    this.showPagination = this.lastPage > 0
  }

  // openDiluteModal(shouldSave = false) {
  //   const product: Product = this.form.get('product').value
  //   const result = this.modals.show(DilutionModalComponent, {
  //     ignoreBackdropClick: true,
  //     class: 'modal-lg',
  //     initialState: {product, shouldSave, unit: 12},
  //   })
  //   if (result) this.form.patchValue(result)
  //   return result
  // }

  getErrors(formControlName: string) {
    return this.form.get(formControlName).errors
  }

  async processProductLines(pl: ProductLine[]) {
    //Add empty control values if they don't exist.
    this.productLines = pl
    let productCount = 0
    let customProductLineExists = false
    let customProductLineProductExists = false
    let customProductLine: ProductLine

    for (const l of pl) {
      if (!l.training) {
        customProductLineExists = true
        customProductLine = l
        if (l.products.length) {
          customProductLineProductExists = true
        }
      }
      for (const p of l.products) {
        productCount++
        if (!p.controlValues || p.controlValues.length === 0) {
          p.controlValues = [
            {
              label: '',
              productId: p.id,
              target: 8,
              minWarn: 6,
              minLimit: 5,
              maxWarn: 10,
              maxLimit: 11,
            },
          ]
        }

        //
        if (!this.form.value.product) {
          this.form.get('product').setValue(p)
          this.selectProduct()
        }
      }
    }

    //Show add modal if no product exists yet.
    // if (productCount === 0 && !this.productLines.length && this.canCreateProductLines) {
    let firstPL = null
    if (!customProductLineExists && this.canCreateProductLines) {
      firstPL = await this.modals.show(AddProductLineModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-md',
      })
      if (firstPL) this.changeCurrentLine(firstPL)
    }

    if (productCount === 0 && this.productLines.length) {
      // if (customProductLineExists && !customProductLineProductExists) {
      const product = await this.openAddProductModal(firstPL, true, true)
    }

  }


  selectProduct(p?: Product) {
    const product: Product = p ? p : this.form.get('product').value

    this.isTrainingProduct = this.productLines.find(l => l.id === product.productLineId).training

    if (!product) return
    this.form.get('productId').setValue(product.id)
    this.form.markAsUntouched()

    //Set control values
    if (product.controlValues[0]) {
      for (const k in product.controlValues[0]) {
        const v: ControlValue = product.controlValues[0]
        if (!v.hasOwnProperty(k)) continue

        this.trySetFormControlValue(k, v[k])
      }
    }
  }

  public changeCurrentLine(productLine) {
    this.currentProductLine.next(productLine)
  }

  private trySetFormControlValue(controlName: string, value: any) {
    if (this.form.value[controlName] === undefined) return
    this.form.get(controlName).setValue(value)
  }

  async addProduct() {
    const productLine = this.currentProductLine.getValue()
    const result = await this.openAddProductModal(productLine)
  }

  async openAddProductModal(productLine, shouldSave = true, isFirst = false) {
    const modalResult = await this.modals.show(AddProductModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {productLine, product: this.modalProduct, isFirst},
    })
    if (!modalResult) {
      this.modalProduct = null
      return
    }
    if (this.modalProduct) {
      this.modalProduct.name = modalResult.name
      this.modalProduct.controlValues = modalResult.controlValues
    } else {
      this.modalProduct = modalResult
    }

    const product = {name: this.modalProduct.name, controlValues: this.modalProduct.controlValues}

    // const exceedsLimit = this.modalProduct.controlValues.filter(cv => {
    //   return (cv.unit === 'mg RE/kg' && cv.target > 30) || (cv.unit === 'IU/g' && cv.target > 100)
    // })


    // if (exceedsLimit.length) {
    //   await this.openDilutModal(productLine, !shouldSave)
    // } else {
    if (!product) {
      return
    }
    if (shouldSave) {
      await this.saveProduct(product, productLine)
      this.modalProduct = null
    } else {
      await this.saveSettings(this.modalProduct, this.modalProduct.controlValues)
      this.modalProduct = null
    }
    // }
  }

  async openDilutModal(pl, shouldSave) {
    const res = await this.modals.show(DilutionModalComponent, {
      class: "modal-lg",
      ignoreBackdropClick: true,
      initialState: {product: this.modalProduct, shouldSave}
    })

    if (!res) {
      await this.openAddProductModal(pl, !shouldSave)
      return
    }
    this.modalProduct.controlValues[0].fortified = res.fortified
    this.modalProduct.controlValues[0].diluteRatio = res.diluteRatio

    if (!shouldSave) {
      await this.saveProduct(this.modalProduct, pl)
      this.modalProduct = null

    } else {
      await this.saveSettings(this.modalProduct, this.modalProduct.controlValues[0])
      this.modalProduct = null
    }
    this.updatePag()
  }

//todo update product creation logic
  async saveProduct(product, productLine) {
    product.productLineId = productLine.id
    this.productsService.saveProduct(product).subscribe(r => {
      //fast fix for creation first product
      if (this.currentProductLine.value.products) {
        this.updateProduct(r.product)
        console.log('this.productLines', this.productLines)
        console.log('this.currentProductLine.value', this.currentProductLine.value)
      } else {
        const idx = this.productLines.findIndex(pl => pl.id === this.currentProductLine.value.id)
        this.productLines[idx].products = [product]
        this.changeCurrentLine(this.productLines[idx])
        console.log('this.productLines', this.productLines)
      }
    })
    this.updatePag(this.currentPage, true)
  }

  async saveSettings(product, controlValues) {
    this.productsService.updateProductName(product).subscribe()
    const res = this.data.updateSettings(controlValues)
  }

  updateProduct(p) {
    const plIdx = this.productLines.findIndex(pl => pl.id === p.productLineId)
    const productIdx = this.productLines[plIdx].products.findIndex(pr => pr.id === p.id)
    this.productLines[plIdx].products[productIdx] = p
    const currentPL = this.currentProductLine.value
    const currentPlProductIdx = this.currentProductLine.value.products.findIndex(product => product.id === p.id)
    currentPL.products[currentPlProductIdx] = p
    this.changeCurrentLine(currentPL)
    // this.currentProductLine.next(currentPL)
    this.modalProduct = null
  }

  addProductLine() {
    const product: Product = this.modals.show(ProductModalComponent, {
      ignoreBackdropClick: true, initialState: {
        canDeleteProductLines: this.canDeleteProductLines,
        canDeleteProducts: this.canDeleteProducts
      }
    })
    if (product) {
      this.form.get('product').setValue(product)
      this.selectProduct()
    }
  }

  save() {
    this.form.markAllAsTouched()

    let v = {...this.form.value}

    if (v.minWarn < v.minLimit || v.minLimit > v.target) {
      this.form.get('minLimit').setErrors({tooHigh: true})
    } else {
      this.form.get('minLimit').setErrors(null)
    }

    if (v.maxWarn > v.maxLimit || v.maxLimit < v.target) {
      this.form.get('maxLimit').setErrors({tooLow: true})
    } else {
      this.form.get('maxLimit').setErrors(null)
    }

    if (this.form.invalid) return

    //Check if we need to dilute
    // if (v.target > 30 && !v.diluteRatio) {
    //   this.openDiluteModal(false)
    //   v = {...this.form.value}
    //   if (!v.diluteRatio || !v.fortified) {
    //     this.toasts.warning('settings.missing-dilute-values')
    //     return
    //   }
    // }

    this.busy = true
    delete v.product

    this.productsService.saveSettings(v).subscribe((response: any) => {
      if (response.result) {
        this.toasts.success('settings.saved')
        this.busy = false
        this.form.get('id').setValue(v.id)

        //Update cache
        this.cache.updateControlValue(v)
      } else {
        this.toasts.warning('settings.not-saved')
        this.busy = false
      }
    })
  }

  async editProduct(product: Product) {
    // this.currentUnit = product.controlValues[0].unit
    const productLine = this.currentProductLine.getValue()
    this.modalProduct = product
    await this.openAddProductModal(productLine, false)
  }

  async delete(product: Product) {
    if (!product.id) {
      this.forceCacheRefresh()
      return
    }

    const confirmed = await this.modals.show(DeleteProductModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-md',
      initialState: {product},
    })

    if (!confirmed) return

    try {
      this.productsService.deleteProduct(product).subscribe(console.log)
    } catch (err) {
      if (err.error && err.error.reason && err.error.reason.match(/(has samples)/)) {
        this.toasts.warning('product-modal.product-has-samples')
      }
    }
    this.updatePag(this.currentPage)
  }

  private forceCacheRefresh() {
    this.cache.productLines.next(this.cache.productLines.value)
  }

  pageSelected(page: number) {
    this.currentPage = page
    this.currentIdx = 4 * (page + 1)
    console.log('this.currentIdx', this.currentIdx)
  }
}
