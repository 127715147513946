import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent {
  @Input() width = '135px'
  @Input() height = '36px'
  @Input() fontSize = '16px'
  @Input() color = '#333333'
  @Input() type: 'dark-green' | 'invisible-icon' | 'outline-gradient' | 'filled-gradient' | 'outline-dark-green' | 'text'
  @Input() text: string
  @Input() borderRadius = '7px'
  @Input() disabled = false
  @Input() image = ''
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();


  constructor() { }
}
