<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ prefix + 'content' | translate }}</p>

  <ul class="list-group">
    <li *ngFor="let i of params" class="list-group-item">
      {{ actionLabel | translate }}: {{ getLabel(i) }}

      <span *ngIf="!i.state" class="pull-right">pending <i class="fa fa-clock-o fa-fw"></i></span>
      <span *ngIf="i.state === 'busy'" class="pull-right">running <i class="fa fa-spinner fa-spin fa-fw"></i></span>
      <span *ngIf="i.state === 'done'" class="text-success pull-right">done <i class="fa fa-check fa-fw"></i></span>
      <span *ngIf="i.state === 'failed'" class="text-danger pull-right">failed <i class="fa fa-times fa-fw"></i></span>
    </li>
  </ul>

  <div class="text-right mt-4" *ngIf="!done">
    <button (click)="modal.hide()" class="btn btn-light ">{{ prefix + 'no' | translate }}</button>
    <button (click)="run()" class="btn btn-primary ml-2">{{ prefix + 'yes' | translate }}</button>
  </div>

  <div class="text-right mt-4" *ngIf="done">
    <button (click)="modal.hide()" class="btn btn-light ">{{ prefix + 'close' | translate }}</button>
  </div>
</div>