import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../../services/onboarding.service';

@Component({
  selector: 'app-onboarding-nav-button',
  templateUrl: './onboarding-nav-button.component.html',
  styleUrls: ['./onboarding-nav-button.component.scss']
})
export class OnboardingNavButtonComponent {

  constructor(
    private os: OnboardingService,
  ) { }

  show() {
    this.os.showAll(true);
  }

}
