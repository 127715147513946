import { trigger, transition, style, animate, query, group, animateChild } from '@angular/animations';

export const fade = trigger("fade", [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.2s ease-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.2s ease-out', style({ opacity: 0 }))
  ])
]);

export const foldIn = [
  animate('.3s ease', style({ 'max-height': 200 }))
];

export const foldOut = [
  animate('.3s ease', style({ 'max-height': 0 }))
];

export const slideInAnimation =
  trigger('routeAnimations', [
    transition(':decrement', [
      //Style Host element styling before animation
      style({ position: 'relative' }),
      //Query child elements and style them before anymation
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ]),
      //Entering child specific styling
      query(':enter', [
        style({ left: '-100%', position: 'relative' })
      ]),
      //Execute any child animations on the leaving element if they exist (not needed, but makes it pretty if the child component has its own animations.)
      query(':leave', animateChild(), { optional: true }),
      //Do leave and enter animation at the same time
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%' }))
        ], { optional: true }),
        query(':enter', [
          animate('200ms ease-out', style({ left: '0%' }))
        ])
      ]),
      //Execute any child animations on the entered element if they exist (not needed, but makes it pretty if the child component has its own animations.)
      query(':enter', animateChild()),
    ]),

    transition(':increment', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '100%', position: 'relative' })
      ]),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '-100%' }))
        ], { optional: true }),
        query(':enter', [
          animate('200ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);