import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip/public_api';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent {
  @Input() text: string;
  @Input() btnClass: string;
  tooltipText = "copy-to-clipboard";
  @ViewChild('tooltip') tooltip: TooltipDirective;

  constructor() { }

  copied(e: Event) {
    this.tooltipText = "copied";
    e.stopPropagation();
    
    setTimeout(() => {
      this.tooltip.hide();
      this.tooltipText = "copy-to-clipboard";
    }, 2000);

    setTimeout(() => {
      this.tooltip.show();
    }, 0);
  }

}
