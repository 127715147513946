<ul id="progressbar">
  <li class="active" id="account"><strong>Account</strong></li>
  <li id="personal"><strong>Personal</strong></li>
  <li id="payment"><strong>Payment</strong></li>
  <li id="confirm"><strong>Finish</strong></li>
</ul>

<!--<div class="step-counter-container">-->
<!--  <div class="circle">-->
<!--    <span>1</span>-->
<!--  </div>-->
<!--  <hr>-->
<!--  <div class="circle">-->
<!--    <span>2</span>-->
<!--  </div>-->
<!--  <hr>-->
<!--  <div class="circle">-->
<!--    <span>3</span>-->
<!--  </div>-->
<!--  <hr>-->
<!--  <div class="circle">-->
<!--    <span>4</span>-->
<!--  </div>-->
<!--</div>-->
