import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ModalService } from '../../shared/modal.service';
import { SharedModule } from '../../shared/shared.module';
import { AddTrainingDataModalComponent } from './add-training-data-modal/add-training-data-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocsComponent } from './docs/docs.component';
import { ImportComponent } from './import/import.component';
import { ProcessedSamplesComponent } from './processed-samples/processed-samples.component';
import { ProductModalComponent } from '../../shared/product-modal/product-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { StockComponent } from './stock/stock.component';
import { TrainingComponent } from './training/training.component';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { FoldoutModule } from '../../foldout/foldout.module';
import { UserTrainingSummaryComponent } from './user-training-summary/user-training-summary.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AllDataComponent } from './all-data/all-data.component';
import { ManualsComponent } from './manuals/manuals.component';
import { ProductsComponent } from './products/products.component';
import { ConsumablesComponent } from './consumables/consumables.component';
import { DevicesComponent } from './devices/devices.component';
import { TrainingChartComponent } from './training-chart/training-chart.component';
import { ReportsComponent } from './reports/reports.component';
import { TrainingResultsComponent } from './training-results/training-results.component';
import {TimeagoModule} from 'ngx-timeago';
import { TransferDataGuideComponent } from './transfer-data-guide/transfer-data-guide.component'
import {TabsModule} from "ngx-bootstrap/tabs";


@NgModule({
  declarations: [
    UserComponent,
    DashboardComponent,
    ProfileComponent,
    StockComponent,
    DocsComponent,
    TrainingComponent,
    SettingsComponent,
    ProductModalComponent,
    ImportComponent,
    ProcessedSamplesComponent,
    AddTrainingDataModalComponent,
    UserTrainingSummaryComponent,
    IntroductionComponent,
    NotificationsComponent,
    AllDataComponent,
    ManualsComponent,
    ProductsComponent,
    ConsumablesComponent,
    DevicesComponent,
    TrainingChartComponent,
    ReportsComponent,
    TrainingResultsComponent,
    TransferDataGuideComponent,
  ],
    imports: [
        CommonModule,
        UserRoutingModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        PopoverModule,
        CollapseModule,
        BsDatepickerModule,
        TooltipModule,
        SharedModule,
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        FoldoutModule,
        TimeagoModule,
        TabsModule,
    ],
  exports: [
    ProductModalComponent,
    UserComponent,
    IntroductionComponent,
  ],
  entryComponents: [
    ProductModalComponent,
    AddTrainingDataModalComponent,
  ],
  providers: [
    ModalService
  ]

})
export class UserModule { }
