<accordion [isAnimated]="true">
  <accordion-group *ngFor="let name of groupNames"
    [heading]="'import.table.group-title' | translate : { name: name, count: grouped[name].length } ">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">{{ 'import.table.date' | translate }}</th>
          <th scope="col">{{ 'import.table.mode' | translate }}</th>
          <th scope="col">{{ 'import.table.batch' | translate }}</th>
          <th scope="col">{{ 'import.table.product' | translate }}</th>
          <th scope="col">{{ 'import.table.result' | translate }}</th>
          <th scope="col">{{ 'import.table.user-batch' | translate }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngFor="let s of grouped[name]">
        <tr [class.table-light]="s.discarded">
          <td>{{ s.created | date : 'dd/MM/yyy HH:mm' }}</td>
          <td>{{ s.mode }} <span *ngIf="s.discarded">{{ 'import.table.discarded' | translate }} {{s.comment}}</span>
          </td>
          <td>{{ 'import.batch' | translate }}: <strong>{{ s.deviceBatchId }}</strong>
            {{ 'import.sample' | translate }}:
            <strong>{{ s.deviceSampleId }}</strong></td>
          <td *ngIf="!showHierarchy">{{ productInfo[s.productId] ? productInfo[s.productId].name : '' }}</td>
          <td *ngIf="showHierarchy && hierarchyProduct">{{ hierarchyProduct.name }}</td>
          <td>{{ s.result }} {{ s.unit }}</td>
          <td>{{ s.userBatchId }}</td>
          <td class="btn-cell">
            <button class="btn btn-sm btn-link" (click)="undo(s, name)" placement="left" container="body"
              [tooltip]="'import.process-again' | translate" *ngIf="s.mode !== 'DEVICE CONTROL'">
              <i class="fa fa-undo"></i>
            </button>
          </td>

        </tr>

      </tbody>
    </table>

  </accordion-group>
</accordion>
