<div class="content-container">
  <div class="content-card card">
    <div class="card-header bg-white border-white pb-0">
      <a [routerLink]="'../welcome'">
        <img class="d-inline-block mr-2" src="assets/icons/back-arrow.svg" alt="back-arrow">
        <p class="d-inline-block text-dark">{{prefix + 'back' | translate }}</p>
      </a>
    </div>

    <div class="card-body content-body text-left pt-0">
      <h2 class="px-5 card-title text-dark mb-2">{{ prefix + 'title' | translate }}</h2>
      <div class="px-5 content mb-4" [innerHtml]="prefix + 'content' | translate"></div>
      <ng-template #noData>
        <div class="container py-3">
          <div class="card">
            <div class="card-body">
              <app-no-data [prefix]="prefix + 'no-data'" icon="fa-bar-chart">
                <button [routerLink]="['/training/product']" class="btn btn-primary">
                  {{ prefix + "getting-started-button" | translate }}
                  <i class="fa fa-arrow-right ml-1"></i>
                </button>
              </app-no-data>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="container py-3" *ngIf="anyData; else noData">

        <div class="row" *ngIf="!viewingMyselfOnly">
          <div class="col">
            <app-user-training-summary [attempts]="attempts"></app-user-training-summary>
          </div>

        </div>

        <div class="row" *ngIf="selectedAttempt && viewingMyselfOnly">
          <div class="col" *ngIf="selectedAttempt.precisionScore as score">
            <!-- Great -->
            <div class="alert" role="alert" [ngClass]="{'alert-success': score == 'good',
      'alert-warning': score == 'meh', 'alert-danger': score == 'poor'}">
              <div class="row" [ngSwitch]="score">

                <!-- Good -->
                <div class="col-9 pr-0" *ngSwitchCase="'good'">
                  <strong class="mr-2">{{prefix + 'congrats' | translate}}</strong>
                  <i class="fa-2x fa fa-smile-o"></i>
                  <div class="col align-self-center pl-0"
                       [innerHtml]="prefix + 'alert-success' | translate : { cv: selectedAttempt.cv }"></div>
                </div>

                <!-- Meh -->
                <div class="col-9 pr-0" *ngSwitchCase="'meh'">
                  <strong class="mr-2">{{prefix + 'getting-there' | translate}}</strong>
                  <i class="fa-2x fa fa-meh-o"></i>
                  <div class="col align-self-center pl-0"
                       [innerHtml]="prefix + 'alert-warning' | translate : { cv: selectedAttempt.cv }">
                  </div>
                </div>

                <!-- Poor -->
                <div class="col-9 pr-0" *ngSwitchCase="'poor'">
                  <strong class="mr-2">{{prefix + 'unsuccessful' | translate}}</strong>
                  <i class="fa-2x fa fa-frown-o"></i>
                  <div class="col align-self-center pl-0" [innerHtml]="prefix + 'alert-danger' | translate : { cv:
          selectedAttempt.cv }">
                  </div>
                </div>

                <div class="col-3 pr-0">
                  <button class="btn btn- mr-2 mt-auto mb-auto float-right p-2" [routerLink]="'/dashboard'">{{prefix +
                  'to-dashboard' |translate }}</button>
                  <button class="btn btn-outline mr-2 mt-auto mb-auto float-right p-2"
                          [routerLink]="'../welcome'">{{prefix + 'repeat-training' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Control Chart -->
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="btn-group position-absolute" role="group" aria-label="Choose Attempt">

                  <div class="special-pointer text-center btn" *ngFor="let a of attempts; let i = index"
                       [style.z-index]="999 - i"
                       [class.selected]="a === selectedAttempt" (click)="selectedAttempt = a; ">
                    <span>{{ prefix + 'attempt' | translate : { index: i+1} }}</span>
                  </div>
                </div>

                <div>
                  <app-chart [translateParams]="{ name: '' }"
                             [options]="selectedAttempt?.controlChart"></app-chart>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Result Table -->
        <div class="row mt-4">
          <div class="col">
            <div class="card">

              <div class="card-body">
                <h5 class="card-title">{{ prefix + 'table.title' | translate }}</h5>
                <app-training-data-table [showNames]="!viewingMyselfOnly" [attempts]="attempts"></app-training-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
