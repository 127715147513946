import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'
import {Subscription} from 'rxjs'

import {ControlChartData, DashboardResponse} from '../../../../model/api-response'
import {
  ControlChartOptions,
  DefaultControlSeries,
  StockChartOptions,
  ToleranceChartOptions,
  usageChartOptions,
  trainingChartOptions
} from '../../../../model/chart-options'
import {Product, ProductCategory} from '../../../../model/Products'
import {SearchCriteria} from '../../../../model/search-criteria'
import {CacheService} from '../../../services/cache.service'
import {DataService} from '../../../services/data.service'
import {ToastService} from '../../../services/toast.service'
import {UserService} from '../../../services/user.service'
import {ControlValue} from '../../../../model/control-value'
import * as moment from 'moment'
import jspdf from 'jspdf'
import html2canvas from 'html2canvas'
import {User} from '../../../../model/user'
import {TrainingService} from 'src/app/services/training.service'
import {StoreService} from '../../../services/store.service'
import {ReportData} from '../../../../model/report-data'
import {OrganizationService} from '../../../organization.service'
import {AdminService} from '../../admin/admin.service'
import {MultiSelectComponent} from '../../../shared/multi-select/multi-select.component'
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service'
import {Organization} from '../../../../model/organization'
import {FormControl, FormGroup} from '@angular/forms'
import {SelectOption} from '../../../shared/form-control/form-control.component'
import {DeviceType} from '../../../../model/deviceType'
import {TrainingAttempt, TrainingChartAttempt} from '../../../../model/TrainingAttempt'


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})


export class DashboardComponent implements OnInit, OnDestroy {
  private STORAGE_KEY = 'dashboard_params'
  @ViewChild('analysts') analystSelection: MultiSelectComponent

  prefix = 'dashboard.'
  products: { [key: number]: Product } = {}
  productLines: any[] = []

  controlChart: ControlChartOptions = null
  controlValue: ControlValue
  controlChartLoading = false
  usageChart: usageChartOptions
  stockChart: StockChartOptions
  toleranceChart: ToleranceChartOptions
  bsDateRange: Date[] = []
  search: SearchCriteria
  controlDataEmpty = true
  title = 'html-to-pdf-angular-application'
  private _subs: Subscription[] = []
  analysts = []
  user: User
  loading = true
  organizationId: number
  userOrg

  trainingChart: trainingChartOptions

  customReportData = null
  productLineName: string
  chartBase64

  organizations: Organization[] = []
  organizationsList: Organization[] = []
  selectedProduct: any
  params: DashboardParams = {
    from: null,
    to: null,
    users: null,
    productId: null,
    organizationId: null,
    measurementTypeId: null
  }
  form: FormGroup
  selectedMeasurementType: SelectOption
  measurementTypes: SelectOption[]
  measurementTypesSelectOptions: SelectOption[] = []
  trainingAttempts: TrainingChartAttempt[]
  selectedControlValueIsFluoro = false
  productCategories: ProductCategory[]
  deviceTypes: DeviceType[]
  role: string
  usersCurrentPage = null
  usersLastPage = null

  switchUnitsOptions = {
    Iron: ['mg/L'],
    'Vitamin A': ['mg RE/kg', 'IU/g', 'µg RE/L']
  }


  constructor(
    private dataService: DataService,
    private cache: CacheService,
    private route: ActivatedRoute,
    private router: Router,
    private toasts: ToastService,
    private us: UserService,
    private trx: TranslateService,
    private ts: TrainingService,
    private store: StoreService,
    private os: OrganizationService,
    private as: AdminService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.search = SearchCriteria.deserialize(this.route.snapshot.queryParams)
    this.bsDateRange = this.search.getDateRangePickerDates()
    this.form = new FormGroup({
      showHierarchyUsers: new FormControl(false)
    })
  }

  async ngOnInit() {
    this._subs.push(this.us.userChanged.subscribe(u => {
      this.role = u.getRole()
      this.user = u
    }))

    const initData = await this.dataService.initDashboard()
    this.usersLastPage = initData.organizationsPage.lastPage
    this.usersCurrentPage = initData.organizationsPage.page
    this.organizationsList = this.organizations = initData.organizationsPage.organizations.map(o => {
      if (o.users.length) {
        o.users.forEach(u => {
          u.selected = this.search.userUuids.indexOf(u.uuid) > -1
          u.disabled = false
        })
      }
      return o
    })
    this.productCategories = this.cache.productCategories.value
    this.deviceTypes = this.cache.deviceTypes.value
    this.trainingAttempts = initData.trainingAttempts
    this.measurementTypes = this.cache.measurementTypes.value.map(({id, name}): SelectOption => ({
      name,
      value: id
    }))

    this._subs.push(this.cache.organization.subscribe(o => {
      this.userOrg = o
    }))

    this.checkProductLines()
    await this.loadFormQuery()

  }

  checkProductLines() {
    this._subs.push(this.cache.productLines.subscribe(pl => {
      const lines = pl.filter(l => !l.training)
      const products = []
      lines.forEach(pLine => {
        if (pLine.products.length) {
          pLine.products.forEach(p => {
            products.push(p)
          })
        }
      })

      if (!products.length) {
        // this.toasts.info('dashboard.no-product-lines-defined')
        this.router.navigate(['/products'])
      }
    }))
  }

  async loadFormQuery() {
    this._subs.push(this.route.queryParams.subscribe(p => {
      if (!Object.keys(p).length && !this.storage.get(this.STORAGE_KEY)) return
      console.log('p.measurementTypeId', p.measurementTypeId)
      if (Object.keys(p).length) {
        this.params.organizationId = +p.organizationId
        this.params.productId = +p.productId
        this.params.from = +p.from
        this.params.to = +p.to
        this.params.users = p.users
        this.params.measurementTypeId = +p.measurementTypeId
      } else {
        this.params = this.storage.get(this.STORAGE_KEY)
      }

      if (p.users) {
        const allUsers = []
        this.organizationsList.forEach(o => {
          if (o.users.length) {
            o.users.forEach(u => {
              allUsers.push(u)
            })
          }
        })
        const userUuid = p.users.split(',')
        const usrs = allUsers.filter(u => userUuid.includes(u.uuid))

        this.analysts = usrs.map(u => `${u.firstName} ${u.lastName}`)
      }

      this.search = SearchCriteria.deserialize(this.params)
      if (this.params.from && this.params.to) {
        this.bsDateRange = [moment.unix(this.params.from).toDate(), moment.unix(this.params.to).toDate()]
      }
      if (this.params.productId) {
        const org = this.organizationsList.find(o => o.id === this.params.organizationId)
        this.selectedProduct = org.productLines
          .map(pl => pl.products.filter(product => product.id === this.params.productId)).filter(pr => pr.length)[0][0]
      }
      if (this.selectedProduct) {
        this.setMeasurementTypes()
      }
      this.resetUserFilter()
      if (this.search.from &&
        this.search.to &&
        this.search.organizationId &&
        this.search.productId &&
        this.search.measurementTypeId) this.loadCharts()
    }))
    await this.loadTrainingChart()
    this.loading = false
  }

  setMeasurementTypes() {
    this.measurementTypesSelectOptions = []
    const cv = this.selectedProduct.controlValues
    this.measurementTypes.forEach(mt => {
      if (cv.some(c => +mt.value === +c.measurementTypeId)) {
        this.measurementTypesSelectOptions.push(mt)
      }
    })
  }

  ngOnDestroy() {
    for (const s of this._subs) {
      s.unsubscribe()
    }
    this._subs = []
  }

  resetUserFilter() {
    this.organizationsList.forEach(o => {
      o.users.forEach(u => u.selected = this.search.userUuids.indexOf(u.uuid) > -1)
    })
  }


  async userFilterChanged(event: any) {
    this.selectedProduct = null
    this.params.measurementTypeId = null
    this.organizationId = event.orgId
    if (!event.orgs.length) {
      const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
      dashboardParams.users = null
      dashboardParams.organizationId = null
      this.storage.set(this.STORAGE_KEY, dashboardParams)
      this.params.users = null
      this.params.organizationId = null
      this.params.productId = null
      this.params.measurementTypeId = null
      this.organizationsList = this.organizations
      return
    } else {
      this.params.users = event.orgs.length ? event.orgs.map(i => i.uuid).join(',') : null
      this.params.organizationId = event.orgs[0].organizationId

      const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
      if (dashboardParams) {
        Object.assign(dashboardParams, {
          organizationId: this.organizationId,
          users: this.params.users,
          productId: null,
          measurementTypeId: null
        })
        this.storage.set(this.STORAGE_KEY, dashboardParams)
      } else {
        this.storage.set(this.STORAGE_KEY, {
          organizationId: this.organizationId,
          users: this.params.users,
          productId: null,
          measurementTypeId: null
        })
      }
    }
  }

  getClearItems() {
    this.organizationsList = this.organizations
  }

  getProductName() {
    if (!this.selectedProduct) {
      return ''
    }
    return this.selectedProduct.name
  }

  getProductTarget() {
    if (!this.params.productId) return ''
    return this.controlValue.target
  }

  dateChange(dates: Date[]) {
    if (!dates || dates.length !== 2) return
    if (dates[0] === null || dates[1] === null) return

    if (this.search.from && this.search.to) {
      if (this.search.from.isSame(dates[0], 'day') && this.search.to.isSame(dates[1], 'day')) return
    }
    this.params.from = Math.round(+dates[0] / 1000)
    this.params.to = Math.round(+dates[1] / 1000)
    const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
    if (dashboardParams) {
      Object.assign(dashboardParams, {
        from: this.params.from,
        to: this.params.to
      })
      this.storage.set(this.STORAGE_KEY, dashboardParams)
    } else {
      this.storage.set(this.STORAGE_KEY, {
        from: this.params.from,
        to: this.params.to
      })
    }
  }

  async switchUnits(unit: string) {
    if (unit === this.controlValue.unit) return
    try {
      this.controlChartLoading = true
      const data = await this.dataService.getControlChart(this.search, unit)

      this.controlValue.unit = unit
      this.loadControlChart(data)
    } catch (err) {
      console.warn('That didn\'t work...', err)
      this.toasts.danger('dashboard.unit-switch-error')
    }

    this.controlChartLoading = false

  }

  public generateReportChart() {
    const data = document.getElementById('qcChart')
    html2canvas(data, {
      backgroundColor: '#ffffff',
      scale: window.devicePixelRatio,
    }).then(canvas => {
      this.chartBase64 = canvas.toDataURL('image/png')
    })
  }

  public downloadReport() {
    const data = document.getElementById('contentToConvert')
    html2canvas(data, {backgroundColor: '#FBF5FE', height: 842, width: 595}).then(canvas => {
      // Few necessary setting options
      const imgWidth = 216
      const imgHeight = canvas.height * imgWidth / canvas.width

      const contentDataURL = canvas.toDataURL('image/png')

      const pdf = new jspdf('p', 'mm', 'a4', true) // A4 size page of PDF
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
      pdf.save('BioAnalyt-Report.pdf') // Generated PDF
      this.chartBase64 = null
    })
  }


  private async loadCharts() {
    const data = await this.dataService.getDashboardData(this.search)
    if (data.controlChart.series[0].data.length === 1) {
      const obj = JSON.parse(JSON.stringify(data.controlChart.series[0].data[0]))
      data.controlChart.series[0].data.push(obj)
      const obj1 = JSON.parse(JSON.stringify(data.controlChart.series[0].data[1]))
      data.controlChart.series[0].data.push(obj1)
      if (typeof data.controlChart.series[0].data[0] === 'object') {
        data.controlChart.series[0].data[0].color = 'transparent'
      }
      if (typeof data.controlChart.series[0].data[2] === 'object') {
        data.controlChart.series[0].data[2].color = 'transparent'
      }
    }
    this.loadControlChart(data.controlChart)
    this.loadUsageChart(data)
    this.loadStockChart(data)
    this.loadReportData(data)
    this.toleranceChart = new ToleranceChartOptions(data.controlChart)
  }

  private async loadTrainingChart() {
    const labeledAttempts = {}

    //trainingnchart

    for (const attempt of this.trainingAttempts) {
      const label = attempt.label

      let controlName = 'Sample not selected'
      if (label) {
        controlName = label
      }

      if (labeledAttempts[controlName] === undefined) {
        labeledAttempts[controlName] = {
          label: controlName,
          data: [],
        }
      }


      if (attempt.cv) {
        labeledAttempts[controlName].data.push({
          user: `${attempt.firstName} ${attempt.lastName}`,
          cv: attempt.cv,
          step: 8,
        })
      } else {
        labeledAttempts[controlName].data.push({
          user: `${attempt.firstName} ${attempt.lastName}`,
          cv: 0.0,
          step: attempt.stepIndex,
        })
      }
    }

    // Get local attempts
    // if (this.role !== 'superAdmin') {
    //   const localTrainingData = this.ts.getTrainingProgress()
    //   if (localTrainingData !== undefined) {
    //     const localTrainingAttempt = {
    //       user: this.store.get('user', null, User),
    //       cv: 0.0,
    //       step: this.ts.trainingProgressIndex.getValue()
    //     }
    //     if (labeledAttempts['Sample not selected'] === undefined) {
    //       labeledAttempts['Sample not selected'] = {
    //         label: 'Sample not selected',
    //         data: [],
    //       }
    //     }
    //
    //     labeledAttempts['Sample not selected'].data.push(localTrainingAttempt)
    //   }
    // } else {
    //   const unsuccessfulTraining = await this.ts.getUnsuccessfulTrainings()
    //   if (labeledAttempts['Sample not selected'] === undefined) {
    //     labeledAttempts['Sample not selected'] = {
    //       label: 'Sample not selected',
    //       data: [],
    //     }
    //   }
    //
    //   unsuccessfulTraining.forEach(t => {
    //     labeledAttempts['Sample not selected'].data.push({
    //       user: t.user,
    //       cv: 0.0,
    //       step: t.stepIndex
    //     })
    //   })
    // }


    this.trainingChart = new trainingChartOptions(labeledAttempts, this.search)
    console.log('this.trainingChart', this.trainingChart)
  }

  private loadControlChart(controlChart: ControlChartData) {
    const c = {
      title: {text: undefined},
      // subtitle: { text: `${this.search.from.format('DD.MM.YYYY')} - ${this.search.to.format('DD.MM.YYYY')}` },
      yAxis: {title: {text: `${controlChart.controlValue.label}, ${controlChart.controlValue.unit}`}},
      xAxis: {allowDecimals: false, title: {text: `charts.control-chart.x-title`}},
      legend: {layout: 'horizontal'},

      series: controlChart.series,
      credits: {enabled: false},
    }

    this.controlValue = controlChart.controlValue
    const chart = new ControlChartOptions(c)
    chart.addControlSeries(new DefaultControlSeries(controlChart.controlValue))
    chart.setUnit(controlChart.controlValue.unit)

    this.controlChart = chart
    this.controlDataEmpty = (controlChart.series[0].data.length === 0)
    //FLUORO
    if (this.controlValue.isFluoro) {
      this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g', 'µg RE/L']
    } else {
      this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g']
    }
  }

  private loadUsageChart(data: DashboardResponse) {
    this.usageChart = new usageChartOptions(data.usageChart, data.search)
  }

  private loadStockChart(data: DashboardResponse) {
    this.stockChart = new StockChartOptions(data.stockChart)
    this.stockChart.title = {text: undefined, align: 'left'}
    this.stockChart.yAxis.title.text = 'Available # of vials'
  }

  private async loadReportData(data) {
    const deviceName = this.deviceTypes.filter(dt => dt.id === this.controlValue.deviceTypeId)[0].name
    const productLine = await this.dataService.getProductLineById(this.selectedProduct.productLineId)
    data.cv = this.controlValue
    data.productCategory = this.productCategories.filter(c => this.controlValue.productCategoryId === c.id)[0].name
    data.deviceName = deviceName
    data.productLineName = productLine.name
    data.product = this.selectedProduct

    this.customReportData = new ReportData(data)
  }

  resetData() {
    this.bsDateRange = []
    this.controlDataEmpty = true
    this.controlChart = null
    this.usageChart = null
    this.stockChart = null
    this.toleranceChart = null
    this.params.from = null
    this.params.to = null
    this.params.users = null
    this.params.measurementTypeId = null
    this.selectedProduct = null
    this.params.organizationId = null
    this.params.productId = null
    this.search.reset()
    this.organizationsList = this.organizations
    if (this.role !== 'user') this.analystSelection.clearSelection()
    this.storage.remove(this.STORAGE_KEY)
    this.router.navigate(['/dashboard'])
  }

  selectProduct(product, orgId) {
    this.selectedProduct = product
    this.selectedMeasurementType = null
    this.params.productId = product.id
    this.params.organizationId = orgId
    this.params.measurementTypeId = null
    this.setMeasurementTypes()
    const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
    if (dashboardParams) {
      Object.assign(dashboardParams, {
        organizationId: this.params.organizationId,
        productId: this.params.productId,
        measurementTypeId: null
      })
      this.storage.set(this.STORAGE_KEY, dashboardParams)
    } else {
      this.storage.set(this.STORAGE_KEY, {
        organizationId: this.params.organizationId,
        productId: this.params.productId,
        measurementTypeId: null
      })
    }

  }

  submit() {
    if (!this.selectedProduct || !this.params.from || !this.params.to) return
    const extras: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: this.params,
    }
    this.router.navigate([], extras)
  }

  canShowProducts(id: number) {
    if (this.role === 'superAdmin') {
      return id === this.params.organizationId
    } else {
      return true
    }
  }

  selectMeasurementType(measurementType) {
    this.selectedMeasurementType = measurementType
    const id = measurementType.value
    this.params.measurementTypeId = id
    const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
    if (dashboardParams) {
      Object.assign(dashboardParams, {
        measurementTypeId: this.params.measurementTypeId
      })
      this.storage.set(this.STORAGE_KEY, dashboardParams)
    } else {
      this.storage.set(this.STORAGE_KEY, {
        measurementTypeId: this.params.measurementTypeId
      })
    }

    // const controlValue = this.selectedProduct.controlValues.filter(cv => cv.measurementTypeId === id)[0]
    this.selectedControlValueIsFluoro =
      this.deviceTypes.filter(d => d.measurementTypeId === +id)[0].type === 'fluoro'
  }


  getMeasurementLabel() {
    if (this.params.measurementTypeId) {
      return this.measurementTypes.filter(mt => +mt.value === +this.params.measurementTypeId)[0].name
    } else {
      return null
    }
  }

  isFluoro() {
    if (this.params.measurementTypeId) {
      const controlValue = this.selectedProduct.controlValues.filter(cv => cv.measurementTypeId === 1)[0]
      if (controlValue) {
        return controlValue.isFluoro
      }
    } else {
      return false
    }
  }

  validateSubmitButton() {
    if (this.role === 'user') {
      return !(this.params.from &&
        this.params.to &&
        this.params.productId &&
        this.params.organizationId &&
        this.params.measurementTypeId)
    } else {
      return !(this.params.from &&
        this.params.to &&
        this.params.productId &&
        this.params.organizationId &&
        this.params.measurementTypeId)
    }

  }

  async loadMoreUsers(page) {
    if (page >= this.usersLastPage) return
    const result = await this.dataService.loadMoreUsers(page)
    this.usersCurrentPage = result.page
    this.usersLastPage = result.lastPage
    // this.organizationsList = this.organizations = initData.organizationsPage.organizations.map(o => {
    //   if (o.users.length) {
    //     o.users.forEach(u => {
    //       u.selected = this.search.userUuids.indexOf(u.uuid) > -1
    //       u.disabled = false
    //     })
    //   }
    //   return o
    // })

    const orgs = result.organizations.map(o => {
      if (!o.users) {
        o.users = []
      } else if (o.users.length) {
        o.users.forEach(u => {
          u.selected = this.search.userUuids.indexOf(u.uuid) > -1
          u.disabled = false
        })
      }
      return o
    })
    this.organizationsList = this.organizationsList.concat(orgs)
    this.organizations = this.organizations.concat(orgs)
  }

  validateDisableButton() {
    let disable = true
    Object.keys(this.params).forEach(k => {
      if (this.params[k]) disable = false
    })

    return disable
  }
}

interface DashboardParams {
  from?: number
  to?: number
  users?: string
  organizationId?: number
  productId?: number
  measurementTypeId?: number
}

// import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core'
// import {ActivatedRoute, NavigationExtras, Router} from '@angular/router'
// import {TranslateService} from '@ngx-translate/core'
// import {Subscription} from 'rxjs'
//
// import {ControlChartData, DashboardResponse} from '../../../../model/api-response'
// import {
//   ControlChartOptions,
//   DefaultControlSeries,
//   StockChartOptions,
//   ToleranceChartOptions,
//   usageChartOptions,
//   trainingChartOptions
// } from '../../../../model/chart-options'
// import {Product, ProductCategory} from '../../../../model/Products'
// import {SearchCriteria} from '../../../../model/search-criteria'
// import {CacheService} from '../../../services/cache.service'
// import {DataService} from '../../../services/data.service'
// import {ToastService} from '../../../services/toast.service'
// import {UserService} from '../../../services/user.service'
// import {ControlValue} from '../../../../model/control-value'
// import * as moment from 'moment'
// import jspdf from 'jspdf'
// import html2canvas from 'html2canvas'
// import {User} from '../../../../model/user'
// import {TrainingService} from 'src/app/services/training.service'
// import {StoreService} from '../../../services/store.service'
// import {ReportData} from '../../../../model/report-data'
// import {OrganizationService} from '../../../organization.service'
// import {AdminService} from '../../admin/admin.service'
// import {MultiSelectComponent} from '../../../shared/multi-select/multi-select.component'
// import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service'
// import {Organization} from '../../../../model/organization'
// import {FormControl, FormGroup} from '@angular/forms'
// import {SelectOption} from '../../../shared/form-control/form-control.component'
// import {DeviceType} from '../../../../model/deviceType'
// import {TrainingAttempt, TrainingChartAttempt} from '../../../../model/TrainingAttempt'
//
//
// @Component({
//   selector: 'app-dashboard',
//   templateUrl: './dashboard.component.html',
//   styleUrls: ['./dashboard.component.scss'],
// })
//
//
// export class DashboardComponent implements OnInit, OnDestroy {
//   private STORAGE_KEY = 'dashboard_params'
//   @ViewChild('analysts') analystSelection: MultiSelectComponent
//
//   prefix = 'dashboard.'
//   products: { [key: number]: Product } = {}
//   productLines: any[] = []
//
//   controlChart: ControlChartOptions = null
//   controlValue: ControlValue
//   controlChartLoading = false
//   usageChart: usageChartOptions
//   stockChart: StockChartOptions
//   toleranceChart: ToleranceChartOptions
//   bsDateRange: Date[] = []
//   search: SearchCriteria
//   controlDataEmpty = true
//   title = 'html-to-pdf-angular-application'
//   private _subs: Subscription[] = []
//   analysts = []
//   user: User
//   loading = true
//   organizationId: number
//   userOrg
//   sharedOrganizations: Organization[] = []
//
//   trainingChart: trainingChartOptions
//
//   customReportData = null
//   productLineName: string
//   chartBase64
//
//   organizations: Organization[] = []
//   organizationsList: Organization[] = []
//   selectedProduct: any
//   params: DashboardParams = {
//     from: null,
//     to: null,
//     users: null,
//     productId: null,
//     organizationId: null,
//     measurementTypeId: null
//   }
//   form: FormGroup
//   selectedMeasurementType: SelectOption
//   measurementTypes: SelectOption[]
//   measurementTypesSelectOptions: SelectOption[] = []
//   trainingAttempts: TrainingChartAttempt[]
//   selectedControlValueIsFluoro = false
//   productCategories: ProductCategory[]
//   deviceTypes: DeviceType[]
//   role: string
//   usersCurrentPage = null
//   usersLastPage = null
//   sharedMode = false
//   sharedUserUuid
//
//   switchUnitsOptions = {
//     Iron: ['mg/L'],
//     'Vitamin A': ['mg RE/kg', 'IU/g', 'µg RE/L']
//   }
//
//
//   constructor(
//     private dataService: DataService,
//     private cache: CacheService,
//     private route: ActivatedRoute,
//     private router: Router,
//     private toasts: ToastService,
//     private us: UserService,
//     private trx: TranslateService,
//     private ts: TrainingService,
//     private store: StoreService,
//     private os: OrganizationService,
//     private as: AdminService,
//     @Inject(LOCAL_STORAGE) private storage: StorageService
//   ) {
//     this.search = SearchCriteria.deserialize(this.route.snapshot.queryParams)
//     this.bsDateRange = this.search.getDateRangePickerDates()
//     this.form = new FormGroup({
//       showHierarchyUsers: new FormControl(false)
//     })
//   }
//
//   async ngOnInit() {
//     this._subs.push(this.us.userChanged.subscribe(u => {
//       this.role = u.getRole()
//       this.user = u
//     }))
//
//     const initData = await this.dataService.initDashboard()
//     this.sharedOrganizations = initData.sharedOrganizations
//     this.usersLastPage = initData.organizationsPage.lastPage
//     this.usersCurrentPage = initData.organizationsPage.page
//     this.organizationsList = this.organizations = initData.organizationsPage.organizations.map(o => {
//       if (o.users.length) {
//         o.users.forEach(u => {
//           u.selected = this.search.userUuids.indexOf(u.uuid) > -1
//           u.disabled = false
//         })
//       }
//       return o
//     })
//     this.productCategories = this.cache.productCategories.value
//     this.deviceTypes = this.cache.deviceTypes.value
//     this.trainingAttempts = initData.trainingAttempts
//     this.measurementTypes = this.cache.measurementTypes.value.map(({id, name}): SelectOption => ({
//       name,
//       value: id
//     }))
//
//     this._subs.push(this.cache.organization.subscribe(o => {
//       this.userOrg = o
//     }))
//
//     this.checkProductLines()
//     await this.loadFormQuery()
//
//   }
//
//   checkProductLines() {
//     this._subs.push(this.cache.productLines.subscribe(pl => {
//       const lines = pl.filter(l => !l.training)
//       const products = []
//       lines.forEach(pLine => {
//         if (pLine.products.length) {
//           pLine.products.forEach(p => {
//             products.push(p)
//           })
//         }
//       })
//
//       if (!products.length) {
//         // this.toasts.info('dashboard.no-product-lines-defined')
//         this.router.navigate(['/products'])
//       }
//     }))
//   }
//
//   async loadFormQuery() {
//     this._subs.push(this.route.queryParams.subscribe(p => {
//       if (!Object.keys(p).length && !this.storage.get(this.STORAGE_KEY)) return
//       console.log('p.measurementTypeId', p.measurementTypeId)
//       if (Object.keys(p).length) {
//         this.params.organizationId = +p.organizationId
//         this.params.productId = +p.productId
//         this.params.from = +p.from
//         this.params.to = +p.to
//         this.params.users = p.users
//         this.params.measurementTypeId = +p.measurementTypeId
//       } else {
//         this.params = this.storage.get(this.STORAGE_KEY)
//       }
//
//       if (p.users) {
//         const allUsers = []
//         this.organizationsList.forEach(o => {
//           if (o.users.length) {
//             o.users.forEach(u => {
//               allUsers.push(u)
//             })
//           }
//         })
//         const userUuid = p.users.split(',')
//         const usrs = allUsers.filter(u => userUuid.includes(u.uuid))
//
//         this.analysts = usrs.map(u => `${u.firstName} ${u.lastName}`)
//       }
//
//       this.search = SearchCriteria.deserialize(this.params)
//       if (this.params.from && this.params.to) {
//         this.bsDateRange = [moment.unix(this.params.from).toDate(), moment.unix(this.params.to).toDate()]
//       }
//       if (this.params.productId) {
//         const org = this.organizationsList.find(o => o.id === this.params.organizationId)
//         this.selectedProduct = org.productLines
//           .map(pl => pl.products.filter(product => product.id === this.params.productId)).filter(pr => pr.length)[0][0]
//       }
//       if (this.selectedProduct) {
//         this.setMeasurementTypes()
//       }
//       this.resetUserFilter()
//       if (this.search.from &&
//         this.search.to &&
//         this.search.organizationId &&
//         this.search.productId &&
//         this.search.measurementTypeId) this.loadCharts()
//     }))
//     await this.loadTrainingChart()
//     this.loading = false
//   }
//
//   setMeasurementTypes() {
//     this.measurementTypesSelectOptions = []
//     const cv = this.selectedProduct.controlValues
//     this.measurementTypes.forEach(mt => {
//       if (cv.some(c => +mt.value === +c.measurementTypeId)) {
//         this.measurementTypesSelectOptions.push(mt)
//       }
//     })
//   }
//
//   ngOnDestroy() {
//     for (const s of this._subs) {
//       s.unsubscribe()
//     }
//     this._subs = []
//   }
//
//   resetUserFilter() {
//     this.organizationsList.forEach(o => {
//       o.users.forEach(u => u.selected = this.search.userUuids.indexOf(u.uuid) > -1)
//     })
//   }
//
//
//   async userFilterChanged(event: any) {
//     this.sharedMode = false
//     this.selectedProduct = null
//     this.params.measurementTypeId = null
//     this.organizationId = event.orgId
//     if (!event.orgs.length) {
//       const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
//       dashboardParams.users = null
//       dashboardParams.organizationId = null
//       this.storage.set(this.STORAGE_KEY, dashboardParams)
//       this.params.users = null
//       this.params.organizationId = null
//       this.params.productId = null
//       this.params.measurementTypeId = null
//       this.organizationsList = this.organizations
//       return
//     } else {
//       this.params.users = event.orgs.length ? event.orgs.map(i => i.uuid).join(',') : null
//       this.params.organizationId = event.orgs[0].organizationId
//
//       const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
//       if (dashboardParams) {
//         Object.assign(dashboardParams, {
//           organizationId: this.organizationId,
//           users: this.params.users,
//           productId: null,
//           measurementTypeId: null
//         })
//         this.storage.set(this.STORAGE_KEY, dashboardParams)
//       } else {
//         this.storage.set(this.STORAGE_KEY, {
//           organizationId: this.organizationId,
//           users: this.params.users,
//           productId: null,
//           measurementTypeId: null
//         })
//       }
//     }
//   }
//
//   getClearItems() {
//     this.organizationsList = this.organizations
//   }
//
//   getProductName() {
//     if (!this.selectedProduct) {
//       return ''
//     }
//     return this.selectedProduct.name
//   }
//
//   getProductTarget() {
//     if (!this.params.productId) return ''
//     return this.controlValue.target
//   }
//
//   dateChange(dates: Date[]) {
//     if (!dates || dates.length !== 2) return
//     if (dates[0] === null || dates[1] === null) return
//
//     if (this.search.from && this.search.to) {
//       if (this.search.from.isSame(dates[0], 'day') && this.search.to.isSame(dates[1], 'day')) return
//     }
//     this.params.from = Math.round(+dates[0] / 1000)
//     this.params.to = Math.round(+dates[1] / 1000)
//     const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
//     if (dashboardParams) {
//       Object.assign(dashboardParams, {
//         from: this.params.from,
//         to: this.params.to
//       })
//       this.storage.set(this.STORAGE_KEY, dashboardParams)
//     } else {
//       this.storage.set(this.STORAGE_KEY, {
//         from: this.params.from,
//         to: this.params.to
//       })
//     }
//   }
//
//   async switchUnits(unit: string) {
//     if (unit === this.controlValue.unit) return
//     try {
//       this.controlChartLoading = true
//       const data = await this.dataService.getControlChart(this.search, unit)
//
//       this.controlValue.unit = unit
//       this.loadControlChart(data)
//     } catch (err) {
//       console.warn('That didn\'t work...', err)
//       this.toasts.danger('dashboard.unit-switch-error')
//     }
//
//     this.controlChartLoading = false
//
//   }
//
//   public generateReportChart() {
//     const data = document.getElementById('qcChart')
//     html2canvas(data, {
//       backgroundColor: '#ffffff',
//       scale: window.devicePixelRatio,
//     }).then(canvas => {
//       this.chartBase64 = canvas.toDataURL('image/png')
//     })
//   }
//
//   public downloadReport() {
//     const data = document.getElementById('contentToConvert')
//     html2canvas(data, {backgroundColor: '#FBF5FE', height: 842, width: 595}).then(canvas => {
//       // Few necessary setting options
//       const imgWidth = 216
//       const imgHeight = canvas.height * imgWidth / canvas.width
//
//       const contentDataURL = canvas.toDataURL('image/png')
//
//       const pdf = new jspdf('p', 'mm', 'a4', true) // A4 size page of PDF
//       pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
//       pdf.save('BioAnalyt-Report.pdf') // Generated PDF
//       this.chartBase64 = null
//     })
//   }
//
//
//   private async loadCharts() {
//     const data = await this.dataService.getDashboardData(this.search)
//     if (data.controlChart.series[0].data.length === 1) {
//       const obj = JSON.parse(JSON.stringify(data.controlChart.series[0].data[0]))
//       data.controlChart.series[0].data.push(obj)
//       const obj1 = JSON.parse(JSON.stringify(data.controlChart.series[0].data[1]))
//       data.controlChart.series[0].data.push(obj1)
//       if (typeof data.controlChart.series[0].data[0] === 'object') {
//         data.controlChart.series[0].data[0].color = 'transparent'
//       }
//       if (typeof data.controlChart.series[0].data[2] === 'object') {
//         data.controlChart.series[0].data[2].color = 'transparent'
//       }
//     }
//     this.loadControlChart(data.controlChart)
//     this.loadUsageChart(data)
//     this.loadStockChart(data)
//     this.loadReportData(data)
//     this.toleranceChart = new ToleranceChartOptions(data.controlChart)
//   }
//
//   private async loadTrainingChart() {
//     const labeledAttempts = {}
//
//     //trainingnchart
//
//     for (const attempt of this.trainingAttempts) {
//       const label = attempt.label
//
//       let controlName = 'Sample not selected'
//       if (label) {
//         controlName = label
//       }
//
//       if (labeledAttempts[controlName] === undefined) {
//         labeledAttempts[controlName] = {
//           label: controlName,
//           data: [],
//         }
//       }
//
//
//       if (attempt.cv) {
//         labeledAttempts[controlName].data.push({
//           user: `${attempt.firstName} ${attempt.lastName}`,
//           cv: attempt.cv,
//           step: 8,
//         })
//       } else {
//         labeledAttempts[controlName].data.push({
//           user: `${attempt.firstName} ${attempt.lastName}`,
//           cv: 0.0,
//           step: attempt.stepIndex,
//         })
//       }
//     }
//
//     // Get local attempts
//     // if (this.role !== 'superAdmin') {
//     //   const localTrainingData = this.ts.getTrainingProgress()
//     //   if (localTrainingData !== undefined) {
//     //     const localTrainingAttempt = {
//     //       user: this.store.get('user', null, User),
//     //       cv: 0.0,
//     //       step: this.ts.trainingProgressIndex.getValue()
//     //     }
//     //     if (labeledAttempts['Sample not selected'] === undefined) {
//     //       labeledAttempts['Sample not selected'] = {
//     //         label: 'Sample not selected',
//     //         data: [],
//     //       }
//     //     }
//     //
//     //     labeledAttempts['Sample not selected'].data.push(localTrainingAttempt)
//     //   }
//     // } else {
//     //   const unsuccessfulTraining = await this.ts.getUnsuccessfulTrainings()
//     //   if (labeledAttempts['Sample not selected'] === undefined) {
//     //     labeledAttempts['Sample not selected'] = {
//     //       label: 'Sample not selected',
//     //       data: [],
//     //     }
//     //   }
//     //
//     //   unsuccessfulTraining.forEach(t => {
//     //     labeledAttempts['Sample not selected'].data.push({
//     //       user: t.user,
//     //       cv: 0.0,
//     //       step: t.stepIndex
//     //     })
//     //   })
//     // }
//
//
//     this.trainingChart = new trainingChartOptions(labeledAttempts, this.search)
//     console.log('this.trainingChart', this.trainingChart)
//   }
//
//   private loadControlChart(controlChart: ControlChartData) {
//     if (!this.sharedMode) {
//       const c = {
//         title: {text: undefined},
//         // subtitle: { text: `${this.search.from.format('DD.MM.YYYY')} - ${this.search.to.format('DD.MM.YYYY')}` },
//         yAxis: {title: {text: `${controlChart.controlValue.label}, ${controlChart.controlValue.unit}`}},
//         xAxis: {allowDecimals: false, title: {text: `charts.control-chart.x-title`}},
//         legend: {layout: 'horizontal'},
//
//         series: controlChart.series,
//         credits: {enabled: false},
//       }
//
//       this.controlValue = controlChart.controlValue
//       const chart = new ControlChartOptions(c)
//       chart.addControlSeries(new DefaultControlSeries(controlChart.controlValue))
//       chart.setUnit(controlChart.controlValue.unit)
//
//       this.controlChart = chart
//       this.controlDataEmpty = (controlChart.series[0].data.length === 0)
//       //FLUORO
//       if (this.controlValue.isFluoro) {
//         this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g', 'µg RE/L']
//       } else {
//         this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g']
//       }
//     } else {
//       console.log("controlChart.series", controlChart)
//       const c = {
//         title: {text: undefined},
//         // subtitle: { text: `${this.search.from.format('DD.MM.YYYY')} - ${this.search.to.format('DD.MM.YYYY')}` },
//         yAxis: {title: {text: `controlChart.controlValue.label, controlChart.controlValue.unit}`}},
//         xAxis: {allowDecimals: false, title: {text: `charts.control-chart.x-title`}},
//         legend: {layout: 'horizontal'},
//
//         series: controlChart.series,
//         credits: {enabled: false},
//       }
//
//       this.controlValue = controlChart.controlValue
//       const chart = new ControlChartOptions(c)
//       chart.addControlSeries(new DefaultControlSeries(null))
//       chart.setUnit('mg RE/kg')
//
//       this.controlChart = chart
//       this.controlDataEmpty = (controlChart.series[0].data.length === 0)
//       //FLUORO
//       if (true) {
//         this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g', 'µg RE/L']
//       } else {
//         this.switchUnitsOptions['Vitamin A'] = ['mg RE/kg', 'IU/g']
//       }
//     }
//   }
//
//   private loadUsageChart(data: DashboardResponse) {
//     this.usageChart = new usageChartOptions(data.usageChart, data.search)
//   }
//
//   private loadStockChart(data: DashboardResponse) {
//     this.stockChart = new StockChartOptions(data.stockChart)
//     this.stockChart.title = {text: undefined, align: 'left'}
//     this.stockChart.yAxis.title.text = 'Available # of vials'
//   }
//
//   private async loadReportData(data) {
//     const deviceName = this.deviceTypes.filter(dt => dt.id === this.controlValue.deviceTypeId)[0].name
//     const productLine = await this.dataService.getProductLineById(this.selectedProduct.productLineId)
//     data.cv = this.controlValue
//     data.productCategory = this.productCategories.filter(c => this.controlValue.productCategoryId === c.id)[0].name
//     data.deviceName = deviceName
//     data.productLineName = productLine.name
//     data.product = this.selectedProduct
//
//     this.customReportData = new ReportData(data)
//   }
//
//   resetData() {
//     this.bsDateRange = []
//     this.controlDataEmpty = true
//     this.controlChart = null
//     this.usageChart = null
//     this.stockChart = null
//     this.toleranceChart = null
//     this.params.from = null
//     this.params.to = null
//     this.params.users = null
//     this.params.measurementTypeId = null
//     this.selectedProduct = null
//     this.params.organizationId = null
//     this.params.productId = null
//     this.search.reset()
//     this.organizationsList = this.organizations
//     if (this.role !== 'user') this.analystSelection.clearSelection()
//     this.storage.remove(this.STORAGE_KEY)
//     this.router.navigate(['/dashboard'])
//   }
//
//   selectProduct(product, orgId) {
//     this.selectedProduct = product
//     this.selectedMeasurementType = null
//     this.params.productId = product.id
//     this.params.organizationId = orgId
//     this.params.measurementTypeId = null
//     this.setMeasurementTypes()
//     const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
//     if (dashboardParams) {
//       Object.assign(dashboardParams, {
//         organizationId: this.params.organizationId,
//         productId: this.params.productId,
//         measurementTypeId: null
//       })
//       this.storage.set(this.STORAGE_KEY, dashboardParams)
//     } else {
//       this.storage.set(this.STORAGE_KEY, {
//         organizationId: this.params.organizationId,
//         productId: this.params.productId,
//         measurementTypeId: null
//       })
//     }
//
//   }
//
//   async submit() {
//     if (!this.sharedMode) {
//       if (!this.selectedProduct || !this.params.from || !this.params.to) return
//       const extras: NavigationExtras = {
//         queryParamsHandling: 'merge',
//         queryParams: this.params,
//       }
//       this.router.navigate([], extras)
//     } else {
//       const data = {
//         uuid: this.sharedUserUuid,
//         from: this.search.from.unix(),
//         to: this.search.to.unix()
//       }
//       console.log('dadadadad', data)
//       const res = await this.dataService.getSharedDashboardData(data)
//       console.log('res', res)
//       // const obj = JSON.parse(JSON.stringify(res.result.series[0].data[0]))
//       this.loadControlChart(res.result)
//     }
//   }
//
//   canShowProducts(id: number) {
//     if (this.role === 'superAdmin') {
//       return id === this.params.organizationId
//     } else {
//       return true
//     }
//   }
//
//   selectMeasurementType(measurementType) {
//     this.selectedMeasurementType = measurementType
//     const id = measurementType.value
//     this.params.measurementTypeId = id
//     const dashboardParams: DashboardParams = this.storage.get(this.STORAGE_KEY)
//     if (dashboardParams) {
//       Object.assign(dashboardParams, {
//         measurementTypeId: this.params.measurementTypeId
//       })
//       this.storage.set(this.STORAGE_KEY, dashboardParams)
//     } else {
//       this.storage.set(this.STORAGE_KEY, {
//         measurementTypeId: this.params.measurementTypeId
//       })
//     }
//
//     // const controlValue = this.selectedProduct.controlValues.filter(cv => cv.measurementTypeId === id)[0]
//     this.selectedControlValueIsFluoro =
//       this.deviceTypes.filter(d => d.measurementTypeId === +id)[0].type === 'fluoro'
//   }
//
//
//   getMeasurementLabel() {
//     if (this.params.measurementTypeId) {
//       return this.measurementTypes.filter(mt => +mt.value === +this.params.measurementTypeId)[0].name
//     } else {
//       return null
//     }
//   }
//
//   isFluoro() {
//     if (this.params.measurementTypeId) {
//       const controlValue = this.selectedProduct.controlValues.filter(cv => cv.measurementTypeId === 1)[0]
//       if (controlValue) {
//         return controlValue.isFluoro
//       }
//     } else {
//       return false
//     }
//   }
//
//   validateSubmitButton() {
//     if (!this.sharedMode) {
//       if (this.role === 'user') {
//         return !(this.params.from &&
//           this.params.to &&
//           this.params.productId &&
//           this.params.organizationId &&
//           this.params.measurementTypeId)
//       } else {
//         return !(this.params.from &&
//           this.params.to &&
//           this.params.productId &&
//           this.params.organizationId &&
//           this.params.measurementTypeId)
//       }
//     } else {
//       return !this.sharedUserUuid
//     }
//
//   }
//
//   async loadMoreUsers(page) {
//     if (page >= this.usersLastPage) return
//     const result = await this.dataService.loadMoreUsers(page)
//     this.usersCurrentPage = result.page
//     this.usersLastPage = result.lastPage
//     // this.organizationsList = this.organizations = initData.organizationsPage.organizations.map(o => {
//     //   if (o.users.length) {
//     //     o.users.forEach(u => {
//     //       u.selected = this.search.userUuids.indexOf(u.uuid) > -1
//     //       u.disabled = false
//     //     })
//     //   }
//     //   return o
//     // })
//
//     const orgs = result.organizations.map(o => {
//       if (!o.users) {
//         o.users = []
//       } else if (o.users.length) {
//         o.users.forEach(u => {
//           u.selected = this.search.userUuids.indexOf(u.uuid) > -1
//           u.disabled = false
//         })
//       }
//       return o
//     })
//     this.organizationsList = this.organizationsList.concat(orgs)
//     this.organizations = this.organizations.concat(orgs)
//   }
//
//   validateDisableButton() {
//     let disable = true
//     Object.keys(this.params).forEach(k => {
//       if (this.params[k]) disable = false
//     })
//
//     return disable
//   }
//
//   onSharedUserSelected(user: User) {
//     this.sharedMode = true
//     this.sharedUserUuid = user.uuid
//     console.log("$event$event", user)
//   }
// }
//
// interface DashboardParams {
//   from?: number
//   to?: number
//   users?: string
//   organizationId?: number
//   productId?: number
//   measurementTypeId?: number
// }
