import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscriptions } from '../../../model/subscriptions';
import { User } from '../../../model/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit, OnDestroy{
  private _subs = new Subscriptions();
  user: User;

  constructor(private um: UserService) {
  }

  ngOnInit() {
    this._subs.add(this.um.userChanged.subscribe(u => this.user = u));
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll();
  }
}
