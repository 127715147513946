import {Component, OnDestroy, OnInit} from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {Sample} from '../../../../model/sample'
import {DataService} from '../../../services/data.service'
import {FormControl, FormGroup} from '@angular/forms'
import moment from "moment/moment";
import jspdf from "jspdf";
import autoTable from "jspdf-autotable";
import {result} from "lodash";
import {ModalService} from "../../../shared/modal.service";
import {
  DataSharingGroupsModalComponent
} from "../../../shared/data-sharing-groups-modal/data-sharing-groups-modal.component";
import {DisableSamplesModalComponent} from "../../../shared/disable-samples-modal/disable-samples-modal.component";
import {AllDataShareModalComponent} from "../../../shared/all-data-share-modal/all-data-share-modal.component";
import {User} from "../../../../model/user";
import {UserService} from "../../../services/user.service";
import {Subscription} from "rxjs";
import {Moment} from "moment";
import {SelectOption} from "../../../shared/form-control/form-control.component";
import {notEmpty, round} from "../../../../model/helpers";

@Component({
  selector: 'app-all-data',
  templateUrl: './all-data.component.html',
  styleUrls: ['./all-data.component.scss'],
})


export class AllDataComponent implements OnInit, OnDestroy {
  prefix = 'all-data.'
  form: FormGroup
  page
  tableData = []
  loading = true
  selected = []
  loadingRows = []
  disabledCols = []
  user: User
  _subs = new Subscription()
  sharedSamplesPage
  tabs = [
    {
      title: 'tabs.own',
      active: true,
      disabled: false,
      type: 'own'
    },
    {
      title: 'tabs.shared',
      active: false,
      disabled: false,
      type: 'shared'
    }
  ]
  activeTab = 'own'
  units: SelectOption[] = [
    {name: 'Select Unit', value: ''},
    {name: 'mg RE/kg', value: 'mg RE/kg'},
    {name: 'IU/g', value: 'IU/g'},
  ]

  constructor(
    private ds: DataService,
    private route: ActivatedRoute,
    private modals: ModalService,
    private um: UserService,
  ) {
  }

  async ngOnInit() {
    this.form = new FormGroup({
      search: new FormControl(''),
      unit: new FormControl(''),
    })
    try {
      this.page = await this.ds.getSamplesPage(0, '')
      this.sharedSamplesPage = await this.ds.getSharedSamplesPage(0, '')
      console.log('notEmpty(this.sharedSamplesPage.items)', notEmpty(this.sharedSamplesPage.items))
      if (notEmpty(this.sharedSamplesPage.items)) {
        this.sharedSamplesPage.items.forEach((item) => {
          item.defaultUnit = item.unit
          item.defaultResult = item.result
        })
      }
      this.getTableData(this.page)
      this.loading = false
    } catch (e) {
      this.loading = false
      console.log("error", e)
    }
    this._subs.add(this.um.userChanged.subscribe(u => this.user = u));
    console.log("this.user", this.tableData)
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  async getPage(page = 0) {
    const search = this.form.value.search ? this.form.value.search.trim() : null
    this.loading = true
    try {
      this.page = await this.ds.getSamplesPage(page, search)
      this.getTableData(this.page)
      this.selected = []
    } catch (err) {
      console.log('Handle error somehow', err)
    }
    this.loading = false
  }

  async getSharedPage(page = 0) {
    const search = this.form.value.search ? this.form.value.search.trim() : null
    this.loading = true
    try {
      this.sharedSamplesPage = await this.ds.getSharedSamplesPage(page, search)
      if (notEmpty(this.sharedSamplesPage.items)) {
        this.sharedSamplesPage.items.forEach((item) => {
          item.defaultUnit = item.unit
          item.defaultResult = item.result
        })
      }
      this.selected = []
    } catch (err) {
      console.log('Handle error somehow', err)
    }
    this.loading = false
  }


  getTableData(page: any) {
    console.log("page.items", page.items)
    this.tableData = page.items.map((s: Sample) => {
      const result: AllDataTable = {
        id: s.id,
        disabled: s.disabled,
        selected: false,
        date: moment(s.created).format('MMM DD, yyyy'),
        time: moment(s.created).format('HH:mm'),
        productLineName: (s.product && s.product.productLine) ? s.product.productLine.name : '-',
        productName: s.product ? s.product.name : '-',
        productBatch: s.userBatchId ? s.userBatchId : '-',
        analyst: `${s.import.user.firstName} ${s.import.user.lastName}`,
        analyte: s.measurementType ? s.measurementType.name : '-',
        result: s.result ? s.result.toString() : '-',
        unit: s.unit ? s.unit : '-',
        organizationName: s.import.user.organization.name,
        organizationCountry: s.import.user.organization.country,
        organizationId: s.import.user.organization.id,
        userId: s.import.user.id,
        organizationCode: s.import.user.organization.code,
        icheckBatch: `Batch: ${s.deviceBatchId} Sample: ${s.deviceSampleId}`,
        deviceType: s.import.device ? s.import.device.type : '-',
        deviceId: s.import.device ? s.import.device.lot : '-',
        standard: "NULL",
        standardLot: "NULL",
        standardReading: s.import.device ?
          `min: ${s.import.device.minRange} max: ${s.import.device.maxRange} unit: ${s.import.device.unit}` :
          '-',
        standardReadingDate: "NULL",
        testKitType: s.testKit ? s.testKit.type : '-',
        testKitLot: s.testKit ? s.testKit.lot : '-',
        created: s.created.unix(),
        defaultUnit: s.unit,
        defaultResult: s.result,
      }
      return result
    })
  }

  onSelected(data: AllDataTable[]) {
    this.selected = data
  }

  onSharedSelected(data: AllDataTable[]) {
    this.selected = data
  }

  async doSearch() {
    await this.getPage(0)
  }

  async disableAllSamples() {
    if (!this.selected.length) return
    const samples = this.selected.map(s => {
      return {
        date: s.date,
        time: s.time,
        result: s.result,
        unit: s.unit,
      }
    })
    const res = await this.modals.show(DisableSamplesModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      initialState: {
        samples,
        state: 'disable'
      }
    })

    if (!res) return
    const ids = this.selected.map(s => s.id)
    this.loadingRows = ids
    try {
      if (this.activeTab === 'own') {
        await this.ds.disableSamples(ids)
        this.tableData.forEach((d: AllDataTable) => {
          if (ids.includes(d.id)) {
            d.disabled = true
            this.loadingRows = this.loadingRows.filter(id => id !== d.id)
          }
        })
      } else if (this.activeTab === 'shared') {
        await this.ds.disableSharedSamples(ids)
        this.sharedSamplesPage.items.forEach(d => {
          if (ids.includes(d.id)) {
            d.disabled = true
            this.loadingRows = this.loadingRows.filter(id => id !== d.id)
          }
        })
      }

    } catch (e) {
      this.loadingRows = []
      console.log(e)
    }
  }

  async enableAllSamples() {
    if (!this.selected.length) return
    const samples = this.selected.map(s => {
      return {
        date: s.date,
        time: s.time,
        result: s.result,
        unit: s.unit,
      }
    })
    const res = await this.modals.show(DisableSamplesModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      initialState: {
        samples,
        state: 'enable'
      }
    })

    if (!res) return
    const ids = this.selected.map(s => s.id)
    this.loadingRows = ids
    try {
      await this.ds.enableSamples(ids)
      this.tableData.forEach((d: AllDataTable) => {
        if (ids.includes(d.id)) {
          d.disabled = false
          this.loadingRows = this.loadingRows.filter(id => id !== d.id)
        }
      })
    } catch (e) {
      this.loadingRows = []
      console.log(e)
    }
  }

  exportCsv() {
    this.loading = true
    const table = this.getTableDataForExport()
    this.ds.exportTpCsv('iCheck-Connect-All-Data-Export.csv', table)
    this.loading = false
  }


  exportPdf() {
    if (!this.selected.length) return
    this.loading = true
    const body = this.getTableDataForExport()
    const doc = new jspdf()
    autoTable(doc, {
      columns: [
        {header: 'Date', dataKey: 'Date'},
        {header: 'Time', dataKey: 'Time'},
        {header: 'Product Type', dataKey: 'Product Type'},
        {header: 'Product Name', dataKey: 'Product Name'},
        {header: 'Product Batch ID', dataKey: 'Product Batch ID'},
        {header: 'Analyst', dataKey: 'Analyst'},
        {header: 'Analyte', dataKey: 'Analyte'},
        {header: 'iCheck Result', dataKey: 'iCheck Result'},
        {header: 'Unit', dataKey: 'Unit'},
        {header: 'Company Name', dataKey: 'Company Name'},
        {header: 'Country', dataKey: 'Country'},
        {header: 'User ID', dataKey: 'User ID'},
        {header: 'Organization Code', dataKey: 'Organization Code'},
        {header: 'iCheck Batch', dataKey: 'iCheck Batch'},
        {header: 'iCheck Device', dataKey: 'iCheck Device'},
        {header: 'iCheck Device ID', dataKey: 'iCheck Device ID'},
        {header: 'iCheck Standard', dataKey: 'iCheck Standard'},
        {header: 'iCheck Standard LOT', dataKey: 'iCheck Standard LOT'},
        {header: 'iCheck Standard reading', dataKey: 'iCheck Standard reading'},
        {header: 'iCheck Standard reading date', dataKey: 'iCheck Standard reading date'},
        {header: 'iCheck Test Kit', dataKey: 'iCheck Test Kit'},
        {header: 'iCheck Test Kit LOT', dataKey: 'iCheck Test Kit LOT'},
      ],
      body,
      styles: {
        fontSize: 5,

      },
      margin: {right: 0, left: 0, top: 0, bottom: 0},
    })
    doc.save('iCheck-Connect-All-Data-Export.pdf')
    this.loading = false
  }


  getTableDataForExport() {
    const samples = this.selected.filter(s => !s.disabled)
    const table = samples.map(s => {
      const data = {
        Date: this.disabledCols.includes('date') ? '-' : s.date,
        Time: this.disabledCols.includes('time') ? '-' : s.time,
        'Product Type': this.disabledCols.includes('productLineName') ? '-' : s.productLineName,
        'Product Name': this.disabledCols.includes('productName') ? '-' : s.productName,
        'Product Batch ID': this.disabledCols.includes('productBatch') ? '-' : s.productBatch,
        Analyst: this.disabledCols.includes('analyst') ? '-' : s.analyst,
        Analyte: this.disabledCols.includes('analyte') ? '-' : s.analyte,
        'iCheck Result': this.disabledCols.includes('result') ? '-' : s.result,
        Unit: this.disabledCols.includes('unit') ? '-' : s.unit,
        'Company Name': this.disabledCols.includes('organizationName') ? '-' : s.organizationName,
        Country: this.disabledCols.includes('organizationCountry') ? '-' : s.organizationCountry,
        'User ID': this.disabledCols.includes('userId') ? '-' : s.userId,
        'Organization Code': this.disabledCols.includes('organizationCode') ? '-' : s.organizationCode,
        'iCheck Batch': this.disabledCols.includes('icheckBatch') ? '-' : s.icheckBatch,
        'iCheck Device': this.disabledCols.includes('deviceType') ? '-' : s.deviceType,
        'iCheck Device ID': this.disabledCols.includes('deviceId') ? '-' : s.deviceId,
        'iCheck Standard': this.disabledCols.includes('standard') ? '-' : s.standard,
        'iCheck Standard LOT': this.disabledCols.includes('standardLot') ? '-' : s.standardLot,
        'iCheck Standard reading': this.disabledCols.includes('standardReading') ? '-' : s.standardReading,
        'iCheck Standard reading date': this.disabledCols.includes('standardReadingDate') ? '-' : s.standardReadingDate,
        'iCheck Test Kit': this.disabledCols.includes('testKitType') ? '-' : s.testKitType,
        'iCheck Test Kit LOT': this.disabledCols.includes('testKitLot') ? '-' : s.testKitLot,
      }
      return data
    })

    return table
  }

  exportToPdf() {

  }

  columnSelected(c: any) {
    this.disabledCols = c
    console.log("this.disabledCols", this.disabledCols)
  }

  async share() {
    if (this.activeTab === 'shared') return
    const filteredSamples = this.filterDisableSamples(this.selected)
    const res = await this.modals.show(AllDataShareModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      initialState: {
        userUuid: this.user.uuid,
        samples: filteredSamples,
      }
    })
    console.log('asdhgfaskdhfasgdfkjasdgfsajdhfk, res', res)
  }

  filterDisableSamples(samples: AllDataTable[]) {
    const copy = JSON.parse(JSON.stringify(samples))
    let activeSamples = copy.filter(s => !s.disabled)
    activeSamples = activeSamples.map(s => {
      delete s.disabled
      delete s.selected
      Object.keys(s).forEach(k => {
        if (this.disabledCols.includes(k)) {
          delete s[k]
        }
      })

      return s
    })
    return activeSamples
  }

  async selectTab(tab) {
    this.disabledCols = []
    tab.type === 'shared' ? await this.getSharedPage() : await this.getPage()
    tab.active = true
    this.activeTab = tab.type
  }

  unitSelected(unit: string) {
    //TODO ADD VALIDATION
    this.convertUnits(unit)
  }


  convertUnits(unit) {
    console.log('this.activeTab', this.activeTab)
    switch (this.activeTab) {
      case 'own':
        if (!this.tableData.length) return
        this.tableData.forEach(s => {
          switch (unit) {
            case 'IU/g':
              if (s.unit === 'mg RE/kg') {
                s.unit = 'IU/g'
                s.result = round(s.result / .33)
              }
              break;
            case 'mg RE/kg':
              if (s.unit === 'IU/g') {
                s.unit = 'mg RE/kg'
                s.result = round(s.result * .33)
              }
              break;
            default:
              s.unit = s.defaultUnit
              s.result = s.defaultResult
              break;
          }
          console.log({SelectedUnit: unit, Unit: s.unit, Result: s.result})
        })
        break;

      case 'shared':
        if (!notEmpty(this.sharedSamplesPage.items)) return
        this.sharedSamplesPage.items.forEach(s => {
          switch (unit) {
            case 'IU/g':
              if (s.unit === 'mg RE/kg') {
                s.unit = 'IU/g'
                s.result = round(s.result / .33)
              }
              break;
            case 'mg RE/kg':
              if (s.unit === 'IU/g') {
                s.unit = 'mg RE/kg'
                s.result = round(s.result * .33)
              }
              break;
            default:
              s.unit = s.defaultUnit
              s.result = s.defaultResult
              break;
          }
          console.log({SelectedUnit: unit, Unit: s.unit, Result: s.result})
        })
        break;
    }
  }
}

export interface AllDataTable {
  id: number;
  disabled: boolean
  selected: boolean
  date: string
  time: string
  productLineName: string
  productName: string
  productBatch: string
  analyst: string
  analyte: string
  result: string
  unit: string
  organizationName: string
  organizationCountry: string
  userId: number
  organizationCode: string
  icheckBatch: string
  deviceType: string
  deviceId: string
  standard: string
  standardLot: string
  standardReading: string
  standardReadingDate: string
  testKitType: string
  testKitLot: string
  organizationId?: number
  created?: number
  defaultUnit?: string
  defaultResult?: number
}


