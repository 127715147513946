<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate : { name: user.getName() } }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <h6>{{ prefix + 'reset-email-title' | translate }}</h6>
  <p>{{ prefix + 'reset-email-content' | translate }}</p>
  <button class="btn btn-block btn-primary" (click)="sendResetEmail()">{{ prefix + 'reset-email-button' | translate }}</button>
  <button *ngIf="!showResetForm" class="btn btn-block btn-light" (click)="showResetForm = true">{{ prefix + 'show-form-button' | translate }}</button>
  
  <div *ngIf="showResetForm">
    <hr>
    <p *ngIf="hasError" class="text-danger">{{ 'error' | translate }}</p>
    <app-password-reset-form (error)="hasError = true" (completed)="completed()" [user]="user" mode="admin">
    </app-password-reset-form>
  </div>


</div>