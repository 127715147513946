import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {Subscription} from 'rxjs'
import {Import} from '../../../../model/import'
import {Sample} from '../../../../model/sample'
import {CacheService} from '../../../services/cache.service'
import {DataService} from '../../../services/data.service'
import {ToastService} from '../../../services/toast.service'
import {ConfirmModalComponent} from '../../../shared/confirm-modal/confirm-modal.component'
import {ModalService} from '../../../shared/modal.service'
import {ProcessedSamplesComponent} from '../processed-samples/processed-samples.component'
import {Device} from '../../../../model/device'
import {SamplesTableComponent} from '../../../shared/samples-table/samples-table.component'

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit, OnDestroy {
  private _sub: Subscription = new Subscription()
  @ViewChild('processedView') processedView: ProcessedSamplesComponent
  @ViewChild('samplesTable') samplesTable: SamplesTableComponent

  selectCount = 0
  unprocessed: Sample[] = []
  processed: Sample[] = []
  modalOpen = false
  loading = true
  showProcessed = true
  import: Import
  devices: Device[]

  constructor(
    private data: DataService,
    private cache: CacheService,
    private modals: ModalService,
    private toast: ToastService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this._sub.add(this.cache.devices.subscribe(d => {
      this.devices = d
    }))
    this.import = await this.data.getImport(this.route.snapshot.params.id)
    this.filterSamples()
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe()
      this._sub = undefined
    }
  }

  addToUnprocessed(sample: Sample) {
    this.unprocessed.push(sample)
  }

  async process(tData, successText = 'import.saved-toast') {
    if (tData) this.unprocessed = tData
    const processing = []

    for (let i = this.unprocessed.length - 1; i >= 0; i--) {
      if (this.unprocessed[i].selected) {
        processing.unshift(this.unprocessed[i])
        this.unprocessed.splice(i, 1)
      }
    }
    this.selectCount = 0
    try {
      for (const s of processing) {
        if (!s.discarded) {
          s.processed = true
        }
      }
      const results = await this.data.saveSamples(processing)

      this.toast.info(successText, undefined, {c: results.success.length})
      this.processed.unshift.apply(this.processed, processing)
      this.processedView.add(processing)
      await this.lastMaintained()
    } catch (err) {
      console.error("Sample saving error", err)
      this.toast.danger("import.save-error-toast")
      this.unprocessed.unshift.apply(this.unprocessed, processing)
      this.selectCount = this.unprocessed.filter(s => s.selected).length
    }

  }

  async discard(tData) {
    this.unprocessed = tData
    const selected = this.unprocessed.filter(s => s.selected === true)

    this.modalOpen = true

    const options = {
      title: 'import.discard.modal-title',
      content: 'import.discard.modal-content',
      placeholder: 'import.discard.modal-placeholder',
      okButton: 'import.discard.modal-ok-button',
      cancelButton: 'import.discard.modal-cancel-button',
      withComment: true,
      params: {c: selected.length}
    }
    const result = await this.modals.show(ConfirmModalComponent, {ignoreBackdropClick: true, initialState: options})
    this.modalOpen = false
    if (!result.confirmed) return

    for (const s of this.unprocessed) {
      if (!s.selected) continue
      s.comment = result.text
      s.discarded = true
    }
    await this.process(null, 'import.discarded-toast')
    this.samplesTable.toggleAll(true)
  }

  private filterSamples() {
    this.unprocessed = []
    this.processed = []
    for (const s of this.import.samples) {
      if (!s.productId && s.mode.toLowerCase() === "sample" && !s.discarded) {
        this.unprocessed.push(s)
      } else {
        // this.processed.push(s)
      }
    }
    this.loading = false
  }

  async lastMaintained() {
    // const dev = this.devices.find(d => d.id === this.import.deviceId)
    // dev.maintained = this.import.created
    // await this.data.updateDevice(dev)
  }
}
