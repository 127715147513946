import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { Toast } from 'src/model/toast';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  animations: [
    trigger("slideIn", [
      transition(':enter', [
        style({ transform: 'translateX(130%)' }),
        animate('.2s ease-out', style({ transform: 'translateX(0)' }))
      ]
      ),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('.2s ease-out', style({ transform: 'translateX(130%)' }))
      ])
    ]),
  ]
})
export class ToastsComponent {

  toasts: Toast[] = [];

  constructor(private toastService: ToastService) {
    this.toastService.onToastAdded.subscribe((t: Toast) => {
      this.toasts.push(t);
    });

    this.toastService.onToastRemoved.subscribe((t: Toast) => {
      this.removeToast(t);
    });
  }

  private removeToast(toast: Toast) {
    const index = this.toasts.findIndex(t => t === toast);
    if (index > -1) this.toasts.splice(index, 1);
  }

  onClick(toast: Toast) {
    if (typeof toast.onClick === "function") {
      toast.onClick(toast);
    }

    this.removeToast(toast);
  }



}
