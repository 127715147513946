import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  toggleMenu = new BehaviorSubject<boolean>(false);

  constructor() { }
}
