import {Component, OnInit} from '@angular/core'
import {ModalService} from '../modal.service'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {FormControl, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-hierarchy-rename-modal',
  templateUrl: './hierarchy-rename-modal.component.html',
  styleUrls: ['./hierarchy-rename-modal.component.scss']
})
export class HierarchyRenameModalComponent implements OnInit {

  prefix = 'admin.hierarchy.hierarchy-rename-modal.'
  hierarchyId
  form: FormGroup
  modalResult

  constructor(
    private ms: ModalService,
    public modal: BsModalRef,
    private as: AdminService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
  }

  async rename() {
    const res = await this.as.renameHierarchy(this.hierarchyId, this.form.get('name').value)
    this.modalResult = res
    this.modal.hide()
  }
}
