<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'title' | translate : {name: user.getName()} }}
  </h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="overflow" [formGroup]="form">
  <div class="modal-body roles-container">
    <p class="title">{{prefix + 'client-roles-title' | translate}}</p>

    <div class="roles">
      <div *ngFor="let r of roles.userRoles; let idx = index"
           class="row role" [ngClass]="{selected: form.get('role').value === r.value}">
        <div class="col p-0 mx-3">
          <div class="custom-control custom-checkbox roles-checkbox">
            <input class="custom-control-input" type="radio" name="role" [value]="r.value" [id]="r.value"
                   formControlName="role" (change)="onRoleChanged($event, r.value)">
            <label class="text-dark custom-control-label roles-label" [for]="r.value">{{prefix + r.value | translate}}</label>
          </div>

          <div class="px-4 permissions">
            <div class="custom-control custom-checkbox" *ngFor="let p of r.permissions">
              <input class="custom-control-input" type="checkbox" [id]="p.label + r.value" [checked]="p.selected" (change)="onPermissionChanged($event, r.value, p.label)">
              <label class="text-dark custom-control-label" [for]="p.label + r.value">{{prefix + p.label | translate}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body roles-container">
    <p class="title">{{prefix + 'global-roles-title' | translate}}</p>

    <div class="global-roles">
      <div *ngFor="let r of roles.globalRoles; let idx = index"
           class="row role">
        <div class="col p-0 mx-3">
          <div class="custom-control custom-checkbox global-roles-checkbox">
            <input class="custom-control-input" type="checkbox" [value]="r.value" [id]="r.value"
                   [formControlName]="r.value">
            <label class="text-dark custom-control-label global-roles-label" [for]="r.value">{{prefix + r.value | translate}}</label>
            <p class="description">{{prefix + r.value + '-description' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-light" (click)="modal.hide()">
    <i class="fa fa-times"></i> {{ prefix + 'discard' | translate }}
  </button>
  <button class="btn btn-primary" (click)="submit()">
    <i class="fa fa-check"></i> {{ prefix + 'save' | translate }}
  </button>
</div>
