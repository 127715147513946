<button
  *ngIf="type === 'dark-green'"
  class="dark-green"
  [disabled]="disabled"
  [ngStyle]="{width, height, fontSize, borderRadius}"
  (click)="onClick.emit()"
>{{text}}</button>

<button
  *ngIf="type === 'invisible-icon'"
  class="invisible-icon"
  [disabled]="disabled"
  [ngStyle]="{width, height, fontSize, borderRadius}"
  (click)="onClick.emit()"
>
  <ng-content></ng-content>
</button>

<button
  *ngIf="type === 'outline-gradient'"
  class="outline-gradient"
  [disabled]="disabled"
  [ngStyle]="{width, height, fontSize, borderRadius}"
  (click)="onClick.emit()"
>
  <img class="btn-image" *ngIf="image" [src]="'assets/icons/' + image + '.svg'">
  <img class="btn-image hover" *ngIf="image" [src]="'assets/icons/' + image + '-white.svg'">
  <p class="outline-gradient__text">{{text}}</p>
</button>

<button
  *ngIf="type === 'filled-gradient'"
  class="filled-gradient"
  [disabled]="disabled"
  [ngStyle]="{width, height, fontSize, borderRadius}"
  (click)="onClick.emit()"
>
  <img class="btn-image" *ngIf="image" [src]="'assets/icons/' + image">
  {{text}}
</button>

<button
  *ngIf="type === 'outline-dark-green'"
  class="outline-dark-green"
  [disabled]="disabled"
  [ngStyle]="{width, height, fontSize, borderRadius}"
  (click)="onClick.emit()"
>{{text}}</button>

<button
  *ngIf="type === 'text'"
  class="text-btn"
  [disabled]="disabled"
  [ngStyle]="{fontSize, color, borderRadius}"
  (click)="onClick.emit()"
>{{text}}</button>
