import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() { }

  public set(name: string, value: any) {
    //Serialize if we can.
    if (typeof value === 'object' && typeof value.serialize === 'function') value = value.serialize();

    localStorage.setItem(name, JSON.stringify(value));
  }

  public get<T>(name: string, defaultValue?: any, type?: any): T {
    const json = localStorage.getItem(name);
    if (json === null) return defaultValue;

    return this.tryParse(json, type);
  }

  private tryParse(data: string, type?: any) {
    try {
      const parsed = JSON.parse(data);

      //Deserialize if we can
      if (type && typeof type.deserialize === 'function') {
        return type.deserialize(parsed);
      } else {
        return parsed;
      }
    } catch (err) { 
      //Can't be parsed. Return the value as-is
      return data;
    }
  }

  public remove(name: string) {
    localStorage.removeItem(name);
  }

  public clear() {
    localStorage.clear();
  }
}
