import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { UploadComponent } from '../pages-authenticated/user/upload/upload.component';
import { AddProductModalComponent } from './add-product-modal/add-product-modal.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { BulkActionModalComponent } from './bulk-action-modal/bulk-action-modal.component';
import { ChartComponent } from './chart/chart.component';
import { ClipboardDirective } from './clipboard.directive';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { DeepValuePipe } from './deep-value.pipe';
import { DeviceModalComponent } from './device-modal/device-modal.component';
import { DocsTableComponent } from './docs-table/docs-table.component';
import { EditUserGroupsModalComponent } from './edit-user-groups-modal/edit-user-groups-modal.component';
import { FancyTableComponent } from './fancy-table/fancy-table.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormControlComponent } from './form-control/form-control.component';
import { InlineInputComponent } from './inline-input/inline-input.component';
import { InputModalComponent } from './input-modal/input-modal.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { MenuComponent } from './menu/menu.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { NotificationNavComponent } from './notification-nav/notification-nav.component';
import { OnboardingMessageComponent } from './onboarding-message/onboarding-message.component';
import { OnboardingNavButtonComponent } from './onboarding-nav-button/onboarding-nav-button.component';
import { OverlaySpinnerComponent } from './overlay-spinner/overlay-spinner.component';
import { PagingComponent } from './paging/paging.component';
import { ProductLineManagerComponent } from './product-line-manager/product-line-manager.component';
import { ProductRowComponent } from './product-row/product-row.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { SearchControlComponent } from './search-control/search-control.component';
import { SelectOrgModalComponent } from './select-org-modal/select-org-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TestKitModalComponent } from './test-kit-modal/test-kit-modal.component';
import { UploadDocModalComponent } from './upload-doc-modal/upload-doc-modal.component';
import { UserDetailsModalComponent } from './user-details-modal/user-details-modal.component';
import { UserNavDropdownComponent } from './user-nav-dropdown/user-nav-dropdown.component';
import { ImportTrainingDataComponent } from '../pages-authenticated/user/import-training-data/import-training-data.component';
import { TrainingDataTableComponent } from '../pages-authenticated/user/training-data-table/training-data-table.component';
import { HelpNavComponent } from './help-nav/help-nav.component';
import { UsersManagerComponent } from './users-manager/users-manager.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DeviceManagerComponent } from './device-manager/device-manager.component';
import { DilutionModalComponent } from './dilution-modal/dilution-modal.component';
import { StepCounterComponent } from './step-counter/step-counter.component';
import { DragDropDirective } from './drag-drop.directive';
import { SamplesTableComponent } from './samples-table/samples-table.component';
import { AddProductLineModalComponent } from './add-product-line-modal/add-product-line-modal.component';
import { ConfirmProfileChangesModalComponent } from './confirm-profile-changes-modal/confirm-profile-changes-modal.component';
import { DeleteProductModalComponent } from './delete-product-modal/delete-product-modal.component';
import { InvalidDateModalComponent } from './invalid-date-modal/invalid-date-modal.component';
import { BugListModalComponent } from './bug-list-modal/bug-list-modal.component';
import { CreateBugReportModalComponent } from './create-bug-report-modal/create-bug-report-modal.component';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { DeviceDetailedModalComponent } from './device-detailed-modal/device-detailed-modal.component';
import {
  SelectHierarchyOrganizationModalComponent
} from './select-hierarchy-organization-modal/select-hierarchy-organization-modal.component';
import { HierarchyModalComponent } from './hierarchy-modal/hierarchy-modal.component';
import { HierarchyRenameModalComponent } from './hierarchy-rename-modal/hierarchy-rename-modal.component';
import { CreateHierarchyModalComponent } from './create-hierarchy-modal/create-hierarchy-modal.component';
import { ParentOrgComponent } from './create-hierarchy-modal/parent-org/parent-org.component';
import { ChildOrgComponent } from './create-hierarchy-modal/child-org/child-org.component';
import { HierarchyPermissionsModalComponent } from './hierarchy-permissions-modal/hierarchy-permissions-modal.component';
import { CreateHierarchyModalParentComponent } from './create-hierarchy-modal-parent/create-hierarchy-modal-parent.component';
import { CreateHierarchyModalChildComponent } from './create-hierarchy-modal-child/create-hierarchy-modal-child.component';
import { PermissionsDropdownComponent } from './permissions-dropdown/permissions-dropdown.component';
import {TimeagoModule} from 'ngx-timeago';
import { DotsLoaderComponent } from './dots-loader/dots-loader.component';
import { EditDeleteDropdownComponent } from './edit-delete-dropdown/edit-delete-dropdown.component';
import { DropdownUserOrganizationsComponent } from './dropdown-user-organizations/dropdown-user-organizations.component';
import { DropdownProductsProductLinesComponent } from './dropdown-products-product-lines/dropdown-products-product-lines.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FileUploadSamplesComponent } from './file-upload-samples/file-upload-samples.component';
import { TestKitsAdvancedComponent } from './test-kits-advanced/test-kits-advanced.component';
import { TestKitsTableComponent } from './test-kits-table/test-kits-table.component';
import { TestKitsMobileTableComponent } from './test-kits-mobile-table/test-kits-mobile-table.component';
import { DevicesAdvancedComponent } from './devices-advanced/devices-advanced.component';
import { DevicesTableComponent } from './devices-table/devices-table.component';
import { DevicesMobileTableComponent } from './devices-mobile-table/devices-mobile-table.component';
import { DocsMobileTableComponent } from './docs-mobile-table/docs-mobile-table.component'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsComponent } from './buttons/buttons.component';
import { DataSharingGroupsTableComponent } from './data-sharing-groups-table/data-sharing-groups-table.component';
import { DataSharingGroupsModalComponent } from './data-sharing-groups-modal/data-sharing-groups-modal.component';
import { DataSharingGroupsAdvancedModalComponent } from './data-sharing-groups-advanced-modal/data-sharing-groups-advanced-modal.component';
import { DataSharingGroupsAdvancedModalOrganizationComponent } from './data-sharing-groups-advanced-modal/data-sharing-groups-advanced-modal-organization/data-sharing-groups-advanced-modal-organization.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component'
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { ScrollDotsLoaderComponent } from './scroll-dots-loader/scroll-dots-loader.component';
import { CustomConfirmModalComponent } from './custom-confirm-modal/custom-confirm-modal.component';
import { AllDataTableComponent } from './all-data-table/all-data-table.component';
import { AddOrganizationModalComponent } from './add-organization-modal/add-organization-modal.component';
import { DisableSamplesModalComponent } from './disable-samples-modal/disable-samples-modal.component';
import { AllDataShareModalComponent } from './all-data-share-modal/all-data-share-modal.component';
import { AllDataShareAproveModalComponent } from './all-data-share-aprove-modal/all-data-share-aprove-modal.component';

@NgModule({
    declarations: [
        ChartComponent,
        UploadComponent,
        FileUploadComponent,
        ClipboardDirective,
        CopyButtonComponent,
        InputModalComponent,
        InlineInputComponent,
        SpinnerComponent,
        ConfirmModalComponent,
        ProductLineManagerComponent,
        ProductRowComponent,
        NoDataComponent,
        NotificationNavComponent,
        NotificationCardComponent,
        MultiSelectComponent,
        UserNavDropdownComponent,
        TestKitModalComponent,
        FormControlComponent,
        OverlaySpinnerComponent,
        NavBarComponent,
        MenuComponent,
        ProfileImageComponent,
        AdminMenuComponent,
        FancyTableComponent,
        DeepValuePipe,
        PagingComponent,
        LoadingBarComponent,
        EditUserGroupsModalComponent,
        UserDetailsModalComponent,
        SelectOrgModalComponent,
        SearchControlComponent,
        BulkActionModalComponent,
        OnboardingMessageComponent,
        OnboardingNavButtonComponent,
        AddProductModalComponent,
        UploadDocModalComponent,
        DocsTableComponent,
        DeviceModalComponent,
        ImportTrainingDataComponent,
        TrainingDataTableComponent,
        HelpNavComponent,
        UsersManagerComponent,
        DeviceManagerComponent,
        DilutionModalComponent,
        StepCounterComponent,
        DragDropDirective,
        SamplesTableComponent,
        AddProductLineModalComponent,
        ConfirmProfileChangesModalComponent,
        DeleteProductModalComponent,
        InvalidDateModalComponent,
        BugListModalComponent,
        CreateBugReportModalComponent,
        MultipleSelectComponent,
        DeviceDetailedModalComponent,
        SelectHierarchyOrganizationModalComponent,
        HierarchyModalComponent,
        HierarchyRenameModalComponent,
        CreateHierarchyModalComponent,
        ParentOrgComponent,
        ChildOrgComponent,
        HierarchyPermissionsModalComponent,
        CreateHierarchyModalParentComponent,
        CreateHierarchyModalChildComponent,
        PermissionsDropdownComponent,
        DotsLoaderComponent,
        EditDeleteDropdownComponent,
        DropdownUserOrganizationsComponent,
        DropdownProductsProductLinesComponent,
        DropdownComponent,
        FileUploadSamplesComponent,
        FileUploadSamplesComponent,
        TestKitsAdvancedComponent,
        TestKitsTableComponent,
        TestKitsMobileTableComponent,
        DevicesAdvancedComponent,
        DevicesTableComponent,
        DevicesMobileTableComponent,
        DocsMobileTableComponent,
        ButtonsComponent,
        DataSharingGroupsTableComponent,
        DataSharingGroupsModalComponent,
        DataSharingGroupsAdvancedModalComponent,
        DataSharingGroupsAdvancedModalOrganizationComponent,
        MultiSelectDropdownComponent,
        ScrollDotsLoaderComponent,
        CustomConfirmModalComponent,
        AllDataTableComponent,
        AddOrganizationModalComponent,
        DisableSamplesModalComponent,
        AllDataShareModalComponent,
        AllDataShareAproveModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        TooltipModule,
        BsDropdownModule,
        PopoverModule,
        BsDatepickerModule,
        ModalModule.forRoot(),
        RouterModule,
        TypeaheadModule,
        CollapseModule,
        TimeagoModule,
        AccordionModule,
        InfiniteScrollModule,
    ],
  exports: [
    ChartComponent,
    FileUploadComponent,
    ClipboardDirective,
    CopyButtonComponent,
    InputModalComponent,
    InlineInputComponent,
    UploadComponent,
    ConfirmModalComponent,
    SpinnerComponent,
    ProductLineManagerComponent,
    ProductRowComponent,
    NoDataComponent,
    NotificationNavComponent,
    MultiSelectComponent,
    UserNavDropdownComponent,
    FormControlComponent,
    OverlaySpinnerComponent,
    NavBarComponent,
    MenuComponent,
    ProfileImageComponent,
    AdminMenuComponent,
    FancyTableComponent,
    DeepValuePipe,
    PagingComponent,
    LoadingBarComponent,
    OnboardingMessageComponent,
    SearchControlComponent,
    DeviceModalComponent,
    OnboardingNavButtonComponent,
    DocsTableComponent,
    ImportTrainingDataComponent,
    TrainingDataTableComponent,
    HelpNavComponent,
    UsersManagerComponent,
    DeviceManagerComponent,
    DilutionModalComponent,
    AddProductModalComponent,
    DragDropDirective,
    SamplesTableComponent,
    MultipleSelectComponent,
    PermissionsDropdownComponent,
    DotsLoaderComponent,
    EditDeleteDropdownComponent,
    DropdownComponent,
    FileUploadSamplesComponent,
    TestKitsAdvancedComponent,
    TestKitsTableComponent,
    TestKitsMobileTableComponent,
    DevicesAdvancedComponent,
    DevicesTableComponent,
    DevicesMobileTableComponent,
    DevicesMobileTableComponent,
    DocsMobileTableComponent,
    ButtonsComponent,
    DataSharingGroupsTableComponent,
    AllDataTableComponent,
  ],
  entryComponents: [
    InputModalComponent,
    ConfirmModalComponent,
    DilutionModalComponent,
    TestKitModalComponent,
    EditUserGroupsModalComponent,
    UserDetailsModalComponent,
    BulkActionModalComponent,
    SelectOrgModalComponent,
    AddProductModalComponent,
    UploadDocModalComponent,
    DeviceModalComponent,
  ]
})
export class SharedModule { }
