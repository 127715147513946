<div class="table-wrapper">
  <table class="table table-hover" id="fancyTable">
    <thead>
    <tr>
      <ng-template #noSelectAll>
        <th></th>
      </ng-template>
      <th *ngIf="options.maxSelected >= items?.length; else noSelectAll" scope="col" (click)="toggleAll()"
          class="pointer min-width-cell">
        <div class="custom-control custom-checkbox">
          <input #allCheck [checked]="allSelected" type="checkbox" class="custom-control-input">
          <label class="custom-control-label"></label>
        </div>
      </th>
      <th *ngFor="let c of columns" scope="col">
        <div class="custom-control custom-checkbox selectable-col-check" *ngIf="c?.selectable; else notSelectable">
          <input [id]="'check-col-' + c.name" type="checkbox" [defaultChecked]="true" class="custom-control-input">
          <label class="custom-control-label"
                 [ngClass]="{'selected': c.checked}">{{ prefix + c.name | translate }}</label>
        </div>
        <ng-template #notSelectable>{{ prefix + c.name | translate }}</ng-template>
      </th>

      <th *ngIf="visibleButtons.length"></th>

    </tr>
    </thead>
    <tbody *ngIf="items?.length === 0">
    <tr class="empty-row">
      <td [attr.colspan]="columns.length + 2">
        {{ prefix + 'no-data' | translate }}
      </td>
    </tr>

    </tbody>
    <tbody *ngIf="items?.length > 0">

    <tr *ngFor="let i of items; let idx = index" class="pointer" (click)="toggle(i)">
      <td scope="row" [class.table-active]="i.selected">
        <div class="custom-control custom-checkbox">
          <img *ngIf="i.disabled" class="disabled-icon" src="/assets/icons/disabled.svg" alt="">
          <input [checked]="i.selected" type="checkbox" class="custom-control-input">
          <label class="custom-control-label"></label>
        </div>
      </td>
      <ng-container *ngFor="let c of columns">

        <!-- Different types of content -->
        <td *ngIf="c.type === 'function'"
            [ngClass]="{'table-active': c.selectable ? c.checked && i.selected : i.selected,
            'hidden': c.selectable ? !c.checked : false, 'no-display': c.selectable ? !c.checked && exporting : false}">
          {{ c.getValue(i)}}
        </td>

        <td *ngIf="c.type === 'text'" [ngClass]="{'table-active': c.selectable ? c.checked && i.selected : i.selected,
            'hidden': c.selectable ? !c.checked : false, 'no-display': c.selectable ? !c.checked && exporting : false}">
          {{ i | deepValue : c.property }}
        </td>

        <td *ngIf="c.type === 'date'" [ngClass]="{'table-active': c.selectable ? c.checked && i.selected : i.selected,
            'hidden': c.selectable ? !c.checked : false, 'no-display': c.selectable ? !c.checked && exporting : false}">
          {{ i | deepValue : c.property | date }}
        </td>

        <td *ngIf="c.type === 'time'" [ngClass]="{'table-active': c.selectable ? c.checked && i.selected : i.selected,
            'hidden': c.selectable ? !c.checked : false, 'no-display': c.selectable ? !c.checked && exporting : false}">
          {{ i | deepValue : c.property | date : 'HH:mm'}}
        </td>

        <td *ngIf="c.type === 'icon'" [ngClass]="{'table-active': c.selectable ? c.checked && i.selected : i.selected,
            'hidden': c.selectable ? !c.checked : false, 'no-display': c.selectable ? !c.checked && exporting : false}">
          <i class="fa" [ngClass]="i | deepValue : c.property"></i>
        </td>
      </ng-container>

      <td class="btn-cell no-wrap" *ngIf="visibleButtons.length">
        <button *ngFor="let b of visibleButtons" type="button" class="btn btn-sm btn-link mr-2"
                (click)="buttonClick(i, b)" [tooltip]="prefix + b.label | translate">
          <i class="fa fa-fw" [ngClass]="b.icon"></i>
        </button>

        <div class="btn-group" *ngIf="contextMenu.length > 0" dropdown #dropdown="bs-dropdown"
             (click)="$event.stopPropagation()">
          <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-link"
                  aria-controls="dropdown-basic">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-basic">
            <ng-container *ngFor="let b of contextMenu">
              <li *ngIf="(b.label !== 'disable' && b.label !== 'remove') || i.uuid !== user.uuid" role="menuitem">
                <div *ngIf="!b.active || b.active(i)">
                  <a class="dropdown-item" href="javascript:void(0)" (click)="buttonClick(i, b); dropdown.hide()">
                    <i class="fa fa-fw mr-2" [ngClass]="b.icon"></i>
                    {{ prefix + b.label | translate }}
                  </a>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </td>


    </tr>
    </tbody>
  </table>

</div>
