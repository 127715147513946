import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalService} from "../modal.service";
import {CustomConfirmModalComponent} from "../custom-confirm-modal/custom-confirm-modal.component";
import {AdminService} from "../../pages-authenticated/admin/admin.service";
import {Organization} from "../../../model/organization";
import {User} from "../../../model/user";
import {AddOrganizationModalComponent} from "../add-organization-modal/add-organization-modal.component";
import {SelectOption} from "../form-control/form-control.component";
import {state} from "@angular/animations";

@Component({
  selector: 'app-data-sharing-groups-advanced-modal',
  templateUrl: './data-sharing-groups-advanced-modal.component.html',
  styleUrls: ['./data-sharing-groups-advanced-modal.component.scss']
})
export class DataSharingGroupsAdvancedModalComponent implements OnInit {
  prefix = 'data-sharing-groups.advanced-modal.';
  form: FormGroup
  initialState
  name
  modalResult
  loading = false
  // organizationsSelect: any = []
  defaultOrganizations
  organizations: DataSharingOrganization[] = []
  isNew = true
  dataSharingGroupId
  deleted = []
  initData

  constructor(
    public modal: BsModalRef,
    private modals: ModalService,
    private as: AdminService
  ) {
  }

  async ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(this.name || '', [Validators.required, Validators.maxLength(150)]),
    });
    this.defaultOrganizations.forEach(o => {
      this.organizations.push(this.convertOrgData(o, this.isNew))
    })
    this.initData = JSON.stringify(this.organizations)

    // this.allOrganizations = await this.as.getAllOrganizations()

    try {
      // this.getSelectItems()
    } catch (e) {
      console.log("error", e)
    }

  }

  goBack() {
    this.modalResult = {
      action: 'back',
      name: this.name,
    }
    this.modal.hide()
  }

  async close() {
    if (this.initData !== JSON.stringify(this.organizations)) {
      const res = await this.modals.showConfirm()
      if (!res) return
      this.modal.hide()
    } else {
      this.modal.hide()
    }
  }

  async save() {
    this.organizations.forEach(org => {
      const selectedSharingUsers = org.sharingUsers.map(u => u.selected).filter(s => s)
      if (!selectedSharingUsers.length) return
      const selectedReceivingUsers = (org.receivingOrganizations.map(o => {
        return o.items.map(i => i.selected).filter(s => s)
      }) as any).flat(1)
      if (!selectedReceivingUsers.length) return
    })

    if (this.organizations.length < 2) return
    this.loading = true
    const organizations = this.organizations.map(org => {
      return {
        id: org.value,
        sharing: org.sharing,
        receiving: org.receiving,
        sharingUsers: org.sharingUsers.map(u => {
          return {
            organizationId: org.value,
            userId: u.id,
            sharing: u.selected
          }
        }),
        receivingUsers: (org.receivingOrganizations.map(o => {
          return o.items.map(u => {
            return {
              organizationId: org.value,
              userId: u.id,
              receiving: u.selected,
            }
          })
        }) as any).flat(2)
      }
    })
    const data = {
      id: this.dataSharingGroupId,
      name: this.form.value.name,
      organizations,
      deletedIds: this.deleted
    }
    if (this.isNew === true) {
      await this.as.createDataSharingGroup(data)
      this.loading = false
      this.modalResult = true
      this.modal.hide()
    } else {
      await this.as.updateDataSharingGroup(data)
      this.loading = false
      this.modalResult = true
      this.modal.hide()
    }

    console.log('SAVIIING', data)
  }

  async onDelete(organizationId: number) {
    const result = await this.modals.show(CustomConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        prefix: 'data-sharing-groups.advanced-modal.confirm-modal.',
        optionalText: this.organizations.filter(organization => organization.value === organizationId)[0].name
      }
    })

    if (!result) return

    this.organizations = this.organizations.filter(org => org.value !== organizationId)
    this.organizations.forEach(org => {
      org.receivingOrganizations = org.receivingOrganizations.filter(o => o.value !== organizationId)
    })
    this.deleted.push(organizationId)
    // this.organizationsSelect = this.organizationsSelect.filter(o => o.value !== organizationId)
  }

  getSelectItems(): void {
    // this.organizations.forEach((o: Organization) => {
    //   const users = o.users.length ?
    //     o.users.map((u: User) => {
    //       return {
    //         name: u.firstName + ' ' + u.lastName,
    //         value: u.uuid,
    //         subLabel: u.email,
    //       }
    //     }) : []
    //   Object.assign(this.usersSelect, {[o.id]: users})
    //
    // })
    //
    // this.organizationsSelect = this.organizations.map((o: Organization) => {
    //   return {
    //     name: o.name,
    //     value: o.id,
    //     items: o.users.map((u: User) => {
    //       return {
    //         name: u.firstName + ' ' + u.lastName,
    //         value: u.uuid,
    //       }
    //     })
    //   }
    // })
  }

  onOrgUsersSelectChange($event: any) {
    console.log('changed selection', {
      event: $event,
      orgs: this.organizations,

    })

    // this.organizations.forEach(org => {
    //   org.sharing = 'custom'
    // })
    // console.log("$event$event$e123123vent", $event)
    // const orgIdx = this.organizationsSelect.findIndex(o => o.value === $event.value)
    // const userIdx = this.organizationsSelect[orgIdx].items.findIndex(u => u.value === $event.item.value)
    // this.organizationsSelect[orgIdx].items[userIdx].selected = $event.item.selected
  }

  async addOrganization() {
    const orgs = await this.modals.show(AddOrganizationModalComponent, {
      ignoreBackdropClick: true,
      backdrop: 'static',
      initialState: {
        existingOrganizations: this.organizations.map(o => o.value)
      }
    })
    if (!orgs) return
    if (orgs.length) {
      this.defaultOrganizations = this.defaultOrganizations.concat(orgs)
      orgs.forEach((o: Organization) => {
        console.log("deleee before", {deletedIds: this.deleted, 'o.id': o.id})
        this.deleted = this.deleted.filter(id => id !== o.id)
        console.log("deleee after", this.deleted)
        const newOrganization = {
          name: o.name,
          value: o.id,
          items: []
        }

        if (o.users.length) {
          o.users.forEach((u: User) => {
            newOrganization.items.push({
              name: u.firstName + ' ' + u.lastName,
              value: u.uuid,
              id: u.id,
              selected: true
            })
          })
        }

        this.organizations.forEach(org => {
          org.receivingOrganizations.push(newOrganization)
        })
      })
    }
    orgs.forEach(o => {
      this.organizations.push(this.convertOrgData(o, true))
    })
    // const newOrganizations = this.allOrganizations.filter(o => ids.includes(o.id))
    // this.organizations = this.organizations.concat(newOrganizations)
    //
    //
    // this.organizationsSelect = this.organizationsSelect.concat(newOrganizations.map(o => {
    //   return {
    //     name: o.name,
    //     value: o.id,
    //     items: o.users.length ? o.users.map((u: User) => {
    //       return {
    //         name: u.firstName + ' ' + u.lastName,
    //         value: u.uuid,
    //       }
    //     }) : []
    //   }
    // }))

  }

  getOrganizationsSelect(orgId: number) {
    // return this.organizationsSelect.filter(o => o.value !== orgId)
  }

  onOrgUsersSelectsAll(items: any) {
    console.log('gagaga')
    // items.forEach(item => {
    //   const orgIdx = this.organizationsSelect.findIndex(o => o.value === item.value)
    //   if (item.items.length) {
    //     item.items.forEach(u => {
    //       const userIdx = this.organizationsSelect[orgIdx].items.findIndex(user => user.value === u.value)
    //       this.organizationsSelect[orgIdx].items[userIdx].selected = u.selected
    //     })
    //   }
    // })
  }

  convertOrgData(o, newOrg) {
    const org = {
      name: o.name,
      value: o.id,
      sharing: o.sharing,
      receiving: o.receiving,
      sharingUsers: [],
      receivingOrganizations: []
    }
    if (o.users.length) {
      o.users.forEach((u: User) => {
        console.log("qweqweu", u)
        org.sharingUsers.push({
          name: u.firstName + ' ' + u.lastName,
          value: u.uuid,
          subLabel: u.email,
          id: u.id,
          selected: newOrg ? true : u.sharing,
          sharingId: u.sharingId ? u.sharing : null
        })
      })
    }

    const anotherOrganizations = this.defaultOrganizations.filter((organization: Organization) => organization.id !== o.id)
    anotherOrganizations.forEach((ao: Organization) => {
      const anotherOrganization = {
        name: ao.name,
        value: ao.id,
        items: []
      }

      if (ao.users.length) {
        ao.users.forEach((u: User) => {
          anotherOrganization.items.push({
            name: u.firstName + ' ' + u.lastName,
            value: u.uuid,
            id: u.id,
            selected: newOrg ? true : o.receivingUsers.filter(user => user.receiving).map(user => user.id).includes(u.id)
          })
        })
      }
      org.receivingOrganizations.push(anotherOrganization)
    })

    return org
  }

  onUserSelect(data: { item: DataSharingUser, orgId: number }) {
    const organization = this.organizations.find(o => o.value === data.orgId)
    if (organization) {
      if (organization.sharingUsers.length) {
        let userState
        organization.sharingUsers.forEach(u => {
          if (userState === false) return
          userState = u.selected
        })
        if (userState === false) {
          organization.sharing = 'custom'
        } else if (userState === true) {
          organization.sharing = 'general'
        }
      }
    }
  }

  onOrgUserSelect(data: { item: SelectOption, orgId: number }) {
    const organization = this.organizations.find(o => o.value === data.orgId)
    if (organization.receivingOrganizations.length) {
      let userState
      organization.receivingOrganizations.forEach(org => {
        if (org.items.length) {
          org.items.forEach(u => {
            if (userState === false) return
            userState = u.selected
          })
          console.log("userState", userState)

        }
      })
      if (userState === false) {
        organization.receiving = 'custom'
      } else if (userState === true) {
        organization.receiving = 'general'
      }
      console.log(organization)
    }
    // if (organization) {
    //   if (organization.sharingUsers.length) {
    //     let userState
    //     organization.sharingUsers.forEach(u => {
    //       if (userState === false) return
    //       userState = u.selected
    //     })
    //     if (userState === false) {
    //       organization.sharing = 'custom'
    //     } else if (userState === true) {
    //       organization.sharing = 'general'
    //     }
    //   }
    // }
  }

  validateSaveBtn() {
    const sharing: any = []
    const receiving: any = []
    this.organizations.forEach(org => {
      sharing.push((org.sharingUsers.map(u => u.selected).filter(s => s) as any).flat(1))
      receiving.push((org.receivingOrganizations.map(o => {
        return o.items.map(i => i.selected).filter(s => s)
      }) as any).flat(1))
    })

    if (this.loading || this.organizations.length < 2 || sharing.some(s => s.length === 0) || receiving.some(r => r.length === 0)) {
      return true
    } else return false
  }
}

export interface DataSharingOrganization {
  name: string;
  value: number;
  sharing: 'general' | 'custom';
  receiving: 'general' | 'custom';
  sharingUsers: DataSharingUser[];
  receivingOrganizations: DataSharingReceivingOrganizations[]
}

export interface DataSharingUser {
  name: string;
  value: string;
  subLabel: string;
  selected: boolean;
  id: number;
}

export interface DataSharingReceivingOrganizations {
  name: string;
  value: number;
  items: SelectOption[]
}
