<div class="content">
  <div class="card bg-transparent border-0">
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="submit()">
<!--      <app-form-control-->
<!--        *ngIf="!this.form.get('sendByUser').value"-->
<!--        type="new-select"-->
<!--        [selectOptions]="deviceSelectOptions"-->
<!--        [prefix]="prefix + 'device'"-->
<!--        [control]="form.get('deviceId')"-->
<!--      ></app-form-control>-->

        <app-dropdown
          *ngIf="!this.form.get('sendByUser').value"
          [items]="deviceSelectOptions"
          (itemSelected)="form.get('deviceId').setValue($event.value)"
          [selectFirst]="false"
          prefix="device"
        ></app-dropdown>

      <app-file-upload-samples *ngIf="form.get('deviceId').value" onlyCsv="true" fileFormats=".csv" [disable]="false" #fileUpload [uploadImmediately]="false"
                       prefix="upload" (progress)="uploadProgress($event)" [deviceId]="getDeviceId()" [useHashes]="form.get('useHashes').value">
      </app-file-upload-samples>

      <app-form-control type="checkbox" *ngIf="user.canUploadInstead() && organization.status === 'parent'"
                        [control]="form.get('sendByUser')" [prefix]="'import.send-by-user'"></app-form-control>

      <app-dropdown-user-organizations
        *ngIf="organization.status === 'parent' && user.canUploadInstead() && form.get('sendByUser').value"
        (userSelected)="selectUser($event)"
        menuMaxHeight="calc(100vh / 4)"
        [organizations]="organizations">
      </app-dropdown-user-organizations>

      <app-dropdown-products-product-lines
        *ngIf="organization.status === 'parent' && user.canUploadInstead() && form.get('sendByUser').value && showSelectedUserDevices"
        (productSelected)="selectProduct($event)"
        menuMaxHeight="calc(100vh / 4)"
        [showLabel]="true"
        [productLines]="productLines">
      </app-dropdown-products-product-lines>

        <app-dropdown
          *ngIf="form.get('sendByUser').value && showSelectedUserDevices"
          [items]="deviceSelectOptions"
          (itemSelected)="form.get('deviceId').setValue($event.value)"
          [selectFirst]="false"
          prefix="device"
        ></app-dropdown>

<!--      <app-form-control-->
<!--        *ngIf="form.get('sendByUser').value && showSelectedUserDevices"-->
<!--        type="new-select"-->
<!--        [selectOptions]="deviceSelectOptions"-->
<!--        [prefix]="prefix + 'device'"-->
<!--        [control]="form.get('deviceId')"-->
<!--      ></app-form-control>-->

      <div class="upload-container" *ngIf="form.get('deviceId').value">
        <button *ngIf="!form.get('sendByUser').value" type="submit" class="btn btn-primary"
                [disabled]="validateUploadButton()">
          {{ 'upload.submit' | translate }}
        </button>
        <button *ngIf="form.get('sendByUser').value" type="submit" class="btn btn-primary"
                [disabled]="validateUploadButton()">
          {{ 'upload.submit' | translate }}
        </button>

        <div *ngIf="user.hasRole('tester')" class="mark-duplicates">
          <app-form-control [prefix]="'import.mark-duplicates'" [marginBottom]="0" type="checkbox"
                            [control]="form.get('useHashes')">
          </app-form-control>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>


<div class="block-loader" *ngIf="loading">
  <div class="loader-container">
    <app-dots-loader></app-dots-loader>
  </div>
</div>
