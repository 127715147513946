<div class="head">
  <div class="top">
    <p class="title">{{prefix + 'title' | translate }}</p>
    <button class="close-btn" (click)="dismiss()">
      <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.967773 0.75L8.90346 8.25M0.967773 8.25L8.90346 0.75L0.967773 8.25Z" stroke="#333333"
              stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>

  <p class="sub-title">{{prefix + 'sub-title-1' | translate }}</p>
  <p class="sub-title mb-25">{{prefix + 'sub-title-2' | translate }}</p>
</div>

<div class="body">
  <button class="add-product-line-btn" (click)="addProductLine()">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0822 7H1.2168M7.64949 1V13V1Z" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
    {{prefix + 'add-product-line-btn' | translate}}
  </button>

  <app-product-line-manager
    [canDeleteProductLines]="canDeleteProductLines"
    [canDeleteProducts]="canDeleteProducts"
    (stateChange)="setState($event)"
    *ngFor="let l of productLines"
    [productLine]="l">
  </app-product-line-manager>
</div>

<div class="footer">
  <button class="done-btn" (click)="dismiss()">{{prefix + 'done-btn' | translate}}</button>
</div>
