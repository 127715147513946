import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core'
import {SearchCriteria} from '../../../model/search-criteria'
import {ActivatedRoute} from '@angular/router'
import {Organization} from '../../../model/organization'
import {User} from "../../../model/user";

interface FilterItem {
  orgName: string
  users: FilteredUsers[]
}

interface FilteredUsers {
  name: string
  selected: boolean
  uuid: string
  orgId: number
  disabled: boolean
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() items: Organization[] = []
  @Input() icon = "fa-users"
  @Input() translateBase = 'multi-select'
  @Input() showClearItem = true
  @Input() type = 'default'
  @Input() disable = false
  @Input() organizationId = null
  @Input() page
  @Input() lastPage
  @Input() sharedOrganizations: Organization[] = []
  @Output() selectionChange = new EventEmitter<any>()
  @Output() onLoadMore = new EventEmitter<any>()
  @Output() sharedUserSelected = new EventEmitter<any>()

  organizations: Organization[] = []
  selectCount = 0
  selected = []
  sharedSelectCount = 0
  sharedSelected = []
  orgId: number = null
  selectedOrgId

  constructor() {
  }

  ngOnInit() {
    this.organizations = this.items
    this.filterChanges()
    // this.users.forEach(o => {
    //   o.users.forEach(u => {
    //     if (u.selected) {
    //       this.selected.push(u)
    //       this.filterByOrgId(u.orgId)
    //     }
    //   })
    // })
  }

  ngOnChanges() {
    this.organizations = this.items
    this.filterChanges()
  }

  filterChanges() {
    this.selected = []
    this.organizations.forEach(o => {
      if (o.users) {
        o.users.forEach(u => {
          if (u.selected) {
            this.selected.push(u)
            // this.filterByOrgId(o.id)
          }
        })
      }
    })
    this.selectCount = this.selected.length
  }

  toggle(item: any, orgId: number, event?: MouseEvent) {
    if (this.disable) return
    if (event) event.stopPropagation()
    if (this.sharedOrganizations.length) {
      this.sharedOrganizations.forEach(o => {
        o.users.forEach(user => {
          user.selected = false
        })
      })
      this.sharedSelected = []
      this.sharedSelectCount = 0
    }

    item.selected = !item.selected
    if (item.selected) {
      if (!this.organizationId) {
        console.log('org not exist')
        this.organizationId = orgId
        item.selected = true
        this.selected.push(item)
        this.selectCount++
      } else {
        console.log('org exist')
        console.log(this.organizationId, orgId)
        if (this.organizationId !== orgId) {
          console.log('selected org exist')
          this.clearSelection()
          item.selected = true
          this.organizationId = orgId
          this.selected.push(item)
          this.selectCount++
        } else {
          console.log('selected org not exist')
          this.selected.push(item)
          this.selectCount++
          // this.filterByOrgId(orgId)
        }
      }

    } else {
      const idx = this.selected.findIndex(i => i.uuid === item.uuid)
      this.selected.splice(idx, 1)
      this.selectCount--
      if (!this.selectCount) {
        this.organizations = this.items
      }
    }
    this.selectionChange.emit({orgs: this.selected, orgId: this.organizationId})
    // this.orgId = this.selected[0] ? this.selected[0].orgId : ''
  }

  // hidden() {
  //   const selected = []
  //   this.organizations.forEach(o => {
  //     o.users.forEach(u => {
  //       if (u.selected) selected.push(u)
  //     })
  //   })
  //   // this.users.forEach(o => {
  //   //     o.users.forEach(u => {
  //   //         if (u.selected) selected.push(u)
  //   //     })
  //   // })
  //   // this.selectionChange.emit(selected)
  // }

  filterByOrgId(id) {
    // this.organizations = this.organizations.filter(o => o.id === id)
    // this.users = this.users.filter(i => i.users.length && id === i.users[0].orgId)
  }

  clearSelection(e?) {
    //If nothing is selected, do nothing.
    if (e) e.stopPropagation()
    if (this.selectCount === 0) {
      return
    }
    this.organizations = this.organizations.map(o => {
      o.users.map(u => u.selected = false)
      return o
    })
    // for (const i of this.users) {
    //     for (const j of i.users) {
    //         j.selected = false
    //     }
    // }
    this.selectCount = 0
    this.selected = []
    this.organizations = this.items
  }

  getItemName(): string {
    if (this.sharedSelectCount > 0) {
      return this.sharedSelected[0].firstName + ' ' + this.sharedSelected[0].lastName
    } else {
      const item = []
      this.organizations.forEach(o => {
        const selectedUser = o.users.find(u => u.selected)
        if (selectedUser) {
          item.push(selectedUser)
        }
      })
      // this.users.forEach(o => {
      //     const selectedUser = o.users.find(u => u.selected)
      //     if (selectedUser) {
      //         item.push(selectedUser)
      //     }
      // })
      if (!item.length) return ""
      return item[0]['firstName'] + ' ' + item[0]['lastName']
    }
  }

  getLabel(): string {
    if (this.sharedSelectCount > 0) {
      if (this.sharedSelectCount > 0) return `${this.translateBase}.one`
    } else {
      if (this.selectCount === 0) return `${this.translateBase}.none`
      if (this.selectCount === 1) return `${this.translateBase}.one`
      if (this.selectCount === this.organizations.length) return `${this.translateBase}.all`

      return `${this.translateBase}.many`
    }
  }

  loadMore(event: MouseEvent) {
    if (event) event.stopPropagation()
    this.onLoadMore.emit(this.page + 1)
  }

  selectOne(u) {
    this.items.forEach(i => {
      i.users.forEach(j => {
        j.selected = false
      })
    })
    this.selectCount = 0
    this.selected = []
    this.sharedSelected = []
    this.sharedOrganizations.forEach(o => {
      o.users.forEach(user => {
        user.selected = false
      })
    })
    u.selected = true
    this.sharedSelectCount = 0
    this.sharedSelected.push(u)
    this.sharedSelectCount = 1
    this.sharedUserSelected.emit(u)
  }
}

export interface MultiSelectItem {
  selected: boolean;
}
