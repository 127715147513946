import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bulk-action-modal',
  templateUrl: './bulk-action-modal.component.html',
  styleUrls: ['./bulk-action-modal.component.scss']
})
export class BulkActionModalComponent implements OnInit {
  modalResult: { allSuccessful: boolean, results: any[] };
  action: Function;
  itemLabelProp: string;
  params: any[];
  prefix = "bulk-action-modal.";
  actionLabel: string;
  busy = false;
  done = false;

  constructor(
    public modal: BsModalRef,
  ) { }

  ngOnInit() {

  }

  getLabel(i: any) {
    const prop = i[this.itemLabelProp];

    //For some reason, calling prop() fucks with the this context.
    if (typeof prop === "function") return i[this.itemLabelProp]();

    if (typeof prop === "string") return prop;

    return 'unknown';
  }

  async run() {
    this.busy = true;
    this.modalResult = { allSuccessful: true, results: [] };

    for (let p of this.params) {
      try {
        p.state = 'busy';
        const result = await this.action(p);
        this.modalResult.results.push(result);
        p.state = 'done';
      } catch (err) {
        this.modalResult.results.push(err);
        this.modalResult.allSuccessful = false
        p.state = 'failed';
      }
    }
    this.busy = false;
    this.done = true;

    if (this.modalResult.allSuccessful) this.modal.hide();
  }

}
