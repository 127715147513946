import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import * as moment from 'moment'
import {TestKit, TestKitType} from '../../../model/testKit'
import {StockService} from '../../services/stock.service'
import {ToastService} from '../../services/toast.service'
import {StoreService} from '../../services/store.service'
import {Organization} from '../../../model/organization'
import {CacheService} from '../../services/cache.service'
import {environment} from '../../../environments/environment'
import {SelectOption} from '../form-control/form-control.component'
import {noWhitespaceValidator} from "../../helpers/validators";

@Component({
  selector: 'app-test-kits-advanced',
  templateUrl: './test-kits-advanced.component.html',
  styleUrls: ['./test-kits-advanced.component.scss']
})

export class TestKitsAdvancedComponent implements OnInit {

  @Input() organization: Organization
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() onCreated: EventEmitter<TestKit> = new EventEmitter<TestKit>()

  prefix = 'stock.advanced.'
  api = environment.api
  form: FormGroup
  measurementTypes: SelectOption[]
  testKitTypes: TestKitType[]
  selectedTestKit: TestKitType = null
  minDate = new Date()

  displayTestKits: any = [
    {
      src: 'assets/img/test-kit-chroma_cutout.png',
      selected: true,
      name: 'iCheck Test Kit'
    },
    {
      src: 'assets/img/vial-bg.webp',
      selected: false,
      name: 'vial'
    }
  ]

  constructor(
    private stockService: StockService,
    private toasts: ToastService,
    private store: StoreService,
    private cache: CacheService
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      organizationId: new FormControl(''),
      lot: new FormControl('', [Validators.required, noWhitespaceValidator]),
      expires: new FormControl(moment().add(1, 'year').format('YYYY-MM-DD'), [Validators.required]),
      unused: new FormControl(100, Validators.required),
      used: new FormControl(''),
      origin: new FormControl('BioAnalyt', [Validators.required]),
      quantity: new FormControl(100, [Validators.required, Validators.min(1)]),
      received: new FormControl(moment().format('YYYY-MM-DD'), [Validators.required]),
      status: new FormControl('active', [Validators.required]),
      active: new FormControl(true),
      type: new FormControl('chroma'),
      measurementTypeId: new FormControl(1),
      id: new FormControl(null),
    })

    this.measurementTypes = this.cache.measurementTypes.value.map(({id, name}): SelectOption => ({
      name,
      value: id
    }))

    this.testKitTypes = this.cache.testKitTypes.value
    this.displayTestKits = this.testKitTypes.filter(tk => tk.measurementTypeId === 1)
    this.selectedTestKit = this.testKitTypes[0];

    this.form.controls['measurementTypeId'].valueChanges.subscribe(value => {
      this.displayTestKits = this.testKitTypes.filter(dt => dt.measurementTypeId === value)
      this.selectedTestKit = this.displayTestKits[0]
    })
  }

  exitAdvanced() {
    this.onClose.emit(true)
  }

  selectTestKit(tk) {
    this.selectedTestKit = tk
  }

  expiredDateChange($event: Date) {
    const date = moment($event).format('YYYY-MM-DD')
    this.form.get('expires').setValue(date)
  }

  receivedDateChange($event: Date) {
    const date = moment($event).format('YYYY-MM-DD')
    this.form.get('received').setValue(date)
  }

  async save() {
    this.form.markAllAsTouched()
    if (this.form.invalid) return
    if (this.organization) this.form.get('organizationId').setValue(this.organization.id)
    const used = this.form.get('quantity').value - this.form.get('unused').value
    this.form.get('used').setValue(used)
    this.form.get('type').setValue(this.selectedTestKit.type)
    const tk = TestKit.fromForm(this.form.value)
    this.store.set('kit-set', true)
    const result = await this.stockService.addTestKit(tk)
    this.toasts.success('test-kit-modal.save-success')
    // this.testKitSelected.next(true)
    this.onCreated.emit(result)
    this.exitAdvanced()
  }
}
