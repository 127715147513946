import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit {
  @Input() property = 'name';
  @Input() items: MultiSelectItem[] = [];
  @Input() icon = "fa-users";
  @Input() translateBase = 'multi-select';
  @Input() showClearItem = true;

  @Output() selectionChange = new EventEmitter<any[]>();

  selectCount = 0;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    const selected = this.items.filter(i => i.selected);
    this.selectCount = selected.length;
  }

  toggle(item: any, event?: MouseEvent) {
    if (event) event.stopPropagation();

    item.selected = !item.selected;

    if (item.selected) {
      this.selectCount++;
    } else {
      this.selectCount--;
    }
  }

  hidden() {
    const selected = this.items.filter(i => i.selected);
    this.selectionChange.emit(selected);
  }

  clearSelection(e) {
    //If nothing is selected, do nothing.
    if (this.selectCount === 0) {
      e.stopPropagation();
      return;
    }

    for (let i of this.items) {
      i.selected = false;
    }
    this.selectCount = 0;
  }

  getItemName(): string {
    const item = this.items.find(i => i.selected);
    if (!item) return "";

    return item[this.property];
  }

  getLabel(): string {
    if (this.selectCount === 0) return `${this.translateBase}.none`;
    if (this.selectCount === 1) return `${this.translateBase}.one`;
    if (this.selectCount === this.items.length) return `${this.translateBase}.all`;

    return `${this.translateBase}.many`;
  }

}

export interface MultiSelectItem {
  selected: boolean;
}
