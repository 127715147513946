<div class="outer-container d-flex flex-column h-100">
  <app-nav-bar></app-nav-bar>

  <div class="layout flex-grow-1 d-flex">
    <app-menu>

      <div class="brand-container">
        <a routerLink="/dashboard" class="navbar-brand d-flex">
          <img class="logo-img" src="../../../assets/icons/logo-white.svg" alt="logo" title="logo"/>
        </a>
      </div>

      <ng-container *ngFor="let section of navLinks">
        <p class="section">{{section.title | translate }}</p>
        <ng-container *ngFor="let link of section.links">
          <ng-container *ngIf="link.subLinks == null">
            <a class="nav-link" routerLinkActive="active" [routerLink]="[link.route]"
               [ngStyle]="{'opacity': link?.disabled ? '0.65' : '1.0'}">
              <img src="assets/icons/{{link.icon}}" alt="{{link.route}}"/>
              <p>{{ link.translate | translate }}</p>
            </a>
          </ng-container>

          <ng-container *ngIf="link.subLinks != null">
            <app-foldout>
              <a class="nav-link" appFoldoutToggle [routerLink]="[link.route]" [routerLinkActive]="['active',
              'parent']">
                <img class="disable-mouse" src="assets/icons/{{link.icon}}" alt="{{link.route}}"/>
                <p class="disable-mouse">{{ link.translate | translate }}</p>
              </a>
              <!-- todo jordan this was wrong, but now the menu doesn't fold in anymore -->
              <div class="sub-menu" appFoldoutContent>
                <a *ngFor="let sub of link.subLinks" class="nav-link" [routerLinkActive]="['active', 'sub-item']"
                   [routerLink]="[sub.route]">
                  <i class="fa {{sub.icon}} fa-fw"></i>
                  {{ sub.translate | translate }}
                </a>
              </div>
            </app-foldout>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-menu>


    <div class="content flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
