import {Component, ElementRef, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-custom-confirm-modal',
  templateUrl: './custom-confirm-modal.component.html',
  styleUrls: ['./custom-confirm-modal.component.scss']
})
export class CustomConfirmModalComponent implements OnInit {
  @ViewChild("input") input: ElementRef;

  private response: Promise<any>;
  private resolve: Function;
  private reject: Function;

  title = 'confirm-modal.title';
  content = null;
  placeholder = 'confirm-modal.placeholder';
  okButton = 'confirm-modal.ok-button';
  cancelButton = 'confirm-modal.cancel-button';
  withComment = false;
  text = "";
  params: any = {};
  prefix
  optionalText

  private resultOk = false;
  modalResult: Boolean | { confirmed: boolean, text: string } = false;

  constructor(
    private modal: BsModalRef,
    private modals: BsModalService,
    private sanitize: DomSanitizer,
  ) { }

  ngOnInit() {
    this.response = new Promise<any>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    this.modals.onHide.subscribe(() => {
      this.resolve({ confirmed: this.resultOk, text: this.text });
    });

    if (this.content) this.content = this.sanitize.sanitize(SecurityContext.HTML, this.content);

    setTimeout(() => {
      if (this.withComment && this.input) this.input.nativeElement.focus();
    });
  }

  getResponse(): Promise<{ confirmed: boolean, text: string }> {
    return this.response;
  }

  close(confirmed = false) {
    this.resultOk = confirmed;
    if (this.withComment) {
      this.modalResult = {
        confirmed: this.resultOk,
        text: this.text
      };
    } else {
      this.modalResult = confirmed;
    }
    this.modal.hide();
  }

}
