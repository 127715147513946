import {Injectable} from '@angular/core'

import {ApiService} from './api.service'
import {BackoffInterval} from '../../model/backoff-interval'
import {CacheService} from './cache.service'
import {Notification} from '../../model/notification'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private started = false

  private interval = new BackoffInterval({keepGoing: true, baseInterval: 10000, onStart: false})

  constructor(
    private api: ApiService,
    private cache: CacheService,
  ) {
  }

  async start() {
    if (this.started) return

    this.started = true
    await this.getNotifications()

    this.interval.set(async () => {
      await this.getNotifications()
    })
  }

  async getNotifications() {
    let not = await this.api.get('u/notifications')
    not = not.notifications
    //Dealing with response is handled by the apiService.handleCommonResponse()
    this.cache.notifications.next(not)
  }

  async getNotificationTypes() {
    return await this.api.get('u/notification-types')
  }

  async deleteNotification(id) {
    const res = await this.api.delete(`u/notification?id=${id}`)
    if (res.status === 'ok') {
      const nots = this.cache.notifications.value.filter(n => n.id !== id)
      this.cache.notifications.next(nots)
    }
  }

  async toastShown(id) {
    await this.api.put(`u/notification-old`, {id})
  }

  stop() {
    this.interval.stop()
  }

  async markAsViewed(ids) {
    return await this.api.put(`u/mark-as-viewed`, {ids})
  }
}
