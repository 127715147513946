import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-dots-loader',
  templateUrl: './scroll-dots-loader.component.html',
  styleUrls: ['./scroll-dots-loader.component.scss']
})
export class ScrollDotsLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
