import {Component, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {User} from '../../../model/user'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {Permissions} from '../../../model/Permissions'

@Component({
  selector: 'app-edit-user-groups-modal',
  templateUrl: './edit-user-groups-modal.component.html',
  styleUrls: ['./edit-user-groups-modal.component.scss']
})
export class EditUserGroupsModalComponent implements OnInit {
  roles = {
    userRoles: [
      {
        value: 'user', permissions: [
          {label: 'canCreateTestKits', selected: false},
          {label: 'canCreateProductLines', selected: false},
          {label: 'canCreateProducts', selected: false},
          {label: 'canCreateDevices', selected: false},
        ]
      },
      {
        value: 'manager', permissions: [
          {label: 'canCreateTestKits', selected: false},
          {label: 'canDeleteTestKits', selected: false},
          {label: 'canCreateProductLines', selected: false},
          {label: 'canDeleteProductLines', selected: false},
          {label: 'canCreateProducts', selected: false},
          {label: 'canDeleteProducts', selected: false},
          {label: 'canCreateDevices', selected: false},
          {label: 'canDeleteDevices', selected: false},
          {label: 'canManageUsers', selected: false},
          {label: 'canViewOrganizationResults', selected: false}
        ]
      },
      {
        value: 'admin', permissions: [
          {label: 'canCreateTestKits', selected: false},
          {label: 'canDeleteTestKits', selected: false},
          {label: 'canCreateProductLines', selected: false},
          {label: 'canDeleteProductLines', selected: false},
          {label: 'canCreateProducts', selected: false},
          {label: 'canDeleteProducts', selected: false},
          {label: 'canCreateDevices', selected: false},
          {label: 'canDeleteDevices', selected: false},
          {label: 'canManageUsers', selected: false},
          {label: 'canViewAllResults', selected: false}
        ]
      }],
    globalRoles: [
      {
        value: 'support',
        selected: false,
      },
      {
        value: 'tester',
        selected: false,
      },
      {
        value: 'superAdmin',
        selected: false,
      }
    ]
  }

  role = 'user'

  prefix = "admin.users.user-groups-modal."
  user: User
  permissions: Permissions
  form: FormGroup
  modalResult


  constructor(
    public modal: BsModalRef,
    private as: AdminService,
  ) {
  }

  ngOnInit() {
    this.permissions = this.user.permissions
    this.roles.userRoles.forEach(r => {
      r.permissions.forEach(p => {
        if (this.permissions[p.label]) p.selected = true
      })
    })

    this.getClientRole()
    console.log('this.role', this.role)
    this.roles.globalRoles.forEach(r => {
      if (this.permissions[r.value]) {
        r.selected = true
      }
    })

    this.form = new FormGroup({
      role: new FormControl(this.role, Validators.required),
      support: new FormControl(this.user.hasRole('support'), Validators.required),
      tester: new FormControl(this.user.hasRole('tester'), Validators.required),
      superAdmin: new FormControl(this.user.hasRole('superAdmin'), Validators.required)
    })
  }

  getClientRole() {
    this.role = this.user.memberships.filter(m => m.role !== 'superAdmin'&& m.role !== 'tester' && m.role !== 'support')[0].role
  }

  async submit() {
    try {

      const roles = {
        support: this.form.get('support').value,
        tester: this.form.get('tester').value,
        superAdmin: this.form.get('superAdmin').value,
        [this.form.get('role').value]: true
      }
      const res = await this.as.setPermissions(this.user, this.permissions, roles)
      this.modalResult = res
      this.modal.hide()
    } catch (e) {
      console.log(e)
    }
  }

  clearPermissions() {
    const userRolePermissions = this.roles.userRoles.find(r => r.value === this.form.get('role').value).permissions.map(p => p.label)
    Object.keys(this.permissions).forEach(p => {
      if (p === 'created' || p === 'id') return
      this.permissions[p] = userRolePermissions.includes(p)
      if (this.form.get("role").value === "manager") {
        this.permissions.canDeleteProductLines = false
        this.permissions.canDeleteTestKits = false
        this.permissions.canDeleteProducts = false
        this.permissions.canDeleteDevices = false
      }
    })
  }

  onRoleChanged(e: Event, value: string) {
    console.log(value, 'valueeee')
    this.clearPermissions()
    this.roles.userRoles.find(r => r.value === value).permissions.forEach(p => {
      if (value === "manager") {
        if (p.label === "canDeleteProductLines" || p.label === "canDeleteTestKits" || p.label === "canDeleteProducts" || p.label === "canDeleteDevices") {
          p.selected = false
        } else {
          p.selected = true
        }
      } else {
        p.selected = true
        this.permissions[p.label] = true
      }
    })
  }

  onPermissionChanged(e, role, permission) {
    const idx = this.roles.userRoles.findIndex(r => r.value === role)
    const permissionIdx = this.roles.userRoles[idx].permissions.findIndex(p => p.label === permission)
    this.roles.userRoles[idx].permissions[permissionIdx].selected = e.target.checked
    this.permissions[permission] = e.target.checked
  }
}
