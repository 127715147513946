import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ControlValue } from '../../../../model/control-value';
import { Product, ProductLine } from '../../../../model/Products';
import { Subscriptions } from '../../../../model/subscriptions';
import { CacheService } from '../../../services/cache.service';
import { DataService } from '../../../services/data.service';
import { ToastService } from '../../../services/toast.service';
import { AddProductModalComponent } from '../../../shared/add-product-modal/add-product-modal.component';
import { ModalService } from '../../../shared/modal.service';
import { ProductModalComponent } from '../../../shared/product-modal/product-modal.component';
import { DilutionModalComponent } from '../../../shared/dilution-modal/dilution-modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  productLines: ProductLine[] = [];
  form: FormGroup;
  busy = false;
  isTrainingProduct = false;
  get productName() { return this.form.value.product ? this.form.value.product.name : 'nothing' }

  private _subs = new Subscriptions();

  constructor(
    private toasts: ToastService,
    private cache: CacheService,
    private data: DataService,
    private modals: ModalService,
  ) {
    this.form = new FormGroup({
      id: new FormControl(null),
      product: new FormControl(null),
      productId: new FormControl('', [Validators.required]),
      label: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      unit: new FormControl('mg RE/kg', [Validators.required]),
      target: new FormControl('', [Validators.required]),
      minLimit: new FormControl('', [Validators.required]),
      minWarn: new FormControl('', [Validators.required]),
      maxWarn: new FormControl('', [Validators.required]),
      maxLimit: new FormControl('', [Validators.required]),
      nationalStandard: new FormControl(null),
      diluteRatio: new FormControl(null),
      fortified: new FormControl(null),
    });

  }

  ngOnInit() {
    this._subs.add(this.cache.productLines.subscribe((pl) => { this.processProductLines(pl); }))
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll()
  }

  async openDiluteModal(shouldSave = true) {
    const product: Product = this.form.get('product').value;
    const result = await this.modals.show(DilutionModalComponent, { class: 'modal-lg', initialState: { product, shouldSave } });
    if (result) this.form.patchValue(result);
    return result;
  }

  getErrors(formControlName: string) {
    return this.form.get(formControlName).errors;
  }

  processProductLines(pl: ProductLine[]) {
    // if (!pl[0].products[0]) {
    //   console.log("No products yet");
    // } else if (!pl[0].products[0].controlValues || pl[0].products[0].controlValues.length === 0) {
    //   console.log("No Control Values");
    // }

    //Add empty control values if they don't exist.
    this.productLines = pl;
    let productCount = 0;
    for (let l of pl) {
      for (let p of l.products) {
        productCount++;
        if (!p.controlValues || p.controlValues.length === 0) {
          p.controlValues = [
            {
              label: "",
              productId: p.id,
              target: 8,
              minWarn: 6,
              minLimit: 5,
              maxWarn: 10,
              maxLimit: 11
            }
          ]
        }

        //
        if (!this.form.value.product) {
          this.form.get('product').setValue(p);
          this.selectProduct();
        }
      }
    }

    //Show add modal if no product exists yet.
    if (productCount === 0) {
      this.modals.show(AddProductModalComponent, { initialState: { productLine: this.productLines[0], isFirst: true } });
    }

  }

  selectProduct() {
    const product: Product = this.form.get('product').value;
    this.isTrainingProduct = this.productLines.find(l => l.id === product.productLineId).training;

    if (!product) return;
    this.form.get('productId').setValue(product.id);
    this.form.markAsUntouched();

    //Set control values
    if (product.controlValues[0]) {
      for (let k in product.controlValues[0]) {
        const v: ControlValue = product.controlValues[0];
        if (!v.hasOwnProperty(k)) continue;

        this.trySetFormControlValue(k, v[k]);
      }
    }
  }

  private trySetFormControlValue(controlName: string, value: any) {
    if (this.form.value[controlName] === undefined) return;
    this.form.get(controlName).setValue(value);
  }

  async addProduct() {
    const product: Product = await this.modals.show(ProductModalComponent);
    if (product) {
      this.form.get('product').setValue(product);
      this.selectProduct();
    }
  }

  async save() {
    this.form.markAllAsTouched();

    let v = { ...this.form.value };

    if (v.minWarn < v.minLimit || v.minLimit > v.target) {
      this.form.get('minLimit').setErrors({ tooHigh: true });
    } else {
      this.form.get('minLimit').setErrors(null);
    }

    if (v.maxWarn > v.maxLimit || v.maxLimit < v.target) {
      this.form.get('maxLimit').setErrors({ tooLow: true });
    } else {
      this.form.get('maxLimit').setErrors(null);
    }

    if (this.form.invalid) return;

    //Check if we need to dilute
    if (v.target > 30 && !v.diluteRatio) {
      await this.openDiluteModal(false);
      v = { ...this.form.value };
      if (!v.diluteRatio || !v.fortified) {
        this.toasts.warning("settings.missing-dilute-values");
        return;
      }
    }

    this.busy = true;
    delete v.product;

    try {
      await this.data.saveSettings(v);
      this.toasts.success("settings.saved");
      this.busy = false;
      this.form.get('id').setValue(v.id);

      //Update cache
      this.cache.updateControlValue(v);
    } catch (err) {
      this.toasts.warning("settings.not-saved");
      this.busy = false;
    }


  }

}
