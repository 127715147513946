import {Component, OnInit, ViewChild} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {FormGroup, FormControl, Validators} from '@angular/forms'
import * as moment from 'moment'
import {FormControlComponent, SelectOption} from '../form-control/form-control.component'
import {TestKit} from '../../../model/testKit'
import {StockService} from '../../services/stock.service'
import {ToastService} from '../../services/toast.service'
import {Organization} from '../../../model/organization'
import {DataService} from '../../services/data.service'

@Component({
  selector: 'app-test-kit-modal',
  templateUrl: './test-kit-modal.component.html',
  styleUrls: ['./test-kit-modal.component.scss']
})
export class TestKitModalComponent implements OnInit {
  mode = 'add'
  modalResult = null
  form: FormGroup
  selectOptions: SelectOption[]
  @ViewChild('lot', {static: true}) lotControl: FormControlComponent
  busy = false
  testKit: TestKit = null
  showConfirmDelete = false
  organization?: Organization
  canDelete
  types

  constructor(
    private modal: BsModalRef,
    private stockService: StockService,
    private toasts: ToastService,
    private dataService: DataService
  ) {
    this.form = new FormGroup({
      id: new FormControl(null),
      organizationId: new FormControl(null),
      type: new FormControl('chroma', [Validators.required]),
      lot: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      origin: new FormControl('BioAnalyt', [Validators.required]),
      quantity: new FormControl('100', [Validators.required, Validators.min(1)]),
      used: new FormControl(null, Validators.min(0)),
      received: new FormControl(new Date(), [Validators.required]),
      expires: new FormControl(moment().add(1, 'year').toDate(), [Validators.required]),
      status: new FormControl('active', [Validators.required]),
      active: new FormControl(true),
      notificationShown: new FormControl(null),
      measurementTypeId: new FormControl(null)
    })
  }

  ngOnInit() {
    this.busy = true
    this.dataService.getTestKitTypes().then(testKitTypes => {
      this.types = testKitTypes
      this.selectOptions = testKitTypes.map(tk => {
        return {name: tk.name, value: tk.type}
      })
      this.busy = false
    })
    if (this.testKit) {
      console.log('Test kit', this.testKit)
      this.mode = "update"
      this.form.setValue(this.testKit.toForm())

    } else if (this.lotControl) {
      this.lotControl.focus()
    }
  }

  hide(result: any) {
    this.modalResult = result
    this.modal.hide()
  }

  async delete() {
    this.busy = true
    try {
      const tk = TestKit.fromForm(this.form.value)
      await this.stockService.deleteTestKit(this.form.value.id)
      tk.active = false
      this.hide(tk)
      this.toasts.success("test-kit-modal.remove-success")
    } catch (err) {
      this.toasts.warning("test-kit-modal.save-error")
    }

    this.busy = false
  }

  async save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }

    //Check if used is more than quantity
    const quantity = this.form.value.quantity
    const used = this.form.value.used
    if (used > quantity) {
      this.form.get('used').setErrors({"too-high": true})
      return
    }

    //Set orgId
    if (this.organization) this.form.get('organizationId').setValue(this.organization.id)
    const measurementTypeId = this.types.filter(t => t.type === this.form.get('type').value)[0].id
    this.form.get('measurementTypeId').setValue(measurementTypeId)
    this.busy = true

    try {
      const tk = TestKit.fromForm(this.form.value)
      if (this.mode === 'add') {
        await this.stockService.addTestKit(tk)
      } else {
        await this.stockService.updateTestKit(tk)
      }
      this.hide(tk)
      this.toasts.success("test-kit-modal.save-success")
      this.busy = false
    } catch (err) {
      //This isn't great, but it is what it is...
      this.toasts.warning("test-kit-modal.save-error")
      this.busy = false
    }

  }

}
