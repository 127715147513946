import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, RoleGaurd } from './auth-guard';
import { LayoutTestComponent } from './shared/layout-test/layout-test.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ToastsComponent } from './shared/toasts/toasts.component';
import { ApiService } from './services/api.service';
import { StoreService } from './services/store.service';
import { UserService } from './services/user.service';
import { SharedModule } from './shared/shared.module';
import { TrainingService } from './services/training.service';
import { AuthModule } from 'src/app/auth/auth.module';
import { LandingComponent } from 'src/app/landing/landing.component';
import { AuthInterceptor } from 'src/app/services/auth.interceptor';
import {UserModule} from './pages-authenticated/user/user.module'
import {TimeagoModule} from 'ngx-timeago'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {InfiniteScrollModule} from "ngx-infinite-scroll";

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LayoutTestComponent,
    LandingComponent,
    ToastsComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),

    ModalModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    SharedModule,
    AuthModule,
    TypeaheadModule.forRoot(),
    UserModule,
    TimeagoModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [
    ApiService,
    UserService,
    StoreService,
    TrainingService,
    AuthGuard,
    RoleGaurd,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: "en" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
