<ng-template #loader>
  <div class="container text-center p-5">
    <app-spinner></app-spinner>
  </div>
</ng-template>

<div class="container py-3">
  <div class="row">
    <!-- Main Col -->
    <div class="col">
      <div class="card border-0">
        <div class="card-body p-0">
          <app-fancy-table #table [items]="samples" [prefix]="prefix + 'table.'" [options]="tableOptions">
          </app-fancy-table>
        </div>
      </div>
    </div>

    <!-- Right Column -->
    <div class="col-3">
      <div class="sticky-top">

        <!-- No selection -->
        <ng-template #explainCard>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ prefix + 'explain-title' | translate }}</h5>
              <p class="card-text" [innerHtml]="prefix + 'explain-content' | translate"></p>
            </div>
          </div>
        </ng-template>

        <!-- Some selected -->
        <div class="card" *ngIf="table.selectCount > 0; else explainCard">
          <div class="card-body">
            <h5 class="card-title">{{ prefix + 'sample-count' | translate : { count: table.selectCount } }}</h5>
            <p class="card-text" [innerHtml]="prefix + 'classify' | translate "></p>


            <div class="btn-group mr-2 w-100" dropdown>
              <button id="button-dropdown" dropdownToggle type="button"
                class="btn btn-light btn-block dropdown-toggle btn-product" aria-controls="dropdown-basic">
                <span *ngIf="product; else noProductSelected">
                  <span class="fa fa-leaf fa-fw"></span>
                  {{ product.name }} <span class="caret"></span>
                </span>
                <ng-template #noProductSelected>...</ng-template>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
<!--                <li *ngFor="let p of trainingLine.products" role="menuitem"><a class="dropdown-item"-->
<!--                    [class.active]="p.id === product?.id" (click)="product = p"-->
<!--                    href="javascript:void(0)">{{ p.name }}</a></li>-->
                <ng-container *ngFor="let l of productLines">
                  <li class="disabled" role="menuitem">
                    <a class="dropdown-item disabled" href="javascript:void(0)">
                      {{ l.name }}
                    </a>
                  </li>
                  <li *ngFor="let p of l.products" role="menuitem">
                    <a class="dropdown-item" (click)="product = p" [class.active]="p.id === product?.id">
                      {{ p.name }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>

            <button type="button" (click)="openBatchNameModal()"
                    class="btn btn-light btn-block btn-batch mt-2">
                <span *ngIf="product; else noProductSelected">
                  <span class="fa fa-leaf fa-fw"></span> {{'import.batch-button' | translate }}
                </span>
              <ng-template #noProductSelected>...</ng-template>
            </button>

            <hr>
            <button *ngIf="table.selectCount > 2; else selectMoreButton" class="btn btn-block process-btn"
                    (click)="process()">
              <i class="fa fa-spinner mr-2"></i>{{ prefix + 'process' | translate : { count: table.selectCount } }}</button>

            <ng-template #selectMoreButton>
              <button class="btn btn-block btn-secondary" disabled>{{ prefix + 'select-more' | translate }}</button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
