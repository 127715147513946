import {Component, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {Moment} from 'moment'
import {TicketService} from '../../services/ticket.service'
import {User} from '../../../model/user'
import {Document} from '../../../model/document'
import {DocsService} from '../../services/docs.service'
import * as moment from 'moment'

interface Bug {
  id?: number
  closed: boolean
  user: User
  created: Moment
  title: string
  description: string
  documents: Document[]
}

@Component({
  selector: 'app-bug-list-modal',
  templateUrl: './bug-list-modal.component.html',
  styleUrls: ['./bug-list-modal.component.scss']
})

export class BugListModalComponent implements OnInit {

  prefix = 'report-bug.'

  selected

  tabs = [
    'open',
    'closed'
  ]

  openBugs: Bug[] = []
  closedBugs: Bug[] = []
  showTab = 'open'

  constructor(
    public modal: BsModalRef,
    private ts: TicketService,
    private ds: DocsService,
  ) {
  }

  async ngOnInit() {
    const bugs = await this.ts.getBugs()
    bugs.forEach(b => {
      b.created = moment.unix(b.created).format('DD-MM-YYYY')
      b.closed ? this.closedBugs.push(b) : this.openBugs.push(b)
    })
  }

  tabClicked(tab) {
    this.showTab = tab
    this.selected = null
  }

  select(bug) {
    this.selected = bug
  }

  download(d: Document) {
    this.ds.download(d);
  }

  async closeBug(b) {
    await this.ts.closeBug(b.id)
    b.closed = true
    this.closedBugs.push(b)
    const idx = this.openBugs.findIndex(bb => bb.id === b.id)
    this.openBugs.splice(idx, 1)
  }
}
