import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Product, ProductLine } from '../../../../model/Products';
import { CacheService } from '../../../services/cache.service';

@Component({
  selector: 'app-add-training-data-modal',
  templateUrl: './add-training-data-modal.component.html',
  styleUrls: ['./add-training-data-modal.component.scss']
})
export class AddTrainingDataModalComponent implements OnInit {
  prefix = "training.add-modal.";
  productId: number;

  get product() {
    return this.trainingLine.products.find(p => p.id === this.productId);
  }

  trainingLine: ProductLine;

  constructor(
    public modal: BsModalRef,
    cache: CacheService,
  ) {
    this.trainingLine = cache.productLines.value.find(l => l.training);
  }

  ngOnInit() {
    if (!this.productId) {
      this.productId = this.trainingLine.products[0].id;
    }
  }

}
