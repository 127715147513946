import { Component, OnInit } from '@angular/core';
import {Product} from '../../../model/Products'
import {BsModalRef} from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-delete-product-modal',
  templateUrl: './delete-product-modal.component.html',
  styleUrls: ['./delete-product-modal.component.scss']
})
export class DeleteProductModalComponent implements OnInit {

  prefix = 'delete-product-modal.'
  product: Product
  modalResult
  constructor(private modal: BsModalRef) { }

  ngOnInit(): void {
  }

  close(confirm) {
    this.modalResult = confirm
    this.modal.hide();
  }
}
