<nav class="custom-navbar">
<!--  <a (click)="toggleMenu()" href="javascript:void(0)" class="nav-link d-md-none">-->
<!--    <div class="navbar-toggler-icon">-->
<!--      <svg width="30" height="30" viewBox="0 0 30 30" fill="none"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <rect width="30" height="4" rx="2" fill="#008C96"/>-->
<!--        <rect y="26" width="30" height="4" rx="2" fill="#008C96"/>-->
<!--        <rect y="13" width="30" height="4" rx="2" fill="#008C96"/>-->
<!--      </svg>-->
<!--    </div>-->
<!--  </a>-->
  <div class="hamburger" [class.open]="sideState" (click)="toggleMenu()">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>

    <ul class="items ml-auto">
      <li class="item mr-2" *ngIf="showAdminButton">
        <button [routerLink]="['/admin']" class="btn btn-outline-dark my-1 h-75 admin-desktop"
                type="button">{{ 'navbar.admin-button' | translate }}</button>

        <button type="button" class="admin-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M18 12h-2.18c-.17.7-.44 1.35-.81 1.93l1.54 1.54l-2.1 2.1l-1.54-1.54c-.58.36-1.23.63-1.91.79V19H8v-2.18c-.68-.16-1.33-.43-1.91-.79l-1.54 1.54l-2.12-2.12l1.54-1.54c-.36-.58-.63-1.23-.79-1.91H1V9.03h2.17c.16-.7.44-1.35.8-1.94L2.43 5.55l2.1-2.1l1.54 1.54c.58-.37 1.24-.64 1.93-.81V2h3v2.18c.68.16 1.33.43 1.91.79l1.54-1.54l2.12 2.12l-1.54 1.54c.36.59.64 1.24.8 1.94H18V12zm-8.5 1.5c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3s1.34 3 3 3z" fill="#68686d"/></svg>
        </button>
      </li>

      <li class="item mr-2" *ngIf="showSupportButton">-->
        <button [routerLink]="['/support']" class="btn btn-outline-light my-2 my-sm-0"
                type="button">{{ 'navbar.support-button' | translate }}</button>
      </li>
      <li class="item">
        <app-notification-nav></app-notification-nav>
      </li>
      <li class="item mr-2">
        <button class="position-relative bug-btn nav-btn btn btn-link text-light my-2 my-sm-0 py-0" type="button"
                (click)="openBugModal()">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
               focusable="false" width="1em" height="1em"
               style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
               preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <g fill="none">
              <path d="M5 9a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v5a7 7 0 0 1-7 7v0a7 7 0 0 1-7-7V9z" stroke="#626262"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M8 6v-.425c0-.981.384-1.96 1.326-2.238c1.525-.45 3.823-.45 5.348 0C15.616 3.615 16 4.594 16 5.575V6"
                stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.5 7.5L22 4" stroke="#626262" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M5.5 7.5L2 4" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 18l-4 3" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12H1.5" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.5 12H19" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 18l4 3" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 13v8" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </button>
      </li>
<!--      <li *ngIf="os.hasTips" class="item">-->
<!--        <app-onboarding-nav-button></app-onboarding-nav-button>-->
<!--      </li>-->
      <li class="item mr-2">
        <app-help-nav></app-help-nav>
      </li>


      <li class="item position-relative" dropdown placement="top right">
        <a class="navbar-brand dropdown-toggle profile-dropdown" href="javascript:void(0)" dropdownToggle
           id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <span class="username">{{ user.getName() }} <span *ngIf="user.organization">- {{ user.organization.name }}</span></span>
          <img class="profile-image rounded-image" [src]="userpic ? userpic : 'assets/img/profile.png'">
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" *dropdownMenu>
          <app-user-nav-dropdown></app-user-nav-dropdown>
        </div>
      </li>
    </ul>
</nav>
