import { Directive, HostListener, ElementRef } from '@angular/core';

import { FoldoutContentDirective } from './foldout-content.directive';

@Directive({
  selector: '[appFoldoutToggle]'
})
export class FoldoutToggleDirective {
  content: FoldoutContentDirective;

  constructor(public el: ElementRef) { }
}
