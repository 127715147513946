import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {Device} from '../../../model/device'
import {DeviceDetailedModalComponent} from '../device-detailed-modal/device-detailed-modal.component'
import {ModalService} from '../modal.service'
import {DataService} from '../../services/data.service'
import * as moment from 'moment'

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss']
})
export class DevicesTableComponent implements OnInit {

  @Input() devices: Device[]
  @Input() canCreate: boolean
  @Input() canDelete: boolean
  @Output() onDeleted: EventEmitter<any> = new EventEmitter<any>()
  @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPending: EventEmitter<boolean> = new EventEmitter<boolean>()


  constructor(
    private modals: ModalService,
    private ds: DataService
  ) {
  }

  ngOnInit(): void {
  }

  async deleteDevice(device?: any) {
    const modalResult = await this.modals.showConfirm()
    if (!modalResult) return
    try {
      this.onPending.emit(true)
      await this.ds.removeDevice(device)
      device.active = false
      this.onDeleted.emit(device.id)
      this.onPending.emit(false)
    } catch (err) {
      console.error("Nope", err)
      this.onPending.emit(false)
    }
  }

  fromUnix(unix) {
    if (!unix) return '-'
    return moment.unix(+unix).format('DD/MM/YYYY hh:mm')
  }

  async toDeviceDetails(device: any) {
    if (!this.canCreate) return
    const result = await this.modals.show(DeviceDetailedModalComponent, {
      ignoreBackdropClick: true,
      initialState: {device, canDelete: this.canDelete}
    })
    if (!result) return
    this.onUpdated.emit(result)
  }

  async actionDropdown(action: string, d: Device) {
    if (action === 'delete') {
      await this.deleteDevice(d)
    } else if (action === 'edit') {
      await this.toDeviceDetails(d)
    }
  }
}
