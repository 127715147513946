import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-counter',
  templateUrl: './step-counter.component.html',
  styleUrls: ['./step-counter.component.scss']
})
export class StepCounterComponent implements OnInit {

  constructor() { }

  @Input() numOfSteps: number;

  ngOnInit(): void {
  }

}
