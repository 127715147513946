<div class="modal-header">
  <h5 class="modal-title pull-left">{{ prefix + 'edit-title' | translate }}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" (ngSubmit)="save()">
    <fieldset [disabled]="busy">
      <div class="row">

        <app-form-control class="col-md-6" *ngIf="typeOptions.length" [showInfo]="false" [prefix]="prefix + 'type'" [control]="form.get('type')"
                          type="select" [selectOptions]="typeOptions"></app-form-control>

        <app-form-control class="col-md-6" [showInfo]="true" [prefix]="prefix + 'lot'" [control]="form.get('lot')">
        </app-form-control>

        <div class="col-md-6">
          <div class="row">
            <app-form-control class="col mt-4" [showInfo]="false" [prefix]="prefix + 'received'"
                              [control]="form.get('received')" type="text"></app-form-control>
          </div>
          <div class="row">
            <app-form-control class="col" [showInfo]="false" [prefix]="prefix + 'origin'"
                              [control]="form.get('origin')" type="text"></app-form-control>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <p class="col-md-12 label-header mb-0">{{prefix + 'device-control' | translate}}</p>
          </div>
          <div class="row">
            <app-form-control class="col" [showInfo]="false" [prefix]="prefix + 'min-range'"
                              [control]="form.get('range').get('minRange')" type="number"></app-form-control>
          </div>

          <div class="row">
            <app-form-control class="col" [showInfo]="false" [prefix]="prefix + 'max-range'"
                              [control]="form.get('range').get('maxRange')" type="number"></app-form-control>
          </div>
          <ng-container *ngIf="form.get('range').errors && form.get('range').get('maxRange').touched">
            <small class="form-text text-danger"
                   *ngIf="form.get('range').errors?.invalidRange">{{ prefix + 'max-range.error-invalidRange' | translate }}</small>
          </ng-container>
        </div>
      </div>

      <div class="text-right">
        <button *ngIf="!newDevice && canDelete" type="button" class="btn btn-danger mr-2" (click)="remove()">
          <i class="fa fa-times fa-fw" aria-hidden="true"></i>
          {{ prefix + 'remove' | translate }}
        </button>
        <button type="submit" class="btn btn-primary" [ngClass]="{'btn-block': newDevice}">
          <i class="fa fa-check fa-fw" aria-hidden="true"></i>
          {{ prefix + 'edit-save' | translate }}
        </button>
      </div>

      <app-overlay-spinner *ngIf="busy"></app-overlay-spinner>
    </fieldset>
  </form>


</div>
