import { Injectable, EventEmitter } from '@angular/core';

import { Document, DocumentsPage } from '../../model/document';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';
import {User} from '../../model/user'

@Injectable({
  providedIn: 'root'
})
export class DocsService {

  processUpdates = new EventEmitter<ProcessStatus>();

  constructor(
    private api: ApiService,
    private toasts: ToastService,
  ) { }

  async getDocuments(page = 0): Promise<DocumentsPage> {
    const r = await this.api.get(`u/documents?page=${page}`);
    r.documents = r.documents.map(data => Document.deserialize(data));
    return r;
  }

  async remove(d: Document) {
    return await this.api.delete('u/document/' + d.id);
  }

  async download(d: Document) {
    const status = await this.api.downloadFile('u/document/' + d.fileReference);
    if (status === 404) {
      this.toasts.warning("docs.file-not-found-toast");
    } else if (status !== 200) {
      console.error("Download failed");
      this.toasts.warning("docs.unknown-download-error-toast");
    }
  }

  async upload(formData: any, user?: User): Promise<Document> {
    if (user) {
      formData.forUserUuid = user.uuid
    }

    const result = await this.api.post('u/document', formData);
    return Document.deserialize(result.document);
  }

  async migrateToS3() {
    const result: ProcessStatus = await this.api.post('admin/migrate-to-s3', {});
    this.processUpdates.emit(result);

    setTimeout(() => { this.checkStatus(result.token) }, 1000);

    return result;
  }

  async deleteUnused() {
    const result: ProcessStatus = await this.api.post('admin/delete-unused', {});
    this.processUpdates.emit(result);

    setTimeout(() => { this.checkStatus(result.token) }, 1000);

    return result;
  }

  private async checkStatus(token: string) {
    const response: ProcessStatus = await this.api.get(`admin/progress/${token}`);
    this.processUpdates.emit(response);
    if (response.status === "in-progress") {
      setTimeout(() => { this.checkStatus(token); }, 1000);
    }
  }

}

export interface ProcessStatus {
  name: string;
  status: "in-progress" | "completed" | "failed";
  buffer: string[];
  token: string;
}
