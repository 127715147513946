import { User } from './user';
import {ProductLine} from './Products'

export interface Organization {
  id: number;
  name: string;
  address: string;
  address2?: string;
  postalCode: string;
  country: string;
  phone?: string;
  code?: string;
  domains?: string[];
  users?: User[];
  ownerUuid: string
  active: boolean
  productLines?: ProductLine[]
  status?: string
  permission?: string
  hierarchyId?: number
  selected?: boolean
  permissions: OrganizationPermission
  receiving?: string
  sharing?: string
  sharingUsers?: {id: number, organizationId: number, userId: number, sharing: boolean}[]
  receivingUsers?: {id: number, organizationId: number, userId: number, sharing: boolean}[]
  isNew?: boolean
}

export interface OrganizationPage {
  q?: string;
  page: number;
  lastPage: number;
  organizations: Organization[];
}

export interface OrganizationPermission {
  id: number;
  organizationId: number;
  sharing: boolean;
  receiving: boolean;
}
