import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { TrainingAttempt } from '../../../../model/TrainingAttempt';

@Component({
  selector: 'app-user-training-summary',
  templateUrl: './user-training-summary.component.html',
  styleUrls: ['./user-training-summary.component.scss']
})
export class UserTrainingSummaryComponent implements OnInit, OnChanges {
  prefix = "training-summary.";

  private _attempts: TrainingAttempt[] = [];
  @Input() set attempts( attempts: TrainingAttempt[]){
    this._attempts = [];
    if (!attempts || attempts.length === 0) return;

    //filter attempts
    const uniqueIds = [];
    for (let i = attempts.length -1; i >= 0; i--) {
      const a = attempts[i];
      if (uniqueIds.indexOf(a.userUuid) > -1) continue;


      uniqueIds.push(a.userUuid);
      this._attempts.unshift(a);
    }

  }

  get attempts() {
    return this._attempts;
  }


  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {


  }

}
