import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'
import {PopoverDirective} from 'ngx-bootstrap/popover'

import {OnboardingService} from '../../services/onboarding.service'

@Component({
  selector: 'app-onboarding-message',
  templateUrl: './onboarding-message.component.html',
  styleUrls: ['./onboarding-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnboardingMessageComponent implements OnInit, OnDestroy {
  @Input() name = 'not-named'
  @ViewChild('popover') popover: PopoverDirective
  @Input() order = 20
  @Input() text = 'onboarding.default-text'
  @Input() placement = "right"
  @Input() okButton = 'onboarding.ok-button'
  @Input() params: any
  @Input() addClass: string = 'd-inline-block'
  @Input() widthPX


  disableTips: boolean

  constructor(
    private os: OnboardingService,
  ) {
  }

  ngOnInit() {
    this.os.register(this)
  }

  ngOnDestroy() {
    if (this.popover) this.popover.hide()
    this.os.unregister(this)
  }

  show(): Promise<OnboardResult> {
    this.disableTips = this.os.disableTips
    return new Promise((resolve, reject) => {
      if (!this.popover) return resolve({disableTips: this.disableTips})

      const sub = this.popover.onHidden.subscribe(() => {
        sub.unsubscribe()
        resolve({disableTips: this.disableTips})
      })

      if (!this.disableTips) {
        this.popover.show()

      }
    })
  }

  done() {
    this.os.markShown(this.name, this.disableTips)
    this.popover.hide()
  }

}

export interface OnboardResult {
  disableTips?: boolean;
}
