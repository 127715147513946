export class BackoffInterval {
  // Random number within a factor of fuzzyBase of baseInterval
  private fuzzyBase = .2;
  private baseInterval = 500; //ms
  private delayFactor = 2;
  private maxDelay = 10;
  private maxRetries = Infinity;
  private keepGoing = false;
  private onStart = true;

  //
  private retries = 0;
  private delay = 1;
  private counter = 0;
  private started = false;

  private callback: Function;
  private successCallback: Function;

  private intervalId: any;

  constructor(options?: BackoffOptions) {
    if (options) Object.assign(this, options);

    //Randomly increase/decrease the base interval.
    const fuzz = this.baseInterval * this.fuzzyBase;
    const rand = Math.floor(Math.random() * (fuzz * 2)) - fuzz;
    this.baseInterval += rand;
  }

  async set(callback: Function, success?: Function) {
    if (this.started) throw "Instance cannot be reused. Instantiate a new object instead.";

    this.started = true;

    this.callback = callback;
    if (success) this.successCallback = success;

    if (this.onStart) await this.tryCallback();

    this.intervalId = setInterval(() => {
      this.counter++;

      //Wait for the delay
      if (this.counter < this.delay) return;

      if (this.retries > this.maxRetries) {
        //Error out.
        this.done(false);
        return;
      }
      //We should fire and reset counter
      this.counter = 0;
      this.retries++;

      this.tryCallback();

    }, this.baseInterval);
  }

  stop() {
    this.done(false);
  }

  private async tryCallback(): Promise<any> {
    try {
      const result = await this.callback();
      this.counter = 0;
      this.delay = 1;
      this.retries = 0;

      return result;
    } catch (err) {
      //Increase delay
      this.delay *= this.delayFactor;
      if (this.delay > this.maxDelay) this.delay = this.maxDelay;
      return undefined;
    }
  }

  private done(success = true) {
    if (!this.keepGoing && this.intervalId) clearInterval(this.intervalId);
    if (this.successCallback) this.successCallback(success);
    this.retries = 0;
    this.counter = 0;
    this.delay = 1;
  }

  static setInterval(callback: Function, success?: Function): BackoffInterval {
    const i = new BackoffInterval();
    i.set(callback, success);
    return i;
  }

}

export interface BackoffOptions {
  fuzzyBase?: number,
  baseInterval?: number,
  maxDelayFactor?: number,
  maxRetries?: number,
  delayFactor?: number,
  keepGoing?: boolean,
  onStart?: boolean,
}