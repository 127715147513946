<accordion [isAnimated]="true">
  <accordion-group [isOpen]="!productLine.training" [panelClass]="'accordion'" #categoryGroup>
    <div class="header position-relative" accordion-heading>
      <div *ngIf="!showConfirmDelete" class="inline-container">
        <app-inline-input class="w-100" position="left" [header]="true" [(value)]="productLine.name"
                          (onSubmit)="saveProductLine($event)"
                          [editIfEmpty]="true"
                          placeholder="{{ 'product-modal.line-placeholder' | translate }}"></app-inline-input>
      </div>
      <!--      <span class="badge badge-light" *ngIf="productLine.training">{{ 'product-modal.training' | translate }}</span>-->

      <div class="delete-box" *ngIf="productLine.products.length === 0 && canDeleteProductLines">
        <button *ngIf="!showConfirmDelete" (click)="showConfirmDelete = true"
                class="delete-button">
          <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.4219 1.875H8.84375V1.40625C8.84375 0.63085 8.2129 0 7.4375 0H5.5625C4.7871 0 4.15625 0.63085 4.15625 1.40625V1.875H1.57812C0.931953 1.875 0.40625 2.4007 0.40625 3.04688V4.6875C0.40625 4.94637 0.616133 5.15625 0.875 5.15625H1.13117L1.53614 13.6606C1.57191 14.4117 2.18891 15 2.94078 15H10.0592C10.8111 15 11.4281 14.4117 11.4639 13.6606L11.8688 5.15625H12.125C12.3839 5.15625 12.5938 4.94637 12.5938 4.6875V3.04688C12.5938 2.4007 12.068 1.875 11.4219 1.875ZM5.09375 1.40625C5.09375 1.14779 5.30404 0.9375 5.5625 0.9375H7.4375C7.69596 0.9375 7.90625 1.14779 7.90625 1.40625V1.875H5.09375V1.40625ZM1.34375 3.04688C1.34375 2.91765 1.4489 2.8125 1.57812 2.8125H11.4219C11.5511 2.8125 11.6562 2.91765 11.6562 3.04688V4.21875C11.5118 4.21875 1.94237 4.21875 1.34375 4.21875V3.04688ZM10.5274 13.616C10.5155 13.8664 10.3098 14.0625 10.0592 14.0625H2.94078C2.69015 14.0625 2.48448 13.8664 2.47259 13.616L2.06973 5.15625H10.9303L10.5274 13.616Z"
              fill="#BDBDBD"/>
            <path
              d="M6.5 13.125C6.75887 13.125 6.96875 12.9151 6.96875 12.6562V6.5625C6.96875 6.30363 6.75887 6.09375 6.5 6.09375C6.24113 6.09375 6.03125 6.30363 6.03125 6.5625V12.6562C6.03125 12.9151 6.2411 13.125 6.5 13.125Z"
              fill="#BDBDBD"/>
            <path
              d="M8.84375 13.125C9.10262 13.125 9.3125 12.9151 9.3125 12.6562V6.5625C9.3125 6.30363 9.10262 6.09375 8.84375 6.09375C8.58488 6.09375 8.375 6.30363 8.375 6.5625V12.6562C8.375 12.9151 8.58485 13.125 8.84375 13.125Z"
              fill="#BDBDBD"/>
            <path
              d="M4.15625 13.125C4.41512 13.125 4.625 12.9151 4.625 12.6562V6.5625C4.625 6.30363 4.41512 6.09375 4.15625 6.09375C3.89738 6.09375 3.6875 6.30363 3.6875 6.5625V12.6562C3.6875 12.9151 3.89735 13.125 4.15625 13.125Z"
              fill="#BDBDBD"/>
          </svg>
        </button>

        <span class="confirm-box" *ngIf="showConfirmDelete">
        {{ 'delete-box.are-you-sure' | translate }}
          <button (click)="delete()" class="text-danger btn btn-sm btn-link">
          <i class="fa fa-check"></i> {{ 'delete-box.yes' | translate }}
        </button>
        <button (click)="showConfirmDelete = false" class="text-normal btn btn-sm btn-link">
          <i class="fa fa-times"></i> {{ 'delete-box.no' | translate }}
        </button>
      </span>
      </div>
      <div class="arrow-container">
        <span [ngClass]="{opened: categoryGroup.isOpen}" class="arrow"></span>
      </div>
    </div>

    <ul class="list-group list-group-flush">
      <li *ngFor="let p of productLine.products" class="list-group-item border-0">
        <app-product-row [canDeleteProducts]="canDeleteProducts" [canDeleteProductLines]="canDeleteProductLines"
                         (stateChange)="stateChange.emit($event)" [product]="p"></app-product-row>
      </li>
      <li class="list-group-item list-button border-0 add-product-container" (click)="addProduct(productLine)"
          *ngIf="productLine.id">

        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.0822 7H1.2168M7.64949 1V13V1Z" stroke="#008C96" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <p class="add-product">{{ 'product-modal.add-product-button' |  translate }}</p>
      </li>
    </ul>
  </accordion-group>
</accordion>

<!--<div class="content">-->
<!--  <div class="header">-->
<!--    <div *ngIf="!showConfirmDelete" class="inline-container">-->
<!--      <app-inline-input class="w-100" position="left" [header]="true" [(value)]="productLine.name"-->
<!--                        (onSubmit)="saveProductLine($event)"-->
<!--                        [editIfEmpty]="true"-->
<!--                        placeholder="{{ 'product-modal.line-placeholder' | translate }}"></app-inline-input>-->
<!--    </div>-->
<!--    &lt;!&ndash;      <span class="badge badge-light" *ngIf="productLine.training">{{ 'product-modal.training' | translate }}</span>&ndash;&gt;-->

<!--    <div class="delete-box" *ngIf="productLine.products.length === 0 && canDeleteProductLines">-->
<!--      <button *ngIf="!showConfirmDelete" (click)="showConfirmDelete = true"-->
<!--              class="delete-button">-->
<!--        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--            d="M11.4219 1.875H8.84375V1.40625C8.84375 0.63085 8.2129 0 7.4375 0H5.5625C4.7871 0 4.15625 0.63085 4.15625 1.40625V1.875H1.57812C0.931953 1.875 0.40625 2.4007 0.40625 3.04688V4.6875C0.40625 4.94637 0.616133 5.15625 0.875 5.15625H1.13117L1.53614 13.6606C1.57191 14.4117 2.18891 15 2.94078 15H10.0592C10.8111 15 11.4281 14.4117 11.4639 13.6606L11.8688 5.15625H12.125C12.3839 5.15625 12.5938 4.94637 12.5938 4.6875V3.04688C12.5938 2.4007 12.068 1.875 11.4219 1.875ZM5.09375 1.40625C5.09375 1.14779 5.30404 0.9375 5.5625 0.9375H7.4375C7.69596 0.9375 7.90625 1.14779 7.90625 1.40625V1.875H5.09375V1.40625ZM1.34375 3.04688C1.34375 2.91765 1.4489 2.8125 1.57812 2.8125H11.4219C11.5511 2.8125 11.6562 2.91765 11.6562 3.04688V4.21875C11.5118 4.21875 1.94237 4.21875 1.34375 4.21875V3.04688ZM10.5274 13.616C10.5155 13.8664 10.3098 14.0625 10.0592 14.0625H2.94078C2.69015 14.0625 2.48448 13.8664 2.47259 13.616L2.06973 5.15625H10.9303L10.5274 13.616Z"-->
<!--            fill="#BDBDBD"/>-->
<!--          <path-->
<!--            d="M6.5 13.125C6.75887 13.125 6.96875 12.9151 6.96875 12.6562V6.5625C6.96875 6.30363 6.75887 6.09375 6.5 6.09375C6.24113 6.09375 6.03125 6.30363 6.03125 6.5625V12.6562C6.03125 12.9151 6.2411 13.125 6.5 13.125Z"-->
<!--            fill="#BDBDBD"/>-->
<!--          <path-->
<!--            d="M8.84375 13.125C9.10262 13.125 9.3125 12.9151 9.3125 12.6562V6.5625C9.3125 6.30363 9.10262 6.09375 8.84375 6.09375C8.58488 6.09375 8.375 6.30363 8.375 6.5625V12.6562C8.375 12.9151 8.58485 13.125 8.84375 13.125Z"-->
<!--            fill="#BDBDBD"/>-->
<!--          <path-->
<!--            d="M4.15625 13.125C4.41512 13.125 4.625 12.9151 4.625 12.6562V6.5625C4.625 6.30363 4.41512 6.09375 4.15625 6.09375C3.89738 6.09375 3.6875 6.30363 3.6875 6.5625V12.6562C3.6875 12.9151 3.89735 13.125 4.15625 13.125Z"-->
<!--            fill="#BDBDBD"/>-->
<!--        </svg>-->
<!--      </button>-->

<!--      <span class="confirm-box" *ngIf="showConfirmDelete">-->
<!--        {{ 'delete-box.are-you-sure' | translate }}-->
<!--        <button (click)="delete()" class="text-danger btn btn-sm btn-link">-->
<!--          <i class="fa fa-check"></i> {{ 'delete-box.yes' | translate }}-->
<!--        </button>-->
<!--        <button (click)="showConfirmDelete = false" class="text-normal btn btn-sm btn-link">-->
<!--          <i class="fa fa-times"></i> {{ 'delete-box.no' | translate }}-->
<!--        </button>-->
<!--      </span>-->
<!--    </div>-->

<!--  </div>-->
<!--  <ul class="list-group list-group-flush">-->
<!--    <li *ngFor="let p of productLine.products" class="list-group-item border-0">-->
<!--      <app-product-row [canDeleteProducts]="canDeleteProducts" [canDeleteProductLines]="canDeleteProductLines"-->
<!--                       (stateChange)="stateChange.emit($event)" [product]="p"></app-product-row>-->
<!--    </li>-->
<!--    <li class="list-group-item list-button border-0 add-product-container" (click)="addProduct(productLine)" *ngIf="productLine.id">-->

<!--      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M14.0822 7H1.2168M7.64949 1V13V1Z" stroke="#008C96" stroke-width="2" stroke-linecap="round"-->
<!--              stroke-linejoin="round"/>-->
<!--      </svg>-->
<!--      <p class="add-product">{{ 'product-modal.add-product-button' |  translate }}</p>-->
<!--    </li>-->
<!--  </ul>-->
<!--</div>-->
