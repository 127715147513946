import { Injectable } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent, ConfirmModalOptions } from './confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalStack: BsModalRef[] = [];
  private resolveStack: Function[] = [];

  constructor(
    private bsModalService: BsModalService,
  ) { 
    this.bsModalService.onHide.subscribe(() => {

      if(this.modalStack.length === 0) {
        throw "Empty Modal stack!";
      }

      const resolve = this.resolveStack.pop();
      const modal = this.modalStack.pop();
      resolve(modal.content.modalResult);

      //Set top modal to not-busy
      if (this.modalStack.length > 0){
        const m = this.modalStack[this.modalStack.length -1];
        m.content.busy = false;
      }
      
    });
  }

  show(content: any, config?: ModalOptions): any {
    const p = new Promise((resolve, reject) => {

      //Set previous modal to 'busy' 
      if (this.modalStack.length > 0){
        const m = this.modalStack[this.modalStack.length -1];
        m.content.busy = true;
      }

      this.modalStack.push(this.bsModalService.show(content, config));
      this.resolveStack.push(resolve);
    });

    return p;
  }

  showConfirm(config?: ConfirmModalOptions): Promise<any> {
    return this.show(ConfirmModalComponent, { initialState: config });
  }
}
