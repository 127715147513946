import {Component, OnInit} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-hierarchy-permissions-modal',
  templateUrl: './hierarchy-permissions-modal.component.html',
  styleUrls: ['./hierarchy-permissions-modal.component.scss']
})
export class HierarchyPermissionsModalComponent implements OnInit {
  prefix = 'admin.hierarchy.hierarchy-permissions-modal.'
  modalResult
  permission
  constructor(
    public modal: BsModalRef,
  ) {
  }

  ngOnInit(): void {
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.permission = 'upload'
    } else {
      this.permission = 'readonly'
    }
  }

  save() {
    this.modalResult = this.permission
    this.modal.hide()
  }
}
