import {Component, Input, OnInit} from '@angular/core'
import {Document, DocumentsPage} from '../../../model/document'
import {DocsService} from '../../services/docs.service'
import {ModalService} from '../modal.service'

@Component({
  selector: 'app-docs-mobile-table',
  templateUrl: './docs-mobile-table.component.html',
  styleUrls: ['./docs-mobile-table.component.scss']
})
export class DocsMobileTableComponent implements OnInit {

  @Input() page: DocumentsPage
  @Input() filters: [] = [];
  prefix = 'docs.mobile-table.'
  selectedDocuments = []

  constructor(
    private ds: DocsService,
    private modals: ModalService
  ) {
  }

  ngOnInit(): void {
  }


  formatBytes(bytes) {
    if (!bytes) return 'Unknown size'

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    let l = 0, n = parseInt(bytes, 10) || 0

    while (n >= 1024 && ++l) {
      n = n / 1024
    }

    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + sizes[l])
  }

  async download(d) {
    await this.ds.download(d)
  }

  async remove(d: Document) {
    const confirmed = await this.modals.showConfirm({
      title: "docs.delete-confirm.title",
      content: "docs.delete-confirm.content",
      params: d
    })
    if (!confirmed) return
    await this.ds.remove(d)
    const index = this.page.documents.findIndex(doc => doc === d)
    if (index > -1) this.page.documents.splice(index, 1)
  }

  selectAllDocuments(event) {
    const checkbox = event.target
    const documentCheckboxes = document.querySelectorAll('.document-checkbox')
    documentCheckboxes.forEach((cb: HTMLInputElement) => {
      cb.checked = checkbox.checked
    })

    if (checkbox.checked) {
      this.selectedDocuments = this.page.documents.concat()
    } else {
      this.selectedDocuments = []
    }
  }

  shouldShow(d) {
    const keys = Object.keys(this.filters);
    let show = true;
    keys.map((k) => {
      if (show === true) {
        const filter = this.filters[k];
        if (filter.attr === 'documentType') {
          show = d.documentType === filter.val;
        } else if (filter.attr === 'author') {
          show = d.author.getName() === filter.val;
        } else if (filter.attr === 'date') {
          const created = d.attachment.created.valueOf()
          const from = filter.val.from.setHours(0, 0, 0, 0)
          const to = filter.val.to.setHours(23, 59, 59)
          show = (from <= created && created <= to);
        }
      }
    });
    return show;
  }

  selectDocument(event, doc) {
    console.log('this.selectedDocuments', this.selectedDocuments)
    const checkbox = event.target
    if (checkbox.checked) {
      this.selectedDocuments.push(doc)
    } else {
      const index = this.selectedDocuments.findIndex(d => doc === d);
      if (index > -1) this.selectedDocuments.splice(index, 1);
    }
  }

  deleteSelected() {
    this.selectedDocuments.forEach(doc => {
      this.page.documents = this.page.documents.filter(d => d.id !== doc.id)
      this.selectedDocuments = []
    })
  }
}
