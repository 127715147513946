<div class="organization expanded" [ngClass]="{'first': isFirst}">
  <div class="organization__head">
    <div class="organization__head__name-container">
      <p class="organization__head__name-container__name">{{organization.name}}</p>
    </div>


    <div class="organization__head__controls">


      <app-buttons
        class="organization__head__delete-btn"
        type="invisible-icon"
        width="auto"
        height="auto"
        (onClick)="onDeleteClicked.emit(organization.value)"
      >
        <img src="/assets/icons/delete.svg">
      </app-buttons>
    </div>
  </div>

  <div class="organization__body expanded">
    <div class="organization__head__controls__permissions-container">
      <app-multi-select-dropdown
        #usersSelect
        class="organization__body__dropdown"
        [items]="organization.sharingUsers"
        [label]="prefix + 'users-select' | translate"
        (selectionChange)="itemSelected($event, organization.value)"
        emptyType="user"
        type="list"
        subLabel="true"
      >
      </app-multi-select-dropdown>
      <div class="organization__head__controls__sharing-container">
        <p class="organization__head__controls__sharing__label">{{prefix + 'sharing' | translate}}</p>
        <div class="radio-btn">
          <input
            type="radio"
            [id]="organization.value + 'sharing-general'"
            [name]="organization.value + 'sharing'"
            [checked]="organization.sharing === 'general'"
            (change)="changeSharing('general')">
          <label [for]="organization.value + 'sharing-general'">{{prefix + 'radio-general' | translate}}</label>
        </div>
        <div class="radio-btn">
          <input
            type="radio"
            [id]="organization.value + 'sharing-custom'"
            [name]="organization.value + 'sharing'"
            [checked]="organization.sharing === 'custom'"
            (change)="changeSharing('custom')">
          <label [for]="organization.value + 'sharing-custom'">{{prefix + 'radio-custom' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="organization__head__controls__permissions-container">
      <app-multi-select-dropdown
        #organizationsSelect
        class="organization__body__dropdown"
        [label]="prefix + 'organization-select' | translate"
        [items]="organization.receivingOrganizations"
        emptyType="user"
        type="group"
        (selectionChange)="groupSelected($event, organization.value)"
        (selectionGroupChange)="groupSelected($event, organization.value)"
      >
      </app-multi-select-dropdown>

      <div class="organization__head__controls__receiving">
        <p class="organization__head__controls__receiving__label">{{prefix + 'receiving' | translate}}</p>
        <div class="radio-btn">
          <input
            type="radio"
            [id]="organization.value + 'receiving-general'"
            [name]="organization.value + 'receiving'"
            [checked]="organization.receiving === 'general'"
            (change)="changeReceiving('general')">
          <label [for]="organization.value + 'receiving-general'">{{prefix + 'radio-general' | translate}}</label>
        </div>
        <div class="radio-btn">
          <input
            type="radio"
            [id]="organization.value + 'receiving-custom'"
            [name]="organization.value + 'receiving'"
            [checked]="organization.receiving === 'custom'"
            (change)="changeReceiving('custom')">
          <label [for]="organization.value + 'receiving-custom'">{{prefix + 'radio-custom' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
