import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private _sub: Subscription;
  prefix = 'login.'

  loading = false;
  credentialsInvalid = false;

  form: FormGroup;
  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public toasts: ToastService,
    private store: StoreService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    const email = this.store.get("expiredEmail", "");

    this.form = new FormGroup({
      email: new FormControl(email, [Validators.required, this.emailValidator]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    this._sub = this.userService.userChanged.subscribe((u) => {
      if (!u) return;
      const fromUrl = this.route.snapshot.queryParams.fromUrl;
      if (fromUrl) {
        console.log(fromUrl);
        this.router.navigateByUrl(fromUrl);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/);
      return matches ? null : {invalidEmail: true};
    } else {
      return null;
    }
  }

  register() {
    this.router.navigate(['/register']);
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }
  }

  async login() {
    this.credentialsInvalid = false;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    try {
      const r = await this.userService.login(this.form.value);
      console.log(r)
    } catch (e) {
      if (e.error && e.error.reason.toLowerCase() === "credentials invalid") {
        this.credentialsInvalid = true;
      }
    }

    this.loading = false;
  }

}
