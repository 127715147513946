import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {BsModalRef} from 'ngx-bootstrap/modal'
import {AdminService} from '../../pages-authenticated/admin/admin.service'
import {Organization} from '../../../model/organization'
import {User} from '../../../model/user'

@Component({
  selector: 'app-create-hierarchy-modal-parent',
  templateUrl: './create-hierarchy-modal-parent.component.html',
  styleUrls: ['./create-hierarchy-modal-parent.component.scss']
})
export class CreateHierarchyModalParentComponent implements OnInit {

  prefix = 'admin.hierarchy.create-hierarchy-modal.'
  form: FormGroup
  organizations: Organization[]
  searchedOrganizations: Organization[]
  selectedOrganizations: Organization[] = []
  modalResult
  q: string
  organisationAllUsers = [];
  organisationPageUsers;
  start = 0;
  end = 10;
  page = 0;
  lastPage;

  // permissionOptions = [
  //   'all',
  //   'canUploadInstead',
  //   'canViewInstead'
  // ]

  updatedUsers: User[] = []

  constructor(
    public modal: BsModalRef,
    private as: AdminService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      search: new FormControl('')
    })
  }


  async ngOnInit() {
    this.organizations = await this.as.getHierarchyOrgsList()
    this.organizations = this.organizations.map(o => {
      o.selected = false
      o.permission = 'readonly'
      o.status = 'parent'
      if (o.users.length) {
        o.users.map(u => {
          u.selected = false
          u.hierarchyPermissions = ['all']
          return u
        })
      }
      return o
    })
  }

  onCheckboxChanged(e, idx) {
    if (this.searchedOrganizations) {
      this.searchedOrganizations[idx].selected = e.target.checked;
      this.organisationAllUsers = this.searchedOrganizations[idx].users;
      this.organisationPageUsers = this.organisationAllUsers.slice(this.start, this.end);
      this.lastPage = Math.ceil(this.organisationAllUsers.length / 10);
    } else {
      this.organizations[idx].selected = e.target.checked;
      this.organisationAllUsers = this.organizations[idx].users;
      this.organisationPageUsers = this.organisationAllUsers.slice(this.start, this.end);
      this.lastPage = Math.ceil(this.organisationAllUsers.length / 10);
    }
    if (e.target.checked) {
      this.selectedOrganizations.push(this.searchedOrganizations ? this.searchedOrganizations[idx] : this.organizations[idx])
    } else {
      this.selectedOrganizations = this.selectedOrganizations.filter(o => o.id !== +e.target.value)
    }
  }

  onAdminChanged(e, orgId, uuid) {
    const orgIdx = this.organizations.findIndex(o => o.id === orgId)
    const userIdx = this.organizations[orgIdx].users.findIndex(u => u.uuid === uuid)
    if (e.target.checked) {
      this.organizations[orgIdx].users[userIdx].selected = true
      this.updatedUsers.push(this.organizations[orgIdx].users[userIdx])
    } else {
      this.organizations[orgIdx].users[userIdx].selected = false
      this.updatedUsers = this.updatedUsers.filter(u => u.uuid !== this.organizations[orgIdx].users[userIdx].uuid)
    }
  }

  next() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return
    this.modalResult = {
      organizations: this.selectedOrganizations,
      name: this.form.get('name').value,
      users: this.updatedUsers
    }
    console.log('this.modalResult', this.modalResult)
    this.modal.hide()
  }

  search() {
    if (!this.form.get('search').value) {
      this.ngOnInit();
    }
    this.q = this.form.get('search').value;
    this.searchedOrganizations = this.organizations.filter(o => {
      if (o.name.toLocaleLowerCase().indexOf(this.q.toLocaleLowerCase()) > -1) {
      o.selected = false
      o.permission = 'readonly'
      o.status = 'parent'
      if (o.users.length) {
        o.users.map(u => {
          u.selected = false
          u.hierarchyPermissions = ['all']
          return u
        })
        return o
      }
    }
    })
  }

  // onPermissionsChanged(e, orgId, uuid, permission) {
  //   const orgIdx = this.organizations.findIndex(o => o.id === orgId)
  //   const userIdx = this.organizations[orgIdx].users.findIndex(u => u.uuid === uuid)
  //   if (e.target.checked) {
  //     const permissions = this.organizations[orgIdx].users[userIdx].permissions.concat(permission)
  //     if (permission === 'all') {
  //       this.organizations[orgIdx].users[userIdx].permissions = ['all']
  //     } else if (permissions.includes(this.permissionOptions[1]) && permissions.includes(this.permissionOptions[2])) {
  //       this.organizations[orgIdx].users[userIdx].permissions = ['all']
  //       e.target.checked = false
  //     } else if (
  //       permissions.includes(this.permissionOptions[0]) &&
  //       permissions.includes(this.permissionOptions[1]) || permissions.includes(this.permissionOptions[2])
  //     ) {
  //       this.organizations[orgIdx].users[userIdx].permissions = this.organizations[orgIdx].users[userIdx].permissions
  //         .filter(p => p !== 'all')
  //       this.organizations[orgIdx].users[userIdx].permissions.push(permission)
  //     } else {
  //       this.organizations[orgIdx].users[userIdx].permissions.push(permission)
  //     }
  //   } else {
  //     if (permission === 'all') {
  //       this.organizations[orgIdx].users[userIdx].permissions = []
  //     } else if (permission !== 'all' && this.organizations[orgIdx].users[userIdx].permissions.includes('all')) {
  //       this.organizations[orgIdx].users[userIdx].permissions = this.organizations[orgIdx].users[userIdx].permissions
  //         .filter(p => p !== 'all').filter(p => p !== permission)
  //       this.organizations[orgIdx].users[userIdx].permissions = this.permissionOptions
  //         .filter(p => p !== 'all').filter(p => p !== permission)
  //     } else {
  //       this.organizations[orgIdx].users[userIdx].permissions = this.organizations[orgIdx].users[userIdx].permissions
  //         .filter(p => p !== permission)
  //     }
  //   }
  //   console.log(this.organizations[orgIdx].users[userIdx].permissions)
  // }

  permissionsChanged(user: User, orgId) {
    const orgIdx = this.organizations.findIndex(o => o.id === orgId)
    const userIdx = this.organizations[orgIdx].users.findIndex(u => u.uuid === user.uuid)
    this.organizations[orgIdx].users[userIdx] = user

    const userExist = this.updatedUsers.find(u => u.uuid === user.uuid)
    if (userExist) {
      const idx = this.updatedUsers.findIndex(u => u.uuid === userExist.uuid)
      this.updatedUsers[idx] = user
    } else {
      this.updatedUsers.push(user)
    }
  }

  changePage(page, idx) {
    this.organisationPageUsers = this.organisationAllUsers.slice((this.start + 10) * page, (this.end * page) + 10);
  }
}
