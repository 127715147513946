import {Component, EventEmitter, OnInit, Output, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core'
import {FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms'

import {UserService} from 'src/app/services/user.service'
import {countryList} from 'src/model/countries'
import {fade} from 'src/model/shared-animations'
import {User} from 'src/model/user'
import {Router} from '@angular/router'


@Component({
  selector: 'app-register-organization',
  templateUrl: './register-organization.component.html',
  styleUrls: ['./register-organization.component.scss'],
  animations: [fade]
})
export class RegisterOrganizationComponent implements OnInit{
  triedSubmit = false
  loading = false
  form: FormGroup
  countries = countryList
  prefix = 'register.org.'
  @Output() onRegistered = new EventEmitter<any>()
  @Output() goToUser = new EventEmitter<any>()
  @Input() user: User
  @ViewChild('addressInput') addressInput: ElementRef
  @ViewChild('addressInput2') addressInput2: ElementRef

  get name() {
    return this.form.get('name')
  }

  get address() {
    return this.form.get('address')
  }

  get address2() {
    return this.form.get('address2')
  }

  get country() {
    return this.form.get('country')
  }

  get orgCode() {
    return this.form.get('orgCode')
  }

  get postalCode() {
    return this.form.get('postalCode')
  }

  get phone() {
    return this.form.get('phone')
  }

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, this.nameValidator]),
      address: new FormControl('', [Validators.required, this.nameValidator]),
      address2: new FormControl(''),
      phone: new FormControl('', Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)),
      country: new FormControl('', [Validators.required, this.countryValidator]),
      postalCode: new FormControl(''),
    })

    // !this.user && this.form.disable()
  }



  public goBack() {
    this.goToUser.emit()
  }

  private countryValidator(c: AbstractControl) {
    const v = c.value
    if (!v) return null

    const countries = countryList
    if (countries.indexOf(v) > -1) return null

    return {invalidCountry: true}
  }

  private nameValidator(c: AbstractControl) {
    const v = c.value
    if (!/\S/.test(v)) {
      return {invalidName: true}
    }
  }

  private addressValidator(c: AbstractControl) {
    const v = c.value
    if (!/\S/.test(v)) {
      return {invalidAddress: true}
    }
  }

  // async submit() {
  //   this.triedSubmit = true
  //   this.form.markAllAsTouched()
  //
  //   if (this.form.invalid) return
  //   this.loading = true
  //
  //   const org = this.form.value
  //   console.log("Submitting", org)
  //
  //   try {
  //     const result = await this.userService.registerOrg(this.user, org)
  //     this.onRegistered.emit(result)
  //     // this.userService.logout()
  //   } catch (err) {
  //     //todo deal with errors
  //     this.loading = false
  //     // const reason = res.error.reason || null;
  //     console.error(err)
  //   }
  //
  // }

  async submit() {
    this.triedSubmit = true
    this.form.markAllAsTouched()

    if (this.form.invalid) return
    this.loading = true

    const org = this.form.value
    org.address2 = org.address2.trim()
    org.postalCode = org.postalCode.trim()
    console.log("Submitting", org)

    this.onRegistered.emit(org)
  }
}
