import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  prefix = 'landing.'
  constructor(private userService: UserService, private router: Router) { }

  async ngOnInit() {
    const isLoggedIn = await this.userService.isLoggedIn()

    if (!isLoggedIn) {
      localStorage.clear()
    } else {
      this.router.navigate(['/introduction'])
    }
  }

}
